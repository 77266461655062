<template>
    <div class="news">

        <!-- 内页顶部banner -->
        <div class="public-nybn case-bn about-bn news-btn customer-bn">
            <!-- <img class="bgimg2" src="../assets/images/ys-83.png" alt=""> -->
            <!-- <img class="bgimg3" src="../assets/images/ys-82.png" alt=""> -->
            <div class="bgimg scaleDraw" v-if="banner.image">
                <img :src="host + banner.image" alt="">
            </div>
            <div class="zz-box">

                <div class="bn-title fadeInUp2">
                    <div class="container">
                        <div class="c-title">
                            <h2 class="h2 font36 text_b">{{ banner.title }}</h2>
                            <div class="syb"></div>
                        </div>
                        <div class="font40 text_b c-text pre"><span>{{ banner.text }}</span></div>

                    </div>
                </div>
            </div>
        </div>

        <div id="pageOffset"></div>
        <div class="customer1 pdtb">
            <div class="container">

                <!-- 搜索栏 -->
                <div class="public-searchNav public-searchNav2" v-show="screen1 != '' || screen2 != ''">
                    <div class="zz-left font16">
                        <div class="c-box" v-show="screen1 != ''">
                            <div class="c-div">
                                {{ screen1.title }}：
                            </div>
                            <ul class="c-ul">
                                <li class="c-li" :class="item.on == true ? 'on' : ''" v-for="(item, index) in screen1.tags"
                                    :key="index" @click="customerNav1Fn(item.tag, index)">
                                    {{ item.tag }}
                                </li>
                                <!-- <li class="c-li">
                                    消费品牌
                                </li> -->
                            </ul>
                        </div>
                        <div class="c-box" v-show="screen2 != ''">
                            <div class="c-div">
                                {{ screen2.title }}：
                            </div>
                            <ul class="c-ul">
                                <li class="c-li" :class="item.on == true ? 'on' : ''" v-for="(item, index) in screen2.tags"
                                    :key="index" @click="customerNav2Fn(item.tag, index)">
                                    {{ item.tag }}
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="zz-right">
                        <div class="inputBox">
                            <input @keyup.enter="searchFn()" type="text" placeholder="搜索..." class="font16" v-model="keyword">
                        </div>
                        <div class="inputBtn" @click="searchFn()">
                            <img src="../assets/images/ys-280.svg" alt="">
                        </div>
                    </div>
                </div>

                <!-- <div v-show="newsList == ''" class="font16" style="text-align: center;margin-top: 0.8rem;color: #666;">
                    没有相关信息
                </div> -->

                <div v-show="newsList==''" class="notInfoBox" style="margin-top: 1.2rem">
                    <img class="c-img" src="../assets/images/ys-401.png">
                    <span class="font16 c-span">暂无相关信息，敬请期待！</span>
                </div>

                <!-- 列表 -->
                <div class="customerList">
                    <ul class="c-ul">
                        <li class="c-li wow fadeInUp" v-for="(item, index) in newsList" :key="index">
                            <router-link :target="item.target" :to="'customerdetail?id=' + item.id" class="c-a">
                                <div class="c-div1">
                                    <img :src="host + item.cover" alt="">

                                </div>
                                <div class="c-box">
                                    <div class="box1">
                                        <div class="c-div4">
                                            <div class="c-icon">
                                                <img :src="host + item.logo" alt="">
                                            </div>
                                            <ul class="c-ulbox">
                                                <li v-if="item.tags" class="c-list font14">{{ item.tags }}</li>
                                                <li v-if="item.tags2" class="c-list font14">{{ item.tags2 }}</li>
                                            </ul>
                                        </div>
                                        <div class="c-div2">
                                            <h3 class="h3 font24 text_b">
                                                {{ item.name }}
                                            </h3>
                                            <div class="c-text font16 pre">{{ item.subName }}</div>
                                        </div>
                                    </div>
                                    <div class="c-div3">
                                        <p class="cn9">
                                            <span class="cn4 font16 font-b">查看详情</span>
                                            <span class="cn25"><img class="hi-ico" src="../assets/images/about-img5.svg"
                                                    onload="SVGInject(this)">
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </router-link>
                        </li>

                        <!-- <li class="c-li wow fadeInUp">
                            <router-link to="customerdetail" class="c-a">
                                <div class="c-div1">
                                    <img src="../assets/images/ys-288.png" alt="">
                                    
                                </div>
                                <div class="c-box">
                                    <div class="box1">
                                    <div class="c-div4">
                                        <div class="c-icon">
                                            <img src="../assets/images/ys-289.png" alt="">
                                        </div>
                                        <ul class="c-ulbox">
                                            <li class="c-list font14">数据云</li>
                                        </ul>
                                    </div>
                                    <div class="c-div2">
                                        <h3 class="h3 font24 text_b">
                                            孩子王
                                        </h3>
                                        <div class="c-text font16">
                                            5000万私域会员，贡献95%的销售额，揭秘孩子王以会员为中心的单客私域经济模型
                                        </div>
                                    </div>
                                    </div>
                                    <div class="c-div3">                                        
                                        <p class="cn9">
                                            <span class="cn4 font16 font-b">查看详情</span>
                                            <span class="cn25"><img class="hi-ico" src="../assets/images/about-img5.svg"
                                                    onload="SVGInject(this)">
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </router-link>
                        </li>

                        <li class="c-li wow fadeInUp">
                            <router-link to="customerdetail" class="c-a">
                                <div class="c-div1">
                                    <img src="../assets/images/ys-290.png" alt="">
                                    
                                </div>
                                <div class="c-box">
                                    <div class="box1">
                                    <div class="c-div4">
                                        <div class="c-icon">
                                            <img src="../assets/images/ys-291.png" alt="">
                                        </div>
                                        <ul class="c-ulbox">
                                            <li class="c-list font14">商超便利</li>
                                            <li class="c-list font14">数据云</li>
                                        </ul>
                                    </div>
                                    <div class="c-div2">
                                        <h3 class="h3 font24 text_b">
                                            云南白药
                                        </h3>
                                        <div class="c-text font16">
                                            8天GMV突破380W！可复制的海外购打法，值得抄作业！
                                        </div>
                                    </div>
                                    </div>

                                    <div class="c-div3">                                        
                                        <p class="cn9">
                                            <span class="cn4 font16 font-b">查看详情</span>
                                            <span class="cn25"><img class="hi-ico" src="../assets/images/about-img5.svg"
                                                    onload="SVGInject(this)">
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </router-link>
                        </li>

                        <li class="c-li wow fadeInUp">
                            <router-link to="customerdetail" class="c-a">
                                <div class="c-div1">
                                    <img src="../assets/images/ys-286.png" alt="">
                                    
                                </div>
                                <div class="c-box">
                                    <div class="box1">
                                    <div class="c-div4">
                                        <div class="c-icon">
                                            <img src="../assets/images/ys-287.png" alt="">
                                        </div>
                                        <ul class="c-ulbox">
                                            <li class="c-list font14">商超便利</li>
                                            <li class="c-list font14">数据云</li>
                                        </ul>
                                    </div>
                                    <div class="c-div2">
                                        <h3 class="h3 font24 text_b">
                                            华润苏果
                                        </h3>
                                        <div class="c-text font16">
                                            疫情下全年营收超200亿，数字化营销思维运营显成效！
                                        </div>
                                    </div>
                                    </div>
                                    <div class="c-div3">                                        
                                        <p class="cn9">
                                            <span class="cn4 font16 font-b">查看详情</span>
                                            <span class="cn25"><img class="hi-ico" src="../assets/images/about-img5.svg"
                                                    onload="SVGInject(this)">
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </router-link>
                        </li>

                        <li class="c-li wow fadeInUp">
                            <router-link to="customerdetail" class="c-a">
                                <div class="c-div1">
                                    <img src="../assets/images/ys-288.png" alt="">
                                    
                                </div>
                                <div class="c-box">
                                    <div class="box1">
                                    <div class="c-div4">
                                        <div class="c-icon">
                                            <img src="../assets/images/ys-289.png" alt="">
                                        </div>
                                        <ul class="c-ulbox">
                                            <li class="c-list font14">数据云</li>
                                        </ul>
                                    </div>
                                    <div class="c-div2">
                                        <h3 class="h3 font24 text_b">
                                            孩子王
                                        </h3>
                                        <div class="c-text font16">
                                            5000万私域会员，贡献95%的销售额，揭秘孩子王以会员为中心的单客私域经济模型
                                        </div>
                                    </div>
                                    </div>
                                    <div class="c-div3">                                        
                                        <p class="cn9">
                                            <span class="cn4 font16 font-b">查看详情</span>
                                            <span class="cn25"><img class="hi-ico" src="../assets/images/about-img5.svg"
                                                    onload="SVGInject(this)">
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </router-link>
                        </li>

                        <li class="c-li wow fadeInUp">
                            <router-link to="customerdetail" class="c-a">
                                <div class="c-div1">
                                    <img src="../assets/images/ys-290.png" alt="">
                                    
                                </div>
                                <div class="c-box">
                                    <div class="box1">
                                    <div class="c-div4">
                                        <div class="c-icon">
                                            <img src="../assets/images/ys-291.png" alt="">
                                        </div>
                                        <ul class="c-ulbox">
                                            <li class="c-list font14">商超便利</li>
                                            <li class="c-list font14">数据云</li>
                                        </ul>
                                    </div>
                                    <div class="c-div2">
                                        <h3 class="h3 font24 text_b">
                                            云南白药
                                        </h3>
                                        <div class="c-text font16">
                                            8天GMV突破380W！可复制的海外购打法，值得抄作业！
                                        </div>
                                    </div>
                                    </div>
                                    
                                    <div class="c-div3">                                        
                                        <p class="cn9">
                                            <span class="cn4 font16 font-b">查看详情</span>
                                            <span class="cn25"><img class="hi-ico" src="../assets/images/about-img5.svg"
                                                    onload="SVGInject(this)">
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </router-link>
                        </li>

                        <li class="c-li wow fadeInUp">
                            <router-link to="customerdetail" class="c-a">
                                <div class="c-div1">
                                    <img src="../assets/images/ys-286.png" alt="">
                                    
                                </div>
                                <div class="c-box">
                                    <div class="box1">
                                    <div class="c-div4">
                                        <div class="c-icon">
                                            <img src="../assets/images/ys-287.png" alt="">
                                        </div>
                                        <ul class="c-ulbox">
                                            <li class="c-list font14">商超便利</li>
                                            <li class="c-list font14">数据云</li>
                                        </ul>
                                    </div>
                                    <div class="c-div2">
                                        <h3 class="h3 font24 text_b">
                                            华润苏果
                                        </h3>
                                        <div class="c-text font16">
                                            疫情下全年营收超200亿，数字化营销思维运营显成效！
                                        </div>
                                    </div>
                                    </div>
                                    <div class="c-div3">                                        
                                        <p class="cn9">
                                            <span class="cn4 font16 font-b">查看详情</span>
                                            <span class="cn25"><img class="hi-ico" src="../assets/images/about-img5.svg"
                                                    onload="SVGInject(this)">
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </router-link>
                        </li>

                        <li class="c-li wow fadeInUp">
                            <router-link to="customerdetail" class="c-a">
                                <div class="c-div1">
                                    <img src="../assets/images/ys-288.png" alt="">
                                    
                                </div>
                                <div class="c-box">
                                    <div class="box1">
                                    <div class="c-div4">
                                        <div class="c-icon">
                                            <img src="../assets/images/ys-289.png" alt="">
                                        </div>
                                        <ul class="c-ulbox">
                                            <li class="c-list font14">数据云</li>
                                        </ul>
                                    </div>
                                    <div class="c-div2">
                                        <h3 class="h3 font24 text_b">
                                            孩子王
                                        </h3>
                                        <div class="c-text font16">
                                            5000万私域会员，贡献95%的销售额，揭秘孩子王以会员为中心的单客私域经济模型
                                        </div>
                                    </div>
                                    </div>
                                    <div class="c-div3">                                        
                                        <p class="cn9">
                                            <span class="cn4 font16 font-b">查看详情</span>
                                            <span class="cn25"><img class="hi-ico" src="../assets/images/about-img5.svg"
                                                    onload="SVGInject(this)">
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </router-link>
                        </li>

                        <li class="c-li wow fadeInUp">
                            <router-link to="customerdetail" class="c-a">
                                <div class="c-div1">
                                    <img src="../assets/images/ys-290.png" alt="">
                                    
                                </div>
                                <div class="c-box">
                                    <div class="box1">
                                    <div class="c-div4">
                                        <div class="c-icon">
                                            <img src="../assets/images/ys-291.png" alt="">
                                        </div>
                                        <ul class="c-ulbox">
                                            <li class="c-list font14">商超便利</li>
                                            <li class="c-list font14">数据云</li>
                                        </ul>
                                    </div>
                                    <div class="c-div2">
                                        <h3 class="h3 font24 text_b">
                                            云南白药
                                        </h3>
                                        <div class="c-text font16">
                                            8天GMV突破380W！可复制的海外购打法，值得抄作业！
                                        </div>
                                    </div>
                                    </div>
                                    
                                    <div class="c-div3">                                        
                                        <p class="cn9">
                                            <span class="cn4 font16 font-b">查看详情</span>
                                            <span class="cn25"><img class="hi-ico" src="../assets/images/about-img5.svg"
                                                    onload="SVGInject(this)">
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </router-link>
                        </li> -->


                    </ul>
                </div>

                <div class="public-page wow fadeInUp" v-show="newsList != ''">
                    <page-pagination :total="page.total" :pageSize="page.pageSize" :currentPage="page.currentPage"
                        :numAround="true" @change="change"></page-pagination>
                </div>

            </div>
        </div>







    </div>
</template>
  
<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

// 这是引用wow
// import { WOW } from 'wowjs'
// 引入swiper
import Swiper from 'swiper';
import $ from 'jquery';
import 'swiper/dist/css/swiper.min.css';
// import vueSeamless from 'vue-seamless-scroll'
// import countTo from 'vue-count-to';
import '../assets/css/style.css';
import pagePagination from "@/components/page-pagination/page-pagination.vue";
import hostUrl from '@/util/baseUrl'

export default {
    // name: "HomeView",
    components: {
        // vueSeamless,
        // countTo,
        pagePagination
    },
    metaInfo() {
        return {
            title: this.seoInfo.title, //设置页面title
            meta: [
                {                 //设置页面meta
                    name: 'keywords',
                    content: this.seoInfo.keyWords
                },
                {
                    name: "description",
                    content: this.seoInfo.description
                },
                {
                    name: "renderer",
                    content: 'webkit',
                },
                {
                    name: "force-rendering",
                    content: 'webkit',
                },
                {
                    name: "format-detection",
                    content: 'telephone=no,telephone=no,email=no,adress=no',
                },
                {
                    name: "viewport",
                    content: 'width=device-width,user-scalable=no,initial-scale=1.0,maximum-scale=1.0,minimum-scale=1.0',
                },
            ]
        }
    },
    data() {
        return {
            // activeName: 0,
            // itemLength: 0,
            // swpActive: 0,     
            // 屏幕宽度参数
            wW: '',
            // 二级导航TOp
            crumbsTop: '',
            crumbsFlag: false,
            fixHead: false,
            // swpNot: false, //禁止轮播滑动
            // 行业分类
            customerNav1: [
                {
                    on: true,
                    navTitle: '商超便利',
                    id: 0,
                    newsList: [
                        {

                        }
                    ],
                },
                {
                    on: false,
                    navTitle: '母婴连锁',
                    id: 1
                },
                {
                    on: false,
                    navTitle: '消费品牌',
                    id: 2
                },
            ],
            // 产品分类
            customerNav2: [
                {
                    on: true,
                    navTitle: '零售云',
                    id: 0,
                    newsList: [
                        {

                        }
                    ],
                },
                {
                    on: false,
                    navTitle: '数据云',
                    id: 1
                },
                {
                    on: false,
                    navTitle: 'PaaS平台',
                    id: 2
                },
            ],
            // 分页器
            page: {
                total: 0,
                pageSize: 6,
                currentPage: 1
            },
            // 接口数据
            host: hostUrl,
            id: 2,
            keyword: '',
            banner: [],
            screen1: [],
            screen2: [],
            newsList: [],
            tags: '',
            tags2: '',
            // seo信息
            seoInfo: {
                title: '',
                keyWords: '',
                description: ''
            },
            // 全局seo
            seoAllInfo: {},

        }
    },

    mounted() {
        // this.$bus.$on("headShow"); //二级导航浮动

        this.getData()

        this.wW = document.documentElement.clientWidth || document.body.clientWidth //获取浏览器宽度

        this.seoAllInfo = JSON.parse(localStorage.getItem('yuanshu_seoInfo')) //获取seo全局信息

        this.$nextTick(() => {
            // 元数简介轮播
            // this.wW = document.documentElement.clientWidth; //获取浏览器宽度
            // console.log(this.wW)

            // 轮播图pc端禁止滑动
            // if (this.wW > 770) {
            //     // $('.about1 .swiper-container').addClass('swiper-no-swiping')
            //     this.swpNot = true
            // }


        })


        // wowjs
        this.$nextTick(() => {
            // var wow = new WOW({
            //   boxClass: 'wow',
            //   animateClass: 'animated',
            //   // offset: 40,
            //   // mobile: true,
            //   live: false,
            // })
            // wow.init()
            // new WOW().init()
            new this.$wow.WOW({
                live: false,
            }).init()
        })

        window.addEventListener('scroll', this.handleScroll)

        // 百度统计代码
        var _hmt = _hmt || [];
        (function () {
            var hm = document.createElement("script");
            hm.src = "https://hm.baidu.com/hm.js?77637ce1a84bee069c1effb2174121bb";
            var s = document.getElementsByTagName("script")[0];
            s.parentNode.insertBefore(hm, s);
        })();

    },
    computed: {
        // defaultOption() {
        //   return {
        //     step: 0.8,
        //     hoverStop: true, // 是否开启鼠标悬停stop
        //     direction: 2, // 0向下 1向上 2向左 3向右
        //     openWatch: false, // 开启数据实时监控刷新dom
        //     // singleHeight: 40, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        //     // singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        //     // waitTime: 2000, // 单步运动停止的时间(默认值1000ms)
        //   }
        // }

    },
    destroyed() {
        // 离开页面时取消二级导航浮动
        // this.$bus.$off("headShow");
    },
    watch: {
        $route: {
            // 监听路由变化
            handler: function (val, oldVal) {
                this.getData()
            },
            deep: true
        }
    },
    methods: {
        // 请求后端接口
        getData() {
            this.keyword = ''
            this.banner = []
            this.screen1 = []
            this.screen2 = []
            this.newsList = []
            this.tags = ''
            this.tags2 = ''
            this.seoInfo = {
                title: '',
                keyWords: '',
                description: ''
            }

            let id = this.id

            // let id = this.$route.query.id
            this.tags = this.$route.query.tags
            this.tags2 = this.$route.query.tags2


            this.$axios.get('/api/page/' + id).then(res => {
                // seo信息
                if (res.data.data.name != '' && res.data.data.name != null && res.data.data.name != undefined) {
                    this.seoInfo.title = res.data.data.name
                }else{
                    this.seoInfo.title=this.seoAllInfo.title
                }

                if (res.data.data.seoKeyword != '' && res.data.data.seoKeyword != null && res.data.data.seoKeyword != undefined) {
                    this.seoInfo.keyWords = res.data.data.seoKeyword
                }else{
                    this.seoInfo.keyWords=this.seoAllInfo.keyWords
                }

                if (res.data.data.seoDesc != '' && res.data.data.seoDesc != null && res.data.data.seoDesc != undefined) {
                    this.seoInfo.description = res.data.data.seoDesc
                }else{
                    this.seoInfo.description=this.seoAllInfo.description
                }

                // banner
                if (res.data.data.blocksObj.banner[0] != '') {
                    this.banner = res.data.data.blocksObj.banner[0]
                }

                // 搜索栏行业分类
                var screenTemp1 = res.data.data.blocksObj.screen[0]
                for (var i = 0; i < screenTemp1.tags.length; i++) {
                    screenTemp1.tags[i].on = false
                    // screenTemp1.tags[0].on=true
                }
                if(screenTemp1.tags.length>0){
                    screenTemp1.tags.unshift({ tag: '全部', on: true })
                }
                this.screen1 = screenTemp1
                // console.log(this.screen1)

                var screenTemp2 = res.data.data.blocksObj.screen[1]
                for (var i = 0; i < screenTemp2.tags.length; i++) {
                    screenTemp2.tags[i].on = false
                    // screenTemp2.tags[0].on=true
                }
                if(screenTemp2.tags.length>0){
                    screenTemp2.tags.unshift({ tag: '全部', on: true })
                }
                this.screen2 = screenTemp2
                console.log(res.data.data)


                // this.page.total=res.data.total
                // console.log(this.screen,'test')

                this.$nextTick(() => {

                    setTimeout(() => {

                    }, 0)

                    // wow动画
                    new this.$wow.WOW({
                        live: false,
                    }).init()


                })
            })

            // 列表和分页
            this.$axios.get('/api/case', {
                params: {
                    pageSize: this.page.pageSize,
                    tags: this.tags,
                    tags2: this.tags2,
                }
            }).then(res => {
                this.newsList = res.data.rows
                this.page.total = res.data.total
                console.log(this.newsList,'newsList')

                if (this.tags != '') {
                    for (var i = 0; i < this.screen1.tags.length; i++) {
                        if (this.screen1.tags[i].tags == this.tags) {
                            // this.screen1.tags[i].on=true
                        }
                    }
                }

                if (this.tags2 != '') {
                    for (var i = 0; i < this.screen2.tags.length; i++) {
                        if (this.screen2.tags[i].tags == this.tags2) {
                            // this.screen2.tags[i].on=true
                        }
                    }
                }

            })

        },
        toast() {
            this.$toast.show('页面建设中，请耐心等待…', '您可通过电话或微信扫描底部二维码联系我们', 5000)
        },
        handleScroll() {
            // this.$nextTick(() => {
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop

        },
        customerNav1Fn(item, index) {
            for (var i = 0; i < this.screen1.tags.length; i++) {
                this.screen1.tags[i].on = false
            }
            this.screen1.tags[index].on = true
            let tags=''
            if(item!='全部'){
                tags = item
            }
             
            this.$axios.get('/api/case', {
                params: {
                    pageSize: this.page.pageSize,
                    tags: tags
                }
            }).then(res => {
                this.newsList = res.data.rows
                this.page.total = res.data.total
                console.log(this.newsList)
            })
        },
        customerNav2Fn(item, index) {
            for (var i = 0; i < this.screen2.tags.length; i++) {
                this.screen2.tags[i].on = false
            }
            this.screen2.tags[index].on = true

            let tags2 = ''
            if(item!='全部'){
                tags2 = item
            }
            this.$axios.get('/api/case', {
                params: {
                    pageSize: this.page.pageSize,
                    tags: tags2
                }
            }).then(res => {
                this.newsList = res.data.rows
                this.page.total = res.data.total
                console.log(this.newsList)
            })
        },
        // 搜索
        searchFn() {
            if(this.keyword==''){
                this.$toast.show('请输入关键字','', 5000)
                return
            }
            let keyword = this.keyword
            let id = this.id
            this.newsList = []
            this.$axios.get('/api/case', {
                params: {
                    keyword: keyword,
                    pageSize: this.page.pageSize,
                }
            }).then(res => {
                this.newsList = res.data.rows
                for (var i = 0; i < this.screen1.tags.length; i++) {
                    this.screen1.tags[i].on = false
                }
                for (var i = 0; i < this.screen2.tags.length; i++) {
                    this.screen2.tags[i].on = false
                }

            })
        },
        // 分页器
        change(currentPage, type) {
            let id = this.id
            let pageNum = currentPage
            let keyword = this.keyword
            this.page.currentPage = currentPage;
            // console.log("点击了" + type + "，当前页：" + currentPage);
            console.log(currentPage, type)
            this.newsList = []
            this.$axios.get('/api/case', {
                params: {
                    pageNum: pageNum,
                    pageSize: this.page.pageSize,
                }
            }).then(res => {
                this.newsList = res.data.rows
                document.documentElement.scrollTop = 0
            })
        },

    },
};
</script>
  
<style></style>