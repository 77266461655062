<template>
    <div class="case">

        <!-- 内页顶部banner -->
        <div class="public-nybn case-bn">
            <img class="bgimg2" src="../assets/images/ys-83.png" alt="">
            <img class="bgimg3" src="../assets/images/ys-82.png" alt="">
            <div class="bgimg scaleDraw" v-if="caseBanner.image">
                <img :src="host + caseBanner.image" alt="">
            </div>
            <div class="zz-box">

                <div class="bn-title fadeInUp2">
                    <div class="container">
                        <div class="c-title">
                            <h2 class="h2 font36 text_b">
                                {{ caseBanner.title }}
                            </h2>
                        </div>
                        <div class="font18 c-text">
                            {{ caseBanner.text }}
                        </div>
                        <div class="btnbox">
                            <router-link v-if="caseBanner.link" :to="caseBanner.link" class="hi-btn1">
                                <span class="hi-text font16">{{ caseBanner.btnname }}</span>
                                <img class="hi-ico" src="../assets/images/hi-btn-arrow1.svg" onload="SVGInject(this)">
                            </router-link>
                        </div>
                    </div>
                </div>


            </div>
        </div>

        <div class="public-crumbs public-crumbs2" :class="crumbsFlag ? 'active' : ''" id="public-crumbs">
            <div class="container">
                <div class="c-box">

                    <h3 class="c-a font18" :class="item.on ? 'on' : ''" @click="scrollIntoView(index)"
                        v-for="(item, index) in crumbs" :key="index">
                        <a href="javascript:;" rel="nofollow">
                            {{ item.title }}
                        </a>
                    </h3>

                    <!-- <h3 class="c-a font18" @click="scrollIntoView('4')">
                        <a href="javascript:;" rel="nofollow">
                            客户案例
                        </a>
                    </h3> -->

                </div>
            </div>
        </div>

        <!-- 第二屏 行业洞察 -->
        <div class="scrollTb" id="0" v-show="caseFmodel.title != ''">
            <div class="case1 pdtb">
                <div class="container">
                    <div class="public-title wow fadeInUp">
                        <h2 class="font40 text_b h2">
                            <span>{{ caseFmodel.title }}</span>
                        </h2>
                    </div>

                    <div class="public-swpBox public-swpBox2">
                        <div class="swpBox wow fadeInUp">
                            <div class="swiper-container" :class="swpNot ? 'swiper-no-swiping' : ''">
                                <div class="swiper-wrapper">

                                    <div class="swiper-slide" :class="Number(caseFmodel.list.length)<4?'on':''" v-for="(item, index) in caseFmodel.list" :key="index">

                                        <div class="c-box">
                                            <img class="swpbgimg2" src="../assets/images/ys-183.png" alt="">
                                            <div class="c-box1">
                                                <div class="imgbox">
                                                    <img class="img1" v-if="item.icon" :src="host + item.icon" alt="">
                                                    <img class="img2" src="../assets/images/ys-184.png" alt="">
                                                </div>
                                                <div class="c-title font22 text_b pre">{{ item.title }}</div>
                                            </div>

                                            <div class="c-box2">
                                                <div class="c-text font18 pre">{{ item.text }}</div>
                                            </div>
                                        </div>

                                    </div>

                                    <!-- <div class="swiper-slide">


                                        <div class="c-box">
                                            <img class="swpbgimg2" src="../assets/images/ys-183.png" alt="">
                                            <div class="c-box1">
                                                <div class="imgbox">
                                                    <img class="img1" src="../assets/images/ys-185.png" alt="">
                                                    <img class="img2" src="../assets/images/ys-185.png" alt="">
                                                </div>
                                                <div class="c-title font24 text_b">
                                                    围绕社区打造美好生活
                                                </div>
                                            </div>

                                            <div class="c-box2">
                                                <div class="c-text font18">
                                                    一刻钟便民生活圈建设进程加快，门店生鲜占比显著增大
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="swiper-slide">


                                        <div class="c-box">
                                            <img class="swpbgimg2" src="../assets/images/ys-183.png" alt="">
                                            <div class="c-box1">
                                                <div class="imgbox">
                                                    <img class="img1" src="../assets/images/ys-186.png" alt="">
                                                    <img class="img2" src="../assets/images/ys-186.png" alt="">
                                                </div>
                                                <div class="c-title font24 text_b">
                                                    全渠道融合加速
                                                </div>
                                            </div>

                                            <div class="c-box2">
                                                <div class="c-text font18">
                                                    线上销售占比提升，数字会员加速增长，全渠道数字化投入加大
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="swiper-slide">


                                        <div class="c-box">
                                            <img class="swpbgimg2" src="../assets/images/ys-183.png" alt="">
                                            <div class="c-box1">
                                                <div class="imgbox">
                                                    <img class="img1" src="../assets/images/ys-187.png" alt="">
                                                    <img class="img2" src="../assets/images/ys-187.png" alt="">
                                                </div>
                                                <div class="c-title font24 text_b">
                                                    供应链优化，源头提效
                                                </div>
                                            </div>

                                            <div class="c-box2">
                                                <div class="c-text font18">
                                                    随着超市业态细分及商品种类的增加，物流中心能力建设需要强化
                                                </div>
                                            </div>
                                        </div>

                                    </div> -->


                                </div>
                            </div>
                        </div>

                        <div class="swp-btnBox">
                            <div class="swiper-pagination"></div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <!-- 第三屏 解决方案 -->
        <div class="scrollTb" id="1" v-show="caseMatrix.title != ''">
            <div class="case2 pdtb">
                <div class="container">
                    <div class="bgimgBox">
                        <div class="public-ani-bgimg public-ani-bgimg1">
                            <img src="../assets/images/ys-82.png" alt="">
                        </div>
                        <div class="public-ani-bgimg public-ani-bgimg4">
                            <img src="../assets/images/ys-82.png" alt="">
                        </div>
                    </div>

                    <div class="public-title wow fadeInUp">
                        <h2 class="font40 text_b h2">
                            {{ caseMatrix.title }}
                        </h2>

                        <div class="c-text font18">
                            {{ caseMatrix.desc }}
                        </div>
                    </div>

                    <ul class="public-list wow fadeInUp" :class="Number(caseMatrixList.length > 6) ? '' : 'public-listTwo'">
                        <li class="c-li" v-for="(item, index) in caseMatrixList" :key="index">
                            <img class="bgimg" src="../assets/images/ys-190.png" alt="">
                            <div class="imgbox">
                                <img v-if="item.icon" :src="host + item.icon" alt="">
                            </div>
                            <h3 class="h3 font24 text_b">
                                {{ item.title }}
                            </h3>
                            <div class="c-text">
                                <router-link v-if="item.link" :to="item.link">
                                    <span class="font16">了解方案详情</span>
                                    <img src="../assets/images/ys-188.png" alt="">
                                </router-link>
                            </div>

                            <div class="c-box" v-if="item.image != ''">
                                <div class="syb"></div>
                                <div class="imgbox2">
                                    <img v-if="item.image" :src="host + item.image" alt="">
                                </div>
                            </div>
                        </li>

                        <!-- <li class="c-li">
                            <img class="bgimg" src="../assets/images/ys-190.png" alt="">
                            <div class="imgbox">
                                <img src="../assets/images/ys-192.svg" alt="" onload="SVGInject(this)">
                            </div>
                            <h3 class="h3 font24 text_b">
                                数字化会员经营
                            </h3>
                            <div class="c-text">
                                <a href="javascript:;" rel="nofollow" @click="toast()">
                                    <span class="font16">了解方案详情</span>
                                    <img src="../assets/images/ys-188.png" alt="">
                                </a>
                            </div>

                            <div class="c-box">
                                <div class="syb"></div>
                                <div class="imgbox2">
                                    <img src="../assets/images/ys-216.png" alt="">
                                </div>
                            </div>
                        </li>

                        <li class="c-li">
                            <img class="bgimg" src="../assets/images/ys-190.png" alt="">
                            <div class="imgbox">
                                <img src="../assets/images/ys-193.svg" alt="" onload="SVGInject(this)">
                            </div>
                            <h3 class="h3 font24 text_b">
                                高效营运及店务
                            </h3>
                            <div class="c-text">
                                <a href="javascript:;" rel="nofollow" @click="toast()">
                                    <span class="font16">了解方案详情</span>
                                    <img src="../assets/images/ys-188.png" alt="">
                                </a>
                            </div>

                            <div class="c-box">
                                <div class="syb"></div>
                                <div class="imgbox2">
                                    <img src="../assets/images/ys-217.png" alt="">
                                </div>
                            </div>
                        </li>

                        <li class="c-li">
                            <img class="bgimg" src="../assets/images/ys-190.png" alt="">
                            <div class="imgbox">
                                <img src="../assets/images/ys-194.svg" alt="" onload="SVGInject(this)">
                            </div>
                            <h3 class="h3 font24 text_b">
                                协同式供应链
                            </h3>
                            <div class="c-text">
                                <a href="javascript:;" rel="nofollow" @click="toast()">
                                    <span class="font16">了解方案详情</span>
                                    <img src="../assets/images/ys-188.png" alt="">
                                </a>
                            </div>

                            <div class="c-box">
                                <div class="syb"></div>
                                <div class="imgbox2">
                                    <img src="../assets/images/ys-218.png" alt="">
                                </div>
                            </div>
                        </li>

                        <li class="c-li">
                            <img class="bgimg" src="../assets/images/ys-190.png" alt="">
                            <div class="imgbox">
                                <img src="../assets/images/ys-200.svg" alt="" onload="SVGInject(this)">
                            </div>
                            <h3 class="h3 font24 text_b">
                                智能仓储物流
                            </h3>
                            <div class="c-text">
                                <a href="javascript:;" rel="nofollow" @click="toast()">
                                    <span class="font16">了解方案详情</span>
                                    <img src="../assets/images/ys-188.png" alt="">
                                </a>
                            </div>

                            <div class="c-box">
                                <div class="syb"></div>
                                <div class="imgbox2">
                                    <img src="../assets/images/ys-219.png" alt="">
                                </div>
                            </div>
                        </li>

                        <li class="c-li">
                            <img class="bgimg" src="../assets/images/ys-190.png" alt="">
                            <div class="imgbox">
                                <img src="../assets/images/ys-195.svg" alt="" onload="SVGInject(this)">
                            </div>
                            <h3 class="h3 font24 text_b">
                                全链路生产加工
                            </h3>
                            <div class="c-text">
                                <a href="javascript:;" rel="nofollow" @click="toast()">
                                    <span class="font16">了解方案详情</span>
                                    <img src="../assets/images/ys-188.png" alt="">
                                </a>
                            </div>

                            <div class="c-box">
                                <div class="syb"></div>
                                <div class="imgbox2">
                                    <img src="../assets/images/ys-220.png" alt="">
                                </div>
                            </div>
                        </li>

                        <li class="c-li">
                            <img class="bgimg" src="../assets/images/ys-190.png" alt="">
                            <div class="imgbox">
                                <img src="../assets/images/ys-196.svg" alt="" onload="SVGInject(this)">
                            </div>
                            <h3 class="h3 font24 text_b">
                                在线式分销及批发
                            </h3>
                            <div class="c-text">
                                <a href="javascript:;" rel="nofollow" @click="toast()" class="a">
                                    <span class="font16">了解方案详情</span>
                                    <img src="../assets/images/ys-188.png" alt="">
                                </a>
                            </div>

                            <div class="c-box">
                                <div class="syb"></div>
                                <div class="imgbox2">
                                    <img src="../assets/images/ys-221.png" alt="">
                                </div>
                            </div>
                        </li>

                        <li class="c-li">
                            <img class="bgimg" src="../assets/images/ys-190.png" alt="">
                            <div class="imgbox">
                                <img src="../assets/images/ys-197.svg" alt="" onload="SVGInject(this)">
                            </div>
                            <h3 class="h3 font24 text_b">
                                全模式加盟
                            </h3>
                            <div class="c-text">
                                <a href="javascript:;" rel="nofollow" @click="toast()">
                                    <span class="font16">了解方案详情</span>
                                    <img src="../assets/images/ys-188.png" alt="">
                                </a>
                            </div>

                            <div class="c-box">
                                <div class="syb"></div>
                                <div class="imgbox2">
                                    <img src="../assets/images/ys-222.png" alt="">
                                </div>
                            </div>
                        </li>

                        <li class="c-li">
                            <img class="bgimg" src="../assets/images/ys-190.png" alt="">
                            <div class="imgbox">
                                <img src="../assets/images/ys-198.svg" alt="" onload="SVGInject(this)">
                            </div>
                            <h3 class="h3 font24 text_b">
                                一体化业财
                            </h3>
                            <div class="c-text">
                                <a href="javascript:;" rel="nofollow" @click="toast()">
                                    <span class="font16">了解方案详情</span>
                                    <img src="../assets/images/ys-188.png" alt="">
                                </a>
                            </div>

                            <div class="c-box">
                                <div class="syb"></div>
                                <div class="imgbox2">
                                    <img src="../assets/images/ys-223.png" alt="">
                                </div>
                            </div>
                        </li>

                        <li class="c-li">
                            <img class="bgimg" src="../assets/images/ys-190.png" alt="">
                            <div class="imgbox">
                                <img src="../assets/images/ys-199.svg" alt="" onload="SVGInject(this)">
                            </div>
                            <h3 class="h3 font24 text_b">
                                数据驱动
                            </h3>
                            <div class="c-text">
                                <a href="javascript:;" rel="nofollow" @click="toast()">
                                    <span class="font16">了解方案详情</span>
                                    <img src="../assets/images/ys-188.png" alt="">
                                </a>
                            </div>

                            <div class="c-box">
                                <div class="syb"></div>
                                <div class="imgbox2">
                                    <img src="../assets/images/ys-224.png" alt="">
                                </div>
                            </div>
                        </li> -->


                    </ul>

                </div>
            </div>
        </div>

        <!-- 应用场景 -->
        <div class="scrollTb" id="2" v-show="caseScene.title != ''">
            <div class="case3 pdtb">
                <div class="container">
                    <div class="public-title wow fadeInUp">
                        <h2 class="font40 text_b h2">
                            {{ caseScene.title }}
                        </h2>

                        <div class="c-text font18">
                            {{ caseScene.desc }}
                        </div>
                    </div>

                    <!-- 分类标题 -->
                    <div class="public-navBox public-navBox2 public-navBox3 wow fadeInUp" v-if="caseSceneList.length > 1">
                        <div class="c-nav">

                            <div class="c-li" :class="[{ 'on': item.on == true }]" v-for="(item, index) in caseSceneList"
                                :key="index" @click="case3NavFn(index)">
                                <div class="c-text">
                                    <h3 class="h3 font20">{{ item.name }}</h3>
                                </div>
                            </div>

                        </div>
                    </div>

                    <!-- 分类列表 -->
                    <div class="li-box wow fadeInUp">

                        <div class="li" v-show="item1.on" v-for="(item1, index1) in caseScene.list" :key="index1">
                            <div class="liBox">
                                <div class="zz-left">
                                    <div class="c-box">
                                        <div class="c-title">
                                            <img v-if="item1.icon" :src="host + item1.icon" alt="">
                                            <h4 class="font30 text_b h4">{{ item1.title }}</h4>
                                        </div>
                                        <ul class="c-ul">
                                            <li class="c-li" v-for="(item2, index2) in item1.text" :key="index2">
                                                <a href="javascript:;" class="a">
                                                    <div class="dian">
                                                        <span class="point"></span>
                                                        <span class="span1"></span>
                                                    </div>
                                                    <div class="c-text font18">{{ item2 }}</div>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>

                                    <div class="btnbox">
                                        <router-link v-if="item1.link" :to="item1.link" class="hi-btn1">
                                            <span class="hi-text font16">{{ item1.btn }}</span>
                                            <img class="hi-ico" src="../assets/images/hi-btn-arrow1.svg"
                                                onload="SVGInject(this)">
                                        </router-link>
                                    </div>

                                </div>
                                <div class="zz-right">
                                    <div class="imgbox">
                                        <img v-if="case3Img" :src="host + case3Img" alt="">
                                    </div>
                                </div>


                                <!-- <div class="zz-left">
                                <div class="c-title">
                                    <img src="../assets/images/ys-202.png" alt="">
                                    <h4 class="font30 text_b h4">全触点、全渠道、全链路闭环</h4>
                                </div>
                                <ul class="c-ul">
                                    <li class="c-li">
                                        <a href="javascript:;" rel="nofollow" @click="toast()" class="a">
                                            <div class="dian">
                                                <span class="point"></span>
                                                <span class="span1"></span>
                                            </div>
                                            <div class="c-text font18"></div>
                                        </a>
                                    </li>
                                </ul>

                                <div class="btnbox">
                                    <a href="javascript:;" rel="nofollow" @click="toast()" class="hi-btn1">
                                        <span class="hi-text font16">立即咨询</span>
                                        <img class="hi-ico" src="../assets/images/hi-btn-arrow1.svg"
                                            onload="SVGInject(this)">
                                    </a>
                                </div>

                            </div>
                            <div class="zz-right">
                                <div class="imgbox">
                                    <img src="../assets/images/ys-201.png" alt="">
                                </div>
                            </div> -->

                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>

        <!-- 业务价值 -->
        <div class="scrollTb" id="3" v-show="caseService.title != ''">
            <div class="case4 pdtb" :class="Number(caseServiceList.length) <= 3 ? 'on' : ''">
                <div class="container">
                    <div class="bgimgBox">
                        <div class="public-ani-bgimg public-ani-bgimg1">
                            <img src="../assets/images/ys-82.png" alt="">
                        </div>
                        <div class="public-ani-bgimg public-ani-bgimg4">
                            <img src="../assets/images/ys-82.png" alt="">
                        </div>
                    </div>
                    <div class="public-title wow fadeInUp">
                        <h2 class="font40 text_b h2">
                            {{ caseService.title }}
                        </h2>
                    </div>

                    <div class="c-box public-poetry">
                        <ul class="c-ul">
                            <li class="c-li wow fadeInUp" :class="item.on == true ? 'on' : ''"
                                v-for="(item, index) in caseServiceList" :key="index">
                                <div class="c-div1">
                                    <div class="zz-left">
                                        <div class="c-title">
                                            <img v-if="item.icon" :src="host + item.icon" alt="图片不存在">
                                            <h3 class="h3 font24 text_b">{{ item.title }}</h3>
                                        </div>
                                        <div class="c-text font18">
                                            <p>{{ item.text }}</p>
                                        </div>
                                    </div>
                                    <img class="bgimg" src="../assets/images/ys-204.png" alt="图片不存在">
                                </div>
                                <div class="c-div2">
                                    <img class="bgimg2" src="../assets/images/ys-215.png" alt="图片不存在">
                                    <div class="zz-left">
                                        <div class="c-title">
                                            <img v-if="item.icon" :src="host + item.icon" alt="图片不存在">
                                            <h3 class="h3 font24 text_b">{{ item.title }}</h3>
                                        </div>
                                        <div class="c-text font18">
                                            {{ item.text }}
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <!-- <li class="c-li wow fadeInUp">
                                <div class="c-div1">
                                    <div class="zz-left">
                                        <div class="c-title">
                                            <img src="../assets/images/ys-205.png" alt="">
                                            <h3 class="h3 font24 text_b">多场景零售能力</h3>
                                        </div>
                                        <div class="c-text font18">
                                            强总部-轻门店设计原则，支撑卖场、社区店、便利店等多业态灵活经营线上商城千店千面，丰富的数字营销工具，构建全场景连接触点，持续释放全域流量价值
                                        </div>
                                    </div>
                                    <img class="bgimg" src="../assets/images/ys-204.png" alt="">
                                </div>
                                <div class="c-div2">
                                    <img class="bgimg2" src="../assets/images/ys-215.png" alt="">
                                    <div class="zz-left">
                                        <div class="c-title">
                                            <img src="../assets/images/ys-205.png" alt="">
                                            <h3 class="h3 font24 text_b">多场景零售能力</h3>
                                        </div>
                                        <div class="c-text font18">
                                            强总部-轻门店设计原则，支撑卖场、社区店、便利店等多业态灵活经营线上商城千店千面，丰富的数字营销工具，构建全场景连接触点，持续释放全域流量价值
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li class="c-li wow fadeInUp on">
                                <div class="c-div1">
                                    <div class="zz-left">
                                        <div class="c-title">
                                            <img src="../assets/images/ys-206.png" alt="">
                                            <h3 class="h3 font24 text_b">全面数字化</h3>
                                        </div>
                                        <div class="c-text font18">
                                            以用户为中心的全价值链条数字化，生产经营要素全面数字化，打造数字化、智能化零售企业，实现导购到会员、供应链到门店、业务到财务的极致效率体验，全方位保障企业稳增长、降成本、提人效。
                                        </div>
                                    </div>
                                    <img class="bgimg" src="../assets/images/ys-204.png" alt="">
                                </div>
                                <div class="c-div2">
                                    <img class="bgimg2" src="../assets/images/ys-215.png" alt="">
                                    <div class="zz-left">
                                        <div class="c-title">
                                            <img src="../assets/images/ys-206.png" alt="">
                                            <h3 class="h3 font24 text_b">全面数字化</h3>
                                        </div>
                                        <div class="c-text font18">
                                            以用户为中心的全价值链条数字化，生产经营要素全面数字化，打造数字化、智能化零售企业，实现导购到会员、供应链到门店、业务到财务的极致效率体验，全方位保障企业稳增长、降成本、提人效。
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li class="c-li wow fadeInUp">
                                <div class="c-div1">
                                    <div class="zz-left">
                                        <div class="c-title">
                                            <img src="../assets/images/ys-207.png" alt="">
                                            <h3 class="h3 font24 text_b">客产值最大化</h3>
                                        </div>
                                        <div class="c-text font18">
                                            连接消费者，洞察生意机会，最大化会员价值强化全渠道精准触达能力，持续裂变获客，赋能会员私域运营，多维度优化消费体验，提升单客产值
                                        </div>
                                    </div>
                                    <img class="bgimg" src="../assets/images/ys-204.png" alt="">
                                </div>
                                <div class="c-div2">
                                    <img class="bgimg2" src="../assets/images/ys-215.png" alt="">
                                    <div class="zz-left">
                                        <div class="c-title">
                                            <img src="../assets/images/ys-207.png" alt="">
                                            <h3 class="h3 font24 text_b">客产值最大化</h3>
                                        </div>
                                        <div class="c-text font18">
                                            连接消费者，洞察生意机会，最大化会员价值强化全渠道精准触达能力，持续裂变获客，赋能会员私域运营，多维度优化消费体验，提升单客产值
                                        </div>
                                    </div>
                                </div>
                            </li>

                            <li class="c-li wow fadeInUp">
                                <div class="c-div1">
                                    <div class="zz-left">
                                        <div class="c-title">
                                            <img src="../assets/images/ys-208.png" alt="">
                                            <h3 class="h3 font24 text_b">决策智慧化</h3>
                                        </div>
                                        <div class="c-text font18">
                                            移动报表、大数据分析平台、智能补货/订货实现全渠道数据实时交互分析并驱动决策，随时随地掌握经营情况
                                        </div>
                                    </div>
                                    <img class="bgimg" src="../assets/images/ys-204.png" alt="">
                                </div>
                                <div class="c-div2">
                                    <img class="bgimg2" src="../assets/images/ys-215.png" alt="">
                                    <div class="zz-left">
                                        <div class="c-title">
                                            <img src="../assets/images/ys-208.png" alt="">
                                            <h3 class="h3 font24 text_b">决策智慧化</h3>
                                        </div>
                                        <div class="c-text font18">
                                            移动报表、大数据分析平台、智能补货/订货实现全渠道数据实时交互分析并驱动决策，随时随地掌握经营情况
                                        </div>
                                    </div>
                                </div>
                            </li> -->


                        </ul>
                    </div>


                </div>
            </div>
        </div>

        <!-- 客户案例 -->
        <div class="scrollTb" id="4" v-show="caseCase.title != ''">
            <div class="case5 pdtb" id="case5">
                <div class="container">
                    <div class="public-title wow fadeInUp">
                        <h2 class="font40 text_b h2">
                            {{ caseCase.title }}
                        </h2>
                    </div>

                    <div class="sysec4Swp case5Swp">

                        <div class="swp-btnBox">
                            <div class="swiper-pagination"></div>
                        </div>
                        <div class="swiper-container">
                            <div class="swiper-wrapper">

                                <div class="swiper-slide" v-for="(item1, index1) in caseCaseList" :key="index1">
                                    <div class="zz-left wow fadeRight">
                                        <img class="bgimg" src="../assets/images/ys-209.png" alt="">
                                        <div class="imgbox">
                                            <img v-if="item1.cbg" :src="host + item1.cbg" alt="">
                                        </div>
                                        <div class="c-cont">
                                            <div class="c-title-box">
                                                <img v-if="item1.icon" :src="host + item1.icon" alt="">
                                                <div class="c-title font24 text_b">
                                                    {{ item1.ctitle }}
                                                </div>
                                            </div>

                                            <div class="c-text font16">
                                                <div class="scroll">
                                                    {{ item1.cdesc }}
                                                </div>
                                            </div>

                                            <div class="imgbox">
                                                <img v-if="item1.cimg" :src="host + item1.cimg" alt="">
                                            </div>

                                        </div>
                                    </div>

                                    <div class="zz-right wow fadeLeft">
                                        <img class="bgimg2" src="../assets/images/ys-55.svg" alt=""
                                            onload="SVGInject(this)">

                                        <div class="c-title-box">
                                            <div class="c-t">
                                                <img v-if="item1.yicon" :src="host + item1.yicon" alt="">
                                                <div class="font24 c-div text_b">{{ item1.ytitle }}</div>
                                            </div>

                                            <ul class="c-ul">
                                                <li class="c-li font18">
                                                    {{ item1.ydesc }}
                                                </li>
                                            </ul>
                                        </div>

                                        <div class="c-cont-box">
                                            <div class="c-t">
                                                <img v-if="item1.zicon" :src="host + item1.zicon" alt="">
                                                <div class="font24 c-div text_b">{{ item1.ztitle }}</div>
                                            </div>

                                            <ul class="c-ul">
                                                <li class="c-li" v-for="(item2, index2) in item1.cons" :key="index2">
                                                    <div class="c-num">
                                                        <div class="c-div1 font24">
                                                            <animate-number mode="manual" duration="3000" :from="0"
                                                                :to="item2.num" ref="caseNum">
                                                            </animate-number>
                                                        </div>
                                                        <div class="c-div2 font20">
                                                            {{ item2.unit }}
                                                        </div>
                                                    </div>
                                                    <div class="c-tips font16">
                                                        {{ item2.title }}
                                                    </div>
                                                </li>

                                                <!-- <li class="c-li">
                                                    <div class="c-num">
                                                        <div class="c-div1 font24">
                                                            <animate-number mode="manual" duration="3000" :from="startVal2"
                                                                :to="endVal2" ref="num2">
                                                            </animate-number>
                                                        </div>
                                                        <div class="c-div2 font20">
                                                            w+
                                                        </div>
                                                    </div>
                                                    <div class="c-tips font16">
                                                        年订单量
                                                    </div>
                                                </li>

                                                <li class="c-li">
                                                    <div class="c-num">
                                                        <div class="c-div1 font24">
                                                            <animate-number mode="manual" duration="3000" :from="startVal3"
                                                                :to="endVal3" ref="num3">
                                                            </animate-number>
                                                        </div>
                                                        <div class="c-div2 font20">
                                                            w+
                                                        </div>
                                                    </div>
                                                    <div class="c-tips font16">
                                                        会员数
                                                    </div>
                                                </li>

                                                <li class="c-li">
                                                    <div class="c-num">
                                                        <div class="c-div1 font24">
                                                            <animate-number mode="manual" duration="3000" :from="startVal4"
                                                                :to="endVal4" ref="num4" :formatter="formatter">
                                                            </animate-number>
                                                        </div>
                                                        <div class="c-div2 font20">
                                                            %
                                                        </div>
                                                    </div>
                                                    <div class="c-tips font16">
                                                        线上订单量增长
                                                    </div>
                                                </li>

                                                <li class="c-li">
                                                    <div class="c-num">
                                                        <div class="c-div1 font24">
                                                            <animate-number mode="manual" duration="3000" :from="startVal5"
                                                                :to="endVal5" ref="num5">
                                                            </animate-number>
                                                        </div>
                                                        <div class="c-div2 font20">
                                                            %
                                                        </div>
                                                    </div>
                                                    <div class="c-tips font16">
                                                        线上交易额增长
                                                    </div>
                                                </li> -->


                                            </ul>

                                        </div>

                                    </div>

                                </div>

                                <!-- <div class="swiper-slide">
                                    <div class="zz-left wow fadeRight">
                                        <img class="bgimg" src="../assets/images/ys-209.png" alt="">
                                        <div class="imgbox">
                                            <img src="../assets/images/ys-210.png" alt="">
                                        </div>
                                        <div class="c-cont">
                                            <div class="c-title-box">
                                                <img src="../assets/images/ys-211.svg" alt="">
                                                <div class="c-title font24 text_b">
                                                    勇惠便利
                                                </div>
                                            </div>

                                            <div class="c-text font16">
                                                <div class="scroll">
                                                    勇惠超市，西南地区零售连锁领先企业，中国连锁经营协会(CCFA)会员。为消费群体提供便捷、时尚、新鲜的“24小时”星级便利服务。
                                                </div>
                                            </div>

                                            <div class="imgbox">
                                                <img src="../assets/images/ys-212.png" alt="">
                                            </div>

                                        </div>
                                    </div>

                                    <div class="zz-right wow fadeLeft">
                                        <img class="bgimg2" src="../assets/images/ys-55.svg" alt=""
                                            onload="SVGInject(this)">

                                        <div class="c-title-box">
                                            <div class="c-t">
                                                <img src="../assets/images/ys-213.svg" alt="">
                                                <div class="font24 c-div text_b">业务挑战</div>
                                            </div>

                                            <ul class="c-ul">
                                                <li class="c-li font18">
                                                    数据化管理缺失、系统稳定性差、业务处理能力弱
                                                </li>
                                            </ul>
                                        </div>

                                        <div class="c-cont-box">
                                            <div class="c-t">
                                                <img src="../assets/images/ys-214.png" alt="">
                                                <div class="font24 c-div text_b">主要成果</div>
                                            </div>

                                            <ul class="c-ul">
                                                <li class="c-li">
                                                    <div class="c-num">
                                                        <div class="c-div1 font24">
                                                            200
                                                        </div>
                                                        <div class="c-div2 font20">
                                                            +
                                                        </div>
                                                    </div>
                                                    <div class="c-tips font16">
                                                        实体门店数
                                                    </div>
                                                </li>

                                                <li class="c-li">
                                                    <div class="c-num">
                                                        <div class="c-div1 font24">
                                                            3200
                                                        </div>
                                                        <div class="c-div2 font20">
                                                            w+
                                                        </div>
                                                    </div>
                                                    <div class="c-tips font16">
                                                        年订单量
                                                    </div>
                                                </li>

                                                <li class="c-li">
                                                    <div class="c-num">
                                                        <div class="c-div1 font24">
                                                            10
                                                        </div>
                                                        <div class="c-div2 font20">
                                                            w+
                                                        </div>
                                                    </div>
                                                    <div class="c-tips font16">
                                                        会员数
                                                    </div>
                                                </li>

                                                <li class="c-li">
                                                    <div class="c-num">
                                                        <div class="c-div1 font24">
                                                            13.8
                                                        </div>
                                                        <div class="c-div2 font20">
                                                            %
                                                        </div>
                                                    </div>
                                                    <div class="c-tips font16">
                                                        线上订单量增长
                                                    </div>
                                                </li>

                                                <li class="c-li">
                                                    <div class="c-num">
                                                        <div class="c-div1 font24">
                                                            84
                                                        </div>
                                                        <div class="c-div2 font20">
                                                            %
                                                        </div>
                                                    </div>
                                                    <div class="c-tips font16">
                                                        线上交易额增长
                                                    </div>
                                                </li>


                                            </ul>

                                        </div>

                                    </div>

                                </div> -->


                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

        <!-- svg渐变 -->
        <div style="height: 0;position: fixed;z-index: -1;">
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
                <defs>
                    <linearGradient id="grad1" x1="0%" y1="0%" x2="0%" y2="100%">
                        <stop offset="0%" style="stop-color:rgb(0,76,227);stop-opacity:1" />
                        <stop offset="100%" style="stop-color:rgb(0,168,255);stop-opacity:0.65" />
                    </linearGradient>
                </defs>
                <ellipse cx="200" cy="70" rx="85" ry="55" fill="url(#grad1)" />
            </svg>
        </div>


    </div>
</template>
  
<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

// 这是引用wow
// import { WOW } from 'wowjs'
// 引入swiper
import Swiper from 'swiper';
import $ from 'jquery';
import 'swiper/dist/css/swiper.min.css';
// import vueSeamless from 'vue-seamless-scroll'
// import countTo from 'vue-count-to';
import '../assets/css/style.css';
import hostUrl from '@/util/baseUrl'


export default {
    // name: "HomeView",
    components: {
    },
    metaInfo() {
        return {
            title: this.seoInfo.title, //设置页面title
            meta: [
                {                 //设置页面meta
                    name: 'keywords',
                    content: this.seoInfo.keyWords
                },
                {
                    name: "description",
                    content: this.seoInfo.description
                },
                {
                    name: "renderer",
                    content: 'webkit',
                },
                {
                    name: "force-rendering",
                    content: 'webkit',
                },
                {
                    name: "format-detection",
                    content: 'telephone=no,telephone=no,email=no,adress=no',
                },
                {
                    name: "viewport",
                    content: 'width=device-width,user-scalable=no,initial-scale=1.0,maximum-scale=1.0,minimum-scale=1.0',
                },
            ]
        }
    },
    data() {
        return {
            // 屏幕宽度参数
            wW: '',
            // case3Data: [
            //     {
            //         navTitle: "场景标题",
            //         on: true,
            //         title: '赋能连锁商超，助力全链路数字化转型',
            //         icon: require('../assets/images/ys-202.svg'),

            //         list: [
            //             {
            //                 text: '智慧化感知消费者线上线下的动态，同时赋能门店导购，实现精准营销，提升客户体验',
            //                 img: require('../assets/images/ys-278.jpg'),
            //             },
            //             {
            //                 text: '深入商超业务场景，打通线上触点、数字门店、三方平台，实现全渠道场景统一管理',
            //                 img: require('../assets/images/ys-278.jpg'),
            //             },
            //             {
            //                 text: '数据化赋能从门店要货到采购、送货、入库、出库、配送等环节的完整供应链管理',
            //                 img: require('../assets/images/ys-278.jpg'),
            //             },
            //         ]
            //     },
            // ],

            // 数字跳动
            // startVal1: 0,
            // endVal1: 200,
            // startVal2: 0,
            // endVal2: 3200,
            // startVal3: 0,
            // endVal3: 10,
            // startVal4: 0,
            // endVal4: 13.8,
            // startVal5: 0,
            // endVal5: 84,

            scrollFlag: false,
            case5Top: '',
            // 应用场景图片
            case3Img: '',
            // 二级导航TOp
            crumbsTop: '',
            crumbsFlag: false,
            crumbs: [
                {
                    title: '行业洞察',
                    on: false,
                },
                {
                    title: '解决方案',
                    on: false,
                },
                {
                    title: '应用场景',
                    on: false,
                },
                {
                    title: '业务价值',
                    on: false,
                },
                {
                    title: '客户案例',
                    on: false,
                },
            ],
            swpNot: false, //禁止轮播滑动

            // 接口数据
            host: hostUrl,
            // 第一屏 banner
            caseBanner: {},
            // 第二屏
            caseFmodel: {},

            caseScene: {},
            caseSceneList: [],
            // 第三屏
            caseMatrix: {},
            caseMatrixList: [],
            // 
            caseService: {},
            caseServiceList:[],
            // 第六屏 案例
            caseCase: {},
            caseCaseList: [],
            // seo信息
            seoInfo: {
                title: '',
                keyWords: '',
                description: ''
            },
            // 全局seo
            seoAllInfo: {},
        }
    },

    mounted() {

        this.getData()

        this.wW = document.documentElement.clientWidth || document.body.clientWidth //获取浏览器宽度

        this.seoAllInfo = JSON.parse(localStorage.getItem('yuanshu_seoInfo')) //获取seo全局信息

        // this.case5Top = $('.case5').offset().top
        let case5Top = document.getElementById('case5')
        this.case5Top = case5Top.offsetTop



        // this.crumbsTop = $('.public-crumbs').offset().top
        let crumbsTop = document.getElementById('public-crumbs')
        this.crumbsTop = crumbsTop.offsetTop
        this.$bus.emit("crumbsTop", crumbsTop.offsetTop)


        // 行业洞察轮播
        if (this.wW > 1200) {
            // $('.case1 .swiper-container').addClass('swiper-no-swiping')
            this.swpNot = true  // 轮播图pc端禁止滑动
        }




        // 百度统计代码
        var _hmt = _hmt || [];
        (function () {
            var hm = document.createElement("script");
            hm.src = "https://hm.baidu.com/hm.js?77637ce1a84bee069c1effb2174121bb";
            var s = document.getElementsByTagName("script")[0];
            s.parentNode.insertBefore(hm, s);
        })();
    },
    computed: {


    },
    destroyed() {
        // 离开页面时取消二级导航浮动        
        // this.$bus.$off("headShow");
    },
    methods: {
        // 请求后端接口
        getData() {


            console.log(this.$route.query, '路由id')

            let id = this.$route.query.id

            // 第一屏
            this.caseBanner = {}
            // 第二屏
            this.caseFmodel = {}

            this.caseScene = {}
            // 第三屏 解决方案
            this.caseMatrix = {}
            this.caseMatrixList = []
            // 
            this.caseService = {}
            // 第六屏 案例
            this.caseCase = {}
            this.caseCaseList = []

            this.seoInfo={
                title:'',
                keyWords:'',
                description:''
            }

            // banner描述
            this.$axios.get('/api/solution/' + id).then(res => {

                // seo信息
        if (res.data.data.name != '' && res.data.data.name != null && res.data.data.name != undefined) {
                    this.seoInfo.title = res.data.data.name
        }else{
                    this.seoInfo.title=this.seoAllInfo.title
        }

                if (res.data.data.seoKeyword != '' && res.data.data.seoKeyword != null && res.data.data.seoKeyword != undefined) {
                    this.seoInfo.keyWords = res.data.data.seoKeyword
                }else{
                    this.seoInfo.keyWords=this.seoAllInfo.keyWords
                }

                if (res.data.data.seoDesc != '' && res.data.data.seoDesc != null && res.data.data.seoDesc != undefined) {
                    this.seoInfo.description = res.data.data.seoDesc
                }else{
                    this.seoInfo.description=this.seoAllInfo.description
                }

                if (res.data.data.blocksObj.banner[0] != '') {
                    this.caseBanner = res.data.data.blocksObj.banner[0]
                }
                this.caseFmodel = res.data.data.blocksObj.fmodel
                this.caseMatrix = res.data.data.blocksObj.matrix
                this.caseMatrixList = res.data.data.blocksObj.matrix.list


                var caseSceneTemp = res.data.data.blocksObj.scene
                for (var i = 0; i < caseSceneTemp.list.length; i++) {
                    caseSceneTemp.list[i].on = false
                    caseSceneTemp.list[0].on = true
                    caseSceneTemp.list[i].text = caseSceneTemp.list[i].text.split('\n')
                }
                this.caseScene = caseSceneTemp
                this.caseSceneList = this.caseScene.list
                if (caseSceneTemp.list.length > 0) {
                    this.case3Img = caseSceneTemp.list[0].image
                }

                var caseServiceTemp = res.data.data.blocksObj.service
                // 判断业务价值的数量，如果是3个，就默认全部展开，如果数组长度的单数，那么最后一行的第3个默认展开，展开就是on为真
                for (var i = 0; i < caseServiceTemp.list.length; i++) {
                    caseServiceTemp.list[i].on = false
                    if (caseServiceTemp.list.length <= 3) {
                        caseServiceTemp.list[i].on = true
                    } else if (caseServiceTemp.list.length % 2 != 0) {
                        var aseService_onNum = caseServiceTemp.list.length - 3 //最后一行的第3个
                        caseServiceTemp.list[aseService_onNum].on = true
                    }
                }
                this.caseService = caseServiceTemp
                this.caseServiceList=res.data.data.blocksObj.service.list

                this.caseCase = res.data.data.blocksObj.case
                this.caseCaseList = this.caseCase.list


                console.log(res, 'caseService')

                this.$nextTick(() => {

                    setTimeout(() => {
                        this.case1Swp()
                    }, 0)

                    setTimeout(() => {
                        this.case5Swp()
                    }, 100)

                    // wow动画
                    new this.$wow.WOW({
                        live: false,
                    }).init()

                    window.addEventListener('scroll', this.handleScroll)

                })


            })


        },
        toast() {
            this.$toast.show('页面建设中，请耐心等待…', '您可通过电话或微信扫描底部二维码联系我们', 5000)
        },
        handleScroll() {
            // this.$nextTick(() => {
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            // console.log(scrollTop, this.about1Top)
            if (scrollTop >= this.case5Top) {
                if (this.scrollFlag == false) {
                    for (var i = 0; i < this.$refs.caseNum.length; i++) {
                        this.$refs.caseNum[i].start()
                    }
                    this.scrollFlag = true
                }
            }

            // 二级导航浮动
            var that = this
            setTimeout(function () {
                if (scrollTop >= that.crumbsTop) {
                    that.crumbsFlag = true
                    // this.$bus.emit("headShow",false);
                } else {
                    that.crumbsFlag = false
                    // this.$bus.emit("headShow",true);
                }
            }, 50)


            // })
        },
        case3NavFn(index) {
            for (var i = 0; i < this.caseScene.list.length; i++) {
                this.caseScene.list[i].on = false
            }
            this.caseScene.list[index].on = true
            this.case3Img = this.caseScene.list[index].image
        },
        case3Mouseover(index1, index2) {
            this.case3Img = this.caseScene.list[index1].image
            // console.log(1)
        },
        // 锚链接跳转
        scrollIntoView(index) {
            let element = document.getElementById(index)
            element.scrollIntoView({
                behavior: 'smooth'
            })
            for (var i = 0; i < this.crumbs.length; i++) {
                this.crumbs[i].on = false
            }
            this.crumbs[index].on = true
        },
        formatter(num) {
            return num.toFixed(1)
        },
        case1Swp() {
            var autoplayFlag=''
            if(this.wW<=770){
                autoplayFlag=true
            }else{
                autoplayFlag=false
            }
            var case1 = new Swiper('.case1 .swiper-container', {
                // autoplay: false,
                autoplay: autoplayFlag,
                autoplay: {
                    delay: 5000,
                },
                slidesPerView: 4,
                // loop: true,
                spaceBetween: 40,
                observer: true,
                observeParents: true,
                pagination: {
                    el: '.case1 .swiper-pagination',
                    clickable: true,
                },
                breakpoints: {
                    1366: {
                        spaceBetween: 30,
                    },
                    1200: {
                        slidesPerView: 3,
                    },
                    768: {
                        slidesPerView: 1,
                        spaceBetween: 30,
                    }
                }
            })
        },
        case5Swp() {
            // 客户案例轮播
            var case5Swp = new Swiper('.case5Swp .swiper-container', {
                autoplay: {
                  delay: 5000,
                },
                slidesPerView: 1,
                // loop: true,
                effect: 'fade',
                observer: true,
                observeParents: true,
                fadeEffect: {
                    crossFade: true,
                },
                pagination: {
                    el: '.case5Swp .swiper-pagination',
                    clickable: true,
                },
                on: {
                    init: function (swiper) {
                        // swiperAnimateCache(this); //隐藏动画元素
                        // swiperAnimate(this);
                    },
                    slideChangeTransitionEnd: function () {
                        // swiperAnimate(this); //每个slide切换结束时也运行当前slide动画
                        //this.slides.eq(this.activeIndex).find('.ani').removeClass('ani'); 动画只展现一次，去除ani类名

                    }
                },
            })
        },

    },
};
</script>
  
<style scoped lang="less"></style>