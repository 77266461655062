<template>
    <div class="product">

        <!-- 内页顶部banner -->
        <div class="public-nybn case-bn pro-bn">
            <img class="bgimg2" src="../assets/images/ys-83.png">
            <img class="bgimg3" src="../assets/images/ys-82.png">
            <div class="bgimg scaleDraw" v-if="proBanner.image">
                <img :src="host + proBanner.image">
            </div>
            <div class="zz-box">

                <div class="bn-title fadeInUp2">
                    <div class="container">
                        <div class="c-title">
                            <h2 class="h2 font40 text_b">
                                {{ proBanner.title }}
                            </h2>
                        </div>
                        <div class="font18 c-text">
                            {{ proBanner.text }}
                        </div>
                        <div class="btnbox">
                            <router-link v-if="proBanner.link" :to="proBanner.link" class="hi-btn1">
                                <span class="hi-text font16">{{ proBanner.btnname }}</span>
                                <img class="hi-ico" src="../assets/images/hi-btn-arrow1.svg" onload="SVGInject(this)">
                            </router-link>
                        </div>
                    </div>
                </div>


            </div>
        </div>

        <!-- 服务化系统 -->
        <div class="product1 pdtb" v-show="fmodel_title != ''">
            <div class="container">
                <div class="public-title wow fadeInUp">
                    <h2 class="font40 text_b h2">
                        <span>{{ fmodel_title }}</span>
                    </h2>
                </div>

                <div class="swpBox wow fadeInUp">
                    <div class="pro1SwpNav">
                        <div class="swiper-container">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide" v-for="(item, index) in proFmodel" :key="index">
                                    <div class="c-icon">
                                        <img class="icon1" :src="host + item.icons" alt="图片不存在">
                                        <img class="icon2" :src="host + item.icon" alt="图片不存在">
                                    </div>
                                    <div class="c-text font20 text_b">{{ item.name }}</div>
                                </div>
                                <!-- <div class="swiper-slide">
                                    <div class="c-icon">
                                        <img class="icon1" src="../assets/images/ys-227.png" alt="图片不存在">
                                        <img class="icon2" src="../assets/images/ys-227-2.png" alt="图片不存在">
                                    </div>
                                    <div class="c-text font20">数据应用</div>
                                </div> -->
                            </div>
                        </div>
                    </div>

                    <div class="pro1Swp">
                        <div class="swiper-container">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide" v-for="(item1, index1) in proFmodel" :key="index1">
                                    <div class="liBox">
                                        <div class="zz-left">
                                            <div class="c-box">
                                                <div class="c-title">
                                                    <img :src="host + item1.icon" alt="图片不存在">
                                                    <h4 class="font30 text_b h4">{{ item1.title }}</h4>
                                                </div>
                                                <ul class="c-ul">
                                                    <li class="c-li" v-for="(item2, index2) in item1.text" :key="index2">
                                                        <div class="a">
                                                            <div class="dian">
                                                                <span class="point"></span>
                                                                <span class="span1"></span>
                                                            </div>
                                                            <div class="c-text font18">{{ item2 }}</div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div class="btnbox">
                                                <router-link v-if="item1.link" :to="item1.link" class="hi-btn1">
                                                    <span class="hi-text font16">了解更多</span>
                                                    <img class="hi-ico" src="../assets/images/hi-btn-arrow1.svg"
                                                        onload="SVGInject(this)">
                                                </router-link>
                                            </div>

                                        </div>
                                        <div class="zz-right" @click="product1VideoPlay(item1.video)">
                                            <div class="imgbox">
                                                <img :src="host + item1.image" alt="图片不存在">
                                            </div>

                                            <div class="btnbox" v-if="product1Von">
                                                <div class="hi-btn12">
                                                    <img class="hi-ico" src="../assets/images/ys-230.svg"
                                                        onload="SVGInject(this)">
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>

                                <!-- <div class="swiper-slide">
                                    <div class="liBox">
                                        <div class="zz-left">
                                            <div class="c-box">
                                                <div class="c-title">
                                                    <img src="../assets/images/ys-228.png" alt="图片不存在">
                                                    <h4 class="font30 text_b h4">用户互动，伙伴互动，员工互动2</h4>
                                                </div>
                                                <ul class="c-ul">
                                                    <li class="c-li">
                                                        <div class="a">
                                                            <div class="dian">
                                                                <span class="point"></span>
                                                                <span class="span1"></span>
                                                            </div>
                                                            <div class="c-text font18">实现企业、用户、伙伴间数字化协同</div>
                                                        </div>
                                                    </li>
                                                    <li class="c-li">
                                                        <div class="a">
                                                            <div class="dian">
                                                                <span class="point"></span>
                                                                <span class="span1"></span>
                                                            </div>
                                                            <div class="c-text font18">用系统实现信息畅通便捷，保障企业运转</div>
                                                        </div>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div class="btnbox">
                                                <a href="javascript:;" rel="nofollow" @click="toast()" class="hi-btn1">
                                                    <span class="hi-text font16">了解更多</span>
                                                    <img class="hi-ico" src="../assets/images/hi-btn-arrow1.svg"
                                                        onload="SVGInject(this)">
                                                </a>
                                            </div>

                                        </div>
                                        <div class="zz-right">
                                            <div class="imgbox">
                                                <img src="../assets/images/ys-229.png" alt="图片不存在">
                                            </div>

                                            <div class="btnbox">
                                                <a href="javascript:;" rel="nofollow" class="hi-btn12">
                                                    <img class="hi-ico" src="../assets/images/ys-230.svg"
                                                        onload="SVGInject(this)">
                                                </a>
                                            </div>

                                        </div>

                                    </div>
                                </div> -->


                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>

        <!-- 核心能力 -->
        <div class="product2 pdtb" v-show="proAccept.title != ''">
            <div class="container">

                <div class="public-ani-bgimg public-ani-bgimg1">
                    <img src="../assets/images/ys-82.png" alt="图片不存在">
                </div>
                <div class="public-ani-bgimg public-ani-bgimg4">
                    <img src="../assets/images/ys-82.png" alt="图片不存在">
                </div>

                <div class="public-title wow fadeInUp">
                    <h2 class="font40 text_b h2">
                        <span>{{ proAccept.title }}</span>
                    </h2>
                </div>

                <div class="imgbox wow fadeInUp" @click="product2Fn()">
                    <img :src="host + proAccept.image" alt="图片不存在">
                </div>

            </div>
        </div>

        <!-- 第四屏 产品矩阵 -->
        <div class="product3 pdtb" v-show="proMatrix1.title != '' || proMatrix2.title != ''">
            <div class="container">

                <div v-if="proMatrix1 != ''">
                    <div class="public-title wow fadeInUp">
                        <h2 class="font40 text_b h2">
                            <span>{{ proMatrix1.title }}</span>
                        </h2>
                    </div>

                    <ul class="public-list public-list2 wow fadeInUp"
                        :class="(proMatrix1.list.length >= 5) ? '' : 'public-listTwo'">

                        <li class="c-li" v-for="(item1, index1) in proMatrix1.list" :key="index1">
                            <img class="bgimg" src="../assets/images/ys-190.png" alt="图片不存在">
                            <div class="imgbox">
                                <img :src="host + item1.icon" alt="图片不存在">
                            </div>
                            <h3 class="h3 font24 text_b">
                                {{ item1.name }}
                            </h3>
                            <div class="c-text">
                                <router-link :to="item1.link" class="a">
                                    <span class="font16 text_b">产品详情</span>
                                    <img src="../assets/images/ys-188.png" alt="图片不存在">
                                </router-link>
                                <div class="c-div font">
                                    {{ item1.desc }}
                                </div>
                            </div>


                            <div class="c-box" v-if="item1.text!='' && item1.text!=undefined">
                                <div class="syb"></div>
                                <div class="contBox">
                                    <div class="imgbox3">
                                        <img src="../assets/images/ys-273.png" alt="图片不存在">
                                    </div>
                                    <div class="c-ul2">
                                        <div class="c-li2" v-for="(item2, index2) in item1.text" :key="index2">
                                            <img class="c-icon" src="../assets/images/ys-272.png" alt="图片不存在">
                                            <div class="c-p font16">
                                                {{ item2 }}
                                            </div>
                                        </div>

                                        <!-- <div class="c-li2">
                                            <img class="c-icon" src="../assets/images/ys-272.png" alt="图片不存在">
                                            <div class="c-p font16">
                                                全渠道一体化管理，聚合三方流量，拓展生意机会
                                            </div>
                                        </div>

                                        <div class="c-li2">
                                            <img class="c-icon" src="../assets/images/ys-272.png" alt="图片不存在">
                                            <div class="c-p font16">
                                                先进的技术架构，支撑业务快速变化
                                            </div>
                                        </div> -->

                                    </div>
                                </div>
                            </div>
                        </li>

                        <!-- <li class="c-li">
                            <img class="bgimg" src="../assets/images/ys-190.png" alt="图片不存在">
                            <div class="imgbox">
                                <img src="../assets/images/ys-262.png" alt="图片不存在">
                            </div>
                            <h3 class="h3 font24 text_b">
                                数智会员
                            </h3>
                            <div class="c-text">
                                <a href="javascript:;" rel="nofollow" @click="toast()" class="a">
                                    <span class="font16 text_b">产品详情</span>
                                    <img src="../assets/images/ys-188.png" alt="图片不存在">
                                </a>
                                <div class="c-div font">
                                    全渠道会员经营与忠诚度管理体系
                                </div>
                            </div>


                            <div class="c-box">
                                <div class="syb"></div>
                                <div class="contBox">
                                    <div class="imgbox3">
                                        <img src="../assets/images/ys-273.png" alt="图片不存在">
                                    </div>
                                    <div class="c-ul2">
                                        <div class="c-li2">
                                            <img class="c-icon" src="../assets/images/ys-272.png" alt="图片不存在">
                                            <div class="c-p font16">
                                                会员管理一体化，跨平台会员资产良性循环
                                            </div>
                                        </div>
                                        <div class="c-li2">
                                            <img class="c-icon" src="../assets/images/ys-272.png" alt="图片不存在">
                                            <div class="c-p font16">
                                                丰富的忠诚度体系建设，全方位锁定会员忠诚度
                                            </div>
                                        </div>
                                        <div class="c-li2">
                                            <img class="c-icon" src="../assets/images/ys-272.png" alt="图片不存在">
                                            <div class="c-p font16">
                                                简单易用，灵活开放，为不同行业提供自定义配置空间
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li class="c-li">
                            <img class="bgimg" src="../assets/images/ys-190.png" alt="图片不存在">
                            <div class="imgbox">
                                <img src="../assets/images/ys-263.png" alt="图片不存在">
                            </div>
                            <h3 class="h3 font24 text_b">
                                数智门店
                            </h3>
                            <div class="c-text">
                                <a href="javascript:;" rel="nofollow" @click="toast()" class="a">
                                    <span class="font16 text_b">产品详情</span>
                                    <img src="../assets/images/ys-188.png" alt="图片不存在">
                                </a>
                                <div class="c-div font">
                                    构建数字化门店，以“智”取胜
                                </div>
                            </div>


                            <div class="c-box">
                                <div class="syb"></div>
                                <div class="contBox">
                                    <div class="imgbox3">
                                        <img src="../assets/images/ys-273.png" alt="图片不存在">
                                    </div>
                                    <div class="c-ul2">
                                        <div class="c-li2">
                                            <img class="c-icon" src="../assets/images/ys-272.png" alt="图片不存在">
                                            <div class="c-p font16">
                                                人、货、场数字化管理，多维度提效
                                            </div>
                                        </div>
                                        <div class="c-li2">
                                            <img class="c-icon" src="../assets/images/ys-272.png" alt="图片不存在">
                                            <div class="c-p font16">
                                                移动化作业，店务提效、服务提效、履约提效
                                            </div>
                                        </div>
                                        <div class="c-li2">
                                            <img class="c-icon" src="../assets/images/ys-272.png" alt="图片不存在">
                                            <div class="c-p font16">
                                                智慧大屏，人机互动，提升门店购物体验
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li class="c-li">
                            <img class="bgimg" src="../assets/images/ys-190.png" alt="图片不存在">
                            <div class="imgbox">
                                <img src="../assets/images/ys-264.png" alt="图片不存在">
                            </div>
                            <h3 class="h3 font24 text_b">
                                数智员工
                            </h3>
                            <div class="c-text">
                                <a href="javascript:;" rel="nofollow" @click="toast()" class="a">
                                    <span class="font16 text_b">产品详情</span>
                                    <img src="../assets/images/ys-188.png" alt="图片不存在">
                                </a>
                                <div class="c-div font">
                                    激活员工效率，助力员工成长
                                </div>
                            </div>


                            <div class="c-box">
                                <div class="syb"></div>
                                <div class="contBox">
                                    <div class="imgbox3">
                                        <img src="../assets/images/ys-273.png" alt="图片不存在">
                                    </div>
                                    <div class="c-ul2">
                                        <div class="c-li2">
                                            <img class="c-icon" src="../assets/images/ys-272.png" alt="图片不存在">
                                            <div class="c-p font16">
                                                任务驱动，业绩透明，实时提成，有效激励员工
                                            </div>
                                        </div>
                                        <div class="c-li2">
                                            <img class="c-icon" src="../assets/images/ys-272.png" alt="图片不存在">
                                            <div class="c-p font16">
                                                学习平台、经营培训、知识库…持续提升员工能力
                                            </div>
                                        </div>




                                    </div>
                                </div>
                            </div>
                        </li>

                        <li class="c-li">
                            <img class="bgimg" src="../assets/images/ys-190.png" alt="图片不存在">
                            <div class="imgbox">
                                <img src="../assets/images/ys-265.png" alt="图片不存在">
                            </div>
                            <h3 class="h3 font24 text_b">
                                数智营运
                            </h3>
                            <div class="c-text">
                                <a href="javascript:;" rel="nofollow" @click="toast()" class="a">
                                    <span class="font16 text_b">产品详情</span>
                                    <img src="../assets/images/ys-188.png" alt="图片不存在">
                                </a>
                                <div class="c-div font">
                                    智能化，可视化，数字化，助力经营提效
                                </div>
                            </div>


                            <div class="c-box">
                                <div class="syb"></div>
                                <div class="contBox">
                                    <div class="imgbox3">
                                        <img src="../assets/images/ys-273.png" alt="图片不存在">
                                    </div>
                                    <div class="c-ul2">
                                        <div class="c-li2">
                                            <img class="c-icon" src="../assets/images/ys-272.png" alt="图片不存在">
                                            <div class="c-p font16">
                                                智能选址、品类规划，科学决策
                                            </div>
                                        </div>
                                        <div class="c-li2">
                                            <img class="c-icon" src="../assets/images/ys-272.png" alt="图片不存在">
                                            <div class="c-p font16">
                                                可视化货架，一键指导门店陈列
                                            </div>
                                        </div>
                                        <div class="c-li2">
                                            <img class="c-icon" src="../assets/images/ys-272.png" alt="图片不存在">
                                            <div class="c-p font16">
                                                智慧巡店，AI赋能，准确高效
                                            </div>
                                        </div>
                                        <div class="c-li2">
                                            <img class="c-icon" src="../assets/images/ys-272.png" alt="图片不存在">
                                            <div class="c-p font16">
                                                多角色任务，定向布达，实时监控进度
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li class="c-li">
                            <img class="bgimg" src="../assets/images/ys-190.png" alt="图片不存在">
                            <div class="imgbox">
                                <img src="../assets/images/ys-266.png" alt="图片不存在">
                            </div>
                            <h3 class="h3 font24 text_b">
                                数智供应链
                            </h3>
                            <div class="c-text">
                                <a href="javascript:;" rel="nofollow" @click="toast()" class="a">
                                    <span class="font16 text_b">产品详情</span>
                                    <img src="../assets/images/ys-188.png" alt="图片不存在">
                                </a>
                                <div class="c-div font">
                                    面向上游供应商高效协同，信息互联
                                </div>
                            </div>


                            <div class="c-box">
                                <div class="syb"></div>
                                <div class="contBox">
                                    <div class="imgbox3">
                                        <img src="../assets/images/ys-273.png" alt="图片不存在">
                                    </div>
                                    <div class="c-ul2">
                                        <div class="c-li2">
                                            <img class="c-icon" src="../assets/images/ys-272.png" alt="图片不存在">
                                            <div class="c-p font16">
                                                从合作到撤场，全生命周期在线化管理
                                            </div>
                                        </div>
                                        <div class="c-li2">
                                            <img class="c-icon" src="../assets/images/ys-272.png" alt="图片不存在">
                                            <div class="c-p font16">
                                                单据自动流转，即时高效协同
                                            </div>
                                        </div>
                                        <div class="c-li2">
                                            <img class="c-icon" src="../assets/images/ys-272.png" alt="图片不存在">
                                            <div class="c-p font16">
                                                业务透明，信息互通，过程可视、可控
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li class="c-li">
                            <img class="bgimg" src="../assets/images/ys-190.png" alt="图片不存在">
                            <div class="imgbox">
                                <img src="../assets/images/ys-267.png" alt="图片不存在">
                            </div>
                            <h3 class="h3 font24 text_b">
                                数智物流
                            </h3>
                            <div class="c-text">
                                <a href="javascript:;" rel="nofollow" @click="toast()" class="a">
                                    <span class="font16 text_b">产品详情</span>
                                    <img src="../assets/images/ys-188.png" alt="图片不存在">
                                </a>
                                <div class="c-div font">
                                    精细化管理，降损提效
                                </div>
                            </div>


                            <div class="c-box">
                                <div class="syb"></div>
                                <div class="contBox">
                                    <div class="imgbox3">
                                        <img src="../assets/images/ys-273.png" alt="图片不存在">
                                    </div>
                                    <div class="c-ul2">
                                        <div class="c-li2">
                                            <img class="c-icon" src="../assets/images/ys-272.png" alt="图片不存在">
                                            <div class="c-p font16">
                                                移动化无纸化操作，电子称台分货，高效节能
                                            </div>
                                        </div>
                                        <div class="c-li2">
                                            <img class="c-icon" src="../assets/images/ys-272.png" alt="图片不存在">
                                            <div class="c-p font16">
                                                自动化补货与找货，支持成批发货/一键出库
                                            </div>
                                        </div>
                                        <div class="c-li2">
                                            <img class="c-icon" src="../assets/images/ys-272.png" alt="图片不存在">
                                            <div class="c-p font16">
                                                营采一体，缩短生鲜流通链路，降低生鲜损耗
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li class="c-li">
                            <img class="bgimg" src="../assets/images/ys-190.png" alt="图片不存在">
                            <div class="imgbox">
                                <img src="../assets/images/ys-268.png" alt="图片不存在">
                            </div>
                            <h3 class="h3 font24 text_b">
                                数智分销
                            </h3>
                            <div class="c-text">
                                <a href="javascript:;" rel="nofollow" @click="toast()" class="a">
                                    <span class="font16 text_b">产品详情</span>
                                    <img src="../assets/images/ys-188.png" alt="图片不存在">
                                </a>
                                <div class="c-div font">
                                    覆盖全场景全链路，让流通更高效
                                </div>
                            </div>


                            <div class="c-box">
                                <div class="syb"></div>
                                <div class="contBox">
                                    <div class="imgbox3">
                                        <img src="../assets/images/ys-273.png" alt="图片不存在">
                                    </div>
                                    <div class="c-ul2">
                                        <div class="c-li2">
                                            <img class="c-icon" src="../assets/images/ys-272.png" alt="图片不存在">
                                            <div class="c-p font16">
                                                一键订货全程可视化跟踪，告别错单漏单
                                            </div>
                                        </div>
                                        <div class="c-li2">
                                            <img class="c-icon" src="../assets/images/ys-272.png" alt="图片不存在">
                                            <div class="c-p font16">
                                                精准客户管控，高效触达，多样化权益提升粘性
                                            </div>
                                        </div>
                                        <div class="c-li2">
                                            <img class="c-icon" src="../assets/images/ys-272.png" alt="图片不存在">
                                            <div class="c-p font16">
                                                从客户到司机，角色化专属界面，移动端便捷协同
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li class="c-li">
                            <img class="bgimg" src="../assets/images/ys-190.png" alt="图片不存在">
                            <div class="imgbox">
                                <img src="../assets/images/ys-269.png" alt="图片不存在">
                            </div>
                            <h3 class="h3 font24 text_b">
                                数智财务
                            </h3>
                            <div class="c-text">
                                <a href="javascript:;" rel="nofollow" @click="toast()" class="a">
                                    <span class="font16 text_b">产品详情</span>
                                    <img src="../assets/images/ys-188.png" alt="图片不存在">
                                </a>
                                <div class="c-div font">
                                    新零售下的全场景一体化业财产品体系
                                </div>
                            </div>


                            <div class="c-box">
                                <div class="syb"></div>
                                <div class="contBox">
                                    <div class="imgbox3">
                                        <img src="../assets/images/ys-273.png" alt="图片不存在">
                                    </div>
                                    <div class="c-ul2">
                                        <div class="c-li2">
                                            <img class="c-icon" src="../assets/images/ys-272.png" alt="图片不存在">
                                            <div class="c-p font16">
                                                连接业务和财务数据信息，提升资源配置能力
                                            </div>
                                        </div>
                                        <div class="c-li2">
                                            <img class="c-icon" src="../assets/images/ys-272.png" alt="图片不存在">
                                            <div class="c-p font16">
                                                费用自动化计算，协同对账，一键开票，全面数字化结算
                                            </div>
                                        </div>
                                        <div class="c-li2">
                                            <img class="c-icon" src="../assets/images/ys-272.png" alt="图片不存在">
                                            <div class="c-p font16">
                                                跨公司资产管理，一键请购，在线审批，灵活管理
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li class="c-li">
                            <img class="bgimg" src="../assets/images/ys-190.png" alt="图片不存在">
                            <div class="imgbox">
                                <img src="../assets/images/ys-270.png" alt="图片不存在">
                            </div>
                            <h3 class="h3 font24 text_b">
                                数智客服
                            </h3>
                            <div class="c-text">
                                <a href="javascript:;" rel="nofollow" @click="toast()" class="a">
                                    <span class="font16 text_b">产品详情</span>
                                    <img src="../assets/images/ys-188.png" alt="图片不存在">
                                </a>
                                <div class="c-div font">
                                    全渠道接入，智能机器人，高效响应
                                </div>
                            </div>




                        </li> -->



                    </ul>
                </div>

                <div v-if="proMatrix2 != ''">
                    <div class="public-title wow fadeInUp">
                        <h2 class="font40 text_b h2">
                            <span>{{ proMatrix2.title }}</span>
                        </h2>
                    </div>
                    <div class="li-box">
                        <div class="li" v-for="(item1, index1) in proMatrix2.list" :key="index1">
                            <div class="liBox">
                                <div class="zz-left wow" :class="index1 % 2 == 0 ? 'fadeRight' : 'fadeLeft'">
                                    <div class="c-box">
                                        <div class="c-title-box">
                                            <div class="c-title">
                                                <img :src="host + item1.icon" alt="图片不存在">
                                                <h4 class="font30 text_b h4">{{ item1.name }}</h4>
                                            </div>
                                            <div class="c-cont font18">
                                                {{ item1.desc }}
                                            </div>
                                        </div>
                                        <ul class="c-ul on">
                                            <li class="c-li" v-for="(item2, index2) in item1.text" :key="index2">
                                                <div class="a">
                                                    <div class="c-icon">
                                                        <img src="../assets/images/ys-234.png" alt="图片不存在">
                                                    </div>
                                                    <div class="c-text font18">{{ item2 }}</div>
                                                </div>
                                            </li>

                                            <!-- <li class="c-li">
                                                <div class="a">
                                                    <div class="c-icon">
                                                        <img src="../assets/images/ys-234.png" alt="图片不存在">
                                                    </div>
                                                    <div class="c-text font18">数字化运营赋能闭环</div>
                                                </div>
                                            </li>

                                            <li class="c-li">
                                                <div class="a">
                                                    <div class="c-icon">
                                                        <img src="../assets/images/ys-234.png" alt="图片不存在">
                                                    </div>
                                                    <div class="c-text font18">加强与用户的直接连接</div>
                                                </div>
                                            </li>

                                            <li class="c-li">
                                                <div class="a">
                                                    <div class="c-icon">
                                                        <img src="../assets/images/ys-234.png" alt="图片不存在">
                                                    </div>
                                                    <div class="c-text font18">自动化智能场景营销</div>
                                                </div>
                                            </li>

                                            <li class="c-li">
                                                <div class="a">
                                                    <div class="c-icon">
                                                        <img src="../assets/images/ys-234.png" alt="图片不存在">
                                                    </div>
                                                    <div class="c-text font18">通过数据驱动业务增长</div>
                                                </div>
                                            </li> -->

                                        </ul>
                                    </div>

                                    <div class="btnbox">
                                        <router-link v-if="item1.link" :to="item1.link" class="hi-btn1">
                                            <span class="hi-text font16">产品详情</span>
                                            <img class="hi-ico" src="../assets/images/hi-btn-arrow1.svg"
                                                onload="SVGInject(this)">
                                        </router-link>
                                    </div>

                                </div>
                                <div class="zz-right wow" :class="index1 % 2 != 0 ? 'fadeRight' : 'fadeLeft'">
                                    <div class="imgbox">
                                        <img v-if="item1.image" :src="host + item1.image" alt="图片不存在">
                                    </div>

                                </div>

                            </div>
                        </div>

                        <!-- <div class="li">
                            <div class="liBox">
                                <div class="zz-left wow fadeLeft">
                                    <div class="c-box">
                                        <div class="c-title-box">
                                            <div class="c-title">
                                                <img src="../assets/images/ys-236.png" alt="图片不存在">
                                                <h4 class="font30 text_b h4">数玲珑·自动补货</h4>
                                            </div>
                                            <div class="c-cont font18">
                                                面向超市、便利店、连锁店等零售行业，助力商户提高商品陈列和销售效率，降低人工成本和库存压力
                                            </div>
                                        </div>
                                        <ul class="c-ul on">
                                            <li class="c-li">
                                                <div class="a">
                                                    <div class="c-icon">
                                                        <img src="../assets/images/ys-234.png" alt="图片不存在">
                                                    </div>
                                                    <div class="c-text font18">高度适配客户业务流程</div>
                                                </div>
                                            </li>
                                            <li class="c-li">
                                                <div class="a">
                                                    <div class="c-icon">
                                                        <img src="../assets/images/ys-234.png" alt="图片不存在">
                                                    </div>
                                                    <div class="c-text font18">业务场景无缝衔接</div>
                                                </div>
                                            </li>

                                            <li class="c-li">
                                                <div class="a">
                                                    <div class="c-icon">
                                                        <img src="../assets/images/ys-234.png" alt="图片不存在">
                                                    </div>
                                                    <div class="c-text font18">提高企业经济效益，减少资源消耗</div>
                                                </div>
                                            </li>

                                            <li class="c-li">
                                                <div class="a">
                                                    <div class="c-icon">
                                                        <img src="../assets/images/ys-234.png" alt="图片不存在">
                                                    </div>
                                                    <div class="c-text font18">强大的技术架构支撑</div>
                                                </div>
                                            </li>

                                            <li class="c-li">
                                                <div class="a">
                                                    <div class="c-icon">
                                                        <img src="../assets/images/ys-234.png" alt="图片不存在">
                                                    </div>
                                                    <div class="c-text font18">丰富的行业经验，预置经典模型</div>
                                                </div>
                                            </li>

                                        </ul>
                                    </div>

                                    <div class="btnbox">
                                        <a href="javascript:;" rel="nofollow" @click="toast()" class="hi-btn1">
                                            <span class="hi-text font16">产品详情</span>
                                            <img class="hi-ico" src="../assets/images/hi-btn-arrow1.svg"
                                                onload="SVGInject(this)">
                                        </a>
                                    </div>

                                </div>
                                <div class="zz-right wow fadeRight">
                                    <div class="imgbox">
                                        <img src="../assets/images/ys-235.png" alt="图片不存在">
                                    </div>

                                </div>

                            </div>
                        </div>

                        <div class="li">
                            <div class="liBox">
                                <div class="zz-left wow fadeRight">
                                    <div class="c-box">
                                        <div class="c-title-box">
                                            <div class="c-title">
                                                <img src="../assets/images/ys-238.png" alt="图片不存在">
                                                <h4 class="font30 text_b h4">数玲珑·老板通</h4>
                                            </div>
                                            <div class="c-cont font18">
                                                提供给零售企业管理人员的销售数据移动查询工具，帮助企业管理者随时随地掌握门店经营数据的趋势变化，指导运营决策
                                            </div>
                                        </div>
                                        <ul class="c-ul on">
                                            <li class="c-li">
                                                <div class="a">
                                                    <div class="c-icon">
                                                        <img src="../assets/images/ys-234.png" alt="图片不存在">
                                                    </div>
                                                    <div class="c-text font18">移动便捷化管理全局</div>
                                                </div>
                                            </li>
                                            <li class="c-li">
                                                <div class="a">
                                                    <div class="c-icon">
                                                        <img src="../assets/images/ys-234.png" alt="图片不存在">
                                                    </div>
                                                    <div class="c-text font18">数据分析颗粒度更细</div>
                                                </div>
                                            </li>

                                            <li class="c-li">
                                                <div class="a">
                                                    <div class="c-icon">
                                                        <img src="../assets/images/ys-234.png" alt="图片不存在">
                                                    </div>
                                                    <div class="c-text font18">多维度、多指标自由切换</div>
                                                </div>
                                            </li>

                                            <li class="c-li">
                                                <div class="a">
                                                    <div class="c-icon">
                                                        <img src="../assets/images/ys-234.png" alt="图片不存在">
                                                    </div>
                                                    <div class="c-text font18">适配多种经营场景和模式</div>
                                                </div>
                                            </li>

                                        </ul>
                                    </div>

                                    <div class="btnbox">
                                        <a href="javascript:;" rel="nofollow" @click="toast()" class="hi-btn1">
                                            <span class="hi-text font16">产品详情</span>
                                            <img class="hi-ico" src="../assets/images/hi-btn-arrow1.svg"
                                                onload="SVGInject(this)">
                                        </a>
                                    </div>

                                </div>
                                <div class="zz-right  wow fadeLeft">
                                    <div class="imgbox">
                                        <img src="../assets/images/ys-237.png" alt="图片不存在">
                                    </div>

                                </div>

                            </div>
                        </div>

                        <div class="li">
                            <div class="liBox">
                                <div class="zz-left wow fadeLeft">
                                    <div class="c-box">
                                        <div class="c-title-box">
                                            <div class="c-title">
                                                <img src="../assets/images/ys-240.png" alt="图片不存在">
                                                <h4 class="font30 text_b h4">数玲珑·店总北极星</h4>
                                            </div>
                                            <div class="c-cont font18">
                                                数据驱动经营的移动端管理工具
                                            </div>
                                        </div>
                                        <ul class="c-ul on">
                                            <li class="c-li">
                                                <div class="a">
                                                    <div class="c-icon">
                                                        <img src="../assets/images/ys-234.png" alt="图片不存在">
                                                    </div>
                                                    <div class="c-text font18">商品结构化分析，自动化诊断，深入洞察生意机会</div>
                                                </div>
                                            </li>
                                            <li class="c-li">
                                                <div class="a">
                                                    <div class="c-icon">
                                                        <img src="../assets/images/ys-234.png" alt="图片不存在">
                                                    </div>
                                                    <div class="c-text font18">重点单品分析，驱动门店精细运营</div>
                                                </div>
                                            </li>

                                            <li class="c-li">
                                                <div class="a">
                                                    <div class="c-icon">
                                                        <img src="../assets/images/ys-234.png" alt="图片不存在">
                                                    </div>
                                                    <div class="c-text font18">会员全局洞察，驱动会员产值提升</div>
                                                </div>
                                            </li>

                                        </ul>
                                    </div>

                                    <div class="btnbox">
                                        <a href="javascript:;" rel="nofollow" @click="toast()" class="hi-btn1">
                                            <span class="hi-text font16">产品详情</span>
                                            <img class="hi-ico" src="../assets/images/hi-btn-arrow1.svg"
                                                onload="SVGInject(this)">
                                        </a>
                                    </div>

                                </div>
                                <div class="zz-right wow fadeRight">
                                    <div class="imgbox">
                                        <img src="../assets/images/ys-239.png" alt="图片不存在">
                                    </div>

                                </div>

                            </div>
                        </div>

                        <div class="li">
                            <div class="liBox">
                                <div class="zz-left wow fadeRight">
                                    <div class="c-box">
                                        <div class="c-title-box">
                                            <div class="c-title">
                                                <img src="../assets/images/ys-242.png" alt="图片不存在">
                                                <h4 class="font30 text_b h4">数玲珑·经营大屏</h4>
                                            </div>
                                            <div class="c-cont font18">
                                                以灵活度高的组件自定义配置数字大屏功能模块，通过可视化的数据呈现，推动业务决策，实现生产经营的精细化管理
                                            </div>
                                        </div>
                                        <ul class="c-ul on">
                                            <li class="c-li">
                                                <div class="a">
                                                    <div class="c-icon">
                                                        <img src="../assets/images/ys-234.png" alt="图片不存在">
                                                    </div>
                                                    <div class="c-text font18">聚焦关键业务的决策场景</div>
                                                </div>
                                            </li>
                                            <li class="c-li">
                                                <div class="a">
                                                    <div class="c-icon">
                                                        <img src="../assets/images/ys-234.png" alt="图片不存在">
                                                    </div>
                                                    <div class="c-text font18">经营数据实时动态更新</div>
                                                </div>
                                            </li>

                                            <li class="c-li">
                                                <div class="a">
                                                    <div class="c-icon">
                                                        <img src="../assets/images/ys-234.png" alt="图片不存在">
                                                    </div>
                                                    <div class="c-text font18">维度指标灵活定义，图例表单按需定制</div>
                                                </div>
                                            </li>

                                            <li class="c-li">
                                                <div class="a">
                                                    <div class="c-icon">
                                                        <img src="../assets/images/ys-234.png" alt="图片不存在">
                                                    </div>
                                                    <div class="c-text font18">轻量级架构，灵活部署</div>
                                                </div>
                                            </li>

                                        </ul>
                                    </div>

                                    <div class="btnbox">
                                        <a href="javascript:;" rel="nofollow" @click="toast()" class="hi-btn1">
                                            <span class="hi-text font16">产品详情</span>
                                            <img class="hi-ico" src="../assets/images/hi-btn-arrow1.svg"
                                                onload="SVGInject(this)">
                                        </a>
                                    </div>

                                </div>
                                <div class="zz-right wow fadeLeft">
                                    <div class="imgbox">
                                        <img src="../assets/images/ys-241.png" alt="图片不存在">
                                    </div>

                                </div>

                            </div>
                        </div> -->

                    </div>
                </div>
            </div>
        </div>

        <!-- 产品优势 -->
        <div class="product4 pdtb" id="product4" v-show="proAdvantage.title != ''">
            <div class="container">
                <div class="public-ani-bgimg public-ani-bgimg1">
                    <img src="../assets/images/ys-82.png" alt="图片不存在">
                </div>
                <div class="public-ani-bgimg public-ani-bgimg4">
                    <img src="../assets/images/ys-82.png" alt="图片不存在">
                </div>

                <div class="public-title wow fadeInUp">
                    <h2 class="font40 text_b h2">
                        <span>{{ proAdvantage.title }}</span>
                    </h2>
                </div>

                <div class="c-poetry">
                    <ul class="c-ul">
                        <li class="c-li wow fadeInUp" v-for="(item, index) in proAdvantage.list" :key="index">
                            <div class="c-div1">
                                <div class="zz-left">
                                    <img class="bgimg1" src="../assets/images/ys-244.png" alt="图片不存在">
                                    <div class="bgimg2">
                                        <img class="" src="../assets/images/ys-245.png" alt="图片不存在">
                                    </div>

                                    <div class="c-title">
                                        <img :src="host + item.icon" alt="图片不存在">
                                        <h3 class="h3 font24 text_b">{{ item.name }}</h3>
                                    </div>
                                    <div class="c-text font18">
                                        <p>{{ item.text }}</p>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <!-- <li class="c-li wow fadeInUp">
                            <div class="c-div1">
                                <div class="zz-left">
                                    <img class="bgimg1" src="../assets/images/ys-244.png" alt="图片不存在">
                                    <div class="bgimg2">
                                        <img class="" src="../assets/images/ys-245.png" alt="图片不存在">
                                    </div>

                                    <div class="c-title">
                                        <img src="../assets/images/ys-247.png" alt="图片不存在">
                                        <h3 class="h3 font24 text_b">决策数字化</h3>
                                    </div>
                                    <div class="c-text font18">
                                        <p>移动端实时监控经营指标，全场景多维度深入洞察生意机会，即时精准决策，实现可预测、确定性增长</p>
                                    </div>
                                </div>
                            </div>
                        </li>

                        <li class="c-li wow fadeInUp">
                            <div class="c-div1">
                                <div class="zz-left">
                                    <img class="bgimg1" src="../assets/images/ys-244.png" alt="图片不存在">
                                    <div class="bgimg2">
                                        <img class="" src="../assets/images/ys-245.png" alt="图片不存在">
                                    </div>

                                    <div class="c-title">
                                        <img src="../assets/images/ys-248.png" alt="图片不存在">
                                        <h3 class="h3 font24 text_b">全链路生产效率提升</h3>
                                    </div>
                                    <div class="c-text font18">
                                        <p>提供从采集、分析到应用的一站式数据体系，实现全链路的生产效率提升；支持在基础配置上自研迭代，灵活配置数据权限，业务更快上手</p>
                                    </div>
                                </div>
                            </div>
                        </li> -->


                    </ul>
                </div>

            </div>
        </div>

        <!-- 第六屏 信任之选 -->
        <div class="product5 pdtb" v-show="proCase.title != ''">
            <div class="container">
                <div class="public-title">
                    <h2 class="font40 text_b h2">
                        <span>{{ proCase.title }}</span>
                    </h2>
                </div>

                <div class="sysec4Swp case5Swp product5Swp">

                    <div class="swp-btnBox">
                        <div class="swiper-pagination"></div>
                    </div>
                    <div class="swiper-container">
                        <div class="swiper-wrapper">

                            <div class="swiper-slide" v-for="(item1, index1) in proCaseList" :key="index1">
                                <div class="zz-left wow fadeRight" :class="index1 == 0 ? 'wow fadeRight' : ''">
                                    <img class="bgimg" src="../assets/images/ys-209.png" alt="图片不存在">
                                    <div class="imgbox">
                                        <img :src="host + item1.cbg" alt="图片不存在">
                                    </div>
                                    <div class="c-cont">
                                        <div class="c-title-box">
                                            <img :src="host + item1.cicon" alt="图片不存在">
                                            <div class="c-title font24 text_b">
                                                {{ item1.ctitle }}
                                            </div>
                                        </div>

                                        <div class="c-text font16">
                                            <div class="scroll">
                                                {{ item1.cdesc }}
                                            </div>
                                        </div>

                                        <div class="imgbox">
                                            <img :src="host + item1.cimg" alt="图片不存在">
                                        </div>

                                    </div>
                                </div>

                                <div class="zz-right wow fadeLeft" :class="index1 == 0 ? 'wow fadeLeft' : ''">
                                    <img class="bgimg2" src="../assets/images/ys-55.svg" onload="SVGInject(this)">

                                    <div class="c-title-box">
                                        <div class="c-t">
                                            <img v-if="item1.yicon" :src="host + item1.yicon" alt="图片不存在">
                                            <div class="font24 c-div text_b">{{ item1.ytitle }}</div>
                                        </div>

                                        <ul class="c-ul">
                                            <li class="c-li font18">
                                                {{ item1.ydesc }}
                                            </li>
                                        </ul>
                                    </div>

                                    <div class="c-cont-box">
                                        <div class="c-t">
                                            <img v-if="item1.zicon" :src="host + item1.zicon" alt="图片不存在">
                                            <div class="font24 c-div text_b">{{ item1.ztitle }}</div>
                                        </div>

                                        <ul class="c-ul">
                                            <li class="c-li" v-for="(item2, index2) in item1.cons" :key="index2">
                                                <div class="c-num">
                                                    <div class="c-div1 font24">
                                                        <animate-number mode="manual" duration="3000" :from="0"
                                                            :to="item2.num" ref="proNum">
                                                        </animate-number>
                                                    </div>
                                                    <div class="c-div2 font20">
                                                        {{ item2.unit }}
                                                    </div>
                                                </div>
                                                <div class="c-tips font16">
                                                    {{ item2.title }}
                                                </div>
                                            </li>

                                            <!-- <li class="c-li">
                                                <div class="c-num">
                                                    <div class="c-div1 font24">
                                                        <animate-number mode="manual" duration="3000" :from="startVal2"
                                                            :to="endVal2" ref="num2">
                                                        </animate-number>
                                                    </div>
                                                    <div class="c-div2 font20">
                                                        w+
                                                    </div>
                                                </div>
                                                <div class="c-tips font16">
                                                    年订单量
                                                </div>
                                            </li>

                                            <li class="c-li">
                                                <div class="c-num">
                                                    <div class="c-div1 font24">
                                                        <animate-number mode="manual" duration="3000" :from="startVal3"
                                                            :to="endVal3" ref="num3">
                                                        </animate-number>
                                                    </div>
                                                    <div class="c-div2 font20">
                                                        w+
                                                    </div>
                                                </div>
                                                <div class="c-tips font16">
                                                    会员数
                                                </div>
                                            </li>

                                            <li class="c-li">
                                                <div class="c-num">
                                                    <div class="c-div1 font24">
                                                        <animate-number mode="manual" duration="3000" :from="startVal4"
                                                            :to="endVal4" ref="num4">
                                                        </animate-number>
                                                    </div>
                                                    <div class="c-div2 font20">
                                                        %
                                                    </div>
                                                </div>
                                                <div class="c-tips font16">
                                                    线上订单量增长
                                                </div>
                                            </li>

                                            <li class="c-li">
                                                <div class="c-num">
                                                    <div class="c-div1 font24">
                                                        <animate-number mode="manual" duration="3000" :from="startVal5"
                                                            :to="endVal5" ref="num5">
                                                        </animate-number>
                                                    </div>
                                                    <div class="c-div2 font20">
                                                        %
                                                    </div>
                                                </div>
                                                <div class="c-tips font16">
                                                    线上交易额增长
                                                </div>
                                            </li> -->


                                        </ul>

                                    </div>

                                </div>

                            </div>

                            <!-- <div class="swiper-slide">
                                <div class="zz-left wow fadeRight">
                                    <img class="bgimg" src="../assets/images/ys-209.png" alt="图片不存在">
                                    <div class="imgbox">
                                        <img src="../assets/images/ys-210.png" alt="图片不存在">
                                    </div>
                                    <div class="c-cont">
                                        <div class="c-title-box">
                                            <img src="../assets/images/ys-211.svg" alt="图片不存在">
                                            <div class="c-title font24 text_b">
                                                勇惠便利
                                            </div>
                                        </div>

                                        <div class="c-text font16">
                                            <div class="scroll">
                                                勇惠超市，西南地区零售连锁领先企业，中国连锁经营协会(CCFA)会员。为消费群体提供便捷、时尚、新鲜的“24小时”星级便利服务。
                                            </div>
                                        </div>

                                        <div class="imgbox">
                                            <img src="../assets/images/ys-212.png" alt="图片不存在">
                                        </div>

                                    </div>
                                </div>

                                <div class="zz-right wow fadeLeft">
                                    <img class="bgimg2" src="../assets/images/ys-55.svg" alt="" onload="SVGInject(this)">

                                    <div class="c-title-box">
                                        <div class="c-t">
                                            <img src="../assets/images/ys-213.svg" alt="图片不存在">
                                            <div class="font24 c-div text_b">业务挑战</div>
                                        </div>

                                        <ul class="c-ul">
                                            <li class="c-li font18">
                                                数据化管理缺失、系统稳定性差、业务处理能力弱
                                            </li>
                                        </ul>
                                    </div>

                                    <div class="c-cont-box">
                                        <div class="c-t">
                                            <img src="../assets/images/ys-214.png" alt="图片不存在">
                                            <div class="font24 c-div text_b">主要成果</div>
                                        </div>

                                        <ul class="c-ul">
                                            <li class="c-li">
                                                <div class="c-num">
                                                    <div class="c-div1 font24">
                                                        200
                                                    </div>
                                                    <div class="c-div2 font20">
                                                        +
                                                    </div>
                                                </div>
                                                <div class="c-tips font16">
                                                    实体门店数
                                                </div>
                                            </li>

                                            <li class="c-li">
                                                <div class="c-num">
                                                    <div class="c-div1 font24">
                                                        3200
                                                    </div>
                                                    <div class="c-div2 font20">
                                                        w+
                                                    </div>
                                                </div>
                                                <div class="c-tips font16">
                                                    年订单量
                                                </div>
                                            </li>

                                            <li class="c-li">
                                                <div class="c-num">
                                                    <div class="c-div1 font24">
                                                        10
                                                    </div>
                                                    <div class="c-div2 font20">
                                                        w+
                                                    </div>
                                                </div>
                                                <div class="c-tips font16">
                                                    会员数
                                                </div>
                                            </li>

                                            <li class="c-li">
                                                <div class="c-num">
                                                    <div class="c-div1 font24">
                                                        13.8
                                                    </div>
                                                    <div class="c-div2 font20">
                                                        %
                                                    </div>
                                                </div>
                                                <div class="c-tips font16">
                                                    线上订单量增长
                                                </div>
                                            </li>

                                            <li class="c-li">
                                                <div class="c-num">
                                                    <div class="c-div1 font24">
                                                        84
                                                    </div>
                                                    <div class="c-div2 font20">
                                                        %
                                                    </div>
                                                </div>
                                                <div class="c-tips font16">
                                                    线上交易额增长
                                                </div>
                                            </li>


                                        </ul>

                                    </div>

                                </div>

                            </div> -->


                        </div>
                    </div>
                </div>

            </div>

            <div class="vueSeamless-box wow fadeInUp">
                <div v-if="proBrands.length">
                    <vue-seamless-scroll :data="proBrands" :class-option="classOption1" class="vueSeamless vueSeamless1">
                        <ul class="ul-item">
                            <li class="li-item" v-for="(item1, index1) in proBrands" :key="index1"
                                v-show="index1 < (proBrands.length - 1) / 2">
                                <div class="imgbox">
                                    <img :src="host + item1" alt="图片不存在">
                                </div>
                            </li>
                        </ul>
                    </vue-seamless-scroll>
                </div>
                <div v-if="proBrands.length">
                    <vue-seamless-scroll :data="proBrands" :class-option="classOption2" class="vueSeamless vueSeamless2">
                        <ul class="ul-item">
                            <li class="li-item" v-for="(item2, index2) in proBrands" :key="index2"
                                v-show="index2 < (proBrands.length - 1) / 2">
                                <div class="imgbox">
                                    <img :src="host + item2" alt="图片不存在">
                                </div>
                            </li>
                        </ul>
                    </vue-seamless-scroll>
                </div>
            </div>

        </div>


        <!-- 视频弹窗 -->
        <div id="hi-video-pop" :class="videoPopFlag ? 'active' : ''">
            <div class="hi-close" @click="product1VideoClose()"></div>
            <div class="hi-video-box">
                <div class="hi-video-wrap">
                    <video id="myVideo" :src="host + product1Video" ref="videoPop" controls autoplay preload="auto" muted=""
                        webkit-playsinline="true" playsinline="true"></video>
                </div>
            </div>
        </div>

        <!-- 图片弹窗 -->
        <div id="hi-img-pop" :class="product2PopFlag ? 'active' : ''">
            <div class="hi-close" @click="product2PoPClose()"></div>
            <div class="hi-video-box">
                <div class="imgbox">
                    <img :src="host + product2Img" alt="图片不存在">
                </div>
            </div>
        </div>




    </div>
</template>
  
<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

// 这是引用wow
// import { WOW } from 'wowjs'
// 引入swiper
import Swiper from 'swiper';
import $ from 'jquery';
import 'swiper/dist/css/swiper.min.css';
// import vueSeamless from 'vue-seamless-scroll'
// import countTo from 'vue-count-to';
import '../assets/css/style.css';
import hostUrl from '@/util/baseUrl'


export default {
    // name: "HomeView",
    components: {
    },
    metaInfo() {
        return {
            title: this.seoInfo.title, //设置页面title
            meta: [
                {                 //设置页面meta
                    name: 'keywords',
                    content: this.seoInfo.keyWords
                },
                {
                    name: "description",
                    content: this.seoInfo.description
                },
                {
                    name: "renderer",
                    content: 'webkit',
                },
                {
                    name: "force-rendering",
                    content: 'webkit',
                },
                {
                    name: "format-detection",
                    content: 'telephone=no,telephone=no,email=no,adress=no',
                },
                {
                    name: "viewport",
                    content: 'width=device-width,user-scalable=no,initial-scale=1.0,maximum-scale=1.0,minimum-scale=1.0',
                },
            ]
        }
    },
    data() {
        return {
            // 屏幕宽度参数
            wW: '',
            //路由id
            id: '',
            // 第一屏数据
            // product1Data: [
            //     {
            //         navIcon: require('../assets/images/ys-226.png'),
            //         navIcon_active: require('../assets/images/ys-226-2.png'),
            //         navTitle: '数据协同',
            //         icon: require('../assets/images/ys-228.png'),
            //         title: '用户互动，伙伴互动，员工互动',
            //         list: [
            //             '实现企业、用户、伙伴间数字化协同',
            //             '用系统实现信息畅通便捷，保障企业运转'
            //         ],
            //         videoImg: require('../assets/images/ys-256.jpg'),
            //         video: '', //视频链接
            //     },
            //     {
            //         navIcon: require('../assets/images/ys-227.png'),
            //         navIcon_active: require('../assets/images/ys-227-2.png'),
            //         navTitle: '数据应用',
            //         icon: require('../assets/images/ys-228.png'),
            //         title: '用户触达，业务分析，决策分析',
            //         list: [
            //             '对各类数据集成、挖掘、洞察',
            //             '驱动数据化运营，智能化决策'
            //         ],
            //         videoImg: require('../assets/images/ys-256.jpg'),
            //         video: '', //视频链接
            //     },
            // ],
            product1Video: '',
            videoPopFlag: false, //视频弹窗
            product1Von: false,
            // 第二屏
            product2PopFlag: false,
            product2Img: '',
            // 数字跳动
            // startVal1: 0,
            // endVal1: 200,
            // startVal2: 0,
            // endVal2: 3200,
            // startVal3: 0,
            // endVal3: 10,
            // startVal4: 0,
            // endVal4: 13.8,
            // startVal5: 0,
            // endVal5: 84,
            scrollFlag: false,
            product4Top: '',
            // 第五屏跑马灯
            // listData: [
            //     {
            //         img: require('../assets/images/ys-58.png'),
            //     },
            //     {
            //         img: require('../assets/images/ys-59.png'),
            //     },
            //     {
            //         img: require('../assets/images/ys-60.png'),
            //     },
            //     {
            //         img: require('../assets/images/ys-57.png'),
            //     },
            //     {
            //         img: require('../assets/images/ys-61.png'),
            //     },
            //     {
            //         img: require('../assets/images/ys-62.png'),
            //     },
            //     {
            //         img: require('../assets/images/ys-63.png'),
            //     },
            //     {
            //         img: require('../assets/images/ys-64.png'),
            //     },
            //     {
            //         img: require('../assets/images/ys-65.png'),
            //     },
            // ],
            // listData2: [
            //     {
            //         img: require('../assets/images/ys-66.png'),
            //     },
            //     {
            //         img: require('../assets/images/ys-67.png'),
            //     },
            //     {
            //         img: require('../assets/images/ys-68.png'),
            //     },
            //     {
            //         img: require('../assets/images/ys-69.png'),
            //     },
            //     {
            //         img: require('../assets/images/ys-70.png'),
            //     },
            //     {
            //         img: require('../assets/images/ys-71.png'),
            //     },
            //     {
            //         img: require('../assets/images/ys-72.png'),
            //     },
            //     {
            //         img: require('../assets/images/ys-73.png'),
            //     },
            //     {
            //         img: require('../assets/images/ys-74.png'),
            //     },
            // ],

            // 接口数据
            host: hostUrl,
            // 第一屏 banner
            proBanner: [],
            // 第二屏
            proFmodel: [],
            fmodel_title: '',
            // 第三屏
            proAccept: [],
            // 第四屏 零售云产品矩阵
            proMatrix1: [],
            proMatrix2: [],
            // 第五屏 产品优势
            proAdvantage: [],
            // 第六屏 案例
            proCase: [],
            proCaseList: [],
            proBrands: [],
            // seo信息
            seoInfo: {
                title: '',
                keyWords: '',
                description: ''
            },
            // 全局seo
            seoAllInfo: {},
        }
    },
    created() {
        // this.$bus.$on("headShow"); //二级导航浮动
        // this.$bus.emit("headShow",true);

    },
    mounted() {
        // this.$bus.$on("headShow"); //二级导航浮动
        // this.$bus.emit("headShow",true);
        // this.$bus.$off("headShow");        

        this.getData()

        this.wW = document.documentElement.clientWidth || document.body.clientWidth //获取浏览器宽度 
        
        this.seoAllInfo = JSON.parse(localStorage.getItem('yuanshu_seoInfo')) //获取seo全局信息

        // this.product4Top = $('.product4').offset().top

        // console.log( product4Top.offsetTop ,this.product4Top)








        // 百度统计代码
        var _hmt = _hmt || [];
        (function () {
            var hm = document.createElement("script");
            hm.src = "https://hm.baidu.com/hm.js?77637ce1a84bee069c1effb2174121bb";
            var s = document.getElementsByTagName("script")[0];
            s.parentNode.insertBefore(hm, s);
        })();
    },
    computed: {
        classOption1() {
            return {
                step: 1,
                limitMoveNum: 1,
                direction: 2,
                openWatch: true, // 开启数据实时监控刷新dom
            }
        },
        classOption2() {
            return {
                step: 1,
                limitMoveNum: 1,
                direction: 3,
                openWatch: true, // 开启数据实时监控刷新dom
            }
        },

    },
    destroyed() {
        // 离开页面时取消二级导航浮动        
        // this.$bus.$off("headShow");
    },
    methods: {
        // 请求后端接口
        getData() {
            this.proBanner = []
            this.proFmodel = []
            this.proAccept = []
            this.proMatrix1 = []
            this.proMatrix2 = []
            this.proAdvantage = []
            this.proCase = []
            this.proCaseList = []
            this.proBrands = []
            console.log(this.$route.query, '路由id')
            let id = this.$route.query.id
            let matrixType=this.$route.query.matrix
            // console.log(matrixType)
            this.seoInfo = {
                title: '',
                keyWords: '',
                description: ''
            }

            this.$axios.get('/api/product/' + id).then(res => {
                // console.log(res.data.data.blocksObj.fmodel)

                // seo信息
                if (res.data.data.name != '' && res.data.data.name != null && res.data.data.name != undefined) {
                    this.seoInfo.title = res.data.data.name
                }else{
                    this.seoInfo.title=this.seoAllInfo.title
                }

                if (res.data.data.seoKeyword != '' && res.data.data.seoKeyword != null && res.data.data.seoKeyword != undefined) {
                    this.seoInfo.keyWords = res.data.data.seoKeyword
                }else{
                    this.seoInfo.keyWords=this.seoAllInfo.keyWords
                }

                if (res.data.data.seoDesc != '' && res.data.data.seoDesc != null && res.data.data.seoDesc != undefined) {
                    this.seoInfo.description = res.data.data.seoDesc
                }else{
                    this.seoInfo.description=this.seoAllInfo.description
                }

                // console.log(res.data.data.blocksObj.matrix,'matrixtest')

                this.proBanner = res.data.data.blocksObj.banner[0]

                // 第二屏
                if (res.data.data.blocksObj.fmodel != undefined) {
                    var proFmodelTemp = res.data.data.blocksObj.fmodel
                    for (var i = 0; i < proFmodelTemp.length; i++) {

                        proFmodelTemp[i].text = proFmodelTemp[i].text.split('\n')

                    }
                    this.proFmodel = proFmodelTemp

                    if (this.proFmodel[0].video != undefined && this.proFmodel[0].video != '') {
                        this.product1Von=true
                    }else{
                        this.product1Von=false
                    }

                    console.log(this.proFmodel, 'proFmodel')
                }

                this.fmodel_title = res.data.data.blocksObj.fmodel_title

                this.proAccept = res.data.data.blocksObj.accept
                this.product2Img = this.proAccept.image

                // 第四屏 产品矩阵
                // 零售云
                // if (id == 1) {
                if(matrixType==0){
                    var proMatrix1Temp = res.data.data.blocksObj.matrix
                    for (var i = 0; i < proMatrix1Temp.list.length; i++) {
                        proMatrix1Temp.list[i].text = proMatrix1Temp.list[i].text.split('\n')
                    }
                    this.proMatrix1 = proMatrix1Temp
                }
                // 数据云 paas平台
                // else if (id == 3 || id == 4) {
                else if (matrixType==1){
                    var proMatrix2Temp = res.data.data.blocksObj.matrix
                    for (var i = 0; i < proMatrix2Temp.list.length; i++) {
                        proMatrix2Temp.list[i].text = proMatrix2Temp.list[i].text.split('\n')
                    }
                    this.proMatrix2 = proMatrix2Temp
                }

                this.proAdvantage = res.data.data.blocksObj.pro

                this.proCase = res.data.data.blocksObj.case
                this.proCaseList = this.proCase.list

                if (res.data.data.blocksObj.brands != undefined) {
                    this.proBrands = res.data.data.blocksObj.brands.split(',')
                }
                console.log(this.proMatrix1.list, 'matrix')

                this.$nextTick(() => {

                    setTimeout(() => {
                        this.pro1Swp()
                    }, 0)

                    // wow动画
                    new this.$wow.WOW({
                        live: false,
                    }).init()

                    let product4Top = document.getElementById('product4')
                    this.product4Top = product4Top.offsetTop

                    window.addEventListener('scroll', this.handleScroll)
                })



            })
        },
        toast() {
            this.$toast.show('页面建设中，请耐心等待…', '您可通过电话或微信扫描底部二维码联系我们', 5000)
        },
        handleScroll() {
            // this.$nextTick(() => {
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            // console.log(scrollTop, this.about1Top)
            // 数字跳动
            if (scrollTop >= this.product4Top) {
                if (this.scrollFlag == false) {
                    // this.$refs.num1.start()
                    // this.$refs.num2.start()
                    // this.$refs.num3.start()
                    // this.$refs.num4.start()
                    // this.$refs.num5.start()
                    for (var i = 0; i < this.$refs.proNum.length; i++) {
                        this.$refs.proNum[i].start()
                    }
                    this.scrollFlag = true
                }
            }

            // 二级导航浮动
            // if (scrollTop >= this.crumbsTop) {
            //     this.crumbsFlag = true
            // } else {
            //     this.crumbsFlag = false
            // }

            // })
        },
        // 第一屏播放视频
        product1VideoPlay(video) {
            if (video != undefined && video != null && video != '') {
                this.product1Video = video
                this.videoPopFlag = true
                this.$refs.videoPop.play()

            }
        },
        product1VideoClose() {
            this.videoPopFlag = false
            this.$refs.videoPop.pause()
        },
        // 第二屏图片放大弹窗
        product2Fn() {
            if (this.wW < 770) {
                this.product2PopFlag = true
            }
        },
        product2PoPClose() {
            if (this.wW < 770) {
                this.product2PopFlag = false
            }
        },
        // pro1NavFn(index) {
        //     for (var i = 0; i < this.product1.length; i++) {
        //         this.product1[i].on = false
        //     }
        //     this.product1[index].on = true
        // },
        // case3Mouseover(index1, index2) {
        //     this.case3Img = this.case3Data[index1].list[index2].img
        //     // console.log(1)
        // },
        // 第二屏轮播切换
        pro1Swp() {
            var pro1SwpNav = new Swiper(".pro1SwpNav .swiper-container", {
                spaceBetween: 40,
                slidesPerView: 'auto',
                // freeMode: true,
                // watchSlidesProgress: true,
                freeMode: true,
                watchSlidesProgress: true,
                normalizeSlideIndex: false,
                slideToClickedSlide: true,
                breakpoints: {
                    768: {
                        spaceBetween: 25,
                        slidesPerView: 2,
                    },
                },
                on: {
                    // slideChange: function () {
                    //     bxc_cpjj_swp.slideTo(this.activeIndex, 600, false)
                    // },
                    click: function () {
                        pro1Swp.slideTo(this.clickedIndex, 800, false)
                    }
                },
            });
            var that=this
            var pro1Swp = new Swiper(".pro1Swp .swiper-container", {
                spaceBetween: 10,
                effect: 'fade',
                fadeEffect: {
                    crossFade: true,
                },
                autoplay: {
                    delay: 5500,
                },
                // thumbs: {
                //     swiper: pro1SwpNav,
                // },
                on: {
                    slideChange: function () {
                        pro1SwpNav.slideTo(this.activeIndex, 800, false)
                        for(var i=0;i<that.proFmodel.length;i++){
                            if (that.proFmodel[this.activeIndex].video != undefined && that.proFmodel[this.activeIndex].video != '') {
                                that.product1Von=true
                            }else{
                                that.product1Von=false
                            }
                        }
                    },
                },
            });
        },
        // 最后一屏轮播
        product5Swp() {

            var product5Swp = new Swiper('.product5Swp .swiper-container', {
                autoplay: {
                  delay: 5000,
                },
                slidesPerView: 1,
                // loop: true,
                effect: 'fade',
                observer: true,
                observeParents: true,
                fadeEffect: {
                    crossFade: true,
                },
                pagination: {
                    el: '.product5Swp .swiper-pagination',
                    clickable: true,
                },
                on: {
                    init: function (swiper) {
                        // swiperAnimateCache(this); //隐藏动画元素
                        // swiperAnimate(this);
                    },
                    slideChangeTransitionEnd: function () {
                        // swiperAnimate(this); //每个slide切换结束时也运行当前slide动画
                        //this.slides.eq(this.activeIndex).find('.ani').removeClass('ani'); 动画只展现一次，去除ani类名

                    }
                },
            })
        }

    },
};
</script>
  
<style scoped lang="less">
// .product5 {
//     display: none;
// }

.vueSeamless-box {
    margin-top: 0.6rem;
    position: relative;

    &::before {
        content: '';
        width: 30.7%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: url('!@/assets/images/ys-75.png');
        z-index: 1;
        background-size: cover;

        @media (max-width: 767px) {
            content: unset;
        }
    }

    &::after {
        content: '';
        width: 30.7%;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        background: url('!@/assets/images/ys-76.png');
        z-index: 1;
        background-size: cover;

        @media (max-width: 767px) {
            content: unset;
        }
    }
}

.vueSeamless {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;

    ul {
        list-style: none;
        padding: 0;
        margin: 0 auto;

        &.ul-item {
            display: flex;

            .li-item {
                // .zz_imgpdh(13.75%,7.05%);
                position: relative;
                height: 120px;
                width: 215px;
                border-radius: 10px;

                @media (max-width:1366px) {
                    width: 180px;
                    height: 85px;
                }

                .imgbox {
                    box-shadow: 0 5px 10px rgba(110, 151, 232, 0.1);
                    // margin: -40px 0;
                    // padding: 40px 0;
                    height: 83.33%;
                    width: 93.02%;

                    border-radius: 10px;
                }

                img {
                    width: 100%;
                    height: 100%;
                    // object-fit: cover;    
                    border-radius: 10px;
                    border: 1px solid #fff;
                }
            }
        }
    }

    &.vueSeamless1 {
        margin-bottom: 0rem;
    }

    &.vueSeamless2 {
        margin-left: -320px;
        width: calc(100% + 320px);

        .ul-item {}

    }
}

@media (max-width: 767px) {
    .vueSeamless ul.ul-item .li-item {
        // width: 2.4rem;
        // height: 1.3rem;
        width: 2.6rem;
        height: 1.5rem;
    }
}

.public-list.public-listTwo {
    margin-top: 0.55rem;
}
</style>