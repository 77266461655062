<template>
    <div class="">

        <div class="yd-mzsm">
        <div class="container">

            <div class="c-box">
                <div class="public-title public-title2 font36">
                    <h2 class="h2">{{ privacyCont.name }}</h2>            
                </div>
    
                <div class="c-cont font16 ql-editor" v-html="privacyCont.more"></div>

            </div>
            

        </div>
    </div>




    </div>
</template>
  
<script>
// 引入swiper
import Swiper from 'swiper';
import $ from 'jquery';
import 'swiper/dist/css/swiper.min.css';
import '../assets/css/style.css';
import '../assets/css/quill.bubble.css'; //富文本样式
import '../assets/css/quill.core.css';
import '../assets/css/quill.snow.css';
import hostUrl from '@/util/baseUrl'


export default {
    components: {
    },
    metaInfo() {
        return {
            title: this.seoInfo.title, //设置页面title
            meta: [
                {                 //设置页面meta
                    name: 'keywords',
                    content: this.seoInfo.keyWords
                },
                {
                    name: "description",
                    content: this.seoInfo.description
                },
                {
                    name: "renderer",
                    content: 'webkit',
                },
                {
                    name: "force-rendering",
                    content: 'webkit',
                },
                {
                    name: "format-detection",
                    content: 'telephone=no,telephone=no,email=no,adress=no',
                },
                {
                    name: "viewport",
                    content: 'width=device-width,user-scalable=no,initial-scale=1.0,maximum-scale=1.0,minimum-scale=1.0',
                },
            ]
        }
    },
    data() {
        return {
            // 屏幕宽度参数
            wW: '',

            // 接口数据
            host: hostUrl,
            privacyCont:{},
            // seo信息
            seoInfo: {
                title: '',
                keyWords: '',
                description: ''
            },
            // 全局seo
            seoAllInfo: {},
        }
    },

    mounted() {

        this.getData()

        this.wW = document.documentElement.clientWidth || document.body.clientWidth //获取浏览器宽度

        this.seoAllInfo = JSON.parse(localStorage.getItem('yuanshu_seoInfo')) //获取seo全局信息

        // wowjs
        this.$nextTick(() => {
            new this.$wow.WOW({
                live: false,
            }).init()
        })

        window.addEventListener('scroll', this.handleScroll)

        // 百度统计代码
        var _hmt = _hmt || [];
        (function () {
            var hm = document.createElement("script");
            hm.src = "https://hm.baidu.com/hm.js?77637ce1a84bee069c1effb2174121bb";
            var s = document.getElementsByTagName("script")[0];
            s.parentNode.insertBefore(hm, s);
        })();


    },
    computed: {

    },   
    methods: {
        // 请求后端接口
        getData() {
            this.privacyCont={}
            this.seoInfo={
                title:'',
                keyWords:'',
                description:''
            }
            
            // 获取文章详情
            this.$axios.get('/api/page/15').then(res => {

                // seo信息
                if (res.data.data.name != '' && res.data.data.name != null && res.data.data.name != undefined) {
                    this.seoInfo.title = res.data.data.name
                }else{
                    this.seoInfo.title=this.seoAllInfo.title
                }

                if (res.data.data.seoKeyword != '' && res.data.data.seoKeyword != null && res.data.data.seoKeyword != undefined) {
                    this.seoInfo.keyWords = res.data.data.seoKeyword
                }else{
                    this.seoInfo.keyWords=this.seoAllInfo.keyWords
                }

                if (res.data.data.seoDesc != '' && res.data.data.seoDesc != null && res.data.data.seoDesc != undefined) {
                    this.seoInfo.description = res.data.data.seoDesc
                }else{
                    this.seoInfo.description=this.seoAllInfo.description
                }
                
                console.log(res.data.data)

                this.privacyCont=res.data.data
                
                this.$nextTick(() => {

                    // wow动画
                    new this.$wow.WOW({
                        live: false,
                    }).init()


                })
            })


        },
        toast() {
            this.$toast.show('页面建设中，请耐心等待…', '您可通过电话或微信扫描底部二维码联系我们', 5000)
        },
        handleScroll() {
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            
        },


    },
};
</script>
  
<style scoped lang="less"></style>