<template>
  <div class="about">

    <!-- 内页顶部banner -->
    <div class="public-nybn about-bn">
      <div class="bgimg scaleDraw" v-if="banner.image">
        <img :src="host + banner.image" alt="">
      </div>
      <div class="zz-box">

        <div class="bn-title fadeInUp2">
          <div class="container">
            <div class="c-title">
              <h2 class="h2 font36 text_b">
                {{ banner.title }}
              </h2>
              <div class="syb"></div>
            </div>
            <div class="font40 text_b c-text pre"><span>{{ banner.text }}</span></div>

          </div>
        </div>


      </div>
    </div>

    <div class="public-crumbs" :class="crumbsFlag ? 'active' : ''" id="public-crumbs">
      <div class="container">
        <div class="c-box">

          <h3 class="c-a font18" :class="item.on ? 'on' : ''" @click="crumbsFn(index)" v-for="(item, index) in crumbs"
            :key="index">
            <router-link v-if="item.url" :to="item.url">
              {{ item.name }}
            </router-link>
          </h3>

          <!-- <h3 class="c-a font18">
            <router-link to="/brand">
              品牌故事
            </router-link>
          </h3>

          <h3 class="c-a font18">
            <router-link to="/trends">
              公司动态
            </router-link>
          </h3>

          <h3 class="c-a font18">
            <router-link to="/team">
              专家团队
            </router-link>
          </h3> -->

          <!-- <h3 class="c-a font18">
            <router-link to="/join">
              加入我们
            </router-link>
          </h3> -->

        </div>
      </div>
    </div>

    <!-- 第二屏 公司简介 -->
    <div class="about1 pdtb" id="about1" v-show="about.title != ''">
      <img class="bgimg" src="../assets/images/ys-83.png" alt="">
      <div class="container">
        <div class="c-box1 wow fadeInUp">
          <div class="zz-left">
            <h2 class="font40 text_b h2">{{ about.title }}</h2>
          </div>
          <div class="zz-right font20">
            <div class="scroll pre">
              <p>
                {{ about.desc }}
              </p>
            </div>
          </div>
        </div>

        <div class="public-aboutBox public-aboutBox2 wow fadeInUp">
          <div class="swiper-container" :class="swpNot ? 'swiper-no-swiping' : ''">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(item, index) in about.nums" :key="index">
                <div class="box2">
                  <div class="box3">
                    <div class="box4">
                      <span class="cn8 num font60 font-b">
                        <animate-number mode="manual" duration="3000" :from="0" :to="item.num" ref="aboutNum">
                        </animate-number>
                      </span>
                      <span class="cn9 font60 font-b">{{ item.more }}</span>
                      <span class="cn10 font18 font-b">{{ item.unit }}</span>
                    </div>
                    <p class="cn7 font18">{{ item.title }}</p>
                  </div>
                </div>
              </div>

              <!-- <div class="swiper-slide">
                <div class="box2">
                  <div class="box3">
                    <div class="box4">
                      <span class="cn8 num font60 font-b">
                        <animate-number mode="manual" duration="3000" :from="startVal2" :to="endVal2" ref="num2">
                        </animate-number>
                      </span>
                      <span class="cn9 font60 font-b">+</span>
                      <span class="cn10 font18 font-b">个</span>
                    </div>
                    <p class="cn7 font18">明星案例</p>
                  </div>
                </div>
              </div>

              <div class="swiper-slide">
                <div class="box2">
                  <div class="box3">
                    <div class="box4">
                      <span class="cn8 num font60 font-b">
                        <animate-number mode="manual" duration="3000" :from="startVal3" :to="endVal3" ref="num3">
                        </animate-number>
                      </span>
                      <span class="cn9 font60 font-b">+</span>
                      <span class="cn10 font18 font-b">亿</span>
                    </div>
                    <p class="cn7 font18">年GMV</p>
                  </div>
                </div>
              </div> -->

            </div>
          </div>
          <div class="swp-btnBox">
            <div class="swiper-pagination"></div>
          </div>
        </div>

      </div>
    </div>

    <!-- 第三屏 发展历程 -->
    <div class="about2 pdtb" v-show="course.title != ''">
      <div class="container">

        <div class="public-title wow fadeInUp">
          <h2 class="font40 text_b h2">
            <span>{{ course.title }}</span>
          </h2>
        </div>

        <!-- 分类导航 -->
        <div class="c-navBox wow fadeInUp">
          <div class="c-nav">
            <div class="c-li" :class="item.on == true ? 'on' : ''" v-for="(item, index) in courseList" :key="index"
              @click="about2Nav(index)">
              <div class="c-text">
                <div class="h3 font24">{{ item.title }}</div>
              </div>
            </div>

            <!-- <div class="c-li"> -->
            <!-- <div class="c-text"> -->
            <!-- <div class="h3 font24">2015-2023</div> -->
            <!-- </div> -->
            <!-- </div> -->



          </div>
        </div>

        <!-- 分类列表 -->
        <div class="li-box wow fadeInUp">

          <div class="li" v-show="item1.on" v-for="(item1, index1) in courseList" :key="index1">
            <div class="liBox">
              <div class="zz-left" @click="videoPlay(about2Video)">
                <div class="btnbox" v-if="about2Von==true">
                  <div class="hi-btn12">
                    <img class="hi-ico" src="../assets/images/ys-230.svg" onload="SVGInject(this)">
                  </div>
                </div>
                <div class="imgbox">
                  <img v-if="about2Img" :src="host + about2Img" alt="">
                </div>
              </div>
              <div class="zz-right">
                <div class="c-list" :class="item2.on == true ? 'on' : ''" v-for="(item2, index2) in item1.cons"
                  :key="index2" @mouseover="about2Over(index1, index2, item2)" @mouseout="about2Out(index1, index2)">
                  <div class="box1">
                    <div class="c-year font30">
                      {{ item2.title }}
                    </div>
                    <div class="c-text font18">
                      {{ item2.text }}
                    </div>
                  </div>
                  <div class="box2">
                    <img v-if="item2.image" :src="host + item2.image" alt="">
                  </div>
                </div>

                <!-- <div class="c-list">
                          <div class="c-year font30">
                            2018
                          </div>
                          <div class="c-text font18">
                            商品化-核心业务中台商业发布
                          </div>
                    </div>-->


              </div>
            </div>
          </div>

          <!-- <div class="li">
            <div class="liBox">
                <div class="zz-left">
                    <div class="imgbox">
                        <img src="../assets/images/ys-142.png" alt="">
                    </div>
                </div>
                <div class="zz-right">
                    <div class="c-list">
                          <div class="c-year font30">
                            2017
                          </div>
                          <div class="c-text font18">
                            平台化—中台架构用户级测试上线
                          </div>
                    </div>

                    <div class="c-list">
                          <div class="c-year font30">
                            2018
                          </div>
                          <div class="c-text font18">
                            商品化-核心业务中台商业发布
                          </div>
                    </div>

                    <div class="c-list">
                          <div class="c-year font30">
                            2021
                          </div>
                          <div class="c-text font18">
                            生态化-全链路﹒新消费D2C& B2B生态创新
                          </div>
                    </div>

                    <div class="c-list">
                          <div class="c-year font30">
                            2022
                          </div>
                          <div class="c-text font18">
                            数智化-赋能价值增长创纪云数智零售平台
                          </div>
                    </div>


                </div>
            </div>
          </div>
        -->

        </div>

      </div>
    </div>

    <!-- 第四屏 企业文化 -->
    <div class="about3 pdtb" v-show="culture.title != ''">
      <div class="container">

        <div class="public-ani-bgimg public-ani-bgimg1">
          <img src="../assets/images/ys-82.png" alt="">
        </div>
        <div class="public-ani-bgimg public-ani-bgimg4">
          <img src="../assets/images/ys-82.png" alt="">
        </div>

        <div class="public-title wow fadeInUp">
          <h2 class="font40 text_b h2">
            <span>{{ culture.title }}</span>
          </h2>
        </div>

        <div class="public-swpBox">
          <div class="swpBox wow fadeInUp">
            <div class="swiper-container" :class="swpNot ? 'swiper-no-swiping' : ''">
              <div class="swiper-wrapper">

                <div class="swiper-slide" v-for="(item, index) in culture.list" :key="index">
                  <img class="swpbgimg" src="../assets/images/ys-154.png" alt="">
                  <div class="iconbox">
                    <img class="c-icon" src="../assets/images/ys-145.svg" alt="">
                  </div>
                  <div class="c-box">
                    <div class="iconbox2">
                      <img class="c-icon" src="../assets/images/ys-145.svg" alt="">
                    </div>
                    <div class="c-box1">
                      <div class="imgbox">
                        <img class="img1" v-if="item.icon" :src="host + item.icon" alt="">
                        <img class="img2" v-if="item.icons" :src="host + item.icons" alt="">
                      </div>
                      <div class="c-title font30 text_b">
                        {{ item.title }}
                      </div>
                    </div>

                    <div class="c-box2">
                      <div class="c-text font18">
                        {{ item.text }}
                      </div>
                    </div>
                  </div>

                </div>

                <!-- <div class="swiper-slide">
                  <img class="swpbgimg" src="../assets/images/ys-154.png" alt="">
                  <div class="iconbox">
                    <img class="c-icon" src="../assets/images/ys-145.svg" alt="">
                  </div>
                  <div class="c-box">
                    <div class="iconbox2">
                      <img class="c-icon" src="../assets/images/ys-145.svg" alt="">
                    </div>
                    <div class="c-box1">
                      <div class="imgbox">
                        <img class="img1" src="../assets/images/ys-150.png" alt="">
                        <img class="img2" src="../assets/images/ys-151.png" alt="">
                      </div>
                      <div class="c-title font30 text_b">
                        愿景
                      </div>
                    </div>

                    <div class="c-box2">
                      <div class="c-text font18">
                        <p>
                          成为国内最具活力的商业数字化与</p>
                        <p>数据化生态，实现数字化从业者，商业企业，服务商的有效的价值链接。
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="swiper-slide">
                  <img class="swpbgimg" src="../assets/images/ys-154.png" alt="">
                  <div class="iconbox">
                    <img class="c-icon" src="../assets/images/ys-145.svg" alt="">
                  </div>
                  <div class="c-box">
                    <div class="iconbox2">
                      <img class="c-icon" src="../assets/images/ys-145.svg" alt="">
                    </div>
                    <div class="c-box1">
                      <div class="imgbox">
                        <img class="img1" src="../assets/images/ys-152.png" alt="">
                        <img class="img2" src="../assets/images/ys-153.png" alt="">
                      </div>
                      <div class="c-title font30 text_b">
                        价值观
                      </div>
                    </div>

                    <div class="c-box2">
                      <div class="c-text font18">
                        <p>
                          一切从用户价值出发，</p>
                        <p>
                          极致专业，效率迭代，公平正直。
                        </p>
                      </div>
                    </div>
                  </div>
                </div> -->

              </div>
            </div>
          </div>

          <div class="swp-btnBox">
            <div class="swiper-pagination"></div>
          </div>
        </div>

      </div>
    </div>

    <!-- 第五屏 荣誉资质 -->
    <div class="about4 pdtb" v-show="honor.title != ''">
      <div class="container">
        <div class="public-title wow fadeInUp">
          <h2 class="font40 text_b h2">
            <span>{{ honor.title }}</span>
          </h2>
        </div>

        <div class="public-navBox public-navBox2 wow fadeInUp">
          <div class="c-nav">

            <div class="c-li" :class="item.on == true ? 'on' : ''" v-for="(item, index) in honorList" :key="index"
              @click="about4Nav(index)">
              <div class="c-text">
                <h3 class="h3 font20">{{ item.title }}</h3>
              </div>
            </div>

          </div>
        </div>

        <div class="swpBox wow fadeInUp">
          <div class="honor-swp" :class="'honor-swp-' + index1" v-show="item1.on" v-for="(item1, index1) in honorList"
            :key="index1">
            <div class="swiper-container">
              <div class="swiper-wrapper">

                <div class="swiper-slide" v-for="(item2, index2) in item1.cons" :key="index2">
                  <div class="a">
                    <div class="div1">
                      <img class="img" v-if="item2.image" :src="host + item2.image" alt="">
                    </div>
                    <div class="div2 font18">
                      <p>{{ item2.title }}</p>
                    </div>
                  </div>
                </div>

                <!-- <div class="swiper-slide">
                  <div class="a">
                    <div class="div1">
                      <img class="img" src="../assets/images/ys-157.png" alt="">
                    </div>
                    <div class="div2 font18">
                      <p>2021海纳星地优秀合作商</p>
                    </div>
                  </div>
                </div>
              -->


              </div>


              <div class="swiper-scrollbar"></div>

              <div class="swpBtn">
                <div class="swiper-button-prev public-swpBtn public-swpBtn2">
                  <img src="../assets/images/swp-arrow-left.svg" alt="" onload="SVGInject(this)">
                </div>
                <div class="swiper-button-next public-swpBtn public-swpBtn2">
                  <img src="../assets/images/swp-arrow-right.svg" alt="" onload="SVGInject(this)">
                </div>
              </div>


            </div>
          </div>

          <!-- <div class="honor-swp honor-swp-1" >
            <div class="swiper-container">
              <div class="swiper-wrapper">

                <div class="swiper-slide">
                  <div class="a">
                    <div class="div1">
                      <img class="img" src="../assets/images/ys-155.png" alt="">
                    </div>
                    <div class="div2 font18">
                      <p>2020金翼榜供应链高校运营榜</p>
                    </div>
                  </div>
                </div>

              </div>


              <div class="swiper-scrollbar"></div>

              <div class="swpBtn">
                <div class="swiper-button-prev public-swpBtn public-swpBtn2">
                  <img src="../assets/images/swp-arrow-left.svg" alt="" onload="SVGInject(this)">
                </div>
                <div class="swiper-button-next public-swpBtn public-swpBtn2">
                  <img src="../assets/images/swp-arrow-right.svg" alt="" onload="SVGInject(this)">
                </div>
              </div>


            </div>
          </div> -->

        </div>

      </div>
    </div>

    <!-- 联系我们 -->
    <div class="about5 pdtb" v-show="contact.title">
      <div class="container">
        <div class="public-ani-bgimg public-ani-bgimg1">
          <img src="../assets/images/ys-82.png" alt="">
        </div>
        <div class="public-ani-bgimg public-ani-bgimg4">
          <img src="../assets/images/ys-82.png" alt="">
        </div>

        <div class="public-title wow fadeInUp">
          <h2 class="font40 text_b h2">
            <span>{{ contact.title }}</span>
          </h2>
          <div class="c-text font18">
            {{ contact.desc }}
          </div>
        </div>

        <div class="box wow fadeInUp">
          <div class="about5Swp">
            <div class="swiper-container" :class="swpNot ? 'swiper-no-swiping' : ''">
              <div class="swiper-wrapper">

                <div class="swiper-slide" v-for="(item, index) in contact.list" v-show="index <= 2" :key="index">
                  <div class="c-box">
                    <div class="imgbox">
                      <img v-if="item.image" :src="host + item.image" alt="">
                    </div>

                    <div class="c-text font18">
                      {{ item.title }}
                    </div>
                  </div>

                </div>

                <!-- <div class="swiper-slide">
                  <div class="c-box">
                    <div class="imgbox">
                      <img src="../assets/images/ys-161.svg" alt="">
                    </div>

                    <div class="c-text font18">
                      +8625 8481 1100
                    </div>
                  </div>
                </div>

                <div class="swiper-slide">
                  <div class="c-box">
                    <div class="imgbox">
                      <img src="../assets/images/ys-162.svg" alt="">
                    </div>

                    <div class="c-text font18">
                      marketing@metabizcent.com
                    </div>
                  </div>
                </div> -->

              </div>


            </div>
            <div class="swp-btnBox">
              <div class="swiper-pagination"></div>
            </div>
          </div>

          <div class="c-qrcode">
            <div class="q-box">

              <div class="c-div" v-for="(item, index) in contact.list" v-show="index >= 3" :key="index">
                <p class="c-img"><img v-if="item.image" :src="host + item.image" alt=""></p>
                <p class="c-text font16">{{ item.title }}</p>
              </div>

              <!-- <div class="c-div">
                <p class="c-img"><img src="../assets/images/ys-116.png" alt=""></p>
                <p class="c-text font16">在线咨询</p>
              </div> -->

            </div>
          </div>

        </div>


      </div>
    </div>

    <!-- svg渐变 -->
    <div style="height: 0;position: fixed;z-index: -1;">
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1">
        <defs>
          <linearGradient id="grad1" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" style="stop-color:rgb(0,76,227);stop-opacity:1" />
            <stop offset="100%" style="stop-color:rgb(0,168,255);stop-opacity:0.65" />
          </linearGradient>
        </defs>
        <ellipse cx="200" cy="70" rx="85" ry="55" fill="url(#grad1)" />
      </svg>
    </div>

    <!-- 视频弹窗 -->
    <div id="hi-video-pop" :class="videoPopFlag ? 'active' : ''">
            <div class="hi-close" @click="videoClose()"></div>
            <div class="hi-video-box">
                <div class="hi-video-wrap">
                    <video id="myVideo" :src="host + about2Video" ref="videoPop" controls autoplay preload="auto" muted=""
                        webkit-playsinline="true" playsinline="true"></video>
                </div>
            </div>
        </div>

  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

// 这是引用wow
// import { WOW } from 'wowjs'
// 引入swiper
import Swiper from 'swiper';
import $ from 'jquery';
import 'swiper/dist/css/swiper.min.css';
// import vueSeamless from 'vue-seamless-scroll'
// import countTo from 'vue-count-to';
import '../assets/css/style.css';
import hostUrl from '@/util/baseUrl'


export default {
  // name: "HomeView",
  components: {
    // vueSeamless,
    // countTo
  },
  metaInfo() {
    return {
      title: this.seoInfo.title, //设置页面title
      meta: [
        {                 //设置页面meta
          name: 'keywords',
          content: this.seoInfo.keyWords
        },
        {
          name: "description",
          content: this.seoInfo.description
        },
        {
          name: "renderer",
          content: 'webkit',
        },
        {
          name: "force-rendering",
          content: 'webkit',
        },
        {
          name: "format-detection",
          content: 'telephone=no,telephone=no,email=no,adress=no',
        },
        {
          name: "viewport",
          content: 'width=device-width,user-scalable=no,initial-scale=1.0,maximum-scale=1.0,minimum-scale=1.0',
        },
      ]
    }
  },
  data() {
    return {
      // activeName: 0,
      // itemLength: 0,
      // swpActive: 0,     
      // 数字跳动
      // startVal1: 0,
      // endVal1: 20,
      // startVal2: 0,
      // endVal2: 900,
      // startVal3: 0,
      // endVal3: 6000,
      scrollFlag: false,
      // 屏幕宽度参数
      wW: '',
      // 
      about1Top: '',
      about2Img: "",
      about2Video: '', //发展历程视频
      about2Von: false,
      videoPopFlag: false, //视频弹窗
      // about2Data: [
      //   {
      //     on: false,
      //     navTitle: "2023",
      //     list: [
      //       {
      //         on: false,
      //         years: '2023.02',
      //         text: '成立南京元数科技有限公司，致力于搭建有温度的数字产业科技新生态',
      //         img: require('../assets/images/ys-163.jpg'),
      //       },
      //     ]
      //   },
      //   {
      //     on: true,
      //     navTitle: "2016-2022",
      //     list: [
      //       {
      //         on: false,
      //         years: '2022',
      //         text: '数智化：创纪云数智零售平台，赋能价值增长',
      //         img: require('../assets/images/ys-164.png'),
      //       },
      //       {
      //         on: false,
      //         years: '2021',
      //         text: '生态化：全链路·新消费，D2C & B2B生态创新',
      //         img: require('../assets/images/ys-165.png'),
      //       },
      //       {
      //         on: false,
      //         years: '2020',
      //         text: 'SaaS化：myERP发布',
      //         img: require('../assets/images/ys-166.png'),
      //       },
      //       {
      //         on: false,
      //         years: '2018',
      //         text: '商品化：核心业务中台商业发布',
      //         img: require('../assets/images/ys-166.png'),
      //       },
      //       {
      //         on: false,
      //         years: '2017',
      //         text: '平台化：中台架构用户级测试上线',
      //         img: require('../assets/images/ys-166.png'),
      //       },
      //     ]
      //   },
      //   {
      //     on: false,
      //     navTitle: "2001-2015",
      //     list: [
      //       {
      //         on: false,
      //         years: '2015',
      //         text: '成立江苏创纪云网络科技有限公司，转型为智慧零售方案提供商和服务商',
      //         img: require('../assets/images/ys-167.jpg'),
      //       },
      //       {
      //         on: false,
      //         years: '2001',
      //         text: '成立江苏百年软件科技有限公司，成为零售ERP提供商',
      //         img: require('../assets/images/ys-168.png'),
      //       },
      //     ]
      //   },
      // ],
      // 荣誉资质
      // about4Data: [
      //   {
      //     on: true,
      //     navTitle: "荣誉奖项",
      //     list: [
      //       {
      //         img: require('../assets/images/ys-169.png'),
      //         text: '2022 阿里云·中国数字化创新伙伴'
      //       },
      //       {
      //         img: require('../assets/images/ys-170.png'),
      //         text: '2022 中国好智造'
      //       },
      //       {
      //         img: require('../assets/images/ys-171.png'),
      //         text: '2021 优秀零售服务商'
      //       },
      //       {
      //         img: require('../assets/images/ys-172.png'),
      //         text: '2019-2020 明星软件产品奖'
      //       },
      //       {
      //         img: require('../assets/images/ys-173.png'),
      //         text: '2019 亚太智慧零售产业联盟副理事长单位'
      //       },
      //       {
      //         img: require('../assets/images/ys-174.jpg'),
      //         text: '2019 中国智慧零售全渠道数字化卓越贡献奖'
      //       },
      //       {
      //         img: require('../assets/images/ys-175.png'),
      //         text: '2018中国商业品牌影响奖'
      //       },
      //     ],
      //   },
      //   {
      //     on: false,
      //     navTitle: "资质证书",
      //     list: [
      //       {
      //         img: require('../assets/images/ys-176.png'),
      //         text: 'ISO9001质量管理体系认证'
      //       },
      //       {
      //         img: require('../assets/images/ys-180.png'),
      //         text: '软件企业认证'
      //       },
      //       {
      //         img: require('../assets/images/ys-177.png'),
      //         text: '软件产品认证'
      //       },
      //       {
      //         img: require('../assets/images/ys-178.png'),
      //         text: '高新技术企业认证'
      //       },
      //       {
      //         img: require('../assets/images/ys-179.png'),
      //         text: '高新技术产品认证'
      //       },
      //     ],
      //   },
      // ],
      // 二级导航TOp
      crumbsTop: '',
      crumbsFlag: false,
      crumbs: [],
      fixHead: false,
      swpNot: false, //禁止轮播滑动

      // 接口数据
      host: hostUrl,
      id: 9,
      about: {},
      banner: {},
      contact: {},
      course: {},
      courseList: [],
      culture: {},
      honor: {},
      honorList: [],
      // seo信息
      seoInfo: {
        title: '',
        keyWords: '',
        description: ''
      },
      // 全局seo
      seoAllInfo: {},
    }
  },

  mounted() {

    this.getData()

    this.wW = document.documentElement.clientWidth || document.body.clientWidth //获取浏览器宽度

    this.seoAllInfo = JSON.parse(localStorage.getItem('yuanshu_seoInfo')) //获取seo全局信息

    // window.addEventListener('scroll', this.scrolling, true)
    this.about1Top = document.getElementById('about1').offsetTop - 500
    // this.crumbsTop = $('.public-crumbs').offset().top
    // console.log(this.crumbsTop)


    let crumbsTop = document.getElementById('public-crumbs')
    this.crumbsTop = crumbsTop.offsetTop
    this.$bus.emit("crumbsTop", crumbsTop.offsetTop)

    this.$nextTick(() => {
      // 元数简介轮播
      // this.wW = document.documentElement.clientWidth; //获取浏览器宽度
      // console.log(this.wW)

      // 轮播图pc端禁止滑动
      if (this.wW > 770) {
        // $('.about1 .swiper-container').addClass('swiper-no-swiping')
        this.swpNot = true
      }





      // 荣誉资质轮播
      // var swpCxlt_arr = []
      // $(".about4 .swpBox .honor-swp .swiper-container").each(function (index, item) {
      //   var i = index
      //   swpCxlt_arr[i] = new Swiper($(this), {
      //     observer: true,
      //     observeParents: true,
      //     slidesPerView: 4,
      //     spaceBetween: 45,

      //     navigation: {
      //       nextEl: '.about4 .swpBox .honor-swp-' + i + ' .swiper-button-next',
      //       prevEl: '.about4 .swpBox .honor-swp-' + i + ' .swiper-button-prev',
      //     },
      //     // autoplay: {
      //     // delay: 5000,
      //     // disableOnInteraction:false,
      //     // },
      //     scrollbar: {
      //       el: '.about4 .swpBox .honor-swp-' + i + ' .swiper-scrollbar',
      //     },
      //     breakpoints: {
      //       1366: {
      //         slidesPerView: 4,
      //         spaceBetween: 30,
      //       },
      //       1200: {
      //         spaceBetween: 20,
      //       },
      //       768: {
      //         slidesPerView: 2,
      //         spaceBetween: 15
      //       }
      //     }
      //   })
      // })






      // $(".about4 .swpBox .honor-swp").each(function(index,item){
      //   $(this).find('.swpBtn').hover(function(){
      //     swpCxlt_arr[index].autoplay.stop()
      //   },function(){
      //     swpCxlt_arr[index].autoplay.start()
      //   })
      // })




    })


    // wowjs
    // this.$nextTick(() => {
    //   new this.$wow.WOW({
    //     live: false,
    //   }).init()
    // })



    // 百度统计代码
    var _hmt = _hmt || [];
    (function () {
      var hm = document.createElement("script");
      hm.src = "https://hm.baidu.com/hm.js?77637ce1a84bee069c1effb2174121bb";
      var s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(hm, s);
    })();

  },
  computed: {
    // defaultOption() {
    //   return {
    //     step: 0.8,
    //     hoverStop: true, // 是否开启鼠标悬停stop
    //     direction: 2, // 0向下 1向上 2向左 3向右
    //     openWatch: false, // 开启数据实时监控刷新dom
    //     // singleHeight: 40, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
    //     // singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
    //     // waitTime: 2000, // 单步运动停止的时间(默认值1000ms)
    //   }
    // }

  },

  destroyed() {
    // 离开页面时取消二级导航浮动        
    // this.$bus.$off("headShow");
  },
  activated() {
    this.crumbsFn()
  },
  methods: {
    // 请求后端接口
    getData() {

      let id = this.id

      this.about = {}
      this.banner = {}
      this.contact = {}
      this.course = {}
      this.culture = {}
      this.honor = {}
      this.seoInfo = {
        title: '',
        keyWords: '',
        description: ''
      }
      this.crumbs = []

      this.$axios.get('/api/page/' + id).then(res => {

        // seo信息
        if (res.data.data.name != '' && res.data.data.name != null && res.data.data.name != undefined) {
          this.seoInfo.title = res.data.data.name
        }else{
                    this.seoInfo.title=this.seoAllInfo.title
        }

        if (res.data.data.seoKeyword != '' && res.data.data.seoKeyword != null && res.data.data.seoKeyword != undefined) {
          this.seoInfo.keyWords = res.data.data.seoKeyword
        }else{
                    this.seoInfo.keyWords=this.seoAllInfo.keyWords
                }

        if (res.data.data.seoDesc != '' && res.data.data.seoDesc != null && res.data.data.seoDesc != undefined) {
          this.seoInfo.description = res.data.data.seoDesc
        }else{
                    this.seoInfo.description=this.seoAllInfo.description
                }

        this.banner = res.data.data.blocksObj.banner[0]
        this.about = res.data.data.blocksObj.about
        this.contact = res.data.data.blocksObj.contact

        this.course = res.data.data.blocksObj.course
        var courseListTemp = res.data.data.blocksObj.course.list
        for (var i = 0; i < courseListTemp.length; i++) {
          courseListTemp[i].on = false
        }
        courseListTemp[1].on = true
        this.courseList = courseListTemp
        this.about2Img = this.courseList[1].cons[0].image
        // 数据初始化显示隐藏视频播放按钮

        
        if(this.courseList[1].cons[0].video!=undefined && this.courseList[1].cons[0].video!='' && this.courseList[1].cons[0].video!=null){
          this.about2Von=true
          this.about2Video=this.courseList[1].cons[0].video
        }
        console.log(this.courseList[1].cons[0].video,'about2Video')

        this.culture = res.data.data.blocksObj.culture

        this.honor = res.data.data.blocksObj.honor
        var honorListTemp = res.data.data.blocksObj.honor.list

        for (var i = 0; i < honorListTemp.length; i++) {
          // honorListTemp[i].on=false
          // honorListTemp[0].on=true
          this.$set(honorListTemp[i], 'on', false)
          this.$set(honorListTemp[0], 'on', true)
        }
        this.honorList = honorListTemp

        this.contact = res.data.data.blocksObj.contact

        console.log(this.courseList, 'courseList')

        this.$nextTick(() => {

          setTimeout(() => {
            this.about1Swp()
          }, 0)

          setTimeout(() => {
            this.about3Swp()
          }, 100)

          setTimeout(() => {
            this.honorSwp0()
          }, 200)

          setTimeout(() => {
            this.honorSwp1()
          }, 250)

          setTimeout(() => {
            this.about5Swp()
          }, 350)



          // wow动画
          new this.$wow.WOW({
            live: false,
          }).init()


          window.addEventListener('scroll', this.handleScroll)

        })
      })

      // 二级导航
      this.$axios.get('/api/menu/66').then(res => {
        var crumbsTemp = res.data.data
        for (var i = 0; i < crumbsTemp.length; i++) {
          crumbsTemp[i].on = false
        }
        this.crumbs = crumbsTemp
        this.crumbsFn()
      })


    },
    toast() {
      this.$toast.show('页面建设中，请耐心等待…', '您可通过电话或微信扫描底部二维码联系我们', 5000)
    },
    handleScroll() {
      // this.$nextTick(() => {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      // console.log(scrollTop, this.about1Top)
      if (scrollTop >= this.about1Top) {
        if (this.scrollFlag == false) {
          for (var i = 0; i < this.$refs.aboutNum.length; i++) {
            this.$refs.aboutNum[i].start()
          }
          this.scrollFlag = true
        }
      }

      // 二级导航浮动
      var that = this
      setTimeout(function () {
        if (scrollTop >= that.crumbsTop) {
          that.crumbsFlag = true
        } else {
          that.crumbsFlag = false
        }
      }, 50)


      // })
    },
    // 发展历程点击切换&鼠标移入移出切换
    about2Nav(index) {
      for (var i = 0; i < this.courseList.length; i++) {
        this.courseList[i].on = false
      }
      this.courseList[index].on = true
      this.about2Img = this.courseList[index].cons[0].image

      if(this.courseList[index].cons[0].video!=undefined && this.courseList[index].cons[0].video!='' && this.courseList[index].cons[0].video!=null){
          this.about2Von=true
          this.about2Video=this.courseList[index].cons[0].video
      }else{
          this.about2Von=false
          this.about2Video=''
      }

      console.log(this.courseList[index].cons[0].image)
    },
    about2Over(index1, index2, item2) {
      this.about2Img = this.courseList[index1].cons[index2].image
      this.courseList[index1].cons[index2].on = true
      
      if (item2.video != undefined && item2.video != '' && item2.video != null) {
        this.about2Video = item2.video
        this.about2Von = true
      }else{
        this.about2Von=false
      }

      console.log(item2.video,this.about2Von)

    },
    about2Out(index1, index2) {
      for (var i = 0; i < this.courseList[index1].cons.length; i++) {
        this.courseList[index1].cons[i].on = false
      }
      // console.log(index1)
    },
    // 荣誉资质
    about4Nav(index) {
      for (var i = 0; i < this.honorList.length; i++) {
        this.$set(this.honorList[i], 'on', false)
        // this.honorList[i].on = false
      }
      // this.honorList[index].on = true
      this.$set(this.honorList[index], 'on', true)
      console.log(this.honorList)
    },
    // 元数简介数字跳动轮播
    about1Swp() {
      var autoplayFlag=''
      if(this.wW<=770){
        autoplayFlag=true
      }else{
        autoplayFlag=false
      }
      var about1 = new Swiper('.about1 .swiper-container', {
        autoplay: autoplayFlag,
        autoplay: {
          delay: 5000,
        },
        slidesPerView: 3,
        // loop: true,
        spaceBetween: 0,
        observer: true,
        observeParents: true,
        pagination: {
          el: '.about1 .swiper-pagination',
          clickable: true,
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
            spaceBetween: 0,
            // autoplay:true,
            // loop: false,
          }
        }
      })
    },
    // 企业文化轮播
    about3Swp() {
      var autoplayFlag=''
      if(this.wW<=770){
        autoplayFlag=true
      }else{
        autoplayFlag=false
      }
      var about3 = new Swiper('.about3 .swiper-container', {
        // autoplay: false,
        autoplay: autoplayFlag,
        autoplay: {
          delay: 5000,
        },
        slidesPerView: 3,
        // loop: true,
        spaceBetween: 50,
        observer: true,
        observeParents: true,
        pagination: {
          el: '.about3 .swiper-pagination',
          clickable: true,
        },
        breakpoints: {
          1366: {
            spaceBetween: 30,
          },
          1200: {
            slidesPerView: 2,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 20,
            // loop: false,
          }
        }
      })
    },
    // 荣誉奖项1轮播
    honorSwp0() {
      var honorSwp0 = new Swiper('.about4 .honor-swp-0 .swiper-container', {
        observer: true,
        observeParents: true,
        slidesPerView: 4,
        spaceBetween: 45,

        navigation: {
          nextEl: '.about4 .swpBox .honor-swp-0 .swiper-button-next',
          prevEl: '.about4 .swpBox .honor-swp-0 .swiper-button-prev',
        },
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        scrollbar: {
          el: '.about4 .swpBox .honor-swp-0 .swiper-scrollbar',
        },
        breakpoints: {
          1366: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
          1200: {
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 15
          }
        }
      })
    },
    // 资质证书轮播
    honorSwp1() {
      var honorSwp1 = new Swiper('.about4 .swpBox .honor-swp-1 .swiper-container', {
        observer: true,
        observeParents: true,
        slidesPerView: 4,
        spaceBetween: 45,

        navigation: {
          nextEl: '.about4 .swpBox .honor-swp-1 .swiper-button-next',
          prevEl: '.about4 .swpBox .honor-swp-1 .swiper-button-prev',
        },
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        scrollbar: {
          el: '.about4 .swpBox .honor-swp-1 .swiper-scrollbar',
        },
        breakpoints: {
          1366: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
          1200: {
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 15
          }
        }
      })
    },
    // 联系我们轮播
    about5Swp() {
      var autoplayFlag=''
      if(this.wW<=770){
        autoplayFlag=true
      }else{
        autoplayFlag=false
      }
      var about5 = new Swiper('.about5 .about5Swp .swiper-container', {
        // autoplay: false,
        autoplay: autoplayFlag,
        autoplay: {
          delay: 5000,
        },
        slidesPerView: 3,
        spaceBetween: 0,
        pagination: {
          el: '.about5 .about5Swp .swiper-pagination',
          clickable: true,
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
            spaceBetween: 0,
            // loop: false,
          }
        }
      })
    },
    // 二级导航切换
    crumbsFn() {
      let urlPath = this.$route.path.replace(/<[^>]+>/g, '')
      for (var i = 0; i < this.crumbs.length; i++) {
        this.crumbs[i].on = false
        if (this.crumbs[i].url == urlPath) {
          this.crumbs[i].on = true
        }
      }
    },
    // 发展历程视频弹窗
    videoPlay(video) {
      if (video != undefined && video!='' && video!=null) {
        this.about2Video = video
        this.videoPopFlag = true
        this.$refs.videoPop.play()
      }
    },
    videoClose() {
            this.videoPopFlag = false
            this.$refs.videoPop.pause()
      },

  },
};
</script>

<style scoped lang="less"></style>