<template>
    <div class="join">

        <!-- 内页顶部banner -->
        <div class="public-nybn about-bn">
            <div class="bgimg scaleDraw" v-if="banner.image">
                <img :src="host + banner.image" alt="">
            </div>
            <div class="zz-box">

                <div class="bn-title fadeInUp2">
                    <div class="container">
                        <div class="c-title">
                            <h2 class="h2 font36 text_b">
                                {{ banner.title }}
                            </h2>
                            <div class="syb"></div>
                        </div>
                        <div class="font40 text_b c-text pre">
                            <span>{{ banner.text }}</span>
                        </div>

                    </div>
                </div>


            </div>
        </div>



        <!-- 公司动态 -->
        <!-- 全部 -->
        <!-- <div class="x-join2">
            <div class="x-con">
                <a href="" class="on font18">全部</a>
                <a href="" class="font18">社会招聘（20）</a>
                <a href="" class="font18">校园招聘（10）</a>
            </div>
        </div> -->

        <div class="public-crumbs public-crumbs2 public-crumbs3" id="public-crumbs">
            <div class="container">
                <div class="c-box">

                    <h3 class="c-a font18" :class="item.on ? 'on' : ''" v-for="(item, index) in crumbs" :key="index"
                        @click="joinNav(index)">
                        <a href="javascript:;" rel="nofollow">
                            {{ item.title }}
                        </a>
                    </h3>

                    <!-- <h3 class="c-a font18">
                        <a href="javascript:;" rel="nofollow">
                            {{ item.title }}
                        </a>
                    </h3>

                    <h3 class="c-a font18">
                        <a href="javascript:;" rel="nofollow">
                            {{ item.title }}
                        </a>
                    </h3> -->

                </div>
            </div>
        </div>



        <!-- 职位类别 -->
        <div class="x-join3">
            <div class="container">
                <div class="x-lb">
                    <ul>
                        <li v-show="joinType != ''">
                            <span>职位类别：</span>
                            <div class="x-a">
                                <a href="javascript:;" :class="item.on ? 'on' : ''" v-for="(item, index) in joinType"
                                    :key="index" @click="joinTypeFn(item, index)">{{ item.name }}</a>
                                <!-- <a href="javascript:;">产品</a> -->
                            </div>
                        </li>
                        <li v-show="joinCity != ''">
                            <span>工作地点：</span>
                            <div class="x-a">
                                <a href="javascript:;" :class="item.on ? 'on' : ''" v-for="(item, index) in joinCity"
                                    :key="index" @click="joinCityFn(item, index)">{{ item.name }}</a>
                                <!-- <a href="javascript:;">广州</a> -->
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="x-xia-con">
                    <div class="x-top">
                        <h3 class="x-title font18">职位名称</h3>
                        <h3 class="x-title font18">职位类别</h3>
                        <h3 class="x-title font18">工作地点</h3>
                    </div>

                    <!-- <div v-show="newsList == ''" class="font16"
                        style="text-align: center;margin-top: 0.8rem;margin-bottom:0.8rem;color: #666;">
                        更多职位，敬请关注！
                    </div> -->

                    <div v-show="newsList==''" class="notInfoBox" style="margin-top: 1rem">
                        <img class="c-img" src="../assets/images/ys-401.png">
                        <span class="font16 c-span">暂无相关信息，敬请关注！</span>
                    </div>

                    <div class="x-xia">
                        <ul>
                            <li class="wow fadeInUp" v-for="(item, index) in newsList" :key="index">
                                <div class="x-tit" @click="joinListFn(item, index)" :class="item.on == true ? 'on' : ''">
                                    <h3 class="x-title c-title">{{ item.title }}</h3>
                                    <h3 class="x-title font18"><img src="../assets/images/x-join2.svg" alt="">{{
                                        item.className }}</h3>
                                    <h3 class="x-title font18"><img src="../assets/images/x-join3.svg" alt="">{{
                                        item.class1Name }}</h3>
                                    <i></i>
                                </div>
                                <div class="x-bjq font16" :class="item.on == true ? 'on' : ''">
                                    <!-- 这里输出文本框 -->
                                    <h4 class="">工作职责∶</h4>
                                    <div class="pre">{{ item.duty }}</div>
                                    <br>
                                    <h4>任职要求︰</h4>
                                    <div class="pre">{{ item.ask }}</div>
                                    <div class="x-aa">
                                        <a href="javascript:;" rel="nofollow" class="hi-btn1"
                                            @click="curtainFn(item.title,item.id)">
                                            <span class="hi-text font16">立即申请</span>
                                            <img class="hi-ico" src="../assets/images/hi-btn-arrow1.svg"
                                                onload="SVGInject(this)">
                                        </a>
                                    </div>
                                </div>
                            </li>

                            <!-- <li class=" wow fadeInUp">
                                <div class="x-tit">
                                    <h3 class="x-title c-title">工业控制工程师</h3>
                                    <h3 class="x-title font18"><img src="../assets/images/x-join2.svg" alt="">开发</h3>
                                    <h3 class="x-title font18"><img src="../assets/images/x-join3.svg" alt="">深圳</h3>
                                    <i></i>
                                </div>
                                <div class="x-bjq font16">
                                    <h4 class="">工作职责∶</h4>
                                    <p>
                                        具备良好沟通能力，负责客户/合作方的技术支持和需求对接;根据公司产品编写案例、应用指南和技术文档。</p>
                                    <br>
                                    <h4>任职要求︰</h4>
                                    <p>
                                        1.电子类相关专业经验<br>
                                        2.熟悉Linux操作系统<br>
                                        3.熟恶C/C++语言，有一定的Debugf代码能力<br>
                                        4.思维逻辑强，熟悉软硬件调试工县、方法，可以独立完成公司DEMO和产品的软硬件环境搭建和运行<br>
                                        5.了解深度学习基本原理和背景者，优先考虑。电子类相关专业经验2.熟悉Linux操作系统电子类相关专业经验2.熟悉Linux操作系统<br>
                                        1.电子类相关专业经验2.熟悉Linux操作系统电子类相关专业经验2.熟悉Linux操作系统<br>
                                        3.熟恶C/C++语言，有一定的Debugf代码能力<br>
                                        4.思维逻辑强，熟悉软硬件调试工县、方法，可以独立完成公司DEMO和产品的软硬件环境搭建和运行<br>
                                        5.了解深度学习基本原理和背景者，优先考虑。
                                    </p>
                                    <div class="x-aa">
                                        <a href="javascript:;" rel="nofollow" class="hi-btn1">
                                            <span class="hi-text font16">立即申请</span>
                                            <img class="hi-ico" src="../assets/images/hi-btn-arrow1.svg"
                                                onload="SVGInject(this)">
                                        </a>
                                    </div>
                                </div>
                            </li> -->
                        </ul>

                        <div class="public-page wow fadeInUp" v-show="newsList != ''">

                            <page-pagination :total="page.total" :pageSize="page.pageSize" :currentPage="page.currentPage"
                                :numAround="true" @change="change"></page-pagination>

                        </div>

                    </div>
                </div>
            </div>
        </div>

        <!-- 职位申请 -->
        <div class="curtain curtain2" :class="curtain_flag == true ? 'on' : ''">
            <div class="mask"></div>
            <div class="curtain_box">
                <div class="curtain_title">
                    <p class="close_tc" @click="curtain_flag = !curtain_flag"></p>
                </div>
                <!-- 内容 -->
                <div class="x-con">
                    <div class="x-left">
                        <div class="x-p font18">
                            <p>申请职位</p>
                        </div>
                        <h3 class="x-title font30" id="c-job-title">{{ curtain_title }}</h3>
                    </div>
                    <div class="x-right">
                        <form class="x-form">

                            <ul>
                                <li>
                                    <input type="text" id="xname" placeholder="姓名*" v-model="name">
                                </li>
                                <li>
                                    <input type="text" id="xphone" placeholder="手机号码*" v-model="job_mobile">
                                </li>
                                <li>
                                    <input type="text" placeholder="邮箱*" v-model="job_email">
                                </li>
                                <li>
                                    <input type="text" placeholder="预计到岗时间*" v-model="check_in_time">
                                    <span class="x-sp">例：2022-07-01</span>
                                </li>
                                <li class="x-li">
                                    <span class="font18">简历上传</span>
                                    <div class="hi-input-box">
                                        <span class="x-filename">{{ job_name }}</span>
                                        <p class="x-pp"></p>
                                        <p class="hi-text">上传简历*</p>
                                        <input type="file" class="hi-file" name="file" value="上传简历"
                                            accept=".pdf,.doc,.docx,.xls,.xlsx" ref="fileInput" @change="uploadFile">
                                    </div>
                                </li>
                                <li class="x-li x-li2">
                                    <div class="x-p">
                                        <span>大小不超过5M，支持.Doc,.Docx,.Pdf</span>
                                        <p>若文件太大请发送至邮箱： <span class="x-sp">{{ joinEmail }}</span>，并在下方备注</p>
                                    </div>
                                </li>
                                <li>
                                    <input type="text" placeholder="请填写您的链接或备注*" v-model="notes">
                                </li>
                                <li class="x-but" @click="jobSumbit()">
                                    <button type="button">立即申请</button>
                                </li>
                            </ul>
                        </form>
                    </div>
                </div>
            </div>
        </div>


    </div>
</template>
  
<script>
// 这是引用wow
// import { WOW } from 'wowjs'
// 引入swiper
import Swiper from 'swiper';
import $ from 'jquery';
import 'swiper/dist/css/swiper.min.css';
import '../assets/css/style.css';
import '../assets/css/proson.css';
import pagePagination from "@/components/page-pagination/page-pagination.vue";
import hostUrl from '@/util/baseUrl'

export default {
    components: {
        pagePagination
    },
    metaInfo() {
        return {
            title: this.seoInfo.title, //设置页面title
            meta: [
                {                 //设置页面meta
                    name: 'keywords',
                    content: this.seoInfo.keyWords
                },
                {
                    name: "description",
                    content: this.seoInfo.description
                },
                {
                    name: "renderer",
                    content: 'webkit',
                },
                {
                    name: "force-rendering",
                    content: 'webkit',
                },
                {
                    name: "format-detection",
                    content: 'telephone=no,telephone=no,email=no,adress=no',
                },
                {
                    name: "viewport",
                    content: 'width=device-width,user-scalable=no,initial-scale=1.0,maximum-scale=1.0,minimum-scale=1.0',
                },
            ]
        }
    },
    data() {
        return {
            scrollFlag: false,
            // 屏幕宽度参数
            wW: '',
            // 分页器
            page: {
                total: 0,
                pageSize: 6,
                currentPage: 1
            },

            // 二级导航TOp
            crumbsTop: '',
            crumbsFlag: false,
            crumbs: [
                {
                    title: '全部',
                    on: true,
                    type: '',
                },
                {
                    title: '社会招聘',
                    on: false,
                    type: 0
                },
                {
                    title: '校园招聘',
                    on: false,
                    type: 1
                },
            ],
            crumbsIndex: '',

            // 简历弹窗
            curtain_flag: false,
            curtain_title: '',

            // 接口数据
            host: hostUrl,
            banner: {},
            joinType: [],
            joinCity: [],
            newsList: [],
            joinEmail: '', //弹窗显示的接收简历的邮箱,不是用户提交的简历邮箱
            class_id: '', //职位类别
            class1_id: '', //工作地点
            // 表单
            job_name:'',
            job_id:'',
            notes:'',
            resume:'',
            check_in_time:'',
            job_mobile:'',
            name:'',
            job_email:'',
            // seo信息
            seoInfo: {
                title: '',
                keyWords: '',
                description: ''
            },
            // 全局seo
            seoAllInfo: {},
        }
    },

    mounted() {
        this.getData()

        for (var i = 0; i < this.crumbs.length; i++) {
            if (this.crumbs[i].on == true) {
                this.crumbsIndex = i
            }
        }

        this.wW = document.documentElement.clientWidth || document.body.clientWidth //获取浏览器宽度

        this.seoAllInfo = JSON.parse(localStorage.getItem('yuanshu_seoInfo')) //获取seo全局信息

        // window.addEventListener('scroll', this.scrolling, true)

        // this.joinEmail = localStorage.getItem('yuanshu_email') // 简历弹窗的邮箱

        // wowjs
        this.$nextTick(() => {
            // var wow = new WOW({
            //   boxClass: 'wow',
            //   animateClass: 'animated',
            //   // offset: 40,
            //   // mobile: true,
            //   live: false,
            // })
            // wow.init()
            // new WOW().init()
            // new this.$wow.WOW({
            //     live: false,
            // }).init()
        })

        window.addEventListener('scroll', this.handleScroll)

        // 百度统计代码
        var _hmt = _hmt || [];
        (function () {
            var hm = document.createElement("script");
            hm.src = "https://hm.baidu.com/hm.js?77637ce1a84bee069c1effb2174121bb";
            var s = document.getElementsByTagName("script")[0];
            s.parentNode.insertBefore(hm, s);
        })();

        
    },
    computed: {

    },
    destroyed() {
        // 离开页面时取消二级导航浮动        
        // this.$bus.$off("headShow");
    },
    watch: {
        $route: {
            // 监听路由变化
            handler: function (val, oldVal) {
                this.getData()
            },
            deep: true
        }
    },
    methods: {
        // 请求后端接口
        getData() {

            // console.log(this.$route.query, '路由id')
            this.banner = {}
            this.joinType = []
            this.joinCity = []
            this.newsList = []
            this.seoInfo={
                title:'',
                keyWords:'',
                description:''
            }

            this.$axios.get('/api/page/13').then(res => {
                // seo信息
                if (res.data.data.name != '' && res.data.data.name != null && res.data.data.name != undefined) {
                            this.seoInfo.title = res.data.data.name
                }else{
                    this.seoInfo.title=this.seoAllInfo.title
                }
                if (res.data.data.seoKeyword != '' && res.data.data.seoKeyword != null && res.data.data.seoKeyword != undefined) {
                    this.seoInfo.keyWords = res.data.data.seoKeyword
                }else{
                    this.seoInfo.keyWords=this.seoAllInfo.keyWords
                }

                if (res.data.data.seoDesc != '' && res.data.data.seoDesc != null && res.data.data.seoDesc != undefined) {
                    this.seoInfo.description = res.data.data.seoDesc
                }else{
                    this.seoInfo.description=this.seoAllInfo.description
                }

                this.banner = res.data.data.blocksObj.banner[0]
                this.joinEmail=res.data.data.blocksObj.email

                // 职位类别
                var joinTypeTemp = res.data.data.extend.type
                for (var i = 0; i < joinTypeTemp.length; i++) {
                    joinTypeTemp[i].on = false
                }
                joinTypeTemp.unshift({ name: '全部', type: '', on: true })
                this.joinType = joinTypeTemp
                // console.log(res.data.data.extend)

                // 工作地点
                var joinCityTemp = res.data.data.extend.city
                // console.log(res.data.data.extend.city,'city')
                for (var i = 0; i < joinCityTemp.length; i++) {
                    joinCityTemp[i].on = false
                }
                joinCityTemp.unshift({ name: '全部', type: '', on: true })
                this.joinCity = joinCityTemp


                this.$nextTick(() => {

                    setTimeout(() => {

                    }, 0)

                    // wow动画
                    new this.$wow.WOW({
                        live: false,
                    }).init()

                })
            })

            // 招聘列表
            this.$axios.get('/api/job', {
                params: {
                    pageSize: this.page.pageSize,
                }
            }).then(res => {

                var newsListTemp = res.data.rows
                this.page.total = res.data.total

                // console.log(newsListTemp)

                for (var i = 0; i < newsListTemp.length; i++) {
                    newsListTemp[i].on = false
                }
                newsListTemp[0].on = true
                this.newsList = newsListTemp

            })

        },
        toast() {
            this.$toast.show('页面建设中，请耐心等待…', '您可通过电话或微信扫描底部二维码联系我们', 5000)
        },
        handleScroll() {
            // this.$nextTick(() => {
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop

            // })
        },
        // 分页器
        change(currentPage, type) {
            let pageNum = currentPage
            this.page.currentPage = currentPage;
            // console.log("点击了" + type + "，当前页：" + currentPage);
            
            this.newsList = []
            this.$axios.get('/api/job/', {
                params: {
                    pageNum: pageNum,
                    pageSize: this.page.pageSize,
                }
            }).then(res => {
                var newsListTemp = res.data.rows
                for (var i = 0; i < newsListTemp.length; i++) {
                    newsListTemp[i].on = false
                }
                
                this.newsList=newsListTemp
                
                // document.documentElement.scrollTop = 0
                document.documentElement.scrollTop = document.getElementById('public-crumbs').offsetTop

            })
            
        },
        // 招聘类型选择 全部-社会-校园
        joinNav(index) {
            for (var i = 0; i < this.crumbs.length; i++) {
                this.crumbs[i].on = false
            }
            this.crumbs[index].on = true
            this.crumbsIndex = index

            this.newsList = []

            let joinParams = {
                pageSize: this.page.pageSize,
            }

            // class_id是职位，class1_id是工作地点，为空则不传值
            if (index == 0) { //全部招聘
                if (this.class_id != '' && this.class1_id == '') {
                    joinParams.class_id = this.class_id
                } else if (this.class_id == '' && this.class1_id != '') {
                    joinParams.class1_id = this.class1_id

                } else if (this.class_id != '' && this.class1_id != '') {
                    joinParams.class_id = this.class_id,
                        joinParams.class1_id = this.class1_id
                }
            } else if (index == 1) { //社会招聘
                joinParams.type = 0
            } else if (index == 2) { //校园招聘
                joinParams.type = 1
            }


            this.$axios.get('/api/job', {
                params: joinParams
            }).then(res => {

                var newsListTemp = res.data.rows
                this.page.total = res.data.total

                if (newsListTemp != '') {
                    for (var i = 0; i < newsListTemp.length; i++) {
                        newsListTemp[i].on = false
                    }
                    newsListTemp[0].on = true
                }
                this.newsList = newsListTemp
                // console.log(this.newsList)
                // document.documentElement.scrollTop = 0
            })


        },
        // 招聘列表展开收起
        joinListFn(item, index) {
            // for (var i = 0; i < this.newsList.length; i++) {
            //     if (this.newsList[i] != this.newsList[index]) {
            //         this.$set(this.newsList[i], 'on', false)
            //     }
            // }

            if (this.newsList[index].on == false) {
                this.$set(this.newsList[index], 'on', true)
            } else {
                this.$set(this.newsList[index], 'on', false)
            }
        },
        // 职位类别
        joinTypeFn(item, index) {

            for (var i = 0; i < this.joinType.length; i++) {
                if (this.joinType[i] != this.joinType[index]) {
                    this.$set(this.joinType[i], 'on', false)
                }
            }

            this.$set(this.joinType[index], 'on', true)
            this.class_id = this.joinType[index].id
            // console.log(this.joinType[index])


            let joinParams = {
                pageSize: this.page.pageSize,
            }

            // 不设type就是全部
            if (this.crumbsIndex == 1) {
                joinParams.type = 0 //社会招聘
            } else if (this.crumbsIndex == 1) {
                joinParams.type = 1  //校园招聘
            }

            // 如果职位不为空则传职位的值，为空则不传
            if (this.class_id != '') {
                joinParams.class_id = this.class_id
            }

            // 如果工作地点不为空则传地点的值，为空则不传
            if (this.class1_id != '') {
                joinParams.class1_id = this.class1_id
            }


            this.$axios.get('/api/job', {
                params: joinParams
            }).then(res => {

                var newsListTemp = res.data.rows
                this.page.total = res.data.total

                if (newsListTemp != '') {
                    for (var i = 0; i < newsListTemp.length; i++) {
                        newsListTemp[i].on = false
                    }
                    newsListTemp[0].on = true
                }

                this.newsList = newsListTemp
                // console.log(this.newsList)
                // document.documentElement.scrollTop = 0
            })

        },
        // 工作地点
        joinCityFn(item, index) {

            for (var i = 0; i < this.joinCity.length; i++) {
                if (this.joinCity[i] != this.joinCity[index]) {
                    this.$set(this.joinCity[i], 'on', false)
                }
            }

            this.$set(this.joinCity[index], 'on', true)
            this.class1_id = this.joinCity[index].id

            let joinParams = {
                pageSize: this.page.pageSize,
            }

            // 不设type就是全部
            if (this.crumbsIndex == 1) {
                joinParams.type = 0 //社会招聘
            } else if (this.crumbsIndex == 1) {
                joinParams.type = 1  //校园招聘
            }

            // 如果职位不为空则传职位的值，为空则不传
            if (this.class_id != '') {
                joinParams.class_id = this.class_id
            }

            // 如果工作地点不为空则传地点的值，为空则不传
            if (this.class1_id != '') {
                joinParams.class1_id = this.class1_id
            }

            // console.log(joinParams)

            this.$axios.get('/api/job', {
                params: joinParams
            }).then(res => {

                var newsListTemp = res.data.rows
                this.page.total = res.data.total

                if (newsListTemp != '') {
                    for (var i = 0; i < newsListTemp.length; i++) {
                        newsListTemp[i].on = false
                    }
                    newsListTemp[0].on = true
                }
                this.newsList = newsListTemp
                // console.log(this.newsList)
                // document.documentElement.scrollTop = 0
            })
        },
        // 简历弹窗
        curtainFn(title,id) {
            this.curtain_flag = !this.curtain_flag
            this.curtain_title = title
            this.job_id=id
            this.name=this.job_mobile=this.job_email=this.check_in_time=this.resume=this.notes=this.job_name=''
        },
        // 简历文件上传
        uploadFile() {
            // this.job_name=''
            const formData = new FormData();
            formData.append('file', this.$refs.fileInput.files[0]);

            // this.job_name=this.$refs.fileInput.files[0].name

            this.$axios.post('/common/upload', 
            formData,{
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }).then(res => {
                
                if (res.data.code == 200) {
                    console.log(res.data)
                    this.resume=res.data.fileName
                }
                else{                    
                    this.$toast.show('提交失败,请刷新页面重试', '', 3000)
                }

            })    
        },
        // 简历提交
        jobSumbit(){
                        
            var t = /^((13|14|15|16|17|18|19)+\d{9})$/;
            var dateRegex = /^\\d{4}-\\d{2}-\\d{2}$/;

            if (this.name == '') {
                this.$toast.show('姓名不能为空', '', 3000)      
                return
            }
            if (this.job_mobile == '') {
                this.$toast.show('手机号不能为空', '', 3000)
                return
            }
            if (!t.test(this.job_mobile)) {
                this.$toast.show('手机号不正确', '', 3000)
                return
            }
            if (this.job_email == '') {
                this.$toast.show('邮箱不能为空', '', 3000)
                return
            }
            if (this.check_in_time == '') {
                this.$toast.show('到岗时间不能为空', '', 3000)
                return
            }
            if (dateRegex.test(this.check_in_time)) {
                this.$toast.show('请按示例填写正确的到岗时间', '', 3000)
                return
            }
            if (this.resume == '') {
                this.$toast.show('请上传简历', '', 3000)
                return
            }
            if (this.notes == '') {
                this.$toast.show('请填写备注', '', 3000)
                return
            }

            var data = {
                name: this.name,
                mobile:this.job_mobile,
                email:this.job_email,
                check_in_time:this.check_in_time,
                resume:this.resume,
                notes:this.notes,
                job_id:this.job_id,
                job_name:this.curtain_title
            }

            this.$axios.post('/api/job', 
            data,{
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }).then(res => {
                
                if (res.data.code == 200) {
                    console.log(res.data)
                    this.$toast.show('简历提交成功', '', 3000)
                    this.name=this.job_mobile=this.job_email=this.check_in_time=this.resume=this.notes=this.job_name=''
                    setTimeout(()=>{
                        this.curtain_flag = !this.curtain_flag
                    },3000)
                }else{
                    this.name=this.job_mobile=this.job_email=this.check_in_time=this.resume=this.notes=this.job_name=''
                    this.$toast.show('提交失败,请刷新页面重试', '', 3000)
                }

            }) 
        }
    },
};
</script>
  
<style scoped lang="less"></style>