<template>
    <div class="newsDetail">


        <div class="newsDetail1 pdtb">
            <div class="container">
                <div class="zz-box" :class="relatedList == '' ? 'on' : ''">
                    <div class="zz-left wow fadeInUp">
                        <div class="zz-title-box">
                            <div class="zz-c-date font18">{{ fixDate(newsDetail.time) }}</div>
                            <h1 class="h1 font30 text_b">{{ newsDetail.title }}</h1>
                        </div>


                        <div class="zz-text font18 ql-editor" v-html="newsDetail.content"></div>

                        <div class="c-tag" v-if="newsDetail.tags != '' && newsDetail.tags != undefined">

                            <div class="c-div font18">标签：</div>
                            <ul>
                                <li v-if="newsDetail.tags">
                                    <router-link v-if="newsDetail.tags" :to="'/news?id=' + listid" class="font14 c-a">{{ newsDetail.tags
                                    }}</router-link>
                                </li>

                            </ul>

                        </div>

                        <div class="c-atBtnBox">
                            <div class="div-1 c-btn" v-if="newsPrev != null"><router-link class="font16 c-a"
                                    :to="{ path: '/newsdetail', query: { id: newsPrev.id, listid: listid } }"><img
                                        class="hi-ico" src="../assets/images/about-img5.svg"
                                        onload="SVGInject(this)">上一篇</router-link>
                            </div>

                            <div class="div-3 c-btn" v-if="newsNext != null"><router-link class="font16 c-a"
                                    :to="{ path: '/newsdetail', query: { id: newsNext.id, listid: listid } }">下一篇<img
                                        class="hi-ico" src="../assets/images/about-img5.svg"
                                        onload="SVGInject(this)"></router-link></div>
                        </div>



                    </div>

                    <!-- 相关文章 -->
                    <div class="zz-right wow fadeInUp" v-show="relatedList != ''">
                        <div class="newsNavBox">
                            <div class="zz-title font30 text_b">相关文章</div>
                            <ul class="c-ul">
                                <li class="c-li" v-for="(item, index) in relatedList" :key="index">
                                    <router-link @click.native="goTop(item.target)" :target="item.target"
                                        :to="{ path: '/newsdetail', query: { id: item.id, listid: item.listid } }">
                                        <div class="zz-date font16">
                                            {{ fixDate(item.time) }}
                                        </div>
                                        <div class="font20 zz-t">
                                            {{ item.title }}
                                        </div>
                                    </router-link>
                                </li>

                                <!-- <li class="c-li">
                                    <router-link to="/newsdetail?id=1">
                                    <div class="zz-date font16">
                                        2023.03.23
                                    </div>
                                    <div class="font20 zz-t">
                                        元戎启行，数往知来 | 南京元数科技有限公司创立大会成功
                                    </div>
                                    </router-link>
                                </li>

                                <li class="c-li">
                                    <router-link to="/newsdetail">
                                    <div class="zz-date font16">
                                        2023.03.23
                                    </div>
                                    <div class="font20 zz-t">
                                        科技让商业更美好!元数科技携创纪云3.0进化而来
                                    </div>
                                    </router-link>
                                </li>

                                <li class="c-li">
                                    <router-link to="/newsdetail">
                                    <div class="zz-date font16">
                                        2023.03.23
                                    </div>
                                    <div class="font20 zz-t">
                                        元戎启行，数往知来 | 南京元数科技有限公司创立大会成功
                                    </div>
                                    </router-link>
                                </li> -->

                            </ul>
                        </div>

                    </div>

                </div>

            </div>
        </div>

        <!-- 热门文章 -->
        <div class="newsDetail2" v-show="hotList != ''">
            <div class="container">
                <div class="public-title wow fadeInUp">
                    <h2 class="font40 text_b h2">
                        <span>热门文章</span>
                    </h2>
                </div>

                <div class="newsList wow fadeInUp">
                    <ul class="c-ul">
                        <li class="c-li" v-for="(item, index) in hotList" :key="index">
                            <router-link @click.native="goTop(item.target)" :target="item.target"
                                :to="{ path: '/newsdetail', query: { id: item.id, listid: item.listid } }" class="c-a">
                                <div class="c-div1">
                                    <div class="font14 c-tips" v-if="item.tags != '' && item.tags != undefined">{{ item.tags
                                    }}
                                    </div>
                                    <img v-if="item.cover" :src="host+item.cover" alt="">
                                </div>
                                <div class="c-box">
                                    <div class="c-div2">
                                        <h3 class="h3 font24 text_b">
                                            {{ item.title }}
                                        </h3>
                                    </div>
                                    <div class="c-div3">
                                        <div class="c-date font16">
                                            {{ fixDate(item.time) }}
                                        </div>
                                        <p class="cn9">
                                            <span class="cn4 font16 font-b">查看详情</span>
                                            <span class="cn25"><img class="hi-ico" src="../assets/images/about-img5.svg"
                                                    onload="SVGInject(this)">
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </router-link>
                        </li>

                        <!-- <li class="c-li">
                            <router-link to="newsdetail" class="c-a">
                                <div class="c-div1">
                                    <div class="font14 c-tips">商超便利</div>
                                    <img src="../assets/images/about-img20.png" alt="">
                                </div>
                                <div class="c-box">
                                    <div class="c-div2">
                                        <h3 class="h3 font24 text_b">
                                            科技让商业更美好!元数科技携创纪云3.0进化而来
                                        </h3>
                                    </div>
                                    <div class="c-div3">
                                        <div class="c-date font16">2023.03.23</div>
                                        <p class="cn9">
                                            <span class="cn4 font16 font-b">查看详情</span>
                                            <span class="cn25"><img class="hi-ico" src="../assets/images/about-img5.svg"
                                                    onload="SVGInject(this)">
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </router-link>
                        </li>

                        <li class="c-li">
                            <router-link to="newsdetail" class="c-a">
                                <div class="c-div1">
                                    <div class="font14 c-tips">商超便利</div>
                                    <img src="../assets/images/about-img21.png" alt="">
                                </div>
                                <div class="c-box">
                                    <div class="c-div2">
                                        <h3 class="h3 font24 text_b">
                                            科技让商业更美好!元数科技携创纪云3.0进化而来
                                        </h3>
                                    </div>
                                    <div class="c-div3">
                                        <div class="c-date font16">2023.03.23</div>
                                        <p class="cn9">
                                            <span class="cn4 font16 font-b">查看详情</span>
                                            <span class="cn25"><img class="hi-ico" src="../assets/images/about-img5.svg"
                                                    onload="SVGInject(this)">
                                            </span>
                                        </p>
                                    </div>
                                </div>
                            </router-link>
                        </li> -->

                    </ul>
                </div>

            </div>
        </div>











    </div>
</template>
  
<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

// 这是引用wow
// import { WOW } from 'wowjs'
// 引入swiper
import Swiper from 'swiper';
import $ from 'jquery';
import 'swiper/dist/css/swiper.min.css';
// import vueSeamless from 'vue-seamless-scroll'
// import countTo from 'vue-count-to';
import '../assets/css/style.css';
import '../assets/css/quill.bubble.css'; //富文本样式
import '../assets/css/quill.core.css';
import '../assets/css/quill.snow.css';

import hostUrl from '@/util/baseUrl'


export default {
    // name: "HomeView",
    components: {
        // vueSeamless,
        // countTo
    },
    metaInfo() {
        return {
            title: this.seoInfo.title, //设置页面title
            meta: [
                {                 //设置页面meta
                    name: 'keywords',
                    content: this.seoInfo.keyWords
                },
                {
                    name: "description",
                    content: this.seoInfo.description
                },
                {
                    name: "renderer",
                    content: 'webkit',
                },
                {
                    name: "force-rendering",
                    content: 'webkit',
                },
                {
                    name: "format-detection",
                    content: 'telephone=no,telephone=no,email=no,adress=no',
                },
                {
                    name: "viewport",
                    content: 'width=device-width,user-scalable=no,initial-scale=1.0,maximum-scale=1.0,minimum-scale=1.0',
                },
            ]
        }
    },
    data() {
        return {
            // activeName: 0,
            // itemLength: 0,
            // swpActive: 0,     
            // 屏幕宽度参数
            wW: '',
            // 二级导航TOp
            crumbsTop: '',
            crumbsFlag: false,
            fixHead: false,
            // swpNot: false, //禁止轮播滑动

            // 接口数据
            host: hostUrl,
            listid: '', //文章列表id
            id: '', //文章详情id            

            newsDetail: [], //文章详情
            newsPrev: [], //上一篇
            newsNext: [], //下一篇
            hotList: [], //热门文章
            relatedList: [], //相关新闻
            // seo信息
            seoInfo: {
                title: '',
                keyWords: '',
                description: ''
            },
            // 全局seo
            seoAllInfo: {},
        }
    },
    mounted() {

        
        this.id = this.$route.query.id
        this.listid = this.$route.query.listid

        this.getData()

        this.wW = document.documentElement.clientWidth || document.body.clientWidth //获取浏览器宽度

        this.seoAllInfo = JSON.parse(localStorage.getItem('yuanshu_seoInfo')) //获取seo全局信息

        this.$nextTick(() => {
            // 元数简介轮播
            // this.wW = document.documentElement.clientWidth; //获取浏览器宽度
            // console.log(this.wW)

            // 轮播图pc端禁止滑动
            // if (this.wW > 770) {
            //     // $('.about1 .swiper-container').addClass('swiper-no-swiping')
            //     this.swpNot = true
            // }


        })


        // wowjs
        this.$nextTick(() => {
            // var wow = new WOW({
            //   boxClass: 'wow',
            //   animateClass: 'animated',
            //   // offset: 40,
            //   // mobile: true,
            //   live: false,
            // })
            // wow.init()
            // new WOW().init()
            new this.$wow.WOW({
                live: false,
            }).init()
        })

        window.addEventListener('scroll', this.handleScroll)

        // 百度统计代码
        var _hmt = _hmt || [];
        (function () {
            var hm = document.createElement("script");
            hm.src = "https://hm.baidu.com/hm.js?77637ce1a84bee069c1effb2174121bb";
            var s = document.getElementsByTagName("script")[0];
            s.parentNode.insertBefore(hm, s);
        })();
        

    },
    updated() {

    },
    computed: {
        // defaultOption() {
        //   return {
        //     step: 0.8,
        //     hoverStop: true, // 是否开启鼠标悬停stop
        //     direction: 2, // 0向下 1向上 2向左 3向右
        //     openWatch: false, // 开启数据实时监控刷新dom
        //     // singleHeight: 40, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        //     // singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        //     // waitTime: 2000, // 单步运动停止的时间(默认值1000ms)
        //   }
        // }

    },
    watch: {
        $route: {
            // 监听路由变化
            handler: function (val, oldVal) {
                
            },
            deep: true
        }
    },
    methods: {
        // 请求后端接口
        getData() {


            console.log(this.$route.query, '路由id')

            let id = this.$route.query.id
            let listid = this.$route.query.listid

            this.newsDetail = [] //文章详情
            this.newsPrev = [] //上一篇
            this.newsNext = [] //下一篇
            this.hotList = [] //热门文章
            this.relatedList = [] //相关新闻

            this.seoInfo = {
                title: '',
                keyWords: '',
                description: ''
            }

            // 获取文章详情
            this.$axios.get('/api/content/' + listid + '?id=' + id).then(res => {

                // seo信息
                if (res.data.rows[0].title != '' && res.data.rows[0].title != null && res.data.rows[0].title != undefined) {
                    this.seoInfo.title = res.data.rows[0].title
                }else{
                    this.seoInfo.title=this.seoAllInfo.title
                }

                if (res.data.rows[0].seoKeyword != '' && res.data.rows[0].seoKeyword != null && res.data.rows[0].seoKeyword != undefined) {
                    this.seoInfo.keyWords = res.data.rows[0].seoKeyword
                }else{
                    this.seoInfo.keyWords=this.seoAllInfo.keyWords
                }

                if (res.data.rows[0].seoDesc != '' && res.data.rows[0].seoDesc != null && res.data.rows[0].seoDesc != undefined) {
                    this.seoInfo.description = res.data.rows[0].seoDesc
                }else{
                    this.seoInfo.description=this.seoAllInfo.description
                }

                this.newsDetail = res.data.rows[0] //当前文章详情页
                this.newsPrev = res.data.rows[1] //上一篇
                this.newsNext = res.data.rows[2] //下一篇

                // console.log(this.newsPrev, 'prev')
                // console.log(this.newsNext, 'next')
                // console.log(this.newsDetail, 'detail')
                this.$nextTick(() => {

                    // wow动画
                    new this.$wow.WOW({
                        live: false,
                    }).init()


                })
            })

            // 获取热门文章
            this.$axios.get('/api/content/' + listid + '?is_hot=1').then(res => {
                var listid = this.$route.query.listid
                var hotListTemp = res.data.rows
                for (var i = 0; i < hotListTemp.length; i++) {
                    hotListTemp[i].listid = listid
                }
                this.hotList = hotListTemp
                // console.log(this.hotList,'hot')

                this.$nextTick(() => {

                    // wow动画
                    new this.$wow.WOW({
                        live: false,
                    }).init()


                })
            })

            // 获取相关新闻
            this.$axios.get('/api/related/?id=' + id).then(res => {
                var listid = this.$route.query.listid
                var relatedListTemp = res.data.data
                for (var i = 0; i < relatedListTemp.length; i++) {
                    relatedListTemp[i].listid = listid
                }
                this.relatedList = relatedListTemp
                // console.log(this.relatedList,'related')

                this.$nextTick(() => {

                    // wow动画
                    new this.$wow.WOW({
                        live: false,
                    }).init()


                })
            })


        },
        toast() {
            this.$toast.show('页面建设中，请耐心等待…', '您可通过电话或微信扫描底部二维码联系我们', 5000)
        },
        handleScroll() {
            // this.$nextTick(() => {
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            // console.log(scrollTop, this.about1Top)


            // })
        },
        fixDate(x) {
            if (x == undefined) {
                return
            }
            x = x.replace(/-/g, '.')
            return x
        },
        // 刷新页面
        refresh() {
            this.$router.go(0)
        },
        // 回到顶部
        goTop(x) {
            document.documentElement.scrollTop = 0
            document.body.scrollTop = 0
        }

    },
};
</script>
  
<style></style>