<template>
    <div class="toast" v-show="isShow">
        <div class="font16 c-div1">
            {{message}}
        </div>
        <div class="font16">
            {{message2}}
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return{
            isShow:false,
            message:'加载中。。。',
            message2:''
        }
    },
    methods:{
        show(message,message2,duration=2000){
            this.isShow = true;
            this.message = message
            this.message2 = message2
            setTimeout(()=>{
                this.isShow = false
                this.message = ''
                this.message2 = ''
            },duration)
        }
    }
}
</script>

<style>
    .toast {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  color: #fff;
  transition: all 0.5s;
  z-index: 9999;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.75);


}
.toast div{
    white-space:nowrap;
}
.toast .c-div1{
        margin-bottom: 0.05rem;
    }
@media (max-width:770px) {
    .toast{
        padding: 0.4rem 0.4rem;
    }
    .toast .c-div1{
        margin-bottom: 0.1rem;
    }
}
</style>