<template>
    <div class="search">

        <!-- 搜索结果 -->
        <div class="search1 pdtb">
            <div class="container">
                <img class="bgimg2" src="../assets/images/ys-83.png" alt="">
                <div class="public-ani-bgimg public-ani-bgimg4">
                    <img src="../assets/images/ys-82.png" alt="">
                </div>

                <div class="c-title-box">
                    <div class="public-title wow fadeInUp">
                        <h2 class="font40 text_b h2">
                            <span>搜索结果</span>
                        </h2>
                    </div>
                    <div class="box">
                        <div class="public-searchBox">
                            <div class="inputBox">
                                <input @keyup.enter="searchFn()" type="text" placeholder="请输入关键字..." class="font16" v-model="searchText">
                            </div>
                            <div class="inputBtn" @click="searchFn()">
                                <img src="../assets/images/ys-295.svg" alt="">
                            </div>
                        </div>
                        <div class="c-tips">
                            关于<span>"{{ sTips }}"</span>的搜索结果，共{{ Number( searchList.length + caseSearchList.length ) }}条
                        </div>
                    </div>
                </div>


                <ul class="c-ul" v-show="searchList != ''">
                    <li class="c-li" v-for="(item, index) in searchList" :key="index">
                        <router-link :to="{ path: '/newsdetail', query: { id: item.id, listid: item.listid } }" class="c-a">
                            <div class="zz-left">
                                <div class="imgbox">
                                    <img :src="host + item.cover" alt="">
                                </div>
                            </div>

                            <div class="zz-right">
                                <div class="c-box1">
                                    <div class="c-date font16">
                                        {{ fixDate(item.time) }}
                                    </div>

                                    <div class="c-title font24 text_b">
                                        {{ item.title }}
                                    </div>

                                    <div class="c-text font16">
                                        {{ item.content }}
                                    </div>
                                </div>

                                <div class="c-box2">
                                    <p class="cn9">
                                        <span class="cn4 font16 font-b">查看详情</span>
                                        <span class="cn25"><img class="hi-ico" src="../assets/images/about-img5.svg"
                                                onload="SVGInject(this)">
                                        </span>
                                    </p>
                                </div>

                            </div>
                        </router-link>
                    </li>

                    <!-- <li class="c-li">
                        <router-link to="/newsdetail?id=1" class="c-a">
                            <div class="zz-left">
                                <div class="imgbox">
                                    <img src="../assets/images/about-img20.png" alt="">
                                </div>
                            </div>

                            <div class="zz-right">
                                <div class="c-box1">
                                    <div class="c-date font16">
                                        2023.03.23
                                    </div>

                                    <div class="c-title font24 text_b">
                                        2023年度全国大区公司营销工作会议圆满结束！
                                    </div>
                                    
                                    <div class="c-text font16">
                                        与营收增长匹配的是快速增长的客户数量。截至2019年、2020年、2021年及2022年3月31日以及2022年9月30日，北森已分别为超过3300名、3800名、4200名、4700名及4900名客户提供服务。
                                    </div>
                                </div>

                                <div class="c-box2">
                                    <p class="cn9">
                                        <span class="cn4 font16 font-b">查看详情</span>
                                        <span class="cn25"><img class="hi-ico" src="../assets/images/about-img5.svg"
                                                onload="SVGInject(this)">
                                        </span>
                                    </p>
                                </div>

                            </div>
                        </router-link>
                    </li> -->

                </ul>

                <ul class="c-ul" v-show="caseSearchList != ''">
                    <li class="c-li" v-for="(item, index) in caseSearchList" :key="index">
                        <router-link :to="{ path: '/customerdetail', query: { id: item.id } }" class="c-a">
                            <div class="zz-left">
                                <div class="imgbox">
                                    <img :src="host + item.cover" alt="">
                                </div>
                            </div>

                            <div class="zz-right">
                                <div class="c-box1">
                                    <div class="c-date font16">
                                        {{ fixDate(item.createTime) }}
                                    </div>

                                    <div class="c-title font24 text_b">
                                        {{ item.name }}
                                    </div>

                                    <div class="c-text font16">
                                        {{ item.subName }}
                                    </div>
                                </div>

                                <div class="c-box2">
                                    <p class="cn9">
                                        <span class="cn4 font16 font-b">查看详情</span>
                                        <span class="cn25"><img class="hi-ico" src="../assets/images/about-img5.svg"
                                                onload="SVGInject(this)">
                                        </span>
                                    </p>
                                </div>

                            </div>
                        </router-link>
                    </li>
                </ul>

            </div>
        </div>





    </div>
</template>
  
<script>

// 这是引用wow
// import { WOW } from 'wowjs'
// 引入swiper
import Swiper from 'swiper';
import $ from 'jquery';
import 'swiper/dist/css/swiper.min.css';
import '../assets/css/style.css';
import hostUrl from '@/util/baseUrl'


export default {
    components: {
    },
    metaInfo() {
        return {
            title: this.seoInfo.title, //设置页面title
            meta: [
                {                 //设置页面meta
                    name: 'keywords',
                    content: this.seoInfo.keyWords
                },
                {
                    name: "description",
                    content: this.seoInfo.description
                },
                {
                    name: "renderer",
                    content: 'webkit',
                },
                {
                    name: "force-rendering",
                    content: 'webkit',
                },
                {
                    name: "format-detection",
                    content: 'telephone=no,telephone=no,email=no,adress=no',
                },
                {
                    name: "viewport",
                    content: 'width=device-width,user-scalable=no,initial-scale=1.0,maximum-scale=1.0,minimum-scale=1.0',
                },
            ]
        }
    },
    data() {
        return {
            sTips: '',
            // 接口数据
            host: hostUrl,
            searchList: [], //资讯搜索结果
            searchText: '',
            caseSearchList:[], //案例搜索结果
            // seo信息
            seoInfo: {
                title: '',
                keyWords: '',
                description: ''
            },
            // 全局seo
            seoAllInfo: {},
        }
    },
    created(){
        // window.addEventListener('keydown',this.handkeyCode,true)
    },
    mounted() {
        this.seoAllInfo = JSON.parse(localStorage.getItem('yuanshu_seoInfo')) //获取seo全局信息
        this.seoInfo.title=this.seoAllInfo.title
        this.seoInfo.keyWords=this.seoAllInfo.keyWords
        this.seoInfo.description=this.seoAllInfo.description
              
        this.getData()

        // wowjs
        this.$nextTick(() => {
            // new this.$wow.WOW({
            //     live: false,
            // }).init()
        })


        // 百度统计代码
        var _hmt = _hmt || [];
        (function () {
            var hm = document.createElement("script");
            hm.src = "https://hm.baidu.com/hm.js?77637ce1a84bee069c1effb2174121bb";
            var s = document.getElementsByTagName("script")[0];
            s.parentNode.insertBefore(hm, s);
        })();

    },
    methods: {
        // 请求后端接口
        getData(x) {

            console.log(this.$route.query, '路由id')
            let urlKeyword=''
            let sKeyword = ''

            // 如果搜索框有关键词，就用搜索框的
            if(this.searchText!=''){
                urlKeyword=this.searchText
                sKeyword = this.sTips = this.searchText
                console.log(this.searchText)
                this.$router.replace({
                    query: {
                        text: sKeyword
                    }
                })
            }
            // 如果搜索框为空，就用url的关键词
            else{
                urlKeyword = this.$route.query.text
                sKeyword = this.sTips = urlKeyword
            }
            

            // 获取搜索列表
            this.$axios.get('/api/content/0', {
                params: {
                    keyword: sKeyword,
                }
            }).then(res => {

                // console.log(res)                    

                    var searchListTemp = res.data.rows
                    var searchListTemp_new=[]
                    for (var i = 0; i < searchListTemp.length; i++) {
                        // 设置文章分类id
                        if (searchListTemp[i].type == 2) { //行业洞察
                            searchListTemp[i].listid = 3
                        }
                        if (searchListTemp[i].type == 3) { //白皮书下载 不显示
                            searchListTemp[i].listid = 4
                        }
                        if (searchListTemp[i].type == 4) { //方案下载 不显示
                            searchListTemp[i].listid = 5
                        }
                        if (searchListTemp[i].type == 5) { //产品热点
                            searchListTemp[i].listid = 6
                        }
                        if (searchListTemp[i].type == 6) { //市场活动
                            searchListTemp[i].listid = 7
                        }
                        if (searchListTemp[i].type == 7) { //帮助中心
                            searchListTemp[i].listid = 8
                        }
                        if (searchListTemp[i].type == 0) { //公司动态
                            searchListTemp[i].listid = 11
                        }
                        if (searchListTemp[i].type == 1) { //专家团队
                            searchListTemp[i].listid = 12
                        }
                        // 替换掉描述中的html标签
                        if (searchListTemp[i].content) {
                            searchListTemp[i].content = searchListTemp[i].content.replace(/<[^>]+>/g, '')
                        }       

                        // 如果不是白皮书和方案下载，就加入新的数组
                        if(searchListTemp[i].type != 3 && searchListTemp[i].type != 4){
                            searchListTemp_new.push(searchListTemp[i])
                        }   
                    }
             
                this.searchList = searchListTemp_new

                // 获取案例搜索结果
                this.$axios.get('/api/case', {
                params: {
                    keyword: sKeyword,
                }
                }).then(res => {
                    var caseSearchListTemp=res.data.rows
                    if(caseSearchListTemp){
                        for(var i=0;i<caseSearchListTemp.length;i++){
                            caseSearchListTemp[i].createTime=caseSearchListTemp[i].createTime.split(' ')[0]
                        }
                    }
                    this.caseSearchList=caseSearchListTemp
                    // console.log(this.caseSearchList,'案例搜索')
                })

                this.$nextTick(() => {

                    // wow动画
                    new this.$wow.WOW({
                        live: false,
                    }).init()


                })
                
                this.searchText=sKeyword

            })




        },
        toast() {
            this.$toast.show('页面建设中，请耐心等待…', '您可通过电话或微信扫描底部二维码联系我们', 5000)
        },
        fixDate(x) {
            if(x==undefined){
                return
            }
            
            x = x.replace(/-/g, '.')
            return x
        },
        // 搜索
        searchFn() {
            if (this.searchText == '') {
                this.$toast.show('请输入关键字','', 5000)
                return
            }

            this.getData()
        },

    },
};
</script>
  
<style></style>