import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue"; //首页
import AboutView from "../views/AboutView.vue"; //关于我们-公司介绍
import TeamView from "../views/TeamView.vue"; //专家团队
import TrendsView from "../views/TrendsView.vue" //公司动态
import BrandView from "../views/BrandView.vue" //品牌故事

// 解决方案
import CaseView from "../views/CaseView.vue";
// import CaseView1 from "../views/CaseView1.vue";
// import CaseView2 from "../views/CaseView2.vue";

// 产品
import ProductView from "../views/ProductView.vue";
// import ProductView1 from "../views/ProductView1.vue";
// import ProductView2 from "../views/ProductView2.vue";
// 产品三级页
import ProductSonView from "../views/ProductSonView.vue";

// 资讯中心
import NewsView from "../views/NewsView.vue";
import NewsDetailView from "../views/NewsDetailView.vue";

// 联系我们
import ContactView from "../views/ContactView.vue";

// 客户案例
import CustomerView from "../views/CustomerView.vue";
import CustomerDetailView from "../views/CustomerDetailView.vue";

// 方案下载
import DownView from "../views/DownView.vue";

// 搜索
import SearchView from "../views/SearchView.vue";

// 加入我们
import JoinView from "../views/JoinView.vue";

// 隐私政策
import PrivacyView from "../views/PrivacyView.vue";

// 服务协议
import ServerView from "../views/ServerView.vue";


Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    // component:()=>import('@/views/HomeView.vue')
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/about",
    name: "about",
    component: AboutView,
    // component:()=>import('@/views/AboutView.vue')
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/team",
    name: "team",
    component: TeamView,
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/trends",
    name: "trends",
    component: TrendsView,
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/case",
    name: "case",
    component: CaseView,
    meta: {
      keepAlive: true      
    }
  },
  // {
  //   path: "/case1",
  //   name: "case1",
  //   component: CaseView1,
  // },
  // {
  //   path: "/case2",
  //   name: "case2",
  //   component: CaseView2,
  // },
  {
    path: "/product",
    name: "product",
    component: ProductView,
    meta: {
      keepAlive: true
    }
  },
  // {
  //   path: "/product1",
  //   name: "product1",
  //   component: ProductView1,
  // },
  // {
  //   path: "/product2",
  //   name: "product2",
  //   component: ProductView2,
  // },
  {
    path: "/productson",
    name: "productson",
    component: ProductSonView,
  },
  {
    path: "/news",
    name: "news",
    component: NewsView,
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/newsdetail",
    name: "newsdetail",
    component: NewsDetailView,
  },
  {
    path: "/contact",
    name: "contact",
    component: ContactView,
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/customer",
    name: "customer",
    component: CustomerView,
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/customerdetail",
    name: "customerdetail",
    component: CustomerDetailView,
  },
  {
    path: "/down",
    name: "down",
    component: DownView,
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/search",
    name: "search",
    component: SearchView,
  },
  {
    path: "/join",
    name: "join",
    component: JoinView,
  },
  {
    path: "/privacy",
    name: "privacy",
    component: PrivacyView,
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/server",
    name: "server",
    component: ServerView,
    meta: {
      keepAlive: true
    }
  },
  {
    path: "/brand",
    name: "brand",
    component: BrandView,
    meta: {
      keepAlive: true
    }
  },
];


const router = new VueRouter({
  mode:'history',
  routes,
  scrollBehavior(to,from,saveTop){
    if(saveTop){
      return saveTop
      
    }else{      
      return {x:0,y:0}
     
    }
  }
});

export default router;


// function getRoutesList(routes, pre) {
//   return routes.reduce((array, route) => {
//     const path = `${pre}${route.path}`;

//     if (route.path !== '*') {
//       array.push(path);
//     }

//     if (route.children) {
//       array.push(...getRoutesList(route.children, `${path}/`));
//     }

//     return array;
//   }, []);
// }

// getRoutesList(router.options.routes, 'https://www.metabizcent.com');

// function getRoutesXML() {
//   const list = getRoutesList(router.options.routes, 'https://zigamiklic.com')
//     .map(route => `<url><loc>${route}</loc></url>`)
//     .join('\r\n');
//   return `<urlset xmlns="http://www.sitemaps.org/schemas/sitemap/0.9" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9 http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">
//     ${list}
//   </urlset>`;
// }

// getRoutesXML();
