<template>
    <div class="news">

        <!-- 内页顶部banner -->
        <div class="public-nybn case-bn about-bn news-btn">
            <img class="bgimg2" src="../assets/images/ys-83.png" alt="">
            <img class="bgimg3" src="../assets/images/ys-82.png" alt="">
            <div class="bgimg scaleDraw" v-if="banner.image">
                <img :src="host + banner.image" alt="">
            </div>
            <div class="zz-box">

                <div class="bn-title fadeInUp2">
                    <div class="container">
                        <div class="c-title">
                            <h2 class="h2 font36 text_b">
                                {{ banner.title }}
                            </h2>
                            <div class="syb"></div>
                        </div>
                        <div class="font40 text_b c-text pre"><span>{{ banner.text }}</span></div>

                    </div>
                </div>


            </div>
        </div>

        <div id="pageOffset"></div>
        <div class="public-crumbs" :class="crumbsFlag ? 'active' : ''" id="public-crumbs">
            <div class="container">
                <div class="c-box">

                    <h3 class="c-a font18" :class="item.on ? 'on' : ''" v-for="(item, index) in crumbs" :key="index">
                        <router-link v-if="item.url" :to="item.url">
                            {{ item.name }}
                        </router-link>
                    </h3>

                    <!-- <h3 class="c-a font18">
                        <router-link to="/news?id=0">
                            行业洞察
                        </router-link>
                    </h3> -->

                </div>
            </div>
        </div>

        <!-- 第二屏列表 -->
        <div class="news1 pdtb">
            <div class="container">

                <!-- 行业分类 -->
                <div class="newsNav public-searchNav wow fadeInUp" v-show="screen.status==1">
                    <div class="zz-left font16">
                        <div class="c-div">
                            {{ screen.title }}：
                        </div>
                        <ul class="c-ul">
                            <li class="c-li" :class="item.on == true ? 'on' : ''" v-for="(item, index) in screen.list"
                                :key="index" @click="newsNavFn(item, index)">
                                {{ item.tag }}
                            </li>
                            <!-- <li class="c-li">
                                消费品牌
                            </li> -->
                        </ul>
                    </div>
                    <div class="zz-right">
                        <div class="inputBox">
                            <input @keyup.enter="searchFn()" type="text" placeholder="搜索..." class="font16" v-model="keyword">
                        </div>
                        <div class="inputBtn" @click="searchFn()">
                            <img src="../assets/images/ys-280.svg" alt="">
                        </div>
                    </div>
                </div>
        

                <div v-show="newsList==''" class="notInfoBox" style="margin-top: 0.8rem;">
                    <img class="c-img" src="../assets/images/ys-401.png">
                    <span class="font16 c-span">暂无相关信息，敬请期待！</span>
                </div>

                <div class="newsList downList" v-show="newsList != ''">
                    <ul class="c-ul">

                        <li class="c-li" v-for="(item, index) in newsList" :key="index">
                            <a href="javascript:;" class="c-a" v-if="token == ''" @click="judgeToken(token,item.id,item.title,item.type)">
                                <div class="c-div1">
                                    <div v-if="item.tags" class="font14 c-tips">{{ item.tags }}</div>
                                    <img v-if="item.cover" :src="host + item.cover" alt="图片不存在">
                                </div>
                                <div class="c-box">
                                    <div class="c-div2">
                                        <h3 class="h3 font24 text_b pre">{{ item.title }}</h3>
                                        <div class="c-text font16 pre">{{ item.desc }}</div>
                                    </div>
                                    <div class="c-div3">
                                        <div class="c-date font16">{{ fixDate(item.time) }}</div>
                                        <div class="c-btn">
                                            <span class="font16">{{ downTips }}下载</span>
                                            <img src="../assets/images/ys-294.svg" alt="" onload="SVGInject(this)">
                                        </div>
                                    </div>
                                </div>
                            </a>

                            <a :href="host + item.file" target="_blank" class="c-a" v-if="token != '' && item.file!=null && item.file!=''" @click="judgeToken(token,item.id,item.title,item.type)">
                                <div class="c-div1">
                                    <div v-if="item.tags" class="font14 c-tips">{{ item.tags }}</div>
                                    <img v-if="item.cover" :src="host + item.cover" alt="图片不存在">
                                </div>
                                <div class="c-box">
                                    <div class="c-div2">
                                        <h3 class="h3 font24 text_b">
                                            {{ item.title }}
                                        </h3>
                                        <div class="c-text font16 pre">{{ item.desc }}</div>
                                    </div>
                                    <div class="c-div3">
                                        <div class="c-date font16">{{ fixDate(item.time) }}</div>
                                        <div class="c-btn">
                                            <span class="font16">{{ downTips }}下载</span>
                                            <img src="../assets/images/ys-294.svg" alt="" onload="SVGInject(this)">
                                        </div>
                                    </div>
                                </div>
                            </a>

                        </li>

                        <!-- <li class="c-li">
                            <a href="javascript:;" class="c-a">
                                <div class="c-div1">
                                    <div class="font14 c-tips">商超便利</div>
                                    <img src="../assets/images/about-img20.png" alt="">
                                </div>
                                <div class="c-box">
                                    <div class="c-div2">
                                        <h3 class="h3 font24 text_b">
                                            全链路一体化数智零售解决方案
                                        </h3>
                                        <div class="c-text font16">
                                            全触点、全渠道、全链路的销售资源协同管理平台，支持多业态、全场景销售、支付、履单和售后服务的综合解决方案
                                        </div>
                                    </div>
                                    <div class="c-div3">
                                        <div class="c-date font16">2023.03.23</div>
                                        <div class="c-btn">
                                            <span class="font16">方案下载</span>
                                            <img src="../assets/images/ys-294.svg" alt="" onload="SVGInject(this)">
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </li>

                        <li class="c-li">
                            <a href="javascript:;" class="c-a">
                                <div class="c-div1">
                                    <div class="font14 c-tips">商超便利</div>
                                    <img src="../assets/images/about-img21.png" alt="">
                                </div>
                                <div class="c-box">
                                    <div class="c-div2">
                                        <h3 class="h3 font24 text_b">
                                            全链路一体化数智零售解决方案
                                        </h3>
                                        <div class="c-text font16">
                                            全触点、全渠道、全链路的销售资源协同管理平台，支持多业态、全场景销售、支付、履单和售后服务的综合解决方案
                                        </div>
                                    </div>
                                    <div class="c-div3">
                                        <div class="c-date font16">2023.03.23</div>
                                        <div class="c-btn">
                                            <span class="font16">方案下载</span>
                                            <img src="../assets/images/ys-294.svg" alt="" onload="SVGInject(this)">
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </li>

                        <li class="c-li">
                            <a href="javascript:;" class="c-a">
                                <div class="c-div1">
                                    <div class="font14 c-tips">商超便利</div>
                                    <img src="../assets/images/about-img2.jpg" alt="">
                                </div>
                                <div class="c-box">
                                    <div class="c-div2">
                                        <h3 class="h3 font24 text_b">
                                            全链路一体化数智零售解决方案
                                        </h3>
                                        <div class="c-text font16">
                                            全触点、全渠道、全链路的销售资源协同管理平台，支持多业态、全场景销售、支付、履单和售后服务的综合解决方案
                                        </div>
                                    </div>
                                    <div class="c-div3">
                                        <div class="c-date font16">2023.03.23</div>
                                        <div class="c-btn">
                                            <span class="font16">方案下载</span>
                                            <img src="../assets/images/ys-294.svg" alt="" onload="SVGInject(this)">
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </li>

                        <li class="c-li">
                            <a href="javascript:;" class="c-a">
                                <div class="c-div1">
                                    <div class="font14 c-tips">商超便利</div>
                                    <img src="../assets/images/about-img20.png" alt="">
                                </div>
                                <div class="c-box">
                                    <div class="c-div2">
                                        <h3 class="h3 font24 text_b">
                                            全链路一体化数智零售解决方案
                                        </h3>
                                        <div class="c-text font16">
                                            全触点、全渠道、全链路的销售资源协同管理平台，支持多业态、全场景销售、支付、履单和售后服务的综合解决方案
                                        </div>
                                    </div>
                                    <div class="c-div3">
                                        <div class="c-date font16">2023.03.23</div>
                                        <div class="c-btn">
                                            <span class="font16">方案下载</span>
                                            <img src="../assets/images/ys-294.svg" alt="" onload="SVGInject(this)">
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </li>

                        <li class="c-li">
                            <a href="javascript:;" class="c-a">
                                <div class="c-div1">
                                    <div class="font14 c-tips">商超便利</div>
                                    <img src="../assets/images/about-img21.png" alt="">
                                </div>
                                <div class="c-box">
                                    <div class="c-div2">
                                        <h3 class="h3 font24 text_b">
                                            全链路一体化数智零售解决方案
                                        </h3>
                                        <div class="c-text font16">
                                            全触点、全渠道、全链路的销售资源协同管理平台，支持多业态、全场景销售、支付、履单和售后服务的综合解决方案
                                        </div>
                                    </div>
                                    <div class="c-div3">
                                        <div class="c-date font16">2023.03.23</div>
                                        <div class="c-btn">
                                            <span class="font16">方案下载</span>
                                            <img src="../assets/images/ys-294.svg" alt="" onload="SVGInject(this)">
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </li> -->

                    </ul>
                </div>

                <div class="public-page wow fadeInUp" v-show="newsList != ''">

                    <page-pagination :total="page.total" :pageSize="page.pageSize" :currentPage="page.currentPage"
                        :numAround="true" @change="change"></page-pagination>

                </div>



            </div>
        </div>





    </div>
</template>
  
<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

// 这是引用wow
// import { WOW } from 'wowjs'
// 引入swiper
import Swiper from 'swiper';
import $ from 'jquery';
import 'swiper/dist/css/swiper.min.css';
// import vueSeamless from 'vue-seamless-scroll'
// import countTo from 'vue-count-to';
import '../assets/css/style.css';
import pagePagination from "@/components/page-pagination/page-pagination.vue";
import hostUrl from '@/util/baseUrl'

export default {
    // name: "HomeView",
    components: {
        // vueSeamless,
        // countTo,
        pagePagination
    },
    metaInfo() {
        return {
            title: this.seoInfo.title, //设置页面title
            meta: [
                {                 //设置页面meta
                    name: 'keywords',
                    content: this.seoInfo.keyWords
                },
                {
                    name: "description",
                    content: this.seoInfo.description
                },
                {
                    name: "renderer",
                    content: 'webkit',
                },
                {
                    name: "force-rendering",
                    content: 'webkit',
                },
                {
                    name: "format-detection",
                    content: 'telephone=no,telephone=no,email=no,adress=no',
                },
                {
                    name: "viewport",
                    content: 'width=device-width,user-scalable=no,initial-scale=1.0,maximum-scale=1.0,minimum-scale=1.0',
                },
            ]
        }
    },
    data() {
        return {
            // activeName: 0,
            // itemLength: 0,
            // swpActive: 0,     
            // 屏幕宽度参数
            wW: '',
            id: '',
            // 二级导航TOp
            crumbsTop: '',
            crumbsFlag: false,
            crumbs: [],
            // crumbs: [
            //     {
            //         title: '行业洞察',
            //         on: false,
            //         href: '/news?id=3',
            //     },
            //     {
            //         title: '白皮书下载',
            //         on: false,
            //         href: '/down?id=4',
            //     },
            //     {
            //         title: '方案下载',
            //         on: false,
            //         href: '/down?id=5',
            //     },
            //     {
            //         title: '产品热点',
            //         on: false,
            //         href: '/news?id=6',
            //     },
            //     {
            //         title: '市场活动',
            //         on: false,
            //         href: '/news?id=7',
            //     },
            //     {
            //         title: '帮助中心',
            //         on: false,
            //         href: '/news?id=8',
            //     },
            // ],
            // swpNot: false, //禁止轮播滑动
            // 行业分类
            // newsNav: [
            //     {
            //         on: true,
            //         navTitle: '商超便利',
            //         id: 0,
            //         newsList: [
            //             {

            //             }
            //         ],
            //     },
            //     {
            //         on: false,
            //         navTitle: '母婴连锁',
            //         id: 1
            //     },
            //     {
            //         on: false,
            //         navTitle: '消费品牌',
            //         id: 2
            //     },
            // ],

            // 分页器
            page: {
                total: 0,
                pageSize: 6,
                currentPage: 1
            },
            downTips: '',
            // 接口数据
            host: hostUrl,
            id: '', //文章列表id
            keyword: '',
            // 第一屏
            banner: {},
            // 第二屏 行业分类
            screen: {},
            newsList: [],
            tags: '',
            token: '', // 下载token
            // seo信息
            seoInfo: {
                title: '',
                keyWords: '',
                description: ''
            },
            // 全局seo
            seoAllInfo: {},
        }
    },

    mounted() {
        // this.$bus.$on("headShow"); //二级导航浮动
        this.id = this.$route.query.id
        // this.crumbsFlag=false
        // console.log(this.crumbsFlag,'crumbsFlag')
        if (this.$route.query.id == 4) {
            this.downTips = '白皮书'
        } else {
            this.downTips = '方案'
        }


        this.getToken() //获取token并运行this.getData()

        // setTimeout(() => {
        //     this.getData()
        // }, 500)


        this.wW = document.documentElement.clientWidth || document.body.clientWidth //获取浏览器宽度

        this.seoAllInfo = JSON.parse(localStorage.getItem('yuanshu_seoInfo')) //获取seo全局信息

        let crumbsTop = document.getElementById('public-crumbs')
        this.crumbsTop = crumbsTop.offsetTop
        this.$bus.emit("crumbsTop", crumbsTop.offsetTop)

        this.$nextTick(() => {
            // 元数简介轮播
            // this.wW = document.documentElement.clientWidth; //获取浏览器宽度
            // console.log(this.wW)

            // 轮播图pc端禁止滑动
            // if (this.wW > 770) {
            //     // $('.about1 .swiper-container').addClass('swiper-no-swiping')
            //     this.swpNot = true
            // }


        })

        // 返回顶部
        // $('.goTop').click(function () {
        //     $("html,body").animate({ scrollTop: 0 }, 500);
        // })

        // wowjs
        this.$nextTick(() => {
            // var wow = new WOW({
            //   boxClass: 'wow',
            //   animateClass: 'animated',
            //   // offset: 40,
            //   // mobile: true,
            //   live: false,
            // })
            // wow.init()
            // new WOW().init()
            new this.$wow.WOW({
                live: false,
            }).init()
        })

        window.addEventListener('scroll', this.handleScroll)

        // 百度统计代码
        var _hmt = _hmt || [];
        (function () {
            var hm = document.createElement("script");
            hm.src = "https://hm.baidu.com/hm.js?77637ce1a84bee069c1effb2174121bb";
            var s = document.getElementsByTagName("script")[0];
            s.parentNode.insertBefore(hm, s);
        })();

    },
    computed: {
        // defaultOption() {
        //   return {
        //     step: 0.8,
        //     hoverStop: true, // 是否开启鼠标悬停stop
        //     direction: 2, // 0向下 1向上 2向左 3向右
        //     openWatch: false, // 开启数据实时监控刷新dom
        //     // singleHeight: 40, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        //     // singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        //     // waitTime: 2000, // 单步运动停止的时间(默认值1000ms)
        //   }
        // }

    },
    destroyed() {
        // 离开页面时取消二级导航浮动
        // this.$bus.$off("headShow");
    },
    // watch: {
    //     $route: {
    //         // 监听路由变化
    //         handler: function (val, oldVal) {
                
    //             console.log(1)
    //         },
    //         deep: true
    //     }
    // },
    methods: {
        // 请求后端接口
        getData() {
            this.keyword = ''
            this.banner = {}
            this.screen = {}
            this.newsList = []
            this.tags = ''

            this.seoInfo = {
                title: '',
                keyWords: '',
                description: ''
            }

            this.crumbs = []

            console.log(this.$route.query, '路由id')

            let id = this.$route.query.id

            // banner描述
            this.$axios.get('/api/page/' + id).then(res => {
                // seo信息
                if (res.data.data.name != '' && res.data.data.name != null && res.data.data.name != undefined) {
                    this.seoInfo.title = res.data.data.name
                }else{
                    this.seoInfo.title=this.seoAllInfo.title
                }

                if (res.data.data.seoKeyword != '' && res.data.data.seoKeyword != null && res.data.data.seoKeyword != undefined) {
                    this.seoInfo.keyWords = res.data.data.seoKeyword
                }else{
                    this.seoInfo.keyWords=this.seoAllInfo.keyWords
                }

                if (res.data.data.seoDesc != '' && res.data.data.seoDesc != null && res.data.data.seoDesc != undefined) {
                    this.seoInfo.description = res.data.data.seoDesc
                }else{
                    this.seoInfo.description=this.seoAllInfo.description
                }


                if (res.data.data.blocksObj.banner[0] != '') {
                    this.banner = res.data.data.blocksObj.banner[0]
                }
                var screenTemp = res.data.data.blocksObj.screen
                for (var i = 0; i < screenTemp.list.length; i++) {
                    screenTemp.list[i].on = false
                    // screenTemp.list[0].on = true
                }
                if (screenTemp.list.length > 0) {
                    screenTemp.list.unshift({ tag: '全部', on: true })
                }
                this.screen = screenTemp
                console.log(this.screen,'screen')

                this.$nextTick(() => {

                    setTimeout(() => {

                    }, 0)

                    // wow动画
                    new this.$wow.WOW({
                        live: false,
                    }).init()


                })
            })


            // 列表和分页
            this.$axios.get('/api/content/' + id, {
                params: {
                    pageSize: this.page.pageSize,
                }, headers: {
                    token: this.token, // 下载token
                }
            }).then(res => {
                console.log(res.data, 'down')
                this.newsList = res.data.rows
                this.page.total = res.data.total
            })

            // 二级导航
            this.$axios.get('/api/menu/65').then(res => {
                var crumbsTemp = res.data.data
                for (var i = 0; i < crumbsTemp.length; i++) {
                    crumbsTemp[i].on = false
                }
                this.crumbs = crumbsTemp
                // console.log(this.crumbs,'crumbs')
                this.crumbsFn(this.id)
            })

        },
        toast() {
            this.$toast.show('页面建设中，请耐心等待…', '您可通过电话或微信扫描底部二维码联系我们', 5000)
        },
        handleScroll() {
            // this.$nextTick(() => {
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            // console.log(scrollTop, this.about1Top)

            // 二级导航浮动
            var that = this

            if (scrollTop >= that.crumbsTop) {
                setTimeout(function () {
                    that.crumbsFlag = true
                }, 50)
            } else {
                setTimeout(function () {
                    that.crumbsFlag = false
                }, 50)
            }




            // })
        },
        // 行业分类切换
        newsNavFn(item, index) {
            let id = this.id
            for (var i = 0; i < this.screen.list.length; i++) {
                this.screen.list[i].on = false
            }
            this.screen.list[index].on = true
            // this.tags = this.screen.list[index].tags
            if (item.tag == '全部') {
                this.tags = ''
            } else {
                this.tags = item.tag
            }
            this.keyword=''

            this.newsList = []
            this.$axios.get('/api/content/' + id, {
                params: {
                    tags: this.tags,
                    pageSize: this.page.pageSize,   
                }, headers: {
                    token: this.token, // 下载token
                }
            }).then(res => {
                this.newsList = res.data.rows
                console.log(this.newsList,'newsList')
            })

        },
        // 搜索
        searchFn() {
            if(this.keyword==''){
                this.$toast.show('请输入关键字','', 5000)
                return
            }
            let keyword = this.keyword
            let id = this.id
            this.newsList = []
            this.$axios.get('/api/content/' + id, {
                params: {
                    keyword: keyword,
                    pageSize: this.page.pageSize,
                }, headers: {
                    token: this.token, // 下载token
                }
            }).then(res => {
                this.newsList = res.data.rows
                // console.log(this.newsList,'test')
            })
        },
        // 分页器
        change(currentPage, type) {
            let id = this.id
            let pageNum = currentPage
            let keyword = this.keyword
            this.page.currentPage = currentPage;
            // console.log("点击了" + type + "，当前页：" + currentPage);
            console.log(currentPage, type)
            this.newsList = []
            this.$axios.get('/api/content/' + id, {
                params: {
                    pageNum: pageNum,
                    keyword: keyword,
                    pageSize: this.page.pageSize,
                }, headers: {
                    token: this.token, // 下载token
                }
            }).then(res => {
                this.newsList = res.data.rows
                // document.documentElement.scrollTop = 0
                document.documentElement.scrollTop = document.getElementById('pageOffset').offsetTop
            })
        },
        // 二级导航切换
        crumbsFn(id) {
            for (var i = 0; i < this.crumbs.length; i++) {
                this.crumbs[i].on = false
                if (this.crumbs[i].url.split('=')[1] == id) {
                    this.crumbs[i].on = true
                }

            }
            // console.log(this.crumbs)
        },
        // 日期格式化
        fixDate(x) {
            if (x == undefined) {
                return
            }
            x = x.replace(/-/g, '.')
            return x
        },
        // 获取token
        getToken() {
            var lgInfo = JSON.parse(localStorage.getItem('yuanshu_info'))
            if (lgInfo != null) {
                this.token = lgInfo.accessToken
            }

            setTimeout(() => {
                this.getData()
            }, 100)

        },
        // 判断是否登录
        judgeToken(token,id,name,type) {

            if (token == '') {
                this.$toast.show('请先登录再下载', '', 1000)
                // setTimeout(()=> {
                this.$bus.emit("loginHideShow", true)
                // }, 3000)
                this.$parent.wxLogin()
            } else {
                var data = {
                    pid:id,
                    pname:name,
                    type:type
                }
                this.$axios.post('/api/download',
                    data, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        token: token,
                    }
                }).then(res => {                    

                })
            }
        },
    },
};
</script>
  
<style></style>