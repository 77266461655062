// baseUrl.js
// 这里是一个默认的url，可以没有
let baseUrl = ''
// XXX自行替换域名-懂得都懂
if (process.env.NODE_ENV === 'delopment') {
  baseUrl = 'https://sysdev.metabizcent.com'
} else {
  baseUrl = 'https://sysdev.metabizcent.com'
}

export default baseUrl