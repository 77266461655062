<template>
  <div class="about">

    <!-- 内页顶部banner -->
    <div class="public-nybn about-bn">
      <div class="bgimg scaleDraw" v-if="banner.image">
        <img :src="host + banner.image" alt="">
      </div>
      <div class="zz-box">

        <div class="bn-title fadeInUp2">
          <div class="container">
            <div class="c-title">
              <h2 class="h2 font36 text_b">
                {{ banner.title }}
              </h2>
              <div class="syb"></div>
            </div>
            <div class="font40 text_b c-text pre"><span>{{ banner.text }}</span></div>

          </div>
        </div>


      </div>
    </div>

    <div class="public-crumbs" :class="crumbsFlag ? 'active' : ''" id="public-crumbs">
      <div class="container">
        <div class="c-box">

          <h3 class="c-a font18" :class="item.on ? 'on' : ''" @click="crumbsFn(index)" v-for="(item, index) in crumbs"
            :key="index">
            <router-link v-if="item.url" :to="item.url">
              {{ item.name }}
            </router-link>
          </h3>

          <!-- <h3 class="c-a on font18">
            <router-link to="/brand">
              品牌故事
            </router-link>
          </h3>

          <h3 class="c-a font18">
            <router-link to="/trends">
              公司动态
            </router-link>
          </h3>

          <h3 class="c-a font18">
            <router-link to="/team">
              专家团队
            </router-link>
          </h3> -->

          <!-- <h3 class="c-a font18">
            <router-link to="/join">
              加入我们
            </router-link>
          </h3> -->

        </div>
      </div>
    </div>

    <div class="newsDetail1 brand1 pdtb">
      <div class="container">
        <div class="zz-box">
          <div class="zz-left wow fadeInUp">
            <!-- <div class="zz-title-box">
              <h1 class="h1 font30 text_b">{{ brand.name }}</h1>
            </div> -->
            <div class="zz-text font18 ql-editor" v-html="brand.more"></div>



          </div>



        </div>

      </div>
    </div>




  </div>
</template>
  
<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

// 这是引用wow
// import { WOW } from 'wowjs'
// 引入swiper
import Swiper from 'swiper';
import $ from 'jquery';
import 'swiper/dist/css/swiper.min.css';
// import vueSeamless from 'vue-seamless-scroll'
// import countTo from 'vue-count-to';
import '../assets/css/style.css';
import '../assets/css/quill.bubble.css'; //富文本样式
import '../assets/css/quill.core.css';
import '../assets/css/quill.snow.css';
import hostUrl from '@/util/baseUrl'


export default {
  // name: "HomeView",
  components: {
    // vueSeamless,
    // countTo
  },
  metaInfo() {
        return {
            title: this.seoInfo.title, //设置页面title
            meta: [
                {                 //设置页面meta
                    name: 'keywords',
                    content: this.seoInfo.keyWords
                },
                {
                    name: "description",
                    content: this.seoInfo.description
                },
                {
                    name: "renderer",
                    content: 'webkit',
                },
                {
                    name: "force-rendering",
                    content: 'webkit',
                },
                {
                    name: "format-detection",
                    content: 'telephone=no,telephone=no,email=no,adress=no',
                },
                {
                    name: "viewport",
                    content: 'width=device-width,user-scalable=no,initial-scale=1.0,maximum-scale=1.0,minimum-scale=1.0',
                },
            ]
        }
    },
  data() {
    return {
      // 屏幕宽度参数
      wW: '',
      // 二级导航TOp
      crumbsTop: '',
      crumbsFlag: false,
      crumbs:[],
      fixHead: false,
      swpNot: false, //禁止轮播滑动

      // 接口数据
      host: hostUrl,
      banner: [],
      brand: '',
       // seo信息
       seoInfo: {
                title: '',
                keyWords: '',
                description: ''
            },
            // 全局seo
      seoAllInfo: {},
    }
  },

  mounted() {
    this.getData()

    this.wW = document.documentElement.clientWidth || document.body.clientWidth //获取浏览器宽度  

    this.seoAllInfo = JSON.parse(localStorage.getItem('yuanshu_seoInfo')) //获取seo全局信息

    let crumbsTop = document.getElementById('public-crumbs')
    this.crumbsTop = crumbsTop.offsetTop
    this.$bus.emit("crumbsTop", crumbsTop.offsetTop)

    this.$nextTick(() => {
      // 元数简介轮播
      // this.wW = document.documentElement.clientWidth; //获取浏览器宽度
      // console.log(this.wW)

      // 轮播图pc端禁止滑动
      if (this.wW > 770) {
        // $('.about1 .swiper-container').addClass('swiper-no-swiping')
        this.swpNot = true
      }

    })



    // wowjs
    this.$nextTick(() => {
      new this.$wow.WOW({
        live: false,
      }).init()
    })

    window.addEventListener('scroll', this.handleScroll)

    // 百度统计代码
    var _hmt = _hmt || [];
    (function () {
      var hm = document.createElement("script");
      hm.src = "https://hm.baidu.com/hm.js?77637ce1a84bee069c1effb2174121bb";
      var s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(hm, s);
    })();

  },
  computed: {

  },
  destroyed() {
    // 离开页面时取消二级导航浮动        
    // this.$bus.$off("headShow");
  },
  activated() {
    this.crumbsFn()
  },
  methods: {
    // 请求后端接口
    getData() {


      console.log(this.$route.query, '路由id')
      this.banner = []
      this.brand = ''
      this.seoInfo={
                title:'',
                keyWords:'',
                description:''
      }

      this.crumbs=[]

      this.$axios.get('/api/page/10').then(res => {

        // seo信息
        if (res.data.data.name != '' && res.data.data.name != null && res.data.data.name != undefined) {
                    this.seoInfo.title = res.data.data.name
        }else{
                    this.seoInfo.title=this.seoAllInfo.title
        }

                if (res.data.data.seoKeyword != '' && res.data.data.seoKeyword != null && res.data.data.seoKeyword != undefined) {
                    this.seoInfo.keyWords = res.data.data.seoKeyword
                }else{
                    this.seoInfo.keyWords=this.seoAllInfo.keyWords
                }

                if (res.data.data.seoDesc != '' && res.data.data.seoDesc != null && res.data.data.seoDesc != undefined) {
                    this.seoInfo.description = res.data.data.seoDesc
                }else{
                    this.seoInfo.description=this.seoAllInfo.description
                }

        // console.log(res.data.data)
        if (res.data.data.blocksObj.banner[0] != '') {
          this.banner = res.data.data.blocksObj.banner[0]
        }
        this.brand = res.data.data
        this.$nextTick(() => {

          setTimeout(() => {

          }, 0)

          // wow动画
          new this.$wow.WOW({
            live: false,
          }).init()


        })
      })

      // 二级导航
      this.$axios.get('/api/menu/66').then(res => {
        var crumbsTemp = res.data.data
        for (var i = 0; i < crumbsTemp.length; i++) {
          crumbsTemp[i].on = false
        }
        this.crumbs = crumbsTemp
        // console.log(this.crumbs,'crumbs')
        this.crumbsFn(this.id)
      })


    },
    toast() {
      this.$toast.show('页面建设中，请耐心等待…', '您可通过电话或微信扫描底部二维码联系我们', 5000)
    },
    handleScroll() {
      // this.$nextTick(() => {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      // console.log(scrollTop, this.about1Top)
      if (scrollTop >= this.about1Top) {
        if (this.scrollFlag == false) {
          this.$refs.num1.start()
          this.$refs.num2.start()
          this.$refs.num3.start()
          this.scrollFlag = true
        }
      }

      // 二级导航浮动
      var that = this
      setTimeout(function () {
        if (scrollTop >= that.crumbsTop) {
          that.crumbsFlag = true
        } else {
          that.crumbsFlag = false
        }
      }, 50)


      // })
    },
    // 二级导航切换
    crumbsFn() {
      let urlPath=this.$route.path.replace(/<[^>]+>/g, '')
      for (var i = 0; i < this.crumbs.length; i++) {
        this.crumbs[i].on = false
        if (this.crumbs[i].url==urlPath) {
            this.$set(this.crumbs[i],'on',true)
        }

      }
    },
  },
};
</script>
  
<style scoped lang="less">
.brand1 .zz-box {
  justify-content: center;
}
</style>