<template>
  <div class="home">
    <!-- <img alt="Vue logo" src="../assets/logo.png" /> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App" /> -->

    <!-- 首页banner轮播 -->

    <div style="width:100%;height:100vh;" v-show="showIndex == false"></div>

    <div class="sysec1 sybn-box" v-show="banner">

      <div class="sybnSwp">
        <div class="swiper-container">
          <div class="swiper-wrapper">

            <div class="swiper-slide" v-for="(item, index) in banner" :key="index">
              <img class="bgimg pc" v-if="item.image" :src="host + item.image" alt="">
              <img class="bgimg wap" v-if="item.mimage" :src="host + item.mimage" alt="">

              <div class="container">
                <div class="c-box">
                  <div>
                    <h2 class="h2 ani text_b wow fadeInUp">
                      <p class="font48 c-span1 pre">{{ item.title }}</p>
                    </h2>

                    <div class="c-text ani font18 wow fadeInUp pre">{{ item.text }}</div>


                    <div class="ani btnbox wow fadeInUp">
                      <router-link v-if="item.link && item.title && !isExternal(item.link)" :to="item.link" rel="nofollow"
                        class="hi-btn1">
                        <span class="hi-text font16">{{ item.btnname }}</span>
                        <img onload="SVGInject(this)" class="hi-ico" src="../assets/images/hi-btn-arrow1.svg">
                      </router-link>

                      <a v-else :href="item.link" target="_blank" rel="nofollow" class="hi-btn1">
                        <span class="hi-text font16">{{ item.btnname }}</span>
                        <img onload="SVGInject(this)" class="hi-ico" src="../assets/images/hi-btn-arrow1.svg">
                      </a>

                    </div>

                  </div>
                </div>


              </div>

            </div>

            <!-- <div class="swiper-slide">
              <img class="bgimg pc ani" swiper-animate-effect="scaleDraw" swiper-animate-delay="0s"
                src="../assets/images/ys-131.jpg" alt="">
              <img class="bgimg wap ani" swiper-animate-effect="scaleDraw" swiper-animate-delay="0s"
                src="../assets/images/ys-129.jpg" alt="">

              <div class="container">
                <div class="c-box">
                  <div>
                    <h2 class="h2 ani text_b" swiper-animate-effect="fadeInUp" swiper-animate-delay="0.15s">
                      <p class="font48 c-span1">商业企业</p>
                      <p class="font48 c-span1">数智化转型最佳伙伴</p>
                    </h2>

                    <div class="c-text ani font18" swiper-animate-effect="fadeInUp" swiper-animate-delay="0.3s">
                      <p>借助20+年的零售行业数字化实践，构建数字零售+数据驱动的数字化体系</p>
                      <p>致力于帮助企业搭建数字化经营能力，实现商业企业的数智化经营增长</p>
                    </div>


                    <div class="ani btnbox" swiper-animate-effect="fadeInUp" swiper-animate-delay="0.45s">
                      <a href="javascript:;" rel="nofollow" class="hi-btn1">
                        <span class="hi-text font16">免费试用</span>
                        <img class="hi-ico" src="../assets/images/hi-btn-arrow1.svg" onload="SVGInject(this)">
                      </a>
                    </div>

                  </div>
                </div>


              </div>
            </div> -->



          </div>
        </div>

        <!-- 轮播底部描述 -->
        <div class="bnul" v-show="fmodel != ''">
          <div class="container">
            <div class="swp-btnBox">
              <div class="swiper-pagination"></div>
            </div>
            <ul class="c-ul">
              <li class="c-li wow fadeInUp" v-for="(item, index) in fmodel" :key="index">
                <div class="c-a">
                  <img v-if="item.bgimg" class="bgimg" :src="host + item.bgimg" alt="">
                  <div class="zz-left">
                    <img v-if="item.icon" :src="host + item.icon" alt="">
                  </div>
                  <div class="zz-right">
                    <div class="c-div">
                      <img src="../assets/images/ys-3.png" alt="">
                      <h3 class="font20 h3 text_b">{{ item.title }}</h3>
                    </div>
                    <div class="font14 c-p">{{ item.text }}</div>
                  </div>
                </div>
              </li>

              <!-- <li class="c-li wow fadeInUp">
                <a href="javascript:;" rel="nofollow" class="c-a">
                  <img class="bgimg" src="../assets/images/ys-2.png" alt="">
                  <div class="zz-left">
                    <img src="../assets/images/ys-4.png" alt="">
                  </div>
                  <div class="zz-right">
                    <div class="c-div">
                      <img src="../assets/images/ys-4.png" alt="">
                      <h3 class="font20 h3 text_b">极致产品</h3>

                    </div>

                    <div class="font14 c-p">更懂零售企业的全渠道数字化零售系统和新—代商业数据系统</div>
                  </div>
                </a>
              </li> -->


            </ul>
          </div>
        </div>
      </div>

      <!-- 头部banner列表移动端轮播 -->
      <div class="bnul2 wow fadeInUp" id="bnul2">
        <div class="container">
          <div class="swiper-container">
            <div class="swiper-wrapper">

              <div class="swiper-slide" v-for="(item, index) in fmodel" :key="index">
                <div class="c-li">
                  <div class="c-a">
                    <img class="bgimg" v-if="item.bgimg" :src="host + item.bgimg" alt="">
                    <div class="zz-left">
                      <img v-if="item.icon" :src="host + item.icon" alt="">
                    </div>
                    <div class="zz-right">
                      <div class="c-div">
                        <h3 class="font20 h3 text_b">{{ item.title }}</h3>
                      </div>
                      <div class="font14 c-p">{{ item.text }}</div>
                    </div>
                  </div>
                </div>
              </div>

              <!-- <div class="swiper-slide">
                <div class="c-li">
                  <a href="javascript:;" rel="nofollow" class="c-a">
                    <div class="zz-left">
                      <img src="../assets/images/ys-4.png" alt="">
                    </div>
                    <div class="zz-right">
                      <div class="c-div">
                        <h3 class="font20 h3 text_b">极致产品</h3>
                      </div>
                      <div class="font14 c-p">更懂零售企业的全渠道数字化零售系统和新—代商业数据系统</div>
                    </div>
                  </a>
                </div>
              </div> -->



            </div>
            <div class="swiper-button-prev public-swp-prev public-swpBtn public-swpBtn2">
              <img src="../assets/images/swp-arrow-left.svg" alt="" onload="SVGInject(this)">
            </div>
            <div class="swiper-button-next public-swp-next public-swpBtn public-swpBtn2">
              <img src="../assets/images/swp-arrow-right.svg" alt="" onload="SVGInject(this)">
            </div>
          </div>
        </div>
      </div>

    </div>

    <!-- 第二屏 -->
    <div class="sysec2 pdtb" v-show="matrix.title">
      <div class="container">
        <div class="public-title wow fadeInUp">

          <h2 class="font40 text_b h2 pre">{{ matrix.title }}</h2>

          <div class="c-text font18">
            {{ matrix.desc }}
          </div>


        </div>

        <div class="c-box">
          <div class="imgbox wow fadeInUp">
            <!-- <img class="bgimg1" src="../assets/images/ys-127.png" al</img>t=""> -->
            <!-- <img class="bgimg2" src="../assets/images/ys-136.png" alt=""> -->
            <img v-if="matrix_bgimg_pc" class="bgimg1" :src="host + matrix_bgimg_pc" alt="">
            <img v-if="matrix_bgimg_wap" class="bgimg2" :src="host + matrix_bgimg_wap" alt="">

            <ul class="c-ul">
              <li class="c-li " :class="['c-li' + (index + 1 - 0), index <= 4 ? 'c-liOne' : 'c-liTwo']"
                v-for="(item, index) in matrix.app" :key="index">
                <span class="font14">{{ item.name }}</span>
                <img v-if="item.icon" :src="host + item.icon" alt="">
              </li>

              <!-- <li class="c-li c-li1 c-liOne">
                <span class="font14">APP</span>
                <img src="../assets/images/ys-117.png" alt="">
              </li>

               <li class="c-li c-li2 c-liOne">
                <span class="font14">微信</span>
                <img src="../assets/images/ys-118.png" alt="">
              </li>

              <li class="c-li c-li3 c-liOne">
                <span class="font14">小程序</span>
                <img src="../assets/images/ys-119.png" alt="">
              </li>

              <li class="c-li c-li4 c-liOne">
                <span class="font14">支付宝</span>
                <img src="../assets/images/ys-120.png" alt="">
              </li>

              <li class="c-li c-li5 c-liOne">
                <span class="font14">抖音</span>
                <img src="../assets/images/ys-121.png" alt="">
              </li>

              <li class="c-li c-li6 c-liTwo">
                <img src="../assets/images/ys-122.png" alt="">
                <span class="font16">POS</span>
              </li>

              <li class="c-li c-li7 c-liTwo">
                <img src="../assets/images/ys-123.png" alt="">
                <span class="font16">企微</span>
              </li>

              <li class="c-li c-li8 c-liTwo">
                <img src="../assets/images/ys-124.png" alt="">
                <span class="font16">钉钉</span>
              </li>

              <li class="c-li c-li9 c-liTwo">
                <img src="../assets/images/ys-125.png" alt="">
                <span class="font16">飞书</span>
              </li>

              <li class="c-li c-li10 c-liTwo">
                <img src="../assets/images/ys-126.png" alt="">
                <span class="font16">大屏</span>
              </li> -->



            </ul>

            <!-- <ul class="c-ul2">
                    <li class="c-li c-li1">
                        <div class="font18 text_b">mPaaS</div>
                        <div class="font14">超级端开发工作台</div>
                    </li>
                   
                    <li class="c-li c-li2">
                        <div class="font18 text_b">BIPaaS</div>
                        <div class="font14">数智分析平台</div>
                    </li>

                    <li class="c-li c-li3">
                        <div class="font18 text_b">BigDataPaaS</div>
                        <div class="font14">大数据开发工作台</div>
                    </li>

                    <li class="c-li c-li4">
                        <div class="font18 text_b">bizPaaS</div>
                        <div class="font14">应用开发平台</div>
                    </li>

                </ul> -->

          </div>

          <!-- 零售云 -->
          <div class="zz-left c-textBox" v-show="matrix_list_left.title">
            <div class="cIcon">
              <img src="../assets/images/ys-7.png" alt="">
            </div>
            <div class="contBox">
              <div class="c-title-box font22 text_b">
                <router-link v-if="matrix_list_left.link" :to="matrix_list_left.link" class="c-title">
                  {{ matrix_list_left.title }}
                </router-link>
                <div class="c-tips">
                  {{ matrix_list_left.desc }}
                </div>
              </div>
              <div class="c-cont">
                <div class="cont-box">
                  <div class="c-icon">
                    <img v-if="matrix_list_left.icon" :src="host + matrix_list_left.icon" alt="">
                  </div>
                  <div class="c-text font20">
                    {{ matrix_list_left.desc }}
                  </div>
                </div>

                <ul class="c-ul">
                  <router-link v-if="matrix_list_left.link" :to="matrix_list_left.link" class="c-ti-box">
                    <div class="c-title">{{ matrix_list_left.title }}</div>
                    <div class="c-tips"> {{ matrix_list_left.desc }}</div>
                  </router-link>
                  <li class="c-li" v-for="(item, index) in matrix_list_left.cons" :key="index">
                    <router-link v-if="item.link" :to="item.link">
                      <div class="zz-l">
                        <img v-if="item.icon" :src="host + item.icon" alt="">
                        <div class="c-p font18">{{ item.title }}</div>
                      </div>
                      <div class="zz-r">
                        <img src="../assets/images/ys-17.svg" alt="" onload="SVGInject(this)">
                      </div>
                    </router-link>
                  </li>

                  <!-- <li class="c-li">
                    <a href="javascript:;" rel="nofollow" @click.stop()="toast()">
                      <div class="zz-l">
                        <img src="../assets/images/ys-18.svg" alt="">
                        <div class="c-p font18">数智会员</div>
                      </div>
                      <div class="zz-r">
                        <img src="../assets/images/ys-17.svg" alt="" onload="SVGInject(this)">
                      </div>
                    </a>
                  </li>

                  <li class="c-li">
                    <a href="javascript:;" rel="nofollow" @click.stop()="toast()">
                      <div class="zz-l">
                        <img src="../assets/images/ys-19.svg" alt="">
                        <div class="c-p font18">数智门店</div>
                      </div>
                      <div class="zz-r">
                        <img src="../assets/images/ys-17.svg" alt="" onload="SVGInject(this)">
                      </div>
                    </a>
                  </li>

                  <li class="c-li">
                    <a href="javascript:;" rel="nofollow" @click.stop()="toast()">
                      <div class="zz-l">
                        <img src="../assets/images/ys-20.svg" alt="">
                        <div class="c-p font18">数智员工</div>
                      </div>
                      <div class="zz-r">
                        <img src="../assets/images/ys-17.svg" alt="" onload="SVGInject(this)">
                      </div>
                    </a>
                  </li>

                  <li class="c-li">
                    <a href="javascript:;" rel="nofollow" @click.stop()="toast()">
                      <div class="zz-l">
                        <img src="../assets/images/ys-21.svg" alt="">
                        <div class="c-p font18">数智营运</div>
                      </div>
                      <div class="zz-r">
                        <img src="../assets/images/ys-17.svg" alt="" onload="SVGInject(this)">
                      </div>
                    </a>
                  </li>

                  <li class="c-li">
                    <a href="javascript:;" rel="nofollow" @click.stop()="toast()">
                      <div class="zz-l">
                        <img src="../assets/images/ys-22.svg" alt="">
                        <div class="c-p font18">数智供应链</div>
                      </div>
                      <div class="zz-r">
                        <img src="../assets/images/ys-17.svg" alt="" onload="SVGInject(this)">
                      </div>
                    </a>
                  </li>

                  <li class="c-li">
                    <a href="javascript:;" rel="nofollow" @click.stop()="toast()">
                      <div class="zz-l">
                        <img src="../assets/images/ys-23.svg" alt="">
                        <div class="c-p font18">数智物流</div>
                      </div>
                      <div class="zz-r">
                        <img src="../assets/images/ys-17.svg" alt="" onload="SVGInject(this)">
                      </div>
                    </a>
                  </li>

                  <li class="c-li">
                    <a href="javascript:;" rel="nofollow" @click.stop()="toast()">
                      <div class="zz-l">
                        <img src="../assets/images/ys-24.svg" alt="">
                        <div class="c-p font18">数智分销</div>
                      </div>
                      <div class="zz-r">
                        <img src="../assets/images/ys-17.svg" alt="" onload="SVGInject(this)">
                      </div>
                    </a>
                  </li>

                  <li class="c-li">
                    <a href="javascript:;" rel="nofollow" @click.stop()="toast()">
                      <div class="zz-l">
                        <img src="../assets/images/ys-25.svg" alt="">
                        <div class="c-p font18">数智财务</div>
                      </div>
                      <div class="zz-r">
                        <img src="../assets/images/ys-17.svg" alt="" onload="SVGInject(this)">
                      </div>
                    </a>
                  </li>

                  <li class="c-li">
                    <a href="javascript:;" rel="nofollow" @click.stop()="toast()">
                      <div class="zz-l">
                        <img src="../assets/images/ys-254.png" alt="">
                        <div class="c-p font18">数智客服</div>
                      </div>
                      <div class="zz-r">
                        <img src="../assets/images/ys-17.svg" alt="" onload="SVGInject(this)">
                      </div>
                    </a>
                  </li> -->

                </ul>

              </div>
            </div>
          </div>

          <!-- 数据云 -->
          <div class="zz-right c-textBox" v-show="matrix_list_right.title">
            <div class="cIcon">
              <img src="../assets/images/ys-8.png" alt="">
            </div>
            <div class="contBox">
              <div class="c-title-box font22 text_b">
                <router-link v-if="matrix_list_right.link" :to="matrix_list_right.link" class="c-title">
                  {{ matrix_list_right.title }}
                </router-link>
                <div class="c-tips">
                  {{ matrix_list_right.desc }}
                </div>
              </div>
              <div class="c-cont">
                <div class="cont-box">
                  <div class="c-icon">
                    <img v-if="matrix_list_right.icon" :src="host + matrix_list_right.icon" alt="">
                  </div>
                  <div class="c-text font18">
                    {{ matrix_list_right.desc }}
                  </div>
                </div>

                <ul class="c-ul">
                  <router-link v-if="matrix_list_right.link" :to="matrix_list_right.link" class="c-ti-box">
                    <div class="c-title">{{ matrix_list_right.title }}</div>
                    <div class="c-tips">{{ matrix_list_right.desc }}</div>
                  </router-link>
                  <li class="c-li" v-for="(item, index) in matrix_list_right.cons">
                    <router-link v-if="item.link" :to="item.link">
                      <div class="zz-l">
                        <img v-if="item.icon" :src="host + item.icon" alt="">
                        <div class="c-p font18">{{ item.title }}</div>
                      </div>
                      <div class="zz-r">
                        <img src="../assets/images/ys-17.svg" alt="" onload="SVGInject(this)">
                      </div>
                    </router-link>
                  </li>

                  <!-- <li class="c-li">
                    <a href="javascript:;" rel="nofollow" @click.stop()="toast()">
                      <div class="zz-l">
                        <img src="../assets/images/ys-27.svg" alt="">
                        <div class="c-p font18">自动补货</div>
                      </div>
                      <div class="zz-r">
                        <img src="../assets/images/ys-17.svg" alt="" onload="SVGInject(this)">
                      </div>
                    </a>
                  </li>

                  <li class="c-li">
                    <a href="javascript:;" rel="nofollow" @click.stop()="toast()">
                      <div class="zz-l">
                        <img src="../assets/images/ys-28.svg" alt="">
                        <div class="c-p font18">老板通</div>
                      </div>
                      <div class="zz-r">
                        <img src="../assets/images/ys-17.svg" alt="" onload="SVGInject(this)">
                      </div>
                    </a>
                  </li>

                  <li class="c-li">
                    <a href="javascript:;" rel="nofollow" @click.stop()="toast()">
                      <div class="zz-l">
                        <img src="../assets/images/ys-29.svg" alt="">
                        <div class="c-p font18">店总北极星</div>
                      </div>
                      <div class="zz-r">
                        <img src="../assets/images/ys-17.svg" alt="" onload="SVGInject(this)">
                      </div>
                    </a>
                  </li>

                  <li class="c-li">
                    <a href="javascript:;" rel="nofollow" @click.stop()="toast()">
                      <div class="zz-l">
                        <img src="../assets/images/ys-30.svg" alt="">
                        <div class="c-p font18">经营大屏</div>
                      </div>
                      <div class="zz-r">
                        <img src="../assets/images/ys-17.svg" alt="" onload="SVGInject(this)">
                      </div>
                    </a>
                  </li> -->

                  <!-- <li class="c-li">
                    <a href="javascript:;" rel="nofollow">
                      <div class="zz-l">
                        <img src="../assets/images/ys-31.svg" alt="">
                        <div class="c-p font18">品类管理</div>
                      </div>
                      <div class="zz-r">
                        <img src="../assets/images/ys-17.svg" alt="" onload="SVGInject(this)">
                      </div>
                    </a>
                  </li>

                  <li class="c-li">
                    <a href="javascript:;" rel="nofollow">
                      <div class="zz-l">
                        <img src="../assets/images/ys-32.svg" alt="">
                        <div class="c-p font18">异常处理</div>
                      </div>
                      <div class="zz-r">
                        <img src="../assets/images/ys-17.svg" alt="" onload="SVGInject(this)">
                      </div>
                    </a>
                  </li> -->

                </ul>

              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
    <div class="sysec2-mask"></div>

    <!-- 第三版 行业分类-->
    <div class="sysec3 pdtb" id="sysec3" v-show="industry.title">
      <div class="container">

        <div class="bgimg bgimg1">
          <img src="../assets/images/ys-82.png" alt="">
        </div>
        <div class="bgimg bgimg2">
          <img src="../assets/images/ys-82.png" alt="">
        </div>
        <div class="bgimg bgimg3">
          <img src="../assets/images/ys-82.png" alt="">
        </div>
        <div class="bgimg bgimg4">
          <img src="../assets/images/ys-82.png" alt="">
        </div>

        <div class="public-title wow fadeInUp">

          <h2 class="font40 text_b h2">
            <span>{{ industry.title }}</span>
          </h2>

          <div class="c-text font18">
            {{ industry.desc }}
          </div>

        </div>

        <!-- 分类标题 -->
        <div class="public-navBox wow fadeInUp" v-show="industryNavLength > 1">
          <div class="c-nav">

            <div class="c-li" :class="[{ 'on': item.on == true }]" v-for="(item, index) in industry.list" :key="index"
              @click="sysec3FnNav(index)" @mouseenter="sysec3FnNavHover(index)">
              <div class="c-text">
                <h3 class="h3 font20">{{ item.title }}</h3>
              </div>
            </div>

            <!-- <div class="c-li">
              <div class="c-text">
                <h3 class="h3 font20">母婴零售</h3>
              </div>
            </div>

            <div class="c-li">
              <div class="c-text">
                <h3 class="h3 font20">品牌零售</h3>
              </div>
            </div>

            <div class="c-li">
              <div class="c-text">
                <h3 class="h3 font20">消费品牌</h3>
              </div>
            </div>

            <div class="c-li">
              <div class="c-text">
                <h3 class="h3 font20">其他行业</h3>
              </div>
            </div> -->

          </div>
        </div>

        <!-- 分类列表 -->
        <div class="li-box wow fadeInUp">

          <div class="li" v-show="item.on" v-for="(item, index1) in industry.list" :key="index1">
            <div class="liBox">
              <div class="c-list zz-left">
                <div class="c-ul">
                  <div class="c-li" :class="{ 'on': item2.on == true }" v-for="(item2, index2) in item.cons" :key="index2"
                    @mouseover="sysec3Fn(index1, index2, 'left')" @mouseout="sysec3Fn2(index1, index2, 'left')"
                    @click="sysec3ClickFn(index1, index2, 'left')" v-show="index2 < (item.cons.length - 1) / 2">
                    <img class="bgimg" src="../assets/images/ys-38.png" alt="">
                    <div class="c-title">
                      <div class="zz-l">
                        <img :src="host + item2.icon" alt="">
                        <div class="c-p font18">{{ item2.title }}</div>
                      </div>
                      <div class="zz-r">
                        <img src="../assets/images/ys-37.svg" alt="">
                      </div>
                    </div>
                    <div class="c-box">
                      <div class="c-ul2">
                        <div class="c-li2" v-for="(item3, index3) in item2.text" :key="index3">
                          <div class="c-text font14">{{ item3 }}</div>
                        </div>
                      </div>
                      <div class="zz-among">
                        <img class="bgimg" src="../assets/images/ys-103.png" alt="">
                        <div class="imgbox">
                          <img v-if="item2.image" :src="host + item2.image" alt="">
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- <div class="c-li">
                  <img class="bgimg" src="../assets/images/ys-38.png" alt="">
                  <div class="c-title">
                    <div class="zz-l">
                      <img src="../assets/images/ys-39.svg" alt="" onload="SVGInject(this)">
                      <div class="c-p font18">数字化会员经营</div>
                    </div>
                    <div class="zz-r">
                      <img src="../assets/images/ys-37.svg" alt="" onload="SVGInject(this)">
                    </div>
                  </div>
                  <div class="c-ul2">
                    <div class="c-li2">
                      <div class="c-text font14">总部统一管控，适度放权，兼顾门店灵活经营</div>
                    </div>
                    <div class="c-li2">
                      <div class="c-text font14">构建全场景连接触点，持续释放全域流量价值</div>
                    </div>
                    <div class="c-li2">
                      <div class="c-text font14">移动POS、自助收银…打破收银局限</div>
                    </div>
                    <div class="c-li2">
                      <div class="c-text font14">全渠道交易闭环、履约闭环和财务闭环</div>
                    </div>
                  </div>
                </div>

                <div class="c-li">
                  <img class="bgimg" src="../assets/images/ys-38.png" alt="">
                  <div class="c-title">
                    <div class="zz-l">
                      <img src="../assets/images/ys-40.svg" alt="" onload="SVGInject(this)">
                      <div class="c-p font18">高效营运及店务</div>
                    </div>
                    <div class="zz-r">
                      <img src="../assets/images/ys-37.svg" alt="" onload="SVGInject(this)">
                    </div>
                  </div>
                  <div class="c-ul2">
                    <div class="c-li2">
                      <div class="c-text font14">总部统一管控，适度放权，兼顾门店灵活经营</div>
                    </div>
                    <div class="c-li2">
                      <div class="c-text font14">构建全场景连接触点，持续释放全域流量价值</div>
                    </div>
                    <div class="c-li2">
                      <div class="c-text font14">移动POS、自助收银…打破收银局限</div>
                    </div>
                    <div class="c-li2">
                      <div class="c-text font14">全渠道交易闭环、履约闭环和财务闭环</div>
                    </div>
                  </div>
                </div>

                <div class="c-li">
                  <img class="bgimg" src="../assets/images/ys-38.png" alt="">
                  <div class="c-title">
                    <div class="zz-l">
                      <img src="../assets/images/ys-41.svg" alt="" onload="SVGInject(this)">
                      <div class="c-p font18">协同式供应链</div>
                    </div>
                    <div class="zz-r">
                      <img src="../assets/images/ys-37.svg" alt="" onload="SVGInject(this)">
                    </div>
                  </div>
                  <div class="c-ul2">
                    <div class="c-li2">
                      <div class="c-text font14">总部统一管控，适度放权，兼顾门店灵活经营</div>
                    </div>
                    <div class="c-li2">
                      <div class="c-text font14">构建全场景连接触点，持续释放全域流量价值</div>
                    </div>
                    <div class="c-li2">
                      <div class="c-text font14">移动POS、自助收银…打破收银局限</div>
                    </div>
                    <div class="c-li2">
                      <div class="c-text font14">全渠道交易闭环、履约闭环和财务闭环</div>
                    </div>
                  </div>
                </div>

                <div class="c-li">
                  <img class="bgimg" src="../assets/images/ys-38.png" alt="">
                  <div class="c-title">
                    <div class="zz-l">
                      <img src="../assets/images/ys-42.svg" alt="" onload="SVGInject(this)">
                      <div class="c-p font18">智能仓储物流</div>
                    </div>
                    <div class="zz-r">
                      <img src="../assets/images/ys-37.svg" alt="" onload="SVGInject(this)">
                    </div>
                  </div>
                  <div class="c-ul2">
                    <div class="c-li2">
                      <div class="c-text font14">总部统一管控，适度放权，兼顾门店灵活经营</div>
                    </div>
                    <div class="c-li2">
                      <div class="c-text font14">构建全场景连接触点，持续释放全域流量价值</div>
                    </div>
                    <div class="c-li2">
                      <div class="c-text font14">移动POS、自助收银…打破收银局限</div>
                    </div>
                    <div class="c-li2">
                      <div class="c-text font14">全渠道交易闭环、履约闭环和财务闭环</div>
                    </div>
                  </div>
                </div> -->

                </div>
              </div>



              <div class="zz-among">
                <img class="bgimg" src="../assets/images/ys-103.png" alt="">
                <div class="imgbox">
                  <!-- <img src="../assets/images/ys-44.png" alt=""> -->
                  <img v-if="sysec3AmongImg" :src="host + sysec3AmongImg" alt="">
                </div>
              </div>

              <div class="c-list zz-right">
                <div class="c-ul">
                  <div class="c-li" :class="{ 'on': item2.on == true }" v-for="(item2, index2) in item.cons" :key="index2"
                    @mouseover="sysec3Fn(index1, index2, 'right')" @mouseout="sysec3Fn2(index1, index2, 'right')"
                    @click="sysec3ClickFn(index1, index2, 'right')" v-show="index2 > (item.cons.length - 1) / 2">
                    <img class="bgimg" src="../assets/images/ys-38.png" alt="">
                    <div class="c-title">
                      <div class="zz-l">
                        <img :src="host + item2.icon" alt="">
                        <div class="c-p font18">{{ item2.title }}</div>
                      </div>
                      <div class="zz-r">
                        <img src="../assets/images/ys-37.svg" alt="" onload="SVGInject(this)">
                      </div>
                    </div>
                    <div class="c-box">
                      <div class="c-ul2">
                        <div class="c-li2" v-for="(item3, index3) in item2.text" :key="index3">
                          <div class="c-text font14">{{ item3 }}</div>
                        </div>
                        <!-- <div class="c-li2">
                        <div class="c-text font14">构建全场景连接触点，持续释放全域流量价值</div>
                      </div> -->
                      </div>
                      <div class="zz-among">
                        <img class="bgimg" src="../assets/images/ys-103.png" alt="">
                        <div class="imgbox">
                          <img v-if="item2.image" :src="host + item2.image" alt="">
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- <div class="c-li">
                  <img class="bgimg" src="../assets/images/ys-38.png" alt="">
                  <div class="c-title">
                    <div class="zz-l">
                      <img src="../assets/images/ys-39.svg" alt="" onload="SVGInject(this)">
                      <div class="c-p font18">在线式分销及批发</div>
                    </div>
                    <div class="zz-r">
                      <img src="../assets/images/ys-37.svg" alt="" onload="SVGInject(this)">
                    </div>
                  </div>
                  <div class="c-ul2">
                    <div class="c-li2">
                      <div class="c-text font14">总部统一管控，适度放权，兼顾门店灵活经营</div>
                    </div>
                    <div class="c-li2">
                      <div class="c-text font14">构建全场景连接触点，持续释放全域流量价值</div>
                    </div>
                    <div class="c-li2">
                      <div class="c-text font14">移动POS、自助收银…打破收银局限</div>
                    </div>
                    <div class="c-li2">
                      <div class="c-text font14">全渠道交易闭环、履约闭环和财务闭环</div>
                    </div>
                  </div>
                </div>

                <div class="c-li">
                  <img class="bgimg" src="../assets/images/ys-38.png" alt="">
                  <div class="c-title">
                    <div class="zz-l">
                      <img src="../assets/images/ys-40.svg" alt="" onload="SVGInject(this)">
                      <div class="c-p font18">全模式加盟</div>
                    </div>
                    <div class="zz-r">
                      <img src="../assets/images/ys-37.svg" alt="" onload="SVGInject(this)">
                    </div>
                  </div>
                  <div class="c-ul2">
                    <div class="c-li2">
                      <div class="c-text font14">总部统一管控，适度放权，兼顾门店灵活经营</div>
                    </div>
                    <div class="c-li2">
                      <div class="c-text font14">构建全场景连接触点，持续释放全域流量价值</div>
                    </div>
                    <div class="c-li2">
                      <div class="c-text font14">移动POS、自助收银…打破收银局限</div>
                    </div>
                    <div class="c-li2">
                      <div class="c-text font14">全渠道交易闭环、履约闭环和财务闭环</div>
                    </div>
                  </div>
                </div>

                <div class="c-li">
                  <img class="bgimg" src="../assets/images/ys-38.png" alt="">
                  <div class="c-title">
                    <div class="zz-l">
                      <img src="../assets/images/ys-41.svg" alt="" onload="SVGInject(this)">
                      <div class="c-p font18">一体化业财</div>
                    </div>
                    <div class="zz-r">
                      <img src="../assets/images/ys-37.svg" alt="" onload="SVGInject(this)">
                    </div>
                  </div>
                  <div class="c-ul2">
                    <div class="c-li2">
                      <div class="c-text font14">总部统一管控，适度放权，兼顾门店灵活经营</div>
                    </div>
                    <div class="c-li2">
                      <div class="c-text font14">构建全场景连接触点，持续释放全域流量价值</div>
                    </div>
                    <div class="c-li2">
                      <div class="c-text font14">移动POS、自助收银…打破收银局限</div>
                    </div>
                    <div class="c-li2">
                      <div class="c-text font14">全渠道交易闭环、履约闭环和财务闭环</div>
                    </div>
                  </div>
                </div>

                <div class="c-li">
                  <img class="bgimg" src="../assets/images/ys-38.png" alt="">
                  <div class="c-title">
                    <div class="zz-l">
                      <img src="../assets/images/ys-42.svg" alt="" onload="SVGInject(this)">
                      <div class="c-p font18">数据驱动</div>
                    </div>
                    <div class="zz-r">
                      <img src="../assets/images/ys-37.svg" alt="" onload="SVGInject(this)">
                    </div>
                  </div>
                  <div class="c-ul2">
                    <div class="c-li2">
                      <div class="c-text font14">总部统一管控，适度放权，兼顾门店灵活经营</div>
                    </div>
                    <div class="c-li2">
                      <div class="c-text font14">构建全场景连接触点，持续释放全域流量价值</div>
                    </div>
                    <div class="c-li2">
                      <div class="c-text font14">移动POS、自助收银…打破收银局限</div>
                    </div>
                    <div class="c-li2">
                      <div class="c-text font14">全渠道交易闭环、履约闭环和财务闭环</div>
                    </div>
                  </div>
                </div> -->

                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>


    <!-- 第四版 品牌 -->
    <div class="sysec4 pdtb" id="sysec4" v-show="accept.title">
      <div class="container">
        <div class="public-title wow fadeInUp">

          <h2 class="font40 text_b h2">
            <span>{{ accept.title }}</span>
          </h2>

          <div class="c-a">
            <router-link v-if="acceptBtn.link" :to="acceptBtn.link">
              <span class="font16">{{ acceptBtn.title }}</span>
              <img src="../assets/images/ys-50.svg" alt="">
            </router-link>
          </div>
        </div>

        <div class="sysec4Swp">

          <div class="swp-btnBox">
            <div class="swiper-pagination"></div>
          </div>
          <div class="swiper-container">
            <div class="swiper-wrapper">

              <div class="swiper-slide" v-for="(item, index) in accept.list" :key="index">
                <div class="zz-left" :class="index == 0 ? 'wow fadeLeft' : ''">
                  <img class="bgimg" src="../assets/images/ys-52.png" alt="">
                  <div class="imgbox">
                    <img v-if="item.image" :src="host + item.image" alt="">
                  </div>
                  <div class="c-cont">
                    <div class="iconbox">
                      <img class="c-icon" :src="host + item.logo" alt="">
                    </div>
                    <router-link v-if="item.link" :to="item.link" class="hi-btn1 hi-btn1-2">
                      <span class="hi-text font16">{{ item.btnname }}</span>
                      <img class="hi-ico" src="../assets/images/ys-50.svg" onload="SVGInject(this)">
                    </router-link>
                  </div>
                </div>

                <div class="zz-right" :class="index == 0 ? 'wow fadeRight' : ''">
                  <img class="bgimg1" src="../assets/images/ys-54.svg" alt="" onload="SVGInject(this)">
                  <img class="bgimg2" src="../assets/images/ys-55.svg" alt="" onload="SVGInject(this)">
                  <img class="bgimg3" src="../assets/images/ys-56.png" alt="">
                  <div class="c-title font30 text_b">
                    {{ item.title }}
                  </div>
                  <div class="c-text font18">
                    <div class="scroll">
                      {{ item.text }}
                    </div>
                  </div>
                </div>

              </div>

              <!-- <div class="swiper-slide">
                <div class="zz-left wow fadeLeft">
                  <img class="bgimg" src="../assets/images/ys-52.png" alt="">
                  <div class="imgbox">
                    <img src="../assets/images/ys-87.png" alt="">
                  </div>
                  <div class="c-cont">
                    <div class="iconbox">
                      <img class="c-icon" src="../assets/images/ys-134.svg" alt="" onload="SVGInject(this)">
                    </div>
                    <a href="javascript:;" rel="nofollow" class="hi-btn1 hi-btn1-2">
                      <span class="hi-text font16">查看详情</span>
                      <img class="hi-ico" src="../assets/images/hi-btn-arrow1.svg" onload="SVGInject(this)">
                    </a>
                  </div>
                </div>

                <div class="zz-right wow fadeRight">
                  <img class="bgimg1" src="../assets/images/ys-54.svg" alt="" onload="SVGInject(this)">
                  <img class="bgimg2" src="../assets/images/ys-55.svg" alt="" onload="SVGInject(this)">
                  <img class="bgimg3" src="../assets/images/ys-56.png" alt="">
                  <div class="c-title font30 text_b">
                    孩子王 | 基于数据驱动，全方位经营用户关系
                  </div>
                  <div class="c-text font18">
                    <div class="scroll">
                      2015年合作以来，帮助孩子王先后实现全渠道业务数字化和基于数据驱动的经营能力，800多个会员标签、近百个会员模型，深度挖掘经营机会，助力孩子王业务飞速增长。
                    </div>
                  </div>
                </div>
              </div> -->


            </div>
          </div>
        </div>

      </div>


      <div class="vueSeamless-box wow fadeInUp">
        <div v-if="acceptBrand.length">
          <vue-seamless-scroll :data="acceptBrand" :class-option="classOption1" class="vueSeamless vueSeamless1">
            <ul class="ul-item">
              <li class="li-item" v-for="(item1, index1) in acceptBrand" :key="index1"
                v-show="index1 < (acceptBrand.length - 1) / 2">
                <div class="imgbox">
                  <img v-if="item1" :src="host + item1" alt="">
                </div>
              </li>
            </ul>
          </vue-seamless-scroll>
        </div>
        <div v-if="acceptBrand.length">
          <vue-seamless-scroll :data="acceptBrand" :class-option="classOption2" class="vueSeamless vueSeamless2">
            <ul class="ul-item">
              <li class="li-item" v-for="(item2, index2) in acceptBrand" :key="index2"
                v-show="index2 > (acceptBrand.length - 1) / 2">
                <div class="imgbox">
                  <img v-if="item2" :src="host + item2" alt="">
                </div>
              </li>
            </ul>
          </vue-seamless-scroll>
        </div>
      </div>



    </div>



    <!-- 关于元数 -->
    <div class="index-about" v-show="aboutYs.title">
      <img class="bgimg" src="../assets/images/ys-83.png" alt="">
      <div class="container">
        <p class="cn1 font40 font-b wow fadeInUp">{{ aboutYs.title }}</p>
        <p class="cn2 font18 wow fadeInUp">{{ aboutYs.desc }} </p>
        <p class="cn24">
          <router-link v-if="aboutYsBtn.link" :to="aboutYsBtn.link" class="cn3 wow fadeInUp">
            <span class="cn4 font16 font-b">{{ aboutYsBtn.title }}</span>
            <span class="cn25"><img class="hi-ico" src="../assets/images/about-img5.svg" onload="SVGInject(this)"></span>
          </router-link>
        </p>
        <div class="public-aboutBox wow fadeInUp">
          <div class="swiper-container" :class="swpNot ? 'swiper-no-swiping' : ''">
            <div class="swiper-wrapper">
              <div class="swiper-slide" v-for="(item, index) in aboutYs.nums">
                <div class="box2">
                  <div class="box3">
                    <div class="box4">
                      <span class="cn8 num font60 font-b">

                        <animate-number mode="manual" duration="3000" :from="0" :to="item.num" ref="syNum">
                        </animate-number>
                      </span>
                      <span class="cn9 font60 font-b">{{ item.more }}</span>
                      <span class="cn10 font18 font-b">{{ item.unit }}</span>
                    </div>
                    <p class="cn7 font18">{{ item.title }}</p>
                  </div>
                </div>
              </div>

              <!-- <div class="swiper-slide">
                <div class="box2">
                  <div class="box3">
                    <div class="box4">
                      <span class="cn8 num font60 font-b">
                        <animate-number mode="manual" duration="3000" :from="numVal.startVal2" :to="numVal.endVal2" ref="syNum">
                        </animate-number>
                      </span>
                      <span class="cn9 font60 font-b">+</span>
                      <span class="cn10 font18 font-b">个</span>
                    </div>
                    <p class="cn7 font18">明星案例</p>
                  </div>
                </div>
              </div>

              <div class="swiper-slide">
                <div class="box2">
                  <div class="box3">
                    <div class="box4">
                      <span class="cn8 num font60 font-b">
                        <animate-number mode="manual" duration="3000" :from="numVal.startVal3" :to="numVal.endVal3" ref="syNum">
                        </animate-number>
                      </span>
                      <span class="cn9 font60 font-b">+</span>
                      <span class="cn10 font18 font-b">亿</span>
                    </div>
                    <p class="cn7 font18">年GMV</p>
                  </div>
                </div>
              </div> -->

            </div>
          </div>
          <div class="swp-btnBox">
            <div class="swiper-pagination"></div>
          </div>
        </div>
      </div>
    </div>


    <!-- 媒体新闻 -->
    <div class="index-news" v-show="newsYs.title">
      <div class="container">
        <p class="cn1 font40 font-b wow fadeInUp">{{ newsYs.title }}</p>
        <p class="cn2 fpmt18 wow fadeInUp">{{ newsYs.desc }}</p>
        <p class="cn24">
          <router-link v-if="newsYsBtn.link" :to="newsYsBtn.link" class="cn3 wow fadeInUp">
            <span class="cn4 font16 font-b">{{ newsYsBtn.title }}</span>
            <span class="cn25"><img class="hi-ico" src="../assets/images/about-img5.svg" onload="SVGInject(this)"></span>
          </router-link>
        </p>

        <div class="box">
          <div class="index-newsa1">
            <div class="swiper-wrapper">
              <div class="swiper-slide wow fadeInUp" v-for="(item, index) in newsYsList" :key="index">
                <router-link :to="{ path: '/newsdetail', query: { id: item.id, listid: 11 } }" class="box2 ">
                  <p class="img public-img"><img v-if="item.cover" :src="host + item.cover" alt=""></p>
                  <p class="cn7 font16">{{ fixNewsTime(item.time) }}</p>
                  <p class="cn8 textflow2 font-b font24">{{ item.title }}</p>
                  <p class="cn9">
                    <span class="cn4 font16 font-b">查看详情</span>
                    <span class="cn25"><img class="hi-ico" src="../assets/images/about-img5.svg" onload="SVGInject(this)">
                    </span>
                  </p>
                </router-link>
              </div>

              <!-- <div class="swiper-slide wow fadeInUp">
                <a href="javascript:;" rel="nofollow" class="box2 ">
                  <p class="img public-img"><img src="../assets/images/about-img20.png" alt=""></p>
                  <p class="cn7 font16">2023.03.14</p>
                  <p class="cn8 textflow2 font-b font24">2023年度全国大区公司营销工作会议圆满结束！</p>
                  <p class="cn9">
                    <span class="cn4 font16 font-b">查看详情</span>
                    <span class="cn25"><img class="hi-ico" src="../assets/images/about-img5.svg" onload="SVGInject(this)">
                    </span>
                  </p>
                </a>
              </div>
              <div class="swiper-slide wow fadeInUp">
                <a href="javascript:;" rel="nofollow" class="box2 ">
                  <p class="img public-img"><img src="../assets/images/about-img21.png" alt=""></p>
                  <p class="cn7 font16">2023.03.10</p>
                  <p class="cn8 textflow2 font-b font24">元戎启行，数往知来 | 南京元数科技有限公司创立大会成功</p>
                  <p class="cn9">
                    <span class="cn4 font16 font-b">查看详情</span>
                    <span class="cn25"><img class="hi-ico" src="../assets/images/about-img5.svg" onload="SVGInject(this)">
                    </span>
                  </p>
                </a>
              </div> -->



            </div>
          </div>
          <div class="swp-btnBox wap">
            <div class="swiper-pagination"></div>
          </div>
        </div>
      </div>
    </div>





  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

// 这是引用wow
// import { WOW } from 'wowjs'
// 引入swiper
import Swiper from 'swiper';
import $ from 'jquery';
import 'swiper/dist/css/swiper.min.css';
// import vueSeamless from 'vue-seamless-scroll'
// import countTo from 'vue-count-to';
import '../assets/css/style.css';
import hostUrl from '@/util/baseUrl'


export default {
  // name: "HomeView",
  components: {
    // vueSeamless,
    // countTo
  },
  metaInfo() {
    return {
      title: this.seoInfo.title, //设置页面title
      meta: [
        {                 //设置页面meta
          name: 'keywords',
          content: this.seoInfo.keyWords
        },
        {
          name: "description",
          content: this.seoInfo.description
        },
        {
          name: "renderer",
          content: 'webkit',
        },
        {
          name: "force-rendering",
          content: 'webkit',
        },
        {
          name: "format-detection",
          content: 'telephone=no,telephone=no,email=no,adress=no',
        },
        {
          name: "viewport",
          content: 'width=device-width,user-scalable=no,initial-scale=1.0,maximum-scale=1.0,minimum-scale=1.0',
        },        
      ]
    }
  },

  data() {
    return {
      showIndex: false,
      activeName: 0,
      itemLength: 0,
      swpActive: 0,
      sysec2Top: '',
      sysec3Top: '',
      sysec3Fn_flag: false,
      sysec3AmongImg: '',
      // sysec3Data: [
      //   {
      //     navTitle: "商超便利",
      //     on: true,
      //     active: true,
      //     left: [
      //       {
      //         on: false,
      //         icon: require('../assets/images/ys-36.svg'),
      //         title: '全渠道全域零售',
      //         img: require('../assets/images/ys-90.png'),
      //         ulList: [
      //           {
      //             text: '总部统一管控，适度放权，兼顾门店灵活经营'
      //           },
      //           {
      //             text: '构建全场景连接触点，持续释放全域流量价值'
      //           },
      //           {
      //             text: '移动POS、自助收银…打破收银局限，高效消费体验'
      //           },
      //           {
      //             text: '全渠道交易闭环、履约闭环和财务闭环'
      //           },
      //         ]
      //       },
      //       {
      //         on: false,
      //         icon: require('../assets/images/ys-39.svg'),
      //         title: '数字化会员经营',
      //         img: require('../assets/images/ys-91.png'),
      //         ulList: [
      //           {
      //             text: '连接消费者，洞察生意机会，最大化会员价值'
      //           },
      //           {
      //             text: '身份统一，营销统一，交易统一，资产统一'
      //           },
      //           {
      //             text: '等级、积分、权益、付费会员…全方位锁定会员忠诚度'
      //           },
      //         ]
      //       },
      //       {
      //         on: false,
      //         icon: require('../assets/images/ys-40.svg'),
      //         title: '高效营运及店务',
      //         img: require('../assets/images/ys-92.png'),
      //         ulList: [
      //           {
      //             text: '一站式门店智能物联，极“智”高效体验'
      //           },
      //           {
      //             text: '移动店务管理，智能任务提醒，灵活便捷'
      //           },
      //           {
      //             text: '实时数据监控，即时精准决策，销售尽在掌握'
      //           },
      //           {
      //             text: '员工激励明确，绩效透明，实时获取业绩佣金'
      //           },
      //         ]
      //       },
      //       {
      //         on: false,
      //         icon: require('../assets/images/ys-41.svg'),
      //         title: '协同式供应链',
      //         img: require('../assets/images/ys-93.png'),
      //         ulList: [
      //           {
      //             text: '链接供应商，实现商品、结算、订单等业务在线协同'
      //           },
      //           {
      //             text: '全生命周期合同管理，灵活适配经销/代销/联营/租赁'
      //           },
      //           {
      //             text: '供应商协同移动化，随时随地协同办公'
      //           },
      //         ]
      //       },
      //       {
      //         on: false,
      //         icon: require('../assets/images/ys-42.svg'),
      //         title: '智能仓储物流',
      //         img: require('../assets/images/ys-94.png'),
      //         ulList: [
      //           {
      //             text: '常温仓储：智能派单，移动化无纸化作业，便捷环保'
      //           },
      //           {
      //             text: '生鲜仓储：营采一体，缩短流通链路，降损增利'
      //           },
      //           {
      //             text: '生鲜专属收货流程，支持降级减重，自定义质检方案'
      //           },
      //         ]
      //       },

      //     ],
      //     right: [
      //       {
      //         on: false,
      //         icon: require('../assets/images/ys-45.svg'),
      //         title: '全链路生产加工',
      //         img: require('../assets/images/ys-95.png'),
      //         ulList: [
      //           {
      //             text: '全程无纸化移动化操作，加工进度实时监管，灵活安排'
      //           },
      //           {
      //             text: '精细化生产统计分析，数据赋能，助力企业持续提效'
      //           },
      //         ]
      //       },
      //       {
      //         on: false,
      //         icon: require('../assets/images/ys-46.svg'),
      //         title: '在线式分销及批发',
      //         img: require('../assets/images/ys-96.png'),
      //         ulList: [
      //           {
      //             text: '一键订货全程可视化跟踪，告别错单漏单'
      //           },
      //           {
      //             text: '精准客户管控，高效触达，多样化权益提升粘性'
      //           },
      //           {
      //             text: '从客户到司机，角色化专属界面，移动端便捷协同'
      //           },
      //         ]
      //       },
      //       {
      //         on: false,
      //         icon: require('../assets/images/ys-47.svg'),
      //         title: '全模式加盟',
      //         img: require('../assets/images/ys-97.png'),
      //         ulList: [
      //           {
      //             text: '有效支持紧密型加盟和松散型加盟'
      //           },
      //           {
      //             text: '灵活管控加盟商补货及信用额度'
      //           },
      //           {
      //             text: '严谨清晰的财务结算，杜绝结算扯皮'
      //           },
      //         ]
      //       },
      //       {
      //         on: false,
      //         icon: require('../assets/images/ys-48.svg'),
      //         title: '一体化业财',
      //         img: require('../assets/images/ys-98.png'),
      //         ulList: [
      //           {
      //             text: '业财同源同时同口径，实现业财一体化管理'
      //           },
      //           {
      //             text: '覆盖采购、加盟、三方、代销等全场景业务结算'
      //           },
      //           {
      //             text: '资金风控，银企直连付款，收付安全有保障'
      //           },
      //           {
      //             text: '资产验收、折旧、拆分及合并，灵活管理企业资产'
      //           },
      //         ]
      //       },
      //       {
      //         on: false,
      //         icon: require('../assets/images/ys-49.svg'),
      //         title: '数据驱动',
      //         img: require('../assets/images/ys-99.png'),
      //         ulList: [
      //           {
      //             text: '销售预测，智能补货，让决策“有数可循”'
      //           },
      //           {
      //             text: 'AI称自动识别商品，提升称重效率'
      //           },
      //         ]
      //       },

      //     ]
      //   },
      //   {
      //     navTitle: "母婴连锁",
      //     on: false,
      //     active: false,
      //     left: [
      //       {
      //         on: false,
      //         icon: require('../assets/images/ys-36.svg'),
      //         title: '会员精细化运营',
      //         img: require('../assets/images/ys-180.jpg'),
      //         ulList: [
      //           {
      //             text: '全渠道一体化会员管理，跨平台会员资产良性循环'
      //           },
      //           {
      //             text: '完善的会员成长体系等全方位锁定会员忠诚度'
      //           },
      //           {
      //             text: '全生命周期差异化精准营销，触达-留存-复购一步到位'
      //           },
      //         ]
      //       },
      //       {
      //         on: false,
      //         icon: require('../assets/images/ys-39.svg'),
      //         title: '全渠道触达和营销',
      //         img: require('../assets/images/ys-180.jpg'),
      //         ulList: [
      //           {
      //             text: '线上商城/社群/视频号等全触点发力主动出击'
      //           },
      //           {
      //             text: '商品+服务+互动+内容全方位满足会员消费体验'
      //           },
      //         ]
      //       },
      //       {
      //         on: false,
      //         icon: require('../assets/images/ys-40.svg'),
      //         title: '全链路一体化数字经营',
      //         img: require('../assets/images/ys-180.jpg'),
      //         ulList: [
      //           {
      //             text: '全流程商品进销存管理，上下游伙伴高效协同'
      //           },
      //           {
      //             text: '手持PDA移动化作业，提高整体运营效率'
      //           },
      //         ]
      //       },
      //     ],
      //     right: [
      //       {
      //         on: false,
      //         icon: require('../assets/images/ys-45.svg'),
      //         title: '一站式员工经营工具',
      //         img: require('../assets/images/ys-180.jpg'),
      //         ulList: [
      //           {
      //             text: '会员关系经营，单客深耕，提升LTV'
      //           },
      //           {
      //             text: '机会客群/会员机会点一目了然'
      //           },
      //           {
      //             text: '卖货素材/好物清单一键分享'
      //           },
      //           {
      //             text: '员工任务/激励/业绩一站式管理'
      //           },
      //         ]
      //       },
      //       {
      //         on: false,
      //         icon: require('../assets/images/ys-46.svg'),
      //         title: '智慧门店',
      //         img: require('../assets/images/ys-180.jpg'),
      //         ulList: [
      //           {
      //             text: '移动收银/自助收银/一体化POS…助力提升收银人效'
      //           },
      //           {
      //             text: '智慧大屏，人机互动，实现门店数字营销'
      //           },
      //         ]
      //       },
      //       {
      //         on: false,
      //         icon: require('../assets/images/ys-47.svg'),
      //         title: '实战化运营赋能',
      //         img: require('../assets/images/ys-180.jpg'),
      //         ulList: [
      //           {
      //             text: '围绕母婴商家的组织力、运营力、经营力实战化带教'
      //           },
      //           {
      //             text: '基于数据驱动助力提升全渠道数字化组织运营能力'
      //           },
      //         ]
      //       },


      //     ]
      //   },
      //   {
      //     navTitle: "消费品牌",
      //     on: false,
      //     active: false,
      //     left: [
      //       {
      //         on: false,
      //         icon: require('../assets/images/ys-36.svg'),
      //         title: '全渠道数据整合',
      //         img: require('../assets/images/ys-181.jpg'),
      //         ulList: [
      //           {
      //             text: '全域数据整合、清洗，建立唯一用户标识体系ONEID'
      //           },
      //           {
      //             text: '构建品牌私域阵地，让品牌与客户直连'
      //           },
      //         ]
      //       },
      //       {
      //         on: false,
      //         icon: require('../assets/images/ys-39.svg'),
      //         title: '会员全生命周期运营',
      //         img: require('../assets/images/ys-181.jpg'),
      //         ulList: [
      //           {
      //             text: '深入洞察客户行为数据，形成360°会员画像与客群分析'
      //           },
      //           {
      //             text: '基于数据挖掘与事件分析，洞察会员潜在需求'
      //           },
      //           {
      //             text: '构建全渠道数字化、一体化的品牌会员管理体系'
      //           },
      //           {
      //             text: '成长、权益、付费体系满足品牌多样化会员运营规则'
      //           },
      //         ]
      //       },
      //       {
      //         on: false,
      //         icon: require('../assets/images/ys-40.svg'),
      //         title: '多场景自动化营销',
      //         img: require('../assets/images/ys-181.jpg'),
      //         ulList: [
      //           {
      //             text: '覆盖全链路经营场景，围绕会员全生命周期差异化营销'
      //           },
      //           {
      //             text: '在全业务场景、全经营链路、全客户生命周期进行自动化营销'
      //           },
      //         ]
      //       },
      //     ],
      //     right: [
      //       {
      //         on: false,
      //         icon: require('../assets/images/ys-45.svg'),
      //         title: '线下服务人员提效',
      //         img: require('../assets/images/ys-181.jpg'),
      //         ulList: [
      //           {
      //             text: '为导购与服务人员提供「单兵作战系统」，高效触达并服务消费者'
      //           },
      //           {
      //             text: '运营团队定向布达沟通任务，一线导购精准回访，激活老客复购'
      //           },
      //           {
      //             text: '统一管理导购与客户资产，业务经营情况一目了然'
      //           },
      //         ]
      //       },
      //       {
      //         on: false,
      //         icon: require('../assets/images/ys-46.svg'),
      //         title: '资深智库团队带教',
      //         img: require('../assets/images/ys-181.jpg'),
      //         ulList: [
      //           {
      //             text: '根据商家的具体情况量体裁衣，提供各阶段的运营与赋能带教'
      //           },
      //           {
      //             text: '提供专业的业务诊断与分析服务，找到品牌增长瓶颈'
      //           },
      //           {
      //             text: '公域运营增效，连接私域会员运营工具，打造公域到私域营销闭环'
      //           },
      //         ]
      //       },
      //       {
      //         on: false,
      //         icon: require('../assets/images/ys-47.svg'),
      //         title: '系统成熟稳定有保障',
      //         img: require('../assets/images/ys-181.jpg'),
      //         ulList: [
      //           {
      //             text: '专业、持续性的技术支持，助力客户成功'
      //           },
      //           {
      //             text: '成熟的数据安全防护，保障客户数据资产'
      //           },
      //         ]
      //       },


      //     ]
      //   },
      // ],
      // listData: [
      //   {
      //     img: require('../assets/images/ys-58.png'),
      //   },
      //   {
      //     img: require('../assets/images/ys-59.png'),
      //   },
      //   {
      //     img: require('../assets/images/ys-60.png'),
      //   },
      //   {
      //     img: require('../assets/images/ys-57.png'),
      //   },
      //   {
      //     img: require('../assets/images/ys-61.png'),
      //   },
      //   {
      //     img: require('../assets/images/ys-62.png'),
      //   },
      //   {
      //     img: require('../assets/images/ys-63.png'),
      //   },
      //   {
      //     img: require('../assets/images/ys-64.png'),
      //   },
      //   {
      //     img: require('../assets/images/ys-65.png'),
      //   },
      // ],
      // listData2: [
      //   {
      //     img: require('../assets/images/ys-66.png'),
      //   },
      //   {
      //     img: require('../assets/images/ys-67.png'),
      //   },
      //   {
      //     img: require('../assets/images/ys-68.png'),
      //   },
      //   {
      //     img: require('../assets/images/ys-69.png'),
      //   },
      //   {
      //     img: require('../assets/images/ys-70.png'),
      //   },
      //   {
      //     img: require('../assets/images/ys-71.png'),
      //   },
      //   {
      //     img: require('../assets/images/ys-72.png'),
      //   },
      //   {
      //     img: require('../assets/images/ys-73.png'),
      //   },
      //   {
      //     img: require('../assets/images/ys-74.png'),
      //   },
      // ],
      // classOption1: {
      //   limitMoveNum: 1,
      //   direction: 2,
      // },
      // classOption2: {
      //   limitMoveNum: 1,
      //   direction: 3,
      // },

      // 数字跳动
      scrollFlag: false,
      // 屏幕宽度参数
      wW: '',
      isReload: true,
      swpNot: false, //禁止轮播滑动

      // 接口数据
      data: [],
      host: hostUrl,
      // 第一屏 banner轮播
      banner: {},
      fmodel: {},
      // 第二屏 通过“端+云+PaaS”
      matrix: {},
      matrix_bgimg_pc: '',
      matrix_bgimg_wap: '',
      matrix_list_left: [],
      matrix_list_right: [],
      // 第三屏 行业最佳实践
      industry: {},
      industryNavLength: 0,
      // 第四屏 众多品牌
      accept: {},
      acceptBtn: {},
      acceptBrand: [],
      // 第五屏 关于元数
      aboutYs: {},
      aboutYsBtn: {},
      aboutTop: '',
      // 第六屏 新闻
      newsYs: {},
      newsYsBtn: {},
      newsYsList: [],
      // seo信息
      seoInfo: {
        title: '',
        keyWords: '',
        description: ''
      },
      // 全局seo
      seoAllInfo: {},
    }
  },
  created() {
    // this.$router.go(0)
    // window.location.reload()

  },
  mounted() {

    // 接收从app.vue传来的seo全局参数
    this.$bus.on("seoInfoTitle", value => {
      this.seoInfo.title = value
    });

    this.$bus.$off("seoInfoKeyWords");
    this.$bus.on("seoInfoKeyWords", value => {
      this.seoInfo.keyWords = value
    });

    this.$bus.$off("seoInfoSeoDesc");
    this.$bus.on("seoInfoSeoDesc", value => {
      this.seoInfo.description = value
    });

    this.getData()


    // this.$refs.num.start()


    // this.reload()
    // this.$router.go(0)
    this.wW = document.documentElement.clientWidth || document.body.clientWidth //获取浏览器宽度
    this.seoAllInfo = JSON.parse(localStorage.getItem('yuanshu_seoInfo')) //获取seo全局信息

    // this.wW = document.documentElement.clientWidth
    // this.aboutTop=aboutTop

    // 监听页面滚动
    // window.addEventListener('scroll', this.scrolling, true)
    // this.handleScroll()

    // this.$nextTick(() => {

    // 轮播图pc端禁止滑动
    if (this.wW > 770) {
      this.swpNot = true
    }

    // })



    // 第二屏列表展开隐藏
    if (this.wW > 770) {
      $('.sysec2 .c-textBox .contBox').mouseover(function () {
        $(this).parent().addClass('active')
        $(this).find('.c-ul').stop().fadeIn()
      })
      $('.sysec2 .c-textBox .contBox').mouseout(function () {
        $(this).parent().removeClass('active')
        $(this).find('.c-ul').stop().fadeOut()
      })
    } else {
      $('.sysec2 .c-box .c-textBox .c-title-box').click(function () {
        $(this).parent().find('.c-ul').fadeIn()
        $('.sysec2-mask').show()
      })
      $('.sysec2-mask').click(function () {
        $('.sysec2 .c-box .c-textBox .c-ul').fadeOut()
        $('.sysec2-mask').hide()
      })
    }

    // wowjs
    this.$nextTick(() => {

    })



    // 百度统计代码
    var _hmt = _hmt || [];
    (function () {
      var hm = document.createElement("script");
      hm.src = "https://hm.baidu.com/hm.js?77637ce1a84bee069c1effb2174121bb";
      var s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(hm, s);
    })();

  },

  updated() {

  },
  computed: {
    // defaultOption() {
    //   return {
    //     step: 0.8,
    //     hoverStop: true, // 是否开启鼠标悬停stop
    //     direction: 2, // 0向下 1向上 2向左 3向右
    //     openWatch: false, // 开启数据实时监控刷新dom
    //     // singleHeight: 40, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
    //     // singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
    //     // waitTime: 2000, // 单步运动停止的时间(默认值1000ms)
    //   }
    // }
    classOption1() {
      return {
        step: 1,
        limitMoveNum: 1,
        direction: 2,
        openWatch: true, // 开启数据实时监控刷新dom
        // singleHeight: 40, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        // singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        // waitTime: 2000, // 单步运动停止的时间(默认值1000ms)
      }
    },
    classOption2() {
      return {
        step: 1,
        limitMoveNum: 1,
        direction: 3,
        openWatch: true, // 开启数据实时监控刷新dom
        // singleHeight: 40, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        // singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        // waitTime: 2000, // 单步运动停止的时间(默认值1000ms)
      }
    },
  },
  watch: {
    $route: {
      // 监听路由变化
      handler: function (val, oldVal) {

        // 接收从app.vue传来的seo全局参数
        this.$bus.on("seoInfoTitle", value => {
          this.seoInfo.title = value
        });

        this.$bus.$off("seoInfoKeyWords");
        this.$bus.on("seoInfoKeyWords", value => {
          this.seoInfo.keyWords = value
        });

        this.$bus.$off("seoInfoSeoDesc");
        this.$bus.on("seoInfoSeoDesc", value => {
          this.seoInfo.description = value
        });

      },
      deep: true
    }
  },
  methods: {

    // 请求后端接口
    getData() {

      this.seoInfo = {
        title: '',
        keyWords: '',
        description: ''
      }

      this.$axios.get('/api/page/1').then(res => {
        // this.data = res.data.data
        // this.data.blocksObj.matrix.bgimg = res.data.data.blocksObj.matrix.bgimg.split(',')
        this.showIndex = true

        // seo信息        
        if (res.data.data.seoKeyword != '' && res.data.data.seoKeyword != null && res.data.data.seoKeyword != undefined) {
          this.seoInfo.keyWords = res.data.data.seoKeyword
        }else{
          this.seoInfo.keyWords=this.seoAllInfo.keyWords
        }

        if (res.data.data.seoDesc != '' && res.data.data.seoDesc != null && res.data.data.seoDesc != undefined) {
          this.seoInfo.description = res.data.data.seoDesc
        }else{
          this.seoInfo.description=this.seoAllInfo.description
        }

        // console.log(res.data.data.seoKeyword,'seoKeyword')


        this.banner = res.data.data.blocksObj.banner
        this.fmodel = res.data.data.blocksObj.fmodel
        this.matrix = res.data.data.blocksObj.matrix
        this.matrix_bgimg_pc = this.matrix.bgimg.split(',')[0]
        this.matrix_bgimg_wap = this.matrix.bgimg.split(',')[1]
        this.matrix_list_left = res.data.data.blocksObj.matrix.list[0]
        this.matrix_list_right = res.data.data.blocksObj.matrix.list[1]

        var industryTemp = res.data.data.blocksObj.industry
        for (var i = 0; i < industryTemp.list.length; i++) {
          if (i == 0) {
            industryTemp.list[i].on = true
            industryTemp.list[i].active = true
          } else {
            industryTemp.list[i].on = false
            industryTemp.list[i].active = false
          }

          for (var j = 0; j < industryTemp.list[i].cons.length; j++) {
            industryTemp.list[i].cons[j].on = false
            industryTemp.list[i].cons[j].text = industryTemp.list[i].cons[j].text.split('\n')
          }
        }
        this.industry = industryTemp
        this.sysec3AmongImg = this.industry.list[0].cons[0].image
        this.industryNavLength = this.industry.list.length

        this.accept = res.data.data.blocksObj.accept
        this.acceptBtn.title = res.data.data.blocksObj.accept.btn[0].title
        this.acceptBtn.link = res.data.data.blocksObj.accept.btn[0].link
        this.acceptBtn.icon = res.data.data.blocksObj.accept.btn[0].icon
        this.acceptBrand = res.data.data.blocksObj.brands.split(',')

        this.aboutYs = res.data.data.blocksObj.about
        this.aboutYsBtn.title = this.aboutYs.btn[0].title
        this.aboutYsBtn.link = this.aboutYs.btn[0].link
        this.aboutYsBtn.icon = this.aboutYs.btn[0].icon

        this.newsYs = res.data.data.blocksObj.news
        this.newsYsBtn.title = this.newsYs.btn[0].title
        this.newsYsBtn.link = this.newsYs.btn[0].link
        this.newsYsBtn.icon = this.newsYs.btn[0].icon
        var newsYsListTemp = res.data.data.extend.news
        for (var i = 0; i < newsYsListTemp.length; i++) {
          newsYsListTemp[i].createTime = newsYsListTemp[i].createTime.split('T')[0].replace(/-/g, '.')
        }
        this.newsYsList = newsYsListTemp

        // console.log(res.data.data.extend.news, 'news')


        this.$nextTick(() => {

          // 头部轮播
          setTimeout(() => {
            this.syBanner()
          }, 0)

          // 头部banner列表移动端轮播
          setTimeout(() => {
            this.sysec1Bnul2()
          }, 100)

          // 品牌轮播
          setTimeout(() => {
            this.sysec4Swp()
          }, 200)

          // 关于元数轮播
          setTimeout(() => {
            this.indexAbout()
          }, 300)

          // 新闻轮播
          setTimeout(() => {
            this.indexNews()
          }, 400)

          // wow动画
          new this.$wow.WOW({
            live: false,
          }).init()

          this.aboutTop = document.getElementById('sysec4').offsetTop
          this.sysec2Top = document.getElementById('bnul2').offsetTop
          this.sysec3Top = document.getElementById('sysec3').offsetTop

          window.addEventListener('scroll', this.handleScroll)
        })



      })
    },
    // 第三屏切换
    sysec3FnNav(index) {
      for (var i = 0; i < this.industry.list.length; i++) {
        this.industry.list[i].on = false
      }
      this.industry.list[index].on = true

      // if(this.industry.list[index].cons[0].image!=undefined||this.industry.list[index].cons[0].image!=''){
      this.sysec3AmongImg = this.industry.list[index].cons[0].image
      // }



    },
    sysec3FnNavHover(index) {
      for (var i = 0; i < this.industry.list.length; i++) {
        this.industry.list[i].active = false
      }
      this.industry.list[index].active = true

    },
    sysec3Fn(index1, index2, seat) {
      if (this.wW > 770) {
        // var _this
        // if (seat == 'left') {
        //   _this = this.sysec3Data[index1].left
        // } else {
        //   _this = this.sysec3Data[index1].right
        // }

        // for (var i = 0; i < _this.length; i++) {
        //   _this[i].on = false
        // }

        // _this[index2].on = true

        // for (var i = 0; i < this.industry.list[index1].cons.length; i++) {
        //   this.industry.list[index1].cons[i].on=false
        // }

        this.industry.list[index1].cons[index2].on = true

        this.sysec3AmongImg = this.industry.list[index1].cons[index2].image

        // if(this.sysec3Fn_flag==false){
        //   _this[index2].on=true
        //   this.sysec3Fn_flag=true
        // }else{
        //   _this[index2].on=false
        //   this.sysec3Fn_flag=false
        // }
        // console.log(1)
      }
    },
    sysec3Fn2(index1, index2, seat) {
      if (this.wW > 770) {
        // var _this
        // if (seat == 'left') {
        //   _this = this.sysec3Data[index1].left
        // } else {
        //   _this = this.sysec3Data[index1].right
        // }

        // this.sysec3Data[index1].left[index2].on=false
        // this.sysec3Data[index1].right[index2].on=false
        // _this[index2].on = false

        // for (var i = 0; i < this.sysec3Data.length; i++) {
        //   for (var j = 0; j < this.sysec3Data[i].left.length; j++) {
        //     this.sysec3Data[i].left[j].on = false
        //   }
        //   for (var j = 0; j < this.sysec3Data[i].right.length; j++) {
        //     this.sysec3Data[i].right[j].on = false
        //   }
        // }

        for (var i = 0; i < this.industry.list.length; i++) {
          for (var j = 0; j < this.industry.list[i].cons.length; j++) {
            this.industry.list[i].cons[j].on = false
          }
        }

        // console.log(2)
      }
    },
    sysec3ClickFn(index1, index2, seat) {
      if (this.wW < 770) {

        // var _this
        // if (seat == 'left') {
        //   _this = this.sysec3Data[index1].left

        //   for (var i = 0; i < this.sysec3Data.length; i++) {
        //     for (var j = 0; j < this.sysec3Data[i].left.length; j++) {
        //       if (_this[index2] != this.sysec3Data[i].left[j]) {
        //         this.sysec3Data[i].left[j].on = false
        //       }
        //     }
        //   }

        // } else {
        //   _this = this.sysec3Data[index1].right

        //   for (var i = 0; i < this.sysec3Data.length; i++) {
        //     for (var j = 0; j < this.sysec3Data[i].right.length; j++) {
        //       if (_this[index2] != this.sysec3Data[i].right[j]) {
        //         this.sysec3Data[i].right[j].on = false
        //       }
        //     }
        //   }

        // }

        // if (_this[index2].on == true) {
        //   _this[index2].on = false
        // } else {
        //   _this[index2].on = true
        // }


        for (var i = 0; i < this.industry.list.length; i++) {
          for (var j = 0; j < this.industry.list[i].cons.length; j++) {
            if (this.industry.list[i].cons[j] == this.industry.list[index1].cons[index2]) {
              if (this.industry.list[index1].cons[index2].on == true) {
                this.industry.list[index1].cons[index2].on = false
              } else {
                this.industry.list[index1].cons[index2].on = true
              }
            } else {
              this.industry.list[i].cons[j].on = false
            }


          }
        }


        // if (this.industry.list[index1].cons[index2].on == false) {
        //   //  this.$set(this.industry.list[index1].cons[index2],on,false)
        //   // this.industry.list[index1].cons[index2].on=false
        //   this.industry.list[index1].cons[index2].on = true
        //   console.log(1)
        // } else {
        //   this.industry.list[index1].cons[index2].on = false
        //   console.log(2)
        //   // this.$set( this.industry.list[index1].cons[index2],on,true)
        // }

        // console.log(this.industry.list[index1].cons[index2].on)

        // console.log(_this[index2].on)

      }

    },
    // 提示
    toast() {
      this.$toast.show('页面建设中，请耐心等待…', '您可通过电话或微信扫描底部二维码联系我们', 5000)
    },
    // 页面滚动
    handleScroll() {
      // this.$nextTick(() => {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      // console.log(scrollTop, this.aboutTop)

      // 关于元数数字滚动触发
      if (scrollTop >= this.aboutTop) {
        if (this.scrollFlag == false) {
          // this.$nextTick(() => {           
          for (var i = 0; i < this.$refs.syNum.length; i++) {
            this.$refs.syNum[i].start()
          }
          // })
          this.scrollFlag = true
        }
      }

      // 第二屏移动端显示隐藏
      if (scrollTop > this.sysec3Top || scrollTop < this.sysec2Top) {
        $('.sysec2 .c-box .c-textBox .c-ul').fadeOut()
        $('.sysec2-mask').hide()
      }
      // })
    },
    // 头部轮播
    syBanner() {
      var syBanner = new Swiper('.sybnSwp .swiper-container', {
        autoplay: {
          delay: 5000,
        },
        slidesPerView: 1,
        // loop: true,
        effect: 'fade',
        observer: true,
        observeParents: true,

        fadeEffect: {
          crossFade: true,
        },
        pagination: {
          el: '.sybnSwp .swiper-pagination',
          clickable: true,
        },
        on: {
          init: function (swiper) {
            // swiperAnimateCache(this); //隐藏动画元素
            // swiperAnimate(this);
          },
          slideChangeTransitionEnd: function () {
            // swiperAnimate(this); //每个slide切换结束时也运行当前slide动画
            //this.slides.eq(this.activeIndex).find('.ani').removeClass('ani'); 动画只展现一次，去除ani类名

          }
        },
      })
    },
    // 头部banner列表移动端轮播
    sysec1Bnul2() {
      var sysec1Bnul2 = new Swiper('.sysec1 .bnul2 .swiper-container', {
        autoplay: {
          delay: 5000,
        },
        slidesPerView: 2,
        // loop: true,
        spaceBetween: 10,
        observer: true,
        observeParents: true,
        navigation: {
          nextEl: '.sysec1 .bnul2 .swiper-button-next',
          prevEl: '.sysec1 .bnul2 .swiper-button-prev',
        },
      })
    },
    // 品牌轮播
    sysec4Swp() {
      var sysec4Swp = new Swiper('.sysec4Swp .swiper-container', {
        autoplay: {
          delay: 5000,
        },
        slidesPerView: 1,
        // loop: true,
        effect: 'fade',
        observer: true,
        observeParents: true,
        fadeEffect: {
          crossFade: true,
        },
        pagination: {
          el: '.sysec4Swp .swiper-pagination',
          clickable: true,
        },
        on: {
          init: function (swiper) {
            // swiperAnimateCache(this); //隐藏动画元素
            // swiperAnimate(this);
          },
          slideChangeTransitionEnd: function () {
            // swiperAnimate(this); //每个slide切换结束时也运行当前slide动画
            //this.slides.eq(this.activeIndex).find('.ani').removeClass('ani'); 动画只展现一次，去除ani类名

          }
        },
      })
    },
    // 关于元数轮播
    indexAbout() {
      var autoplayFlag=''
      var autoLoop=''
      if(this.wW<=770){
        autoplayFlag=true
        autoLoop=false
      }else{
        autoplayFlag=false
        autoLoop=true
      }
      var indexAbout = new Swiper('.index-about .swiper-container', {
        // autoplay: false,
        autoplay: {
          delay: 5000,
        },
        autoplay: autoplayFlag,
        
        slidesPerView: 3,
        loop: autoLoop,
        // loop:false,
        spaceBetween: 40,
        observer: true,
        observeParents: true,
        pagination: {
          el: '.index-about .swiper-pagination',
          clickable: true,
        },
        breakpoints: {
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
            // loop: false,
          }
        }
      })
    },
    // 新闻轮播
    indexNews() {
      var autoplayFlag=''
      if(this.wW<=770){
        autoplayFlag=true
      }else{
        autoplayFlag=false
      }
      var indexNews = new Swiper('.index-newsa1', {
        slidesPerView: 3,
        autoplay: autoplayFlag,
        autoplay: {
          delay: 5000,
        },
        // loop: true,
        // loopedSlides: 3,
        spaceBetween: 50,
        observer: true,
        observeParents: true,
        pagination: {
          el: '.index-news .swiper-pagination',
          clickable: true,
        },
        breakpoints: {
          991: {
            slidesPerView: 2,
            spaceBetween: 20,
            // centeredSlides: true,
          },
          768: {
            slidesPerView: 1,
            spaceBetween: 20,
            // centeredSlides: true,
          }
        }
      })
    },
    fixNewsTime(x) {
      x = x.split('T')[0].replace(/-/g, '.')

      return x
    },
    // 判断链接是不是外链
    isExternal(x) {
      return /^(https?:|mailto:|tel:)/.test(x);
    },

    // 强制刷新页面
    // reload(){
    //     this.isReload=false
    //     this.$nextTick(()=>{
    //         this.isReload=true
    //     })
    // }    
  },

};
</script>

<style scoped lang="less">
.vueSeamless-box {
  margin-top: 0.6rem;
  position: relative;

  &::before {
    content: '';
    width: 30.7%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background: url('!@/assets/images/ys-75.png');
    z-index: 1;
    background-size: cover;

    @media (max-width: 767px) {
      content: unset;
    }
  }

  &::after {
    content: '';
    width: 30.7%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    background: url('!@/assets/images/ys-76.png');
    z-index: 1;
    background-size: cover;

    @media (max-width: 767px) {
      content: unset;
    }
  }
}

.vueSeamless {
  width: 100%;
  margin: 0 auto;
  overflow: hidden;

  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;

    &.ul-item {
      display: flex;

      .li-item {
        // .zz_imgpdh(13.75%,7.05%);
        position: relative;
        height: 120px;
        width: 215px;
        border-radius: 10px;

        @media (max-width:1366px) {
          width: 180px;
          height: 85px;
        }

        .imgbox {
          box-shadow: 0 5px 10px rgba(110, 151, 232, 0.1);
          // margin: -40px 0;
          // padding: 40px 0;
          height: 83.33%;
          width: 93.02%;

          border-radius: 10px;
        }

        img {
          width: 100%;
          height: 100%;
          // object-fit: cover;    
          border-radius: 10px;
          border: 1px solid #fff;
          // width:auto;
          // height:auto;

        }
      }
    }
  }

  &.vueSeamless1 {
    margin-bottom: 0rem;
  }

  &.vueSeamless2 {
    margin-left: -320px;
    width: calc(100% + 320px);

    .ul-item {}

  }
}

@media (max-width: 767px) {
  .vueSeamless ul.ul-item .li-item {
    // width: 2.4rem;
    // height: 1.3rem;
    width: 2.6rem;
    height: 1.5rem;
  }
}
</style>