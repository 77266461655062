<template>
    <div class="contact">

        <div class="contact-box">
            <img class="bgimg1" src="../assets/images/ys-83.png" alt="">
            <div class="public-ani-bgimg public-ani-bgimg4"><img src="img/ys-82.0f06c67e.png" alt=""></div>
            <div class="container">
                <div class="c-box">
                    <div class="zz-left">
                        <div class="box1">
                            <div class="c-title font30 text_b">
                                {{ contactList.title }}
                            </div>

                            <ul class="c-ul">
                                <li class="c-li" v-for="(item,index) in contactList_cons" :key="index">
                                    <div class="a">
                                        <div class="c-icon">
                                            <img src="../assets/images/ys-234.png" alt="">
                                        </div>
                                        <div class="c-text font18">{{ item }}</div>
                                    </div>
                                </li>
                                <!-- <li class="c-li">
                                    <div class="a">
                                        <div class="c-icon">
                                            <img src="../assets/images/ys-234.png" alt="">
                                        </div>
                                        <div class="c-text font18">免费上门演示与产品试用</div>
                                    </div>
                                </li>
                                <li class="c-li">
                                    <div class="a">
                                        <div class="c-icon">
                                            <img src="../assets/images/ys-234.png" alt="">
                                        </div>
                                        <div class="c-text font18">企业量身定制解决方案</div>
                                    </div>
                                </li> -->
                            </ul>
                        </div>

                        <div class="box2">
                            <div class="c-title font30 text_b">
                                {{ contactList.title2 }}
                            </div>

                            <div class="vueSeamless-box">
                                <div v-if="contactList_brands.length">
                                    <vue-seamless-scroll :data="contactList_brands" :class-option="classOption1"
                                        class="vueSeamless vueSeamless1">
                                        <ul class="ul-item">
                                            <li class="li-item" v-for="(item1, index1) in contactList_brands" :key="index1" v-show="index1<(contactList_brands.length-1)/2">
                                                <div class="imgbox">
                                                    <img :src="host+item1" alt="">
                                                </div>
                                            </li>
                                        </ul>
                                    </vue-seamless-scroll>
                                </div>
                                <div v-if="contactList_brands.length">
                                    <vue-seamless-scroll :data="contactList_brands" :class-option="classOption2"
                                        class="vueSeamless vueSeamless2">
                                        <ul class="ul-item">
                                            <li class="li-item" v-for="(item2, index2) in contactList_brands" :key="index2" v-show="index2>(contactList_brands.length-1)/2">
                                                <div class="imgbox">
                                                    <img :src="host+item2" alt="">
                                                </div>
                                            </li>
                                        </ul>
                                    </vue-seamless-scroll>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div class="zz-right">
                        <div class="c-form">
                            <div class="c-title-box">
                                <div class="c-title font30 text_b">
                                    {{ form_text.title }}
                                </div>
                                <div class="c-tips font18">
                                    {{ form_text.desc }}
                                </div>
                            </div>

                            <div class="public-input">
                                <div class="c-div">
                                    <input type="text" v-model="name" class="font16" placeholder="姓名">
                                </div>
                            </div>

                            <div class="public-input">
                                <div class="c-div">
                                    <input type="text" v-model="come" class="font16" placeholder="公司名称">
                                </div>
                            </div>

                            <div class="public-input">
                                <div class="c-div">
                                    <input type="text" v-model="tel" class="font16" placeholder="手机号">
                                </div>
                            </div>

                            <div class="public-input2">
                                <div class="c-div">
                                    <input type="text" name="" v-model="code" class="font16" placeholder="验证码">

                                    <div class="countDown">
                                        <div class="c-p c-p-1 font16" @click="getCode" v-if="!countFlag">
                                            <span>获取验证码</span>
                                        </div>

                                        <div class="c-p c-p-2 font16" v-if="countFlag">{{ countNum }}s后重发</div>
                                    </div>
                                </div>
                            </div>

                            <div class="public-textarea">
                                <textarea placeholder="您的需求" class="font16" v-model="demand"></textarea>
                            </div>

                            <div class="c-btn" @click="submit()">
                                <span class="font18 text_b">立即提交</span>
                                <img src="../assets/images/about-img5.svg" alt="" onload="SVGInject(this)">
                            </div>

                            <div class="form-tips" v-show="formTips">
                                <div class="box1">
                                    <div class="imgbox">
                                        <img :src="host+form_success.icon" alt="">
                                    </div>
                                    <div class="c-title font30 text_b">
                                        {{ form_success.title }}
                                    </div>
                                    <div class="c-text font18">
                                        {{ form_success.desc }}
                                    </div>
                                </div>

                                <div class="box2">
                                    <div class="imgbox">
                                        <img class="bgimg" src="../assets/images/ys-379.png" alt="">
                                        <img class="c-code" :src="host+form_success.qrcode" alt="">
                                    </div>
                                    <div class="c-text font16">
                                        {{ form_success.qrdesc }}
                                    </div>
                                </div>

                            </div>

                        </div>

                    </div>

                </div>
            </div>
        </div>





    </div>
</template>
  
<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

// 这是引用wow
// import { WOW } from 'wowjs'
// 引入swiper
import Swiper from 'swiper';
import $ from 'jquery';
import 'swiper/dist/css/swiper.min.css';
// import vueSeamless from 'vue-seamless-scroll'
// import countTo from 'vue-count-to';
import '../assets/css/style.css';
import '../assets/css/proson.css';
import hostUrl from '@/util/baseUrl'

export default {
    // name: "HomeView",
    components: {
        // vueSeamless,
        // countTo
    },
    metaInfo() {
        return {
            title: this.seoInfo.title, //设置页面title
            meta: [
                {                 //设置页面meta
                    name: 'keywords',
                    content: this.seoInfo.keyWords
                },
                {
                    name: "description",
                    content: this.seoInfo.description
                },
                {
                    name: "renderer",
                    content: 'webkit',
                },
                {
                    name: "force-rendering",
                    content: 'webkit',
                },
                {
                    name: "format-detection",
                    content: 'telephone=no,telephone=no,email=no,adress=no',
                },
                {
                    name: "viewport",
                    content: 'width=device-width,user-scalable=no,initial-scale=1.0,maximum-scale=1.0,minimum-scale=1.0',
                },
            ]
        }
    },
    data() {
        return {
            // activeName: 0,
            // itemLength: 0,
            // swpActive: 0,     
            // 屏幕宽度参数
            wW: '',
            // 名企成功应用
            // listData: [
            //     {
            //         img: require('../assets/images/ys-58.png'),
            //     },
            //     {
            //         img: require('../assets/images/ys-59.png'),
            //     },
            //     {
            //         img: require('../assets/images/ys-60.png'),
            //     },
            //     {
            //         img: require('../assets/images/ys-57.png'),
            //     },
            //     {
            //         img: require('../assets/images/ys-61.png'),
            //     },
            //     {
            //         img: require('../assets/images/ys-62.png'),
            //     },
            //     {
            //         img: require('../assets/images/ys-63.png'),
            //     },
            //     {
            //         img: require('../assets/images/ys-64.png'),
            //     },
            //     {
            //         img: require('../assets/images/ys-65.png'),
            //     },
            // ],
            // listData2: [
            //     {
            //         img: require('../assets/images/ys-66.png'),
            //     },
            //     {
            //         img: require('../assets/images/ys-67.png'),
            //     },
            //     {
            //         img: require('../assets/images/ys-68.png'),
            //     },
            //     {
            //         img: require('../assets/images/ys-69.png'),
            //     },
            //     {
            //         img: require('../assets/images/ys-70.png'),
            //     },
            //     {
            //         img: require('../assets/images/ys-71.png'),
            //     },
            //     {
            //         img: require('../assets/images/ys-72.png'),
            //     },
            //     {
            //         img: require('../assets/images/ys-73.png'),
            //     },
            //     {
            //         img: require('../assets/images/ys-74.png'),
            //     },
            // ],
            // 表单
            name: '',
            come: '',
            tel: '',
            code: '',
            demand: '',
            countNum: 60,
            countFlag: false,
            verify: '',
            formTips: false,

             // 接口数据
             host: hostUrl,
             contactList:[],
             contactList_cons:[],
             contactList_brands:[],
             form_success:{},
             form_text:{},
            // seo信息
            seoInfo: {
                title: '',
                keyWords: '',
                description: ''
            },
            // 全局seo
            seoAllInfo: {},
        }
    },

    mounted() {

        this.getData()
        
        this.$bus.$on("headShow"); //二级导航浮动

        this.wW = document.documentElement.clientWidth || document.body.clientWidth //获取浏览器宽度

        this.seoAllInfo = JSON.parse(localStorage.getItem('yuanshu_seoInfo')) //获取seo全局信息

        this.$nextTick(() => {
            // 元数简介轮播
            // this.wW = document.documentElement.clientWidth; //获取浏览器宽度
            // console.log(this.wW)

            // 轮播图pc端禁止滑动
            // if (this.wW > 770) {
            //     // $('.about1 .swiper-container').addClass('swiper-no-swiping')
            //     this.swpNot = true
            // }


        })



        // wowjs
        this.$nextTick(() => {
            // var wow = new WOW({
            //   boxClass: 'wow',
            //   animateClass: 'animated',
            //   // offset: 40,
            //   // mobile: true,
            //   live: false,
            // })
            // wow.init()
            // new WOW().init()
            new this.$wow.WOW({
                live: false,
            }).init()
        })

        window.addEventListener('scroll', this.handleScroll)

        // 百度统计代码
        var _hmt = _hmt || [];
        (function () {
            var hm = document.createElement("script");
            hm.src = "https://hm.baidu.com/hm.js?77637ce1a84bee069c1effb2174121bb";
            var s = document.getElementsByTagName("script")[0];
            s.parentNode.insertBefore(hm, s);
        })();

    },
    computed: {
        classOption1() {
            return {
                step: 1,
                limitMoveNum: 1,
                direction: 2,
                openWatch: true, // 开启数据实时监控刷新dom
                // singleHeight: 40, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                // singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                // waitTime: 2000, // 单步运动停止的时间(默认值1000ms)
            }
        },
        classOption2() {
            return {
                step: 1,
                limitMoveNum: 1,
                direction: 3,
                openWatch: true, // 开启数据实时监控刷新dom
                // singleHeight: 40, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
                // singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
                // waitTime: 2000, // 单步运动停止的时间(默认值1000ms)
            }
        },

    },
    destroyed() {
        // 离开页面时取消二级导航浮动
        // this.$bus.$off("headShow");
    },
    methods: {
        // 请求后端接口
        getData() {
            

            console.log(this.$route.query, '路由id')
         
            this.contactList=[]
             this.contactList_cons=[]
             this.contactList_brands=[]
             this.form_success={}
             this.form_text={}
            this.seoInfo={
                title:'',
                keyWords:'',
                description:''
            }

            // 获取文章详情
            this.$axios.get('/api/page/14').then(res => {

                // seo信息
                if (res.data.data.name != '' && res.data.data.name != null && res.data.data.name != undefined) {
                    this.seoInfo.title = res.data.data.name
                }else{
                    this.seoInfo.title=this.seoAllInfo.title
                }

                if (res.data.data.seoKeyword != '' && res.data.data.seoKeyword != null && res.data.data.seoKeyword != undefined) {
                    this.seoInfo.keyWords = res.data.data.seoKeyword
                }else{
                    this.seoInfo.keyWords=this.seoAllInfo.keyWords
                }

                if (res.data.data.seoDesc != '' && res.data.data.seoDesc != null && res.data.data.seoDesc != undefined) {
                    this.seoInfo.description = res.data.data.seoDesc
                }else{
                    this.seoInfo.description=this.seoAllInfo.description
                }

                this.contactList=res.data.data.blocksObj
                this.contactList_cons=this.contactList.content.split('\n')
                this.contactList_brands=this.contactList.brands.split(',')
                this.form_success=this.contactList.success
                this.form_text=this.contactList.form
                // console.log(res.data.data)
                
                this.$nextTick(() => {

                    // wow动画
                    new this.$wow.WOW({
                        live: false,
                    }).init()


                })
            })



        },
        toast() {
            this.$toast.show('页面建设中，请耐心等待…', '您可通过电话或微信扫描底部二维码联系我们', 5000)
        },
        handleScroll() {
            // this.$nextTick(() => {
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            // console.log(scrollTop, this.about1Top)

            // })
        },
        getCode() {

            var t = /^((13|14|15|16|17|18|19)+\d{9})$/;
            if (this.tel == '') {
                this.$toast.show('手机号不能为空', '', 3000)
                return
            }
            if (!t.test(this.tel)) {
                this.$toast.show('手机号不正确', '', 3000)
                return
            }
            this.countFlag = !this.countFlag
            this.countDown2()
            this.$axios.get('/api/send_verify', {
                params: {
                    mobile: this.tel,
                }
            }).then(res => {
                this.verify = res.data.msg
                this.$toast.show('验证码已发送', '', 3000)
                console.log(this.verify, 'code')
            }).catch(error => {
                this.$toast.show('请求发送超时,请刷新页面重试', '', 3000)
            })
        },
        countDown2() {
            this.countNum = 60

            var timers = setInterval(() => {
                this.countNum -= 1
                if (this.countNum == 0) {
                    clearInterval(timers)
                    this.countFlag = !this.countFlag
                }
            }, 1000)
        },
        submit() {
            var t = /^((13|14|15|16|17|18|19)+\d{9})$/;
            if (this.name == '') {
                this.$toast.show('姓名不能为空', '', 3000)
                return
            }
            if (this.come == '') {
                this.$toast.show('公司名称不能为空', '', 3000)
                return
            }
            if (this.tel == '') {
                this.$toast.show('手机号不能为空', '', 3000)
                return
            }
            if (!t.test(this.tel)) {
                this.$toast.show('手机号不正确', '', 3000)
                return
            }
            if (this.code == '') {
                this.$toast.show('验证码不能为空', '', 3000)
                return
            }
            if (this.verify == '') {
                this.$toast.show('请先获取验证码', '', 3000)
                return
            }

            var data = {
                name: this.name,
                company: this.come,
                mobile: this.tel,
                code: this.code,
                verify: this.verify,
                demand: this.demand,
            }
            this.$axios.post('/api/apply_trial', 
            data,{
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }).then(res => {
                console.log(res.data,'test')
                if (res.data.code == 200) {
                    this.formTips = true
                    this.name = this.come = this.tel = this.code = this.demand = ''
                }else{
                    this.$toast.show('提交失败,请刷新页面重试', '', 3000)
                }

            })



        }

    },
};
</script>
  
<style scoped lang="less">

.vueSeamless-box {
    margin-top: 20px;
    position: relative;

    &::before {
        content: unset;
        width: 30.7%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        background: unset;
        z-index: 1;
        background-size: cover;

        @media (max-width: 767px) {
            content: unset;
        }
    }

    &::after {
        content: unset;
        width: 30.7%;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        background: unset;
        z-index: 1;
        background-size: cover;

        @media (max-width: 767px) {
            content: unset;
        }
    }
}

.vueSeamless {
    width: 100%;
    margin: 0 auto;
    overflow: hidden;

    ul {
        list-style: none;
        padding: 0;
        margin: 0 auto;

        &.ul-item {
            display: flex;

            .li-item {
                // .zz_imgpdh(13.75%,7.05%);
                position: relative;
                width: 130px;
                height: 75px;
                border-radius: 10px;

                @media (max-width:1366px) {
                    width: 120px;
                    height: 65px;
                }

                .imgbox {
                    box-shadow: 0 5px 10px rgba(110, 151, 232, 0.1);
                    // margin: -40px 0;
                    // padding: 40px 0;
                    height: 83.33%;
                    width: 93.02%;

                    border-radius: 10px;
                }

                img {
                    width: 100%;
                    height: 100%;
                    // object-fit: cover;    
                    border-radius: 10px;
                    border: 1px solid #fff;
                }
            }
        }
    }

    &.vueSeamless1 {
        margin-bottom: 0rem;
    }

    &.vueSeamless2 {

        .ul-item {}

    }
}

@media (max-width: 767px) {
    .vueSeamless ul.ul-item .li-item {
        width: 2.4rem;
        height: 1.3rem;
        // width: 2.6rem;
        // height: 1.5rem;
    }

    .vueSeamless-box {
        margin-top: 0.6rem;
    }
}
</style>