<template>
    <div class="trends">

        <!-- 内页顶部banner -->
        <div class="public-nybn about-bn">
            <div class="bgimg scaleDraw" v-if="banner.image">
                <img :src="host + banner.image" alt="">
            </div>
            <div class="zz-box">

                <div class="bn-title fadeInUp2">
                    <div class="container">
                        <div class="c-title">
                            <h2 class="h2 font36 text_b">{{ banner.title }}</h2>
                            <div class="syb"></div>
                        </div>
                        <div class="font40 text_b c-text pre"><span>{{ banner.text }}</span></div>

                    </div>
                </div>


            </div>
        </div>

        <div id="pageOffset"></div>
        <div class="public-crumbs" :class="crumbsFlag ? 'active' : ''" id="public-crumbs">
            <div class="container">
                <div class="c-box">

                    <h3 class="c-a font18" :class="item.on ? 'on' : ''" @click="crumbsFn(index)"
                        v-for="(item, index) in crumbs" :key="index">
                        <router-link v-if="item.url" :to="item.url">
                            {{ item.name }}
                        </router-link>
                    </h3>

                    <!-- <h3 class="c-a font18">
                        <router-link to="/brand">
                            品牌故事
                        </router-link>
                    </h3>

                    <h3 class="c-a font18 on">
                        <router-link to="/trends">
                            公司动态
                        </router-link>
                    </h3>

                    <h3 class="c-a font18">
                        <router-link to="/team">
                            专家团队
                        </router-link>
                    </h3> -->

                    <!-- <h3 class="c-a font18">
                        <router-link to="/join">
                            加入我们
                        </router-link>
                    </h3> -->

                </div>
            </div>
        </div>

        <!-- 公司动态 -->
        <div class="trends1 pdtb">
            <div class="container">
                <!-- <div v-show="newsList == ''" class="font16" style="text-align: center;margin-top: 0.8rem;color: #666;">
                    没有相关信息
                </div> -->

                <div v-show="newsList==''" class="notInfoBox">
                        <img class="c-img" src="../assets/images/ys-401.png">
                        <span class="font16 c-span">暂无相关信息，敬请期待！</span>
                </div>

                <ul class="c-ul" v-show="newsList != ''">

                    <li class="c-li wow fadeInUp" v-for="(item, index) in newsList" :key="index">
                        <router-link :target="item.target" :to="'newsdetail?id=' + item.id + '&listid=' + id" class="c-a">
                            <div class="imgbox">
                                <!-- <img src="../assets/images/about-img2.jpg" alt=""> -->
                                <img v-if="item.cover" :src="host + item.cover" alt="图片加载出错,请刷新">
                            </div>
                            <div class="c-cont">
                                <div class="c-box">
                                    <div class="c-date font16">
                                        {{ fixDate(item.time) }}
                                    </div>
                                    <div class="c-title font24 text_b">
                                        {{ item.title }}
                                    </div>

                                </div>
                                <div class="c-div">
                                    <p class="cn9">
                                        <span class="cn4 font16 font-b">查看详情</span>
                                        <span class="cn25"><img class="hi-ico" src="../assets/images/about-img5.svg"
                                                onload="SVGInject(this)">
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </router-link>
                    </li>

                    <!-- <li class="c-li wow fadeInUp">
                        <router-link to="newsdetail?id=0" class="c-a">
                            <div class="imgbox">
                                <img src="../assets/images/about-img20.png" alt="">
                            </div>
                            <div class="c-cont">
                                <div class="c-box">
                                    <div class="c-date font16">
                                        2023.03.23
                                    </div>
                                    <div class="c-title font24 text_b">
                                        科技让商业更美好!元数科技携创纪云3.0进化而来
                                    </div>

                                </div>
                                <div class="c-div">
                                    <p class="cn9">
                                        <span class="cn4 font16 font-b">查看详情</span>
                                        <span class="cn25"><img class="hi-ico" src="../assets/images/about-img5.svg"
                                                onload="SVGInject(this)">
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </router-link>
                    </li>

                    <li class="c-li wow fadeInUp">
                        <router-link to="newsdetail?id=0" class="c-a">
                            <div class="imgbox">
                                <img src="../assets/images/about-img21.png" alt="">
                            </div>
                            <div class="c-cont">
                                <div class="c-box">
                                    <div class="c-date font16">
                                        2023.03.23
                                    </div>
                                    <div class="c-title font24 text_b">
                                        科技让商业更美好!元数科技携创纪云3.0进化而来
                                    </div>

                                </div>
                                <div class="c-div">
                                    <p class="cn9">
                                        <span class="cn4 font16 font-b">查看详情</span>
                                        <span class="cn25"><img class="hi-ico" src="../assets/images/about-img5.svg"
                                                onload="SVGInject(this)">
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </router-link>
                    </li>

                    <li class="c-li wow fadeInUp">
                        <router-link to="newsdetail?id=0" class="c-a">
                            <div class="imgbox">
                                <img src="../assets/images/about-img2.jpg" alt="">
                            </div>
                            <div class="c-cont">
                                <div class="c-box">
                                    <div class="c-date font16">
                                        2023.03.23
                                    </div>
                                    <div class="c-title font24 text_b">
                                        科技让商业更美好!元数科技携创纪云3.0进化而来
                                    </div>

                                </div>
                                <div class="c-div">
                                    <p class="cn9">
                                        <span class="cn4 font16 font-b">查看详情</span>
                                        <span class="cn25"><img class="hi-ico" src="../assets/images/about-img5.svg"
                                                onload="SVGInject(this)">
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </router-link>
                    </li>

                    <li class="c-li wow fadeInUp">
                        <router-link to="newsdetail?id=0" class="c-a">
                            <div class="imgbox">
                                <img src="../assets/images/about-img20.png" alt="">
                            </div>
                            <div class="c-cont">
                                <div class="c-box">
                                    <div class="c-date font16">
                                        2023.03.23
                                    </div>
                                    <div class="c-title font24 text_b">
                                        科技让商业更美好!元数科技携创纪云3.0进化而来
                                    </div>

                                </div>
                                <div class="c-div">
                                    <p class="cn9">
                                        <span class="cn4 font16 font-b">查看详情</span>
                                        <span class="cn25"><img class="hi-ico" src="../assets/images/about-img5.svg"
                                                onload="SVGInject(this)">
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </router-link>
                    </li>

                    <li class="c-li wow fadeInUp">
                        <router-link to="newsdetail?id=0" class="c-a">
                            <div class="imgbox">
                                <img src="../assets/images/about-img21.png" alt="">
                            </div>
                            <div class="c-cont">
                                <div class="c-box">
                                    <div class="c-date font16">
                                        2023.03.23
                                    </div>
                                    <div class="c-title font24 text_b">
                                        科技让商业更美好!元数科技携创纪云3.0进化而来
                                    </div>

                                </div>
                                <div class="c-div">
                                    <p class="cn9">
                                        <span class="cn4 font16 font-b">查看详情</span>
                                        <span class="cn25"><img class="hi-ico" src="../assets/images/about-img5.svg"
                                                onload="SVGInject(this)">
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </router-link>
                    </li>

                    <li class="c-li wow fadeInUp">
                        <router-link to="newsdetail?id=0" class="c-a">
                            <div class="imgbox">
                                <img src="../assets/images/about-img2.jpg" alt="">
                            </div>
                            <div class="c-cont">
                                <div class="c-box">
                                    <div class="c-date font16">
                                        2023.03.23
                                    </div>
                                    <div class="c-title font24 text_b">
                                        科技让商业更美好!元数科技携创纪云3.0进化而来
                                    </div>

                                </div>
                                <div class="c-div">
                                    <p class="cn9">
                                        <span class="cn4 font16 font-b">查看详情</span>
                                        <span class="cn25"><img class="hi-ico" src="../assets/images/about-img5.svg"
                                                onload="SVGInject(this)">
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </router-link>
                    </li>

                    <li class="c-li wow fadeInUp">
                        <router-link to="newsdetail?id=0" class="c-a">
                            <div class="imgbox">
                                <img src="../assets/images/about-img20.png" alt="">
                            </div>
                            <div class="c-cont">
                                <div class="c-box">
                                    <div class="c-date font16">
                                        2023.03.23
                                    </div>
                                    <div class="c-title font24 text_b">
                                        科技让商业更美好!元数科技携创纪云3.0进化而来
                                    </div>

                                </div>
                                <div class="c-div">
                                    <p class="cn9">
                                        <span class="cn4 font16 font-b">查看详情</span>
                                        <span class="cn25"><img class="hi-ico" src="../assets/images/about-img5.svg"
                                                onload="SVGInject(this)">
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </router-link>
                    </li>

                    <li class="c-li wow fadeInUp">
                        <router-link to="newsdetail?id=0" class="c-a">
                            <div class="imgbox">
                                <img src="../assets/images/about-img21.png" alt="">
                            </div>
                            <div class="c-cont">
                                <div class="c-box">
                                    <div class="c-date font16">
                                        2023.03.23
                                    </div>
                                    <div class="c-title font24 text_b">
                                        科技让商业更美好!元数科技携创纪云3.0进化而来
                                    </div>

                                </div>
                                <div class="c-div">
                                    <p class="cn9">
                                        <span class="cn4 font16 font-b">查看详情</span>
                                        <span class="cn25"><img class="hi-ico" src="../assets/images/about-img5.svg"
                                                onload="SVGInject(this)">
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </router-link>
                    </li> -->

                </ul>

                <div class="public-page wow fadeInUp" v-show="newsList != ''">

                    <page-pagination :total="page.total" :pageSize="page.pageSize" :currentPage="page.currentPage"
                        :numAround="true" @change="change"></page-pagination>

                </div>

            </div>
        </div>



    </div>
</template>
  
<script>
// 这是引用wow
// import { WOW } from 'wowjs'
// 引入swiper
import Swiper from 'swiper';
import $ from 'jquery';
import 'swiper/dist/css/swiper.min.css';
import '../assets/css/style.css';
import pagePagination from "@/components/page-pagination/page-pagination.vue";
import hostUrl from '@/util/baseUrl'

export default {
    components: {
        pagePagination
    },
    metaInfo() {
        return {
            title: this.seoInfo.title, //设置页面title
            meta: [
                {                 //设置页面meta
                    name: 'keywords',
                    content: this.seoInfo.keyWords
                },
                {
                    name: "description",
                    content: this.seoInfo.description
                },
                {
                    name: "renderer",
                    content: 'webkit',
                },
                {
                    name: "force-rendering",
                    content: 'webkit',
                },
                {
                    name: "format-detection",
                    content: 'telephone=no,telephone=no,email=no,adress=no',
                },
                {
                    name: "viewport",
                    content: 'width=device-width,user-scalable=no,initial-scale=1.0,maximum-scale=1.0,minimum-scale=1.0',
                },
            ]
        }
    },
    data() {
        return {
            scrollFlag: false,
            // 屏幕宽度参数
            wW: '',

            // 二级导航TOp
            crumbsTop: '',
            crumbsFlag: false,
            crumbs: [],
            // 分页器
            page: {
                total: 0,
                pageSize: 6,
                currentPage: 1
            },

            // 接口数据
            host: hostUrl,
            id: 11,
            banner: [],
            newsList: [],
            // seo信息
            seoInfo: {
                title: '',
                keyWords: '',
                description: ''
            },
            // 全局seo
            seoAllInfo: {},
        }
    },

    mounted() {
        this.getData()

        this.wW = document.documentElement.clientWidth || document.body.clientWidth //获取浏览器宽度

        this.seoAllInfo = JSON.parse(localStorage.getItem('yuanshu_seoInfo')) //获取seo全局信息

        let crumbsTop = document.getElementById('public-crumbs')
        this.crumbsTop = crumbsTop.offsetTop
        this.$bus.emit("crumbsTop", crumbsTop.offsetTop)

        // wowjs
        this.$nextTick(() => {
            new this.$wow.WOW({
                live: false,
            }).init()
        })

        window.addEventListener('scroll', this.handleScroll)

        // 百度统计代码
        var _hmt = _hmt || [];
        (function () {
            var hm = document.createElement("script");
            hm.src = "https://hm.baidu.com/hm.js?77637ce1a84bee069c1effb2174121bb";
            var s = document.getElementsByTagName("script")[0];
            s.parentNode.insertBefore(hm, s);
        })();


    },
    computed: {
        // defaultOption() {
        //   return {
        //     step: 0.8,
        //     hoverStop: true, // 是否开启鼠标悬停stop
        //     direction: 2, // 0向下 1向上 2向左 3向右
        //     openWatch: false, // 开启数据实时监控刷新dom
        //     // singleHeight: 40, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        //     // singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        //     // waitTime: 2000, // 单步运动停止的时间(默认值1000ms)
        //   }
        // }

    },
    destroyed() {
        // 离开页面时取消二级导航浮动        
        // this.$bus.$off("headShow");
    },
    activated() {
        this.crumbsFn()
    },
    methods: {
        // 请求后端接口
        getData() {

            console.log(this.$route.query, '路由id')
            this.banner = []
            this.newsList = []
            this.seoInfo = {
                title: '',
                keyWords: '',
                description: ''
            }

            let id = this.id

            // banner描述
            this.$axios.get('/api/page/' + id).then(res => {
                // seo信息
                if (res.data.data.name != '' && res.data.data.name != null && res.data.data.name != undefined) {
                    this.seoInfo.title = res.data.data.name
                }else{
                    this.seoInfo.title=this.seoAllInfo.title
                }

                if (res.data.data.seoKeyword != '' && res.data.data.seoKeyword != null && res.data.data.seoKeyword != undefined) {
                    this.seoInfo.keyWords = res.data.data.seoKeyword
                }else{
                    this.seoInfo.keyWords=this.seoAllInfo.keyWords
                }

                if (res.data.data.seoDesc != '' && res.data.data.seoDesc != null && res.data.data.seoDesc != undefined) {
                    this.seoInfo.description = res.data.data.seoDesc
                }else{
                    this.seoInfo.description=this.seoAllInfo.description
                }

                if (res.data.data.blocksObj.banner[0] != '') {
                    this.banner = res.data.data.blocksObj.banner[0]
                }

                // console.log(this.screen,'test')

                this.$nextTick(() => {

                    setTimeout(() => {

                    }, 0)

                    // wow动画
                    new this.$wow.WOW({
                        live: false,
                    }).init()


                })
            })

            // 列表和分页
            this.$axios.get('/api/content/' + id, {
                params: {
                    pageSize: this.page.pageSize,
                }
            }).then(res => {
                this.newsList = res.data.rows
                this.page.total = res.data.total
            })

            // 二级导航
            this.$axios.get('/api/menu/66').then(res => {
                var crumbsTemp = res.data.data
                for (var i = 0; i < crumbsTemp.length; i++) {
                    crumbsTemp[i].on = false
                }
                this.crumbs = crumbsTemp
                // console.log(this.crumbs,'crumbs')
                this.crumbsFn(this.id)
            })

        },
        toast() {
            this.$toast.show('页面建设中，请耐心等待…', '您可通过电话或微信扫描底部二维码联系我们', 5000)
        },
        handleScroll() {
            // this.$nextTick(() => {
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop

            // 二级导航浮动
            var that = this

            setTimeout(function () {
                if (scrollTop >= that.crumbsTop) {
                    that.crumbsFlag = true
                } else {
                    that.crumbsFlag = false
                }
            }, 50)



            // })
        },
        // 分页器
        change(currentPage, type) {
            let id = this.id
            let pageNum = currentPage
            let keyword = this.keyword
            this.page.currentPage = currentPage;
            // console.log("点击了" + type + "，当前页：" + currentPage);
            console.log(currentPage, type)
            this.newsList = []
            this.$axios.get('/api/content/' + id, {
                params: {
                    pageNum: pageNum,
                    pageSize: this.page.pageSize,
                }
            }).then(res => {
                this.newsList = res.data.rows
                // document.documentElement.scrollTop = 0
                document.documentElement.scrollTop = document.getElementById('pageOffset').offsetTop
            })
        },
        fixDate(x) {
            if (x == undefined) {
                return
            }

            x = x.replace(/-/g, '.')
            return x
        },
        // 二级导航切换
        crumbsFn() {
            let urlPath = this.$route.path.replace(/<[^>]+>/g, '')
            for (var i = 0; i < this.crumbs.length; i++) {
                this.crumbs[i].on = false
                if (this.crumbs[i].url == urlPath) {
                    this.$set(this.crumbs[i], 'on', true)
                }

            }
        },

    },
};
</script>
  
<style scoped lang="less"></style>