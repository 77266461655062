import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
// import './assets/css/style.css';
// import './assets/js/jquery.js';
import './assets/js/svg-inject.min.js';


import wow from 'wowjs'
Vue.prototype.$wow=wow

// 跑马灯
import vueSeamlessScroll from 'vue-seamless-scroll'
Vue.use(vueSeamlessScroll)

// toast提示
import toast from './components/Toast/index.js'
Vue.use(toast) 

// 数字跳到
import VueAnimateNumber from 'vue-animate-number'
Vue.use(VueAnimateNumber)

// 传值
import VueBus from 'vue-bus'
Vue.use(VueBus)

// 头部seo信息
import MetaInfo from 'vue-meta-info'
Vue.use(MetaInfo)

// import VueLazyload from 'vue-lazyload'
// Vue.use(VueLazyload) 

//将axios绑定到vue的原型上
Vue.prototype.$axios = axios;
// Vue.prototype.$axios.defaults.baseURL = 'https://21.mdxcx.net'
Vue.prototype.$axios.defaults.baseURL = 'https://sysdev.metabizcent.com'


// 是否开启生成环境;
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");



// 这是wow.js的引用
// import animated from 'animate.css'
// import 'animate.css'
import 'wowjs/css/libs/animate.css'
// Vue.use(animated)