<template>
    <div class="customerDetail">

        <div class="customerDetail-bn ">
            <div class="imgbox scaleDraw" v-if="newsDetail.banner">
                <img :src="host+newsDetail.banner" alt="">
            </div>
        </div>

        <div class="customerDetail1">
            <div class="container">
                <div class="c-box">
                    <img class="c-icon" src="../assets/images/ys-55.png" alt="">
                    <div class="box1">
                        <div class="imgbox">
                            <img :src="host+newsDetail.logo" alt="">
                        </div>
                        
                    </div>
                    <div class="box2">
                        <div class="c-text font20 text_b pre">{{ newsDetail.desc }}</div>
                        <router-link v-if="btnInfo.link" :to="btnInfo.link" class="hi-btn1">
                            <span class="hi-text font16">{{ btnInfo.title }}</span>
                            <img class="hi-ico" src="../assets/images/hi-btn-arrow1.svg" onload="SVGInject(this)">
                        </router-link>
                    </div>
                </div>

            </div>
        </div>

        <div class="newsDetail1 customerDetail2 pdtb">
            <div class="container">
                <div class="zz-box" :class="newsRelated==''?'on':''">
                    <div class="zz-left wow fadeInUp">

                        <div class="zz-text font18 ql-editor" v-html="newsDetail.content"></div>

                        <div class="c-tag" v-show="newsDetail.tags!='' || newsDetail.tags2!=''">
                            <div class="c-div font18">标签：</div>
                            <ul>
                                <li v-if="newsDetail.tags">
                                    <router-link v-if="newsDetail.tags" :to="'/customer?tags='+newsDetail.tags" class="font14 c-a">{{ newsDetail.tags }}</router-link>
                                </li>
                                <li v-if="newsDetail.tags2">
                                    <router-link v-if="newsDetail.tags2" :to="'/customer?tags2='+newsDetail.tags2" class="font14 c-a">{{ newsDetail.tags2 }}</router-link>
                                </li>
                            </ul>

                        </div>

                        <div class="c-atBtnBox">
                            <div class="div-1 c-btn" v-if="newsPrev!=null"><router-link class="font16 c-a" :to="{path:'/customerdetail',query:{id:newsPrev.id}}"><img
                                        class="hi-ico" src="../assets/images/about-img5.svg"
                                        onload="SVGInject(this)">上一篇</router-link>
                            </div>

                            <div class="div-3 c-btn" v-if="newsNext!=null"><router-link class="font16 c-a" :to="{path:'/customerdetail',query:{id:newsNext.id}}">下一篇<img
                                        class="hi-ico" src="../assets/images/about-img5.svg"
                                        onload="SVGInject(this)"></router-link></div>
                        </div>



                    </div>

                    <div class="zz-right wow fadeInUp" v-show="newsRelated!=''">
                        <div class="newsNavBox">
                            <div class="zz-title font30 text_b">相关案例</div>
                            <ul class="c-ul">
                                <li class="c-li" v-for="(item,index) in newsRelated" :key="index">
                                    <router-link @click.native="goTop(item.target)" :target="item.target" :to="{path:'/customerdetail',query:{id:item.id}}">
                                        <div class="font24 zz-t text_b">
                                            {{ item.name }}
                                        </div>
                                        <div class="zz-c font16">
                                            {{ item.subName }}
                                        </div>
                                    </router-link>
                                </li>

                                <!-- <li class="c-li">
                                    <router-link to="/customerdetail?id=0">
                                        <div class="font24 zz-t text_b">
                                            华润苏果
                                        </div>
                                        <div class="zz-c font16">
                                            疫情下全年营收超200亿，数字化营销思维运营显成效！
                                        </div>
                                    </router-link>
                                </li>

                                <li class="c-li">
                                    <router-link to="/customerdetail?id=0">
                                        <div class="font24 zz-t text_b">
                                            云南白药
                                        </div>
                                        <div class="zz-c font16">
                                            8天GMV突破380W！可复制的海外购打法，值得抄作业！
                                        </div>
                                    </router-link>
                                </li>

                                <li class="c-li">
                                    <router-link to="/customerdetail?id=0">
                                        <div class="font24 zz-t text_b">
                                            华润苏果
                                        </div>
                                        <div class="zz-c font16">
                                            疫情下全年营收超200亿，数字化营销思维运营显成效！
                                        </div>
                                    </router-link>
                                </li> -->

                            </ul>
                        </div>

                    </div>

                </div>

            </div>
        </div>




    </div>
</template>
  
<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

// 这是引用wow
// import { WOW } from 'wowjs'
// 引入swiper
import Swiper from 'swiper';
import $ from 'jquery';
import 'swiper/dist/css/swiper.min.css';
// import vueSeamless from 'vue-seamless-scroll'
// import countTo from 'vue-count-to';
import '../assets/css/style.css';
import '../assets/css/quill.bubble.css'; //富文本样式
import '../assets/css/quill.core.css';
import '../assets/css/quill.snow.css';
import hostUrl from '@/util/baseUrl'


export default {
    // name: "HomeView",
    components: {
        // vueSeamless,
        // countTo
    },
    metaInfo() {
        return {
            title: this.seoInfo.title, //设置页面title
            meta: [
                {                 //设置页面meta
                    name: 'keywords',
                    content: this.seoInfo.keyWords
                },
                {
                    name: "description",
                    content: this.seoInfo.description
                },
                {
                    name: "renderer",
                    content: 'webkit',
                },
                {
                    name: "force-rendering",
                    content: 'webkit',
                },
                {
                    name: "format-detection",
                    content: 'telephone=no,telephone=no,email=no,adress=no',
                },
                {
                    name: "viewport",
                    content: 'width=device-width,user-scalable=no,initial-scale=1.0,maximum-scale=1.0,minimum-scale=1.0',
                },
            ]
        }
    },
    data() {
        return {
            // activeName: 0,
            // itemLength: 0,
            // swpActive: 0,     
            // 屏幕宽度参数
            wW: '',
            // 二级导航TOp
            crumbsTop: '',
            crumbsFlag: false,
            fixHead: false,
            // swpNot: false, //禁止轮播滑动

            // 接口数据
            host: hostUrl,
            id:'',
            banner:[],
            screen1:[],
            screen2:[],
            newsDetail:'',
            newsPrev:'',
            newsNext:'',
            newsList:'',
            newsRelated:'', //相关案例
            btnInfo:{},
            // seo信息
            seoInfo: {
                title: '',
                keyWords: '',
                description: ''
            },
            // 全局seo
            seoAllInfo: {},
        }
    },

    mounted() {

        this.getData()

        this.wW = document.documentElement.clientWidth || document.body.clientWidth //获取浏览器宽度

        this.seoAllInfo = JSON.parse(localStorage.getItem('yuanshu_seoInfo')) //获取seo全局信息

        this.$nextTick(() => {
            // 元数简介轮播
            // this.wW = document.documentElement.clientWidth; //获取浏览器宽度
            // console.log(this.wW)

            // 轮播图pc端禁止滑动
            // if (this.wW > 770) {
            //     // $('.about1 .swiper-container').addClass('swiper-no-swiping')
            //     this.swpNot = true
            // }
        })


        // wowjs
        this.$nextTick(() => {
            // var wow = new WOW({
            //   boxClass: 'wow',
            //   animateClass: 'animated',
            //   // offset: 40,
            //   // mobile: true,
            //   live: false,
            // })
            // wow.init()
            // new WOW().init()
            new this.$wow.WOW({
                live: false,
            }).init()
        })

        window.addEventListener('scroll', this.handleScroll)

        // 百度统计代码
        var _hmt = _hmt || [];
        (function () {
            var hm = document.createElement("script");
            hm.src = "https://hm.baidu.com/hm.js?77637ce1a84bee069c1effb2174121bb";
            var s = document.getElementsByTagName("script")[0];
            s.parentNode.insertBefore(hm, s);
        })();

    },
    computed: {
        // defaultOption() {
        //   return {
        //     step: 0.8,
        //     hoverStop: true, // 是否开启鼠标悬停stop
        //     direction: 2, // 0向下 1向上 2向左 3向右
        //     openWatch: false, // 开启数据实时监控刷新dom
        //     // singleHeight: 40, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        //     // singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        //     // waitTime: 2000, // 单步运动停止的时间(默认值1000ms)
        //   }
        // }

    },
    methods: {
        // 请求后端接口
        getData() {
            this.keyword=''
            this.banner=[]
            this.screen1=[]
            this.screen2=[]
            this.newsDetail=''
            this.newsPrev=''
            this.newsNext=''
            this.newsList=''
            this.newsRelated=[]

            this.seoInfo={
                title:'',
                keyWords:'',
                description:''
            }
   
            let id = this.$route.query.id

            this.$axios.get('/api/page/2').then(res => {
                console.log(res,'test')
                this.btnInfo=res.data.data.blocksObj.btn
            })
           
            // 列表和分页
            this.$axios.get('/api/case?id='+id,{
                
            }).then(res => {

                    // seo信息
                if (res.data.rows[0].name != '' && res.data.rows[0].name != null && res.data.rows[0].name != undefined) {
                    this.seoInfo.title = res.data.rows[0].name
                }else{
                    this.seoInfo.title=this.seoAllInfo.title
                }

                if (res.data.rows[0].seoKeyword != '' && res.data.rows[0].seoKeyword != null && res.data.rows[0].seoKeyword != undefined) {
                    this.seoInfo.keyWords = res.data.rows[0].seoKeyword
                }else{
                    this.seoInfo.keyWords=this.seoAllInfo.keyWords
                }

                if (res.data.rows[0].seoDesc != '' && res.data.rows[0].seoDesc != null && res.data.rows[0].seoDesc != undefined) {
                    this.seoInfo.description = res.data.rows[0].seoDesc
                }else{
                    this.seoInfo.description=this.seoAllInfo.description
                }
                

                    this.newsDetail=res.data.rows[0]
                    this.newsPrev=res.data.rows[1]
                    this.newsNext=res.data.rows[2]
                    this.newsList=res.data.rows
                    for(var i=0;i<this.newsList.length;i++){
                        if(i>2 && i!=null){
                            this.newsRelated.push( this.newsList[i] )
                        }
                    }
                    console.log(this.newsDetail,'newsDetail')
            })

        },
        toast() {
            this.$toast.show('页面建设中，请耐心等待…', '您可通过电话或微信扫描底部二维码联系我们', 5000)
        },
        handleScroll() {
            // this.$nextTick(() => {
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            // console.log(scrollTop, this.about1Top)

            // })
        },
        // 回到顶部
        goTop(x){            
            document.documentElement.scrollTop=0
            document.body.scrollTop=0
        }


    },
};
</script>
  
<style></style>