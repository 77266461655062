<template>
    <div id="app">
        <nav>
            <div id="c-header" v-show="headShow" :class="switchFlag ? 'c-open' : ''">
                <div class="container">

                    <!-- 顶部logo -->
                    <div class="c-logo" title="logo">
                        <router-link to="/">
                            <div class="c-img-box">
                                <img class="logo1" v-if="setting.logo" :src="hostUrl + setting.logo" alt="logo">
                                <img class="logo2" v-if="setting.logo" :src="hostUrl + setting.logo" alt="logo">
                            </div>
                            <span class="font20 text_b">{{ setting.logoname }}</span>
                        </router-link>
                    </div>

                    <!-- 右侧 -->
                    <div class="c-right-box">
                        <!-- 顶部pc导航 -->
                        <ul class="c-nav">
                            <li class="" v-for="(item1, index1) in header" :key="index1">
                                <div class="c-title-box">

                                    <router-link v-if="item1.url" :target="item1.target" :to="item1.url" class="font18">{{ item1.name
                                    }}</router-link>                                    
                                    <img v-show="item1.child.length > 0" src="./assets/images/ico-arrow.svg" alt=""
                                        onload="SVGInject(this)">
                                </div>

                                <div v-if="item1.child.length > 0 && (index1 > 0 && index1 < 3)"
                                    :id="'drop-downBox' + index1" class="drop-downBox" :class="[item1.icon!=null&&item1.icon!=''&&item1.icon!='-'?'drop-downBox-type'+item1.icon:'']">
                                    <div class="drop-down">
                                        <div class="drop-box">
                                            <div class="dul1">
                                                <img class="bgimg" src="./assets/images/ys-301.png" alt="">
                                                <img class="bgimg2" src="./assets/images/ys-307.png" alt="">
                                                <div class="dli1" v-for="(item2, index2) in item1.child" :key="index2">

                                                    <div class="d-left">
                                                        <router-link v-if="item2.url" :target="item2.target" :to="item2.url" class="da2-a">
                                                            <div class="c-icon"><img :src="hostUrl + item2.icon" alt="">
                                                            </div>
                                                            <div class="font22 text_b c-title">{{ item2.name }}</div>
                                                        </router-link>
                                                        <div v-else class="da2-a on">
                                                            <div class="c-icon"><img :src="hostUrl + item2.icon" alt="">
                                                            </div>
                                                            <div class="font22 text_b c-title">{{ item2.name }}</div>
                                                        </div>
                                                    </div>
                                                    <div class="d-right">

                                                        <div class="dul2">
                                                            <div class="dscroll">

                                                                <div class="dli2" v-for="(item3, index3) in item2.child"
                                                                    :key="index3">
                                                                    <router-link v-if="item3.url" :target="item3.target" :to="item3.url"
                                                                        class="da2-a">

                                                                        <div class="da2-box2">
                                                                            <div class="c-title-box">
                                                                                <div class="box">
                                                                                    <img class="da2-icon"
                                                                                        :src="hostUrl + item3.icon" alt="">
                                                                                    <div class="da2-title font18">
                                                                                        {{ item3.name }}
                                                                                    </div>
                                                                                </div>
                                                                                <div class="c-icon">
                                                                                    <img src="./assets/images/ys-298.svg"
                                                                                        alt="">
                                                                                </div>
                                                                            </div>

                                                                            <div class="c-text font14">
                                                                                {{ item3.subName }}
                                                                            </div>

                                                                        </div>

                                                                    </router-link>
                                                                </div>


                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="item1.child.length > 0 && index1 > 3" :id="'drop-downBox' + index1"
                                    class="drop-downBox2">

                                    <div class="drop-down">
                                        <img class="bgimg" src="./assets/images/ys-321.png" alt="">
                                        <div class="drop-box">
                                            <div class="dul">
                                                <div class="dli" v-for="(item2, index2) in item1.child" :key="index2">

                                                    <!-- <router-link :target="item2.target" v-if="item2.url" :to="item2.url"
                                                        class="da2-a">
                                                        <div class="box1">
                                                            <div class="c-icon"><img :src="hostUrl + item2.icon" alt="">
                                                            </div>
                                                            <div class="font18 c-title">{{ item2.name }}</div>
                                                        </div>
                                                        <div class="box2">
                                                            <img src="./assets/images/ys-320.svg" alt=""
                                                                onload="SVGInject(this)">
                                                        </div>
                                                    </router-link> -->

                                                  
                                                        <router-link :target="item2.target" v-if="!isExternal(item2.url) && item2.url" :to="item2.url" class="da2-a">                                               
                                                            <div class="box1">
                                                                <div class="c-icon"><img :src="hostUrl + item2.icon" alt="">
                                                                </div>
                                                                <div class="font18 c-title">{{ item2.name }}</div>
                                                            </div>
                                                            <div class="box2">
                                                                <img src="./assets/images/ys-320.svg" alt=""
                                                                    onload="SVGInject(this)">
                                                            </div>
                                                        </router-link>

                                                        <a class="da2-a" :href="item2.url" rel="nofollow" v-else-if="item2.url">
                                                            <div class="box1">
                                                                <div class="c-icon"><img :src="hostUrl + item2.icon" alt="">
                                                                </div>
                                                                <div class="font18 c-title">{{ item2.name }}</div>
                                                            </div>
                                                            <div class="box2">
                                                                <img src="./assets/images/ys-320.svg" alt=""
                                                                    onload="SVGInject(this)">
                                                            </div>
                                                        </a>
                                                 

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </li>

                            <!-- <li class="">
                                <div class="c-title-box">
                                    <router-link to="" rel="nofollow" class="font18">产品</router-link>
                                    <img src="./assets/images/ico-arrow.svg" alt="" onload="SVGInject(this)">
                                </div>

                                <div id="drop-downBox1" class="drop-downBox">
                                    <div class="drop-down">
                                        <div class="drop-box">
                                            <div class="dul1">
                                                <img class="bgimg" src="./assets/images/ys-301.png" alt="">
                                                <img class="bgimg2" src="./assets/images/ys-307.png" alt="">
                                                <div class="dli1">

                                                    <div class="d-left">
                                                        <router-link to="/product1" class="da2-a" @click.native="refresh()">
                                                            <div class="c-icon"><img src="./assets/images/ys-296.png"
                                                                    alt="">
                                                            </div>
                                                            <div class="font22 text_b c-title">零售云</div>
                                                        </router-link>
                                                    </div>
                                                    <div class="d-right">

                                                        <div class="dul2">
                                                            <div class="dscroll">

                                                                <div class="dli2">
                                                                    <router-link to="" class="da2-a"
                                                                        @click.native="toast()">

                                                                        <div class="da2-box2">
                                                                            <div class="c-title-box">
                                                                                <div class="box">
                                                                                    <img class="da2-icon"
                                                                                        src="./assets/images/ys-16.svg"
                                                                                        alt="">
                                                                                    <div class="da2-title font18">
                                                                                        数智零售
                                                                                    </div>
                                                                                </div>
                                                                                <div class="c-icon">
                                                                                    <img src="./assets/images/ys-298.svg"
                                                                                        alt="">
                                                                                </div>
                                                                            </div>

                                                                            <div class="c-text font14">
                                                                                全触点|全渠道|全场景的销售资源协同管理平台
                                                                            </div>

                                                                        </div>

                                                                    </router-link>
                                                                </div>

                                                                <div class="dli2">
                                                                    <router-link to="" class="da2-a"
                                                                        @click.native="toast()">

                                                                        <div class="da2-box2">
                                                                            <div class="c-title-box">
                                                                                <div class="box">
                                                                                    <img class="da2-icon"
                                                                                        src="./assets/images/ys-18.svg"
                                                                                        alt="">
                                                                                    <div class="da2-title font18">
                                                                                        数智会员
                                                                                    </div>
                                                                                </div>
                                                                                <div class="c-icon">
                                                                                    <img src="./assets/images/ys-298.svg"
                                                                                        alt="">
                                                                                </div>
                                                                            </div>

                                                                            <div class="c-text font14">
                                                                                全渠道会员经营与忠诚度管理体系
                                                                            </div>

                                                                        </div>

                                                                    </router-link>
                                                                </div>

                                                                <div class="dli2">
                                                                    <router-link to="" class="da2-a"
                                                                        @click.native="toast()">

                                                                        <div class="da2-box2">
                                                                            <div class="c-title-box">
                                                                                <div class="box">
                                                                                    <img class="da2-icon"
                                                                                        src="./assets/images/ys-19.svg"
                                                                                        alt="">
                                                                                    <div class="da2-title font18">
                                                                                        数智门店
                                                                                    </div>
                                                                                </div>
                                                                                <div class="c-icon">
                                                                                    <img src="./assets/images/ys-298.svg"
                                                                                        alt="">
                                                                                </div>
                                                                            </div>

                                                                            <div class="c-text font14">
                                                                                构建数字化门店，以“智”取胜
                                                                            </div>

                                                                        </div>

                                                                    </router-link>
                                                                </div>

                                                                <div class="dli2">
                                                                    <router-link to="" class="da2-a"
                                                                        @click.native="toast()">

                                                                        <div class="da2-box2">
                                                                            <div class="c-title-box">
                                                                                <div class="box">
                                                                                    <img class="da2-icon"
                                                                                        src="./assets/images/ys-20.svg"
                                                                                        alt="">
                                                                                    <div class="da2-title font18">
                                                                                        数智员工
                                                                                    </div>
                                                                                </div>
                                                                                <div class="c-icon">
                                                                                    <img src="./assets/images/ys-298.svg"
                                                                                        alt="">
                                                                                </div>
                                                                            </div>

                                                                            <div class="c-text font14">
                                                                                激活员工效率，助力员工成长
                                                                            </div>

                                                                        </div>

                                                                    </router-link>
                                                                </div>

                                                                <div class="dli2">
                                                                    <router-link to="" class="da2-a"
                                                                        @click.native="toast()">

                                                                        <div class="da2-box2">
                                                                            <div class="c-title-box">
                                                                                <div class="box">
                                                                                    <img class="da2-icon"
                                                                                        src="./assets/images/ys-21.svg"
                                                                                        alt="">
                                                                                    <div class="da2-title font18">
                                                                                        数智营运
                                                                                    </div>
                                                                                </div>
                                                                                <div class="c-icon">
                                                                                    <img src="./assets/images/ys-298.svg"
                                                                                        alt="">
                                                                                </div>
                                                                            </div>

                                                                            <div class="c-text font14">
                                                                                智能化、可视化、数字化，助力经营提效
                                                                            </div>

                                                                        </div>

                                                                    </router-link>
                                                                </div>

                                                                <div class="dli2">
                                                                    <router-link to="" class="da2-a"
                                                                        @click.native="toast()">

                                                                        <div class="da2-box2">
                                                                            <div class="c-title-box">
                                                                                <div class="box">
                                                                                    <img class="da2-icon"
                                                                                        src="./assets/images/ys-22.svg"
                                                                                        alt="">
                                                                                    <div class="da2-title font18">
                                                                                        数智供应链
                                                                                    </div>
                                                                                </div>
                                                                                <div class="c-icon">
                                                                                    <img src="./assets/images/ys-298.svg"
                                                                                        alt="">
                                                                                </div>
                                                                            </div>

                                                                            <div class="c-text font14">
                                                                                面向上游供应商高效协同，信息互联
                                                                            </div>

                                                                        </div>

                                                                    </router-link>
                                                                </div>

                                                                <div class="dli2">
                                                                    <router-link to="" class="da2-a"
                                                                        @click.native="toast()">

                                                                        <div class="da2-box2">
                                                                            <div class="c-title-box">
                                                                                <div class="box">
                                                                                    <img class="da2-icon"
                                                                                        src="./assets/images/ys-23.svg"
                                                                                        alt="">
                                                                                    <div class="da2-title font18">
                                                                                        数智物流
                                                                                    </div>
                                                                                </div>
                                                                                <div class="c-icon">
                                                                                    <img src="./assets/images/ys-298.svg"
                                                                                        alt="">
                                                                                </div>
                                                                            </div>

                                                                            <div class="c-text font14">
                                                                                精细化管理，降损提效
                                                                            </div>

                                                                        </div>

                                                                    </router-link>
                                                                </div>

                                                                <div class="dli2">
                                                                    <router-link to="" class="da2-a"
                                                                        @click.native="toast()">

                                                                        <div class="da2-box2">
                                                                            <div class="c-title-box">
                                                                                <div class="box">
                                                                                    <img class="da2-icon"
                                                                                        src="./assets/images/ys-24.svg"
                                                                                        alt="">
                                                                                    <div class="da2-title font18">
                                                                                        数智分销
                                                                                    </div>
                                                                                </div>
                                                                                <div class="c-icon">
                                                                                    <img src="./assets/images/ys-298.svg"
                                                                                        alt="">
                                                                                </div>
                                                                            </div>

                                                                            <div class="c-text font14">
                                                                                覆盖全场景全链路，让流通更高效
                                                                            </div>

                                                                        </div>

                                                                    </router-link>
                                                                </div>

                                                                <div class="dli2">
                                                                    <router-link to="" class="da2-a"
                                                                        @click.native="toast()">

                                                                        <div class="da2-box2">
                                                                            <div class="c-title-box">
                                                                                <div class="box">
                                                                                    <img class="da2-icon"
                                                                                        src="./assets/images/ys-25.svg"
                                                                                        alt="">
                                                                                    <div class="da2-title font18">
                                                                                        数智财务
                                                                                    </div>
                                                                                </div>
                                                                                <div class="c-icon">
                                                                                    <img src="./assets/images/ys-298.svg"
                                                                                        alt="">
                                                                                </div>
                                                                            </div>

                                                                            <div class="c-text font14">
                                                                                新零售下的全场景一体化业财产品体系
                                                                            </div>

                                                                        </div>

                                                                    </router-link>
                                                                </div>

                                                                <div class="dli2">
                                                                    <router-link to="" class="da2-a"
                                                                        @click.native="toast()">

                                                                        <div class="da2-box2">
                                                                            <div class="c-title-box">
                                                                                <div class="box">
                                                                                    <img class="da2-icon"
                                                                                        src="./assets/images/ys-254.png"
                                                                                        alt="">
                                                                                    <div class="da2-title font18">
                                                                                        数智客服
                                                                                    </div>
                                                                                </div>
                                                                                <div class="c-icon">
                                                                                    <img src="./assets/images/ys-298.svg"
                                                                                        alt="">
                                                                                </div>
                                                                            </div>

                                                                            <div class="c-text font14">
                                                                                全渠道接入，智能机器人，高效响应
                                                                            </div>

                                                                        </div>

                                                                    </router-link>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>


                                                <div class="dli1">

                                                    <div class="d-left">
                                                        <router-link to="/product" class="da2-a" @click.native="refresh()">
                                                            <div class="c-icon"><img src="./assets/images/ys-299.png"
                                                                    alt="">
                                                            </div>
                                                            <div class="font22 text_b c-title">数据云</div>
                                                        </router-link>
                                                    </div>
                                                    <div class="d-right">

                                                        <div class="dul2">
                                                            <div class="dscroll">

                                                                <div class="dli2">
                                                                    <router-link to="/productson?id=2" class="da2-a">

                                                                        <div class="da2-box2">
                                                                            <div class="c-title-box">
                                                                                <div class="box">
                                                                                    <img class="da2-icon"
                                                                                        src="./assets/images/ys-26.svg"
                                                                                        alt="">
                                                                                    <div class="da2-title font18">
                                                                                        会员营销
                                                                                    </div>
                                                                                </div>
                                                                                <div class="c-icon">
                                                                                    <img src="./assets/images/ys-298.svg"
                                                                                        alt="">
                                                                                </div>
                                                                            </div>

                                                                            <div class="c-text font14">
                                                                                聚焦会员全生命周期精准营销，提升单客产值
                                                                            </div>

                                                                        </div>

                                                                    </router-link>
                                                                </div>

                                                                <div class="dli2">
                                                                    <router-link to="" class="da2-a"
                                                                        @click.native="toast()">

                                                                        <div class="da2-box2">
                                                                            <div class="c-title-box">
                                                                                <div class="box">
                                                                                    <img class="da2-icon"
                                                                                        src="./assets/images/ys-30.svg"
                                                                                        alt="">
                                                                                    <div class="da2-title font18">
                                                                                        自动补货
                                                                                    </div>
                                                                                </div>
                                                                                <div class="c-icon">
                                                                                    <img src="./assets/images/ys-298.svg"
                                                                                        alt="">
                                                                                </div>
                                                                            </div>

                                                                            <div class="c-text font14">
                                                                                自动化智能补货，精准高效预测，释放库存压力
                                                                            </div>

                                                                        </div>

                                                                    </router-link>
                                                                </div>

                                                                <div class="dli2">
                                                                    <router-link to="" class="da2-a"
                                                                        @click.native="toast()">

                                                                        <div class="da2-box2">
                                                                            <div class="c-title-box">
                                                                                <div class="box">
                                                                                    <img class="da2-icon"
                                                                                        src="./assets/images/ys-29.svg"
                                                                                        alt="">
                                                                                    <div class="da2-title font18">
                                                                                        老板通
                                                                                    </div>
                                                                                </div>
                                                                                <div class="c-icon">
                                                                                    <img src="./assets/images/ys-298.svg"
                                                                                        alt="">
                                                                                </div>
                                                                            </div>

                                                                            <div class="c-text font14">
                                                                                实时准确掌握全局数据，指导经营决策
                                                                            </div>

                                                                        </div>

                                                                    </router-link>
                                                                </div>

                                                                <div class="dli2">
                                                                    <router-link to="" class="da2-a"
                                                                        @click.native="toast()">

                                                                        <div class="da2-box2">
                                                                            <div class="c-title-box">
                                                                                <div class="box">
                                                                                    <img class="da2-icon"
                                                                                        src="./assets/images/ys-28.svg"
                                                                                        alt="">
                                                                                    <div class="da2-title font18">
                                                                                        店总北极星
                                                                                    </div>
                                                                                </div>
                                                                                <div class="c-icon">
                                                                                    <img src="./assets/images/ys-298.svg"
                                                                                        alt="">
                                                                                </div>
                                                                            </div>

                                                                            <div class="c-text font14">
                                                                                移动数智工作台，数据驱动经营逐步深入升级
                                                                            </div>

                                                                        </div>

                                                                    </router-link>
                                                                </div>

                                                                <div class="dli2">
                                                                    <router-link to="" class="da2-a"
                                                                        @click.native="toast()">

                                                                        <div class="da2-box2">
                                                                            <div class="c-title-box">
                                                                                <div class="box">
                                                                                    <img class="da2-icon"
                                                                                        src="./assets/images/ys-27.svg"
                                                                                        alt="">
                                                                                    <div class="da2-title font18">
                                                                                        经营大屏
                                                                                    </div>
                                                                                </div>
                                                                                <div class="c-icon">
                                                                                    <img src="./assets/images/ys-298.svg"
                                                                                        alt="">
                                                                                </div>
                                                                            </div>

                                                                            <div class="c-text font14">
                                                                                通过可视化的数据呈现，推动业务决策
                                                                            </div>

                                                                        </div>

                                                                    </router-link>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div class="dli1">

                                                    <div class="d-left">
                                                        <router-link to="/product2" class="da2-a" @click.native="refresh()">
                                                            <div class="c-icon"><img src="./assets/images/ys-300.png"
                                                                    alt="">
                                                            </div>
                                                            <div class="font22 text_b c-title">PaaS平台</div>
                                                        </router-link>
                                                    </div>
                                                    <div class="d-right">

                                                        <div class="dul2">
                                                            <div class="dscroll">

                                                                <div class="dli2">
                                                                    <router-link to="" class="da2-a"
                                                                        @click.native="toast()">

                                                                        <div class="da2-box2">
                                                                            <div class="c-title-box">
                                                                                <div class="box">
                                                                                    <img class="da2-icon"
                                                                                        src="./assets/images/ys-303.svg"
                                                                                        alt="">
                                                                                    <div class="da2-title font18">
                                                                                        mPaaS
                                                                                    </div>
                                                                                </div>
                                                                                <div class="c-icon">
                                                                                    <img src="./assets/images/ys-298.svg"
                                                                                        alt="">
                                                                                </div>
                                                                            </div>

                                                                            <div class="c-text font14">
                                                                                超级端开发工作台
                                                                            </div>

                                                                        </div>

                                                                    </router-link>
                                                                </div>

                                                                <div class="dli2">
                                                                    <router-link to="" class="da2-a"
                                                                        @click.native="toast()">

                                                                        <div class="da2-box2">
                                                                            <div class="c-title-box">
                                                                                <div class="box">
                                                                                    <img class="da2-icon"
                                                                                        src="./assets/images/ys-304.svg"
                                                                                        alt="">
                                                                                    <div class="da2-title font18">
                                                                                        BIPaaS
                                                                                    </div>
                                                                                </div>
                                                                                <div class="c-icon">
                                                                                    <img src="./assets/images/ys-298.svg"
                                                                                        alt="">
                                                                                </div>
                                                                            </div>

                                                                            <div class="c-text font14">
                                                                                数智分析平台
                                                                            </div>

                                                                        </div>

                                                                    </router-link>
                                                                </div>

                                                                <div class="dli2">
                                                                    <router-link to="" class="da2-a"
                                                                        @click.native="toast()">

                                                                        <div class="da2-box2">
                                                                            <div class="c-title-box">
                                                                                <div class="box">
                                                                                    <img class="da2-icon"
                                                                                        src="./assets/images/ys-305.svg"
                                                                                        alt="">
                                                                                    <div class="da2-title font18">
                                                                                        BigdataPaaS
                                                                                    </div>
                                                                                </div>
                                                                                <div class="c-icon">
                                                                                    <img src="./assets/images/ys-298.svg"
                                                                                        alt="">
                                                                                </div>
                                                                            </div>

                                                                            <div class="c-text font14">
                                                                                大数据开发工作台
                                                                            </div>

                                                                        </div>

                                                                    </router-link>
                                                                </div>

                                                                <div class="dli2">
                                                                    <router-link to="" class="da2-a"
                                                                        @click.native="toast()">

                                                                        <div class="da2-box2">
                                                                            <div class="c-title-box">
                                                                                <div class="box">
                                                                                    <img class="da2-icon"
                                                                                        src="./assets/images/ys-306.svg"
                                                                                        alt="">
                                                                                    <div class="da2-title font18">
                                                                                        bizPaaS
                                                                                    </div>
                                                                                </div>
                                                                                <div class="c-icon">
                                                                                    <img src="./assets/images/ys-298.svg"
                                                                                        alt="">
                                                                                </div>
                                                                            </div>

                                                                            <div class="c-text font14">
                                                                                应用开发平台
                                                                            </div>

                                                                        </div>

                                                                    </router-link>
                                                                </div>


                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </li>
                            <li>
                                <div class="c-title-box">
                                    <router-link to="" rel="nofollow" class="font18">解决方案</router-link>
                                    <img src="./assets/images/ico-arrow.svg" alt="" onload="SVGInject(this)">
                                </div>

                                <div id="drop-downBox2" class="drop-downBox">
                                    <div class="drop-down">
                                        <div class="drop-box">
                                            <div class="dul1">
                                                <img class="bgimg" src="./assets/images/ys-301.png" alt="">
                                                <img class="bgimg2" src="./assets/images/ys-307.png" alt="">

                                                <div class="dli1">

                                                    <div class="d-left">
                                                        <router-link to="" rel="nofollow" class="da2-a on">
                                                            <div class="c-icon"><img src="./assets/images/ys-308.png"
                                                                    alt="">
                                                            </div>
                                                            <div class="font22 text_b c-title">行业方案</div>
                                                        </router-link>
                                                    </div>
                                                    <div class="d-right">

                                                        <div class="dul2">
                                                            <div class="dscroll">

                                                                <div class="dli2">
                                                                    <router-link to="case" class="da2-a"
                                                                        @click.native="refresh()">

                                                                        <div class="da2-box2">
                                                                            <div class="c-title-box">
                                                                                <div class="box">
                                                                                    <img class="da2-icon"
                                                                                        src="./assets/images/ys-19.svg"
                                                                                        alt="">
                                                                                    <div class="da2-title font18">
                                                                                        商超便利
                                                                                    </div>
                                                                                </div>
                                                                                <div class="c-icon">
                                                                                    <img src="./assets/images/ys-298.svg"
                                                                                        alt="">
                                                                                </div>
                                                                            </div>

                                                                            <div class="c-text font14">
                                                                                全渠道、全链路、一站式智慧经营
                                                                            </div>

                                                                        </div>

                                                                    </router-link>
                                                                </div>

                                                                <div class="dli2">
                                                                    <router-link to="case1" class="da2-a"
                                                                        @click.native="refresh()">

                                                                        <div class="da2-box2">
                                                                            <div class="c-title-box">
                                                                                <div class="box">
                                                                                    <img class="da2-icon"
                                                                                        src="./assets/images/ys-310.svg"
                                                                                        alt="">
                                                                                    <div class="da2-title font18">
                                                                                        母婴连锁
                                                                                    </div>
                                                                                </div>
                                                                                <div class="c-icon">
                                                                                    <img src="./assets/images/ys-298.svg"
                                                                                        alt="">
                                                                                </div>
                                                                            </div>

                                                                            <div class="c-text font14">
                                                                                盘活存量，激活增量，长效增长
                                                                            </div>

                                                                        </div>

                                                                    </router-link>
                                                                </div>

                                                                <div class="dli2">
                                                                    <router-link to="case2" class="da2-a"
                                                                        @click.native="refresh()">

                                                                        <div class="da2-box2">
                                                                            <div class="c-title-box">
                                                                                <div class="box">
                                                                                    <img class="da2-icon"
                                                                                        src="./assets/images/ys-311.svg"
                                                                                        alt="">
                                                                                    <div class="da2-title font18">
                                                                                        消费品牌
                                                                                    </div>
                                                                                </div>
                                                                                <div class="c-icon">
                                                                                    <img src="./assets/images/ys-298.svg"
                                                                                        alt="">
                                                                                </div>
                                                                            </div>

                                                                            <div class="c-text font14">
                                                                                数据驱动，智慧营销，经营闭环
                                                                            </div>

                                                                        </div>

                                                                    </router-link>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li> 

                            <li>
                                <div class="c-title-box">
                                    <router-link to="customer" class="font18">客户案例</router-link>
                                </div>
                            </li>
                            <li>
                                <div class="c-title-box">
                                    <router-link to="" class="font18">资讯中心</router-link>
                                    <img src="./assets/images/ico-arrow.svg" alt="" onload="SVGInject(this)">
                                </div>
                                <div id="drop-downBox3" class="drop-downBox2">

                                    <div class="drop-down">
                                        <img class="bgimg" src="./assets/images/ys-321.png" alt="">
                                        <div class="drop-box">
                                            <div class="dul">
                                                <div class="dli">
                                                    <router-link to="/news?id=0" class="da2-a">
                                                        <div class="box1">
                                                            <div class="c-icon"><img src="./assets/images/ys-314.svg"
                                                                    alt="">
                                                            </div>
                                                            <div class="font18 c-title">行业洞察</div>
                                                        </div>
                                                        <div class="box2">
                                                            <img src="./assets/images/ys-320.svg" alt=""
                                                                onload="SVGInject(this)">
                                                        </div>
                                                    </router-link>
                                                </div>
                                                <div class="dli">
                                                    <router-link to="/down?id=1" class="da2-a">
                                                        <div class="box1">
                                                            <div class="c-icon"><img src="./assets/images/ys-315.svg"
                                                                    alt="">
                                                            </div>
                                                            <div class="font18 c-title">白皮书下载</div>
                                                        </div>

                                                        <div class="box2">
                                                            <img src="./assets/images/ys-320.svg" alt=""
                                                                onload="SVGInject(this)">
                                                        </div>
                                                    </router-link>
                                                </div>
                                                <div class="dli">
                                                    <router-link to="/down?id=2" class="da2-a">
                                                        <div class="box1">
                                                            <div class="c-icon"><img src="./assets/images/ys-316.svg"
                                                                    alt="">
                                                            </div>
                                                            <div class="font18 c-title">方案下载</div>
                                                        </div>

                                                        <div class="box2">
                                                            <img src="./assets/images/ys-320.svg" alt=""
                                                                onload="SVGInject(this)">
                                                        </div>
                                                    </router-link>
                                                </div>
                                                <div class="dli">
                                                    <router-link to="/news?id=3" class="da2-a">
                                                        <div class="box1">
                                                            <div class="c-icon"><img src="./assets/images/ys-317.svg"
                                                                    alt="">
                                                            </div>
                                                            <div class="font18 c-title">产品热点</div>
                                                        </div>

                                                        <div class="box2">
                                                            <img src="./assets/images/ys-320.svg" alt=""
                                                                onload="SVGInject(this)">
                                                        </div>
                                                    </router-link>
                                                </div>
                                                <div class="dli">
                                                    <router-link to="/news?id=4" class="da2-a">
                                                        <div class="box1">
                                                            <div class="c-icon"><img src="./assets/images/ys-318.svg"
                                                                    alt="">
                                                            </div>
                                                            <div class="font18 c-title">市场活动</div>
                                                        </div>

                                                        <div class="box2">
                                                            <img src="./assets/images/ys-320.svg" alt=""
                                                                onload="SVGInject(this)">
                                                        </div>
                                                    </router-link>
                                                </div>
                                                <div class="dli">
                                                    <router-link to="/news?id=5" class="da2-a">
                                                        <div class="box1">
                                                            <div class="c-icon"><img src="./assets/images/ys-319.svg"
                                                                    alt="">
                                                            </div>
                                                            <div class="font18 c-title">帮助中心</div>
                                                        </div>

                                                        <div class="box2">
                                                            <img src="./assets/images/ys-320.svg" alt=""
                                                                onload="SVGInject(this)">
                                                        </div>
                                                    </router-link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <div class="c-title-box">
                                    <router-link to="" class="font18">关于我们</router-link>
                                    <img src="./assets/images/ico-arrow.svg" alt="" onload="SVGInject(this)">
                                </div>
                                <div id="drop-downBox4" class="drop-downBox2">

                                    <div class="drop-down">
                                        <img class="bgimg" src="./assets/images/ys-321.png" alt="">
                                        <div class="drop-box">
                                            <div class="dul">
                                                <div class="dli">
                                                    <router-link to="/about" class="da2-a">
                                                        <div class="box1">
                                                            <div class="c-icon"><img src="./assets/images/ys-366.svg"
                                                                    alt="">
                                                            </div>
                                                            <div class="font18 c-title">公司介绍</div>
                                                        </div>
                                                        <div class="box2">
                                                            <img src="./assets/images/ys-320.svg" alt=""
                                                                onload="SVGInject(this)">
                                                        </div>
                                                    </router-link>
                                                </div>
                                                <div class="dli">
                                                    <router-link to="/newsdetail?id=10" class="da2-a">
                                                        <div class="box1">
                                                            <div class="c-icon"><img src="./assets/images/ys-362.svg"
                                                                    alt="">
                                                            </div>
                                                            <div class="font18 c-title">品牌故事</div>
                                                        </div>

                                                        <div class="box2">
                                                            <img src="./assets/images/ys-320.svg" alt=""
                                                                onload="SVGInject(this)">
                                                        </div>
                                                    </router-link>
                                                </div>
                                                <div class="dli">
                                                    <router-link to="/trends" class="da2-a">
                                                        <div class="box1">
                                                            <div class="c-icon"><img src="./assets/images/ys-363.svg"
                                                                    alt="">
                                                            </div>
                                                            <div class="font18 c-title">公司动态</div>
                                                        </div>

                                                        <div class="box2">
                                                            <img src="./assets/images/ys-320.svg" alt=""
                                                                onload="SVGInject(this)">
                                                        </div>
                                                    </router-link>
                                                </div>
                                                <div class="dli">
                                                    <router-link to="/team" class="da2-a">
                                                        <div class="box1">
                                                            <div class="c-icon"><img src="./assets/images/ys-364.svg"
                                                                    alt="">
                                                            </div>
                                                            <div class="font18 c-title">专家团队</div>
                                                        </div>

                                                        <div class="box2">
                                                            <img src="./assets/images/ys-320.svg" alt=""
                                                                onload="SVGInject(this)">
                                                        </div>
                                                    </router-link>
                                                </div>

                                                <div class="dli">
                                                    <router-link to="/join" class="da2-a">
                                                        <div class="box1">
                                                            <div class="c-icon"><img src="./assets/images/ys-365.svg"
                                                                    alt="">
                                                            </div>
                                                            <div class="font18 c-title">加入我们</div>
                                                        </div>

                                                        <div class="box2">
                                                            <img src="./assets/images/ys-320.svg" alt=""
                                                                onload="SVGInject(this)">
                                                        </div>
                                                    </router-link>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>  -->
                        </ul>

                        <!-- 顶部移动端导航 -->
                        <ul class="c-nav2">
                            <li class="" v-for="(item1, index1) in header" :key="index1">
                                <div class="c-title-box">
                                    <router-link v-if="item1.url" :to="item1.url" @click.native="switchFn()">{{ item1.name }}</router-link>
                                    <img v-show="item1.child != ''" src="./assets/images/ico-arrow.svg" alt=""
                                        onload="SVGInject(this)">
                                </div>
                                <ul v-if="item1.child.length > 0">
                                    <li v-for="(item2, index2) in item1.child" :key="index2">

                                        <div class="c-title-box">
                                            <!-- <router-link v-if="item2.url" :to="item2.url" @click.native="switchFn()">{{ item2.name
                                            }}</router-link> -->

                                            <router-link v-if="!isExternal(item2.url) && item2.url" :to="item2.url" @click.native="switchFn()">                                               
                                                {{ item2.name }}
                                            </router-link>

                                            <a href="javascript:;" rel="nofollow" v-else  @click="switchFn()">
                                                {{ item2.name }}
                                            </a>


                                            <img v-if="item2.child.length > 0" src="./assets/images/ico-arrow.svg" alt=""
                                                onload="SVGInject(this)">
                                        </div>

                                        <ul v-if="item2.child.length > 0">
                                            <li v-for="(item3, index3) in item2.child" :key="index3">
                                                <router-link v-if="item3.url" :to="item3.url" @click.native="switchFn()">{{ item3.name
                                                }}</router-link>
                                            </li>
                                        </ul>

                                    </li>
                                </ul>
                            </li>

                            <!-- <li class="">
                                <div class="c-title-box">
                                    <router-link to='/' @click.native="switchFn()">首页</router-link>
                                </div>
                                
                            </li>

                            <li>
                                <div class="c-title-box">
                                    <router-link to="" @click.native="switchFn()">产品</router-link>
                                    <img src="./assets/images/ico-arrow.svg" alt="" onload="SVGInject(this)">
                                </div>
                                <ul>
                                    <li>

                                        <div class="c-title-box">
                                            <router-link to="/product1" @click.native="switchFn()">零售云</router-link>
                                            <img src="./assets/images/ico-arrow.svg" alt="" onload="SVGInject(this)">
                                        </div>

                                        <ul>
                                            <li><router-link to="" @click.native="toast('c')">数智零售</router-link></li>
                                            <li><router-link to="" @click.native="toast('c')">数智会员</router-link></li>
                                            <li><router-link to="" @click.native="toast('c')">数智门店</router-link></li>
                                            <li><router-link to="" @click.native="toast('c')">数智员工</router-link></li>
                                            <li><router-link to="" @click.native="toast('c')">数智营运</router-link></li>
                                            <li><router-link to="" @click.native="toast('c')">数智供应链</router-link></li>
                                            <li><router-link to="" @click.native="toast('c')">数智物流</router-link></li>
                                            <li><router-link to="" @click.native="toast('c')">数智分销</router-link></li>
                                            <li><router-link to="" @click.native="toast('c')">数智财务</router-link></li>
                                            <li><router-link to="" @click.native="toast('c')">数智客服</router-link></li>
                                        </ul>

                                    </li>
                                    <li>
                                        <div class="c-title-box">
                                            <router-link to="/product" @click.native="switchFn()">数据云</router-link>
                                            <img src="./assets/images/ico-arrow.svg" alt="" onload="SVGInject(this)">
                                        </div>
                                        <ul>
                                            <li><router-link to="productson?id=0"
                                                    @click.native="switchFn()">会员营销</router-link></li>
                                            <li><router-link to="" @click.native="toast('c')">自动补货</router-link></li>
                                            <li><router-link to="" @click.native="toast('c')">老板通</router-link></li>
                                            <li><router-link to="" @click.native="toast('c')">店总北极星</router-link></li>
                                            <li><router-link to="" @click.native="toast('c')">经营大屏</router-link></li>
                                        </ul>
                                    </li>
                                    <li>
                                        <div class="c-title-box">
                                            <router-link to="/product2" @click.native="switchFn()">PaaS平台</router-link>
                                            <img src="./assets/images/ico-arrow.svg" alt="" onload="SVGInject(this)">
                                        </div>
                                        <ul>
                                            <li><router-link to="" @click.native="toast('c')">aPaaS</router-link></li>
                                            <li><router-link to="" @click.native="toast('c')">BIPaaS</router-link></li>
                                            <li><router-link to="" @click.native="toast('c')">BigdataPaaS</router-link></li>
                                            <li><router-link to="" @click.native="toast('c')">bizPaaS</router-link></li>
                                        </ul>
                                    </li>
                                </ul>
                            </li>
                            <li>
                                <div class="c-title-box">
                                    <router-link to="/case" @click.native="switchFn()">解决方案</router-link>
                                    <img src="./assets/images/ico-arrow.svg" alt="" onload="SVGInject(this)">
                                </div>
                                <ul>
                                    <li>

                                        <div class="c-title-box">
                                            <router-link to="" @click.native="switchFn('not')">行业方案</router-link>
                                            <img src="./assets/images/ico-arrow.svg" alt="" onload="SVGInject(this)">
                                        </div>

                                        <ul>
                                            <li><router-link to="/case" @click.native="switchFn()">商超零售</router-link></li>
                                            <li><router-link to="/case1" @click.native="switchFn()">母婴连锁</router-link></li>
                                            <li><router-link to="/case2" @click.native="switchFn()">消费品牌</router-link></li>
                                        </ul>

                                    </li>

                                </ul>
                            </li>
                            <li>
                                <div class="c-title-box">
                                    <router-link to="/customer" @click.native="switchFn()">客户案例</router-link>
                                </div>
                            </li>
                            <li>
                                <div class="c-title-box">
                                    <router-link to="/news" @click.native="switchFn()">资讯中心</router-link>
                                    <img src="./assets/images/ico-arrow.svg" alt="" onload="SVGInject(this)">
                                </div>

                                <ul>
                                    <li><router-link to="/news?id=0" @click.native="switchFn()">行业洞察</router-link></li>
                                    <li><router-link to="/down?id=1" @click.native="switchFn()">白皮书下载</router-link></li>
                                    <li><router-link to="/down?id=2" @click.native="switchFn()">方案下载</router-link></li>
                                    <li><router-link to="/news?id=3" @click.native="switchFn()">产品热点</router-link></li>
                                    <li><router-link to="/news?id=4" @click.native="switchFn()">市场活动</router-link></li>
                                    <li><router-link to="/news?id=5" @click.native="switchFn()">帮助中心</router-link></li>
                                </ul>
                            </li>
                            <li>
                                <div class="c-title-box">
                                    <router-link to="" @click.native="switchFn('not')">关于我们</router-link>
                                    <img src="./assets/images/ico-arrow.svg" alt="" onload="SVGInject(this)">
                                </div>
                                <ul>
                                    <li><router-link to="/about" @click.native="switchFn()">公司介绍</router-link></li>
                                    <li><router-link to="/newsdetail?id=10" @click.native="switchFn()">品牌故事</router-link>
                                    </li>
                                    <li><router-link to="/trends" @click.native="switchFn()">公司动态</router-link></li>
                                    <li><router-link to="/team" @click.native="switchFn()">专家团队</router-link></li>
                                    <li><router-link to="/join" @click.native="switchFn()">加入我们</router-link></li>
                                </ul>
                            </li> -->

                            <div class="searchBox-wap">
                                <div class="public-searchBox public-searchBox-wap">
                                    <div class="inputBox">
                                        <input @keyup.enter="enterSearch()" type="text" v-model="search"
                                            placeholder="请输入关键字..." class="font16">
                                    </div>
                                    <div class="inputBtn" @click="searchFn()">
                                        <img src="./assets/images/ys-295.svg" alt="" onload="SVGInject(this)">
                                    </div>
                                </div>
                            </div>

                            <div class="wapNav-lgBox">
                                <router-link v-if="!isExternal(setting.free_link) && setting.free_link" :to="setting.free_link" class="font14 c-a" @click.native="switchFn()">
                                    <!-- 免费试用 -->
                                    {{ setting.free_text }}
                                </router-link>

                                <a v-else :href="setting.free_link" target="_blank" class="font14 c-a" @click="switchFn()">
                                    <!-- 免费试用 -->
                                    {{ setting.free_text }}
                                </a>

                                <span class="c-span">|</span>
                                <div class="lgStateBox">
                                    <div @click="loginBtn('wap')" class="font14" v-show="login_state == false">
                                        立即登录
                                    </div>
                                    <div @click="logout()" class="font14" v-show="login_state == true">
                                        退出登录
                                    </div>
                                </div>
                            </div>
                        </ul>

                        <!-- 顶部功能区 -->
                        <div class="c-gn">
                            <form @click="searchHideShow()">
                                <div class="img1" :class="searchFlag == false ? '' : 'on'">
                                    <img src="./assets/images/ys-79.svg" alt="" onload="SVGInject(this)">
                                </div>
                                <div class="img2" :class="searchFlag == true ? 'on' : ''">
                                    <img src="./assets/images/ys-356.svg" alt="" onload="SVGInject(this)">
                                </div>
                            </form>
                            <div class="c-phone">
                                <img src="./assets/images/ys-77.svg" alt="">
                                <span class="c-text">{{ setting.mobile }}</span>
                            </div>
                            <div class="c-btnBox">
                                <router-link v-if="!isExternal(setting.free_link) && setting.free_link" :to="setting.free_link" class="hi-btn1 c-a1">
                                    <span class="hi-text font16">
                                        <!-- 免费试用 -->
                                        {{ setting.free_text }}
                                    </span>
                                    <img class="hi-ico" src="./assets/images/hi-btn-arrow1.svg" onload="SVGInject(this)">
                                </router-link>

                                <a v-else :href="setting.free_link" target="_blank" class="hi-btn1 c-a1">
                                    <span class="hi-text font16">
                                        <!-- 免费试用 -->
                                        {{ setting.free_text }}
                                    </span>
                                    <img class="hi-ico" src="./assets/images/hi-btn-arrow1.svg" onload="SVGInject(this)">
                                </a>

                                <a href="javascript:;" rel="nofollow" @click="loginBtn()" v-if="login_state == false"
                                    class="hi-btn1 hi-btn1-2 c-a2">
                                    <img class="hi-ico" src="./assets/images/ys-78.svg" onload="SVGInject(this)">
                                    <span class="hi-text font16">登录</span>
                                </a>
                                <div class="user-box" v-if="login_state == true">
                                    <div class="user-tx">
                                        <img v-if="lgWay == 1" src="./assets/images/ys-400.jpg" alt="">
                                        <img v-if="lgWay == 2" src="./assets/images/ys-399.jpg" alt="">
                                    </div>
                                    <div class="user-contBox">
                                        <div class="user-cont">
                                            <div class="syb"></div>
                                            <div class="user-ul">
                                                <div class="user-list">
                                                    <img src="./assets/images/ys-359.svg" alt="">
                                                    <div class="font16 c-div">您好<span v-if="lgWay == 1">，</span>{{ userName }}
                                                    </div>
                                                </div>
                                                <div class="user-list" @click="logout()">
                                                    <img src="./assets/images/ys-358.svg" alt="" onload="SVGInject(this)">
                                                    <div class="font16 c-div">退出登录</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="public-mask" :class="searchFlag ? 'active' : ''"></div>
                        <div class="drop-searchBox" :class="searchFlag ? 'active' : ''">
                            <div class="public-ani-bgimg public-ani-bgimg1"><img src="./assets/images/ys-82.png" alt="">
                            </div>
                            <div class="public-ani-bgimg public-ani-bgimg4"><img src="./assets/images/ys-82.png" alt="">
                            </div>
                            <div class="c-box">
                                <div class="box">
                                    <div class="public-searchBox">
                                        <div class="inputBox">
                                            <input @keyup.enter="enterSearch()" type="text" v-model="search"
                                                placeholder="请输入关键字..." class="font16">
                                        </div>
                                        <div class="inputBtn" @click="searchFn()">
                                            <img src="./assets/images/ys-295.svg" alt="">
                                        </div>
                                    </div>

                                    <div class="c-text">
                                        <div class="c-div font16">
                                            热门搜索:
                                        </div>
                                        <ul class="c-ul">
                                            <li class="c-li" v-for="(item, index) in hotSearch" :key="index">
                                                <router-link :target="item.target" @click.native="searchFlag = !searchFlag"
                                                    :to="{ path: '/search', query: { text: item } }" class="font16 c-a">{{
                                                        item }}</router-link>
                                            </li>
                                            <!-- <li class="c-li">
                                                <router-link to="/search?id=1" class="font16 c-a">数据智能</router-link>
                                            </li>
                                            <li class="c-li">
                                                <router-link to="/search?id=2" class="font16 c-a">全渠道交易</router-link>
                                            </li>
                                            <li class="c-li">
                                                <router-link to="/search?id=3" class="font16 c-a">数字中台</router-link>
                                            </li> -->
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- 顶部移动端导航开关 -->
                        <div class="c-switch" @click="switchFn()">
                            <i></i>
                            <i></i>
                            <i></i>
                        </div>
                    </div>
                </div>
            </div>

            <!-- 占位标签，设置导航样式 -->
            <div id="c-placeholder" v-show="headShow"></div>
        </nav>

        <!-- 缓存页面 -->
        <keep-alive>
            <router-view :key="$route.fullPath" v-if="$route.meta.keepAlive" />
        </keep-alive>

        <router-view :key="$route.fullPath" v-if="!$route.meta.keepAlive" />


        <!-- 底部栏 -->
        <div v-show="receive.title">
            <div class="index-dibu" v-show="!contactFlag">
                <img class="bgimg" v-if="receive.image" :src="hostUrl + receive.image" alt="">
                <div class="container">
                    <p class="font36 font-b cn1 wow fadeInUp">{{ receive.title }}</p>
                    <p class="cn24 wow fadeInUp">
                        <!-- <router-link v-if="receive.link" :to="receive.link" class="hi-btn1 hi-btn1-2">
                            <span class="hi-text font16 text_b">{{ receive.name }}</span>
                            <img class="hi-ico" src="./assets/images/ys-50.svg" onload="SVGInject(this)">
                        </router-link>

                        <router-link v-if="!isExternal(receive.link)" :to="receive.link"
                            class="hi-btn1 hi-btn1-2">{{ receive.name }}</router-link> -->

                        <router-link v-if="!isExternal(receive.link) && receive.link" :to="receive.link" class="hi-btn1 hi-btn1-2">
                            <span class="hi-text font16 text_b">{{ receive.name }}</span>
                            <img class="hi-ico" src="./assets/images/ys-50.svg" onload="SVGInject(this)">
                        </router-link>

                        <a :href="receive.link" target="_blank" rel="nofollow" v-else class="hi-btn1 hi-btn1-2">
                            <span class="hi-text font16 text_b">{{ receive.name }}</span>
                            <img class="hi-ico" src="./assets/images/ys-50.svg" onload="SVGInject(this)">
                        </a>

                    </p>
                </div>
            </div>
        </div>

        <!-- 侧边栏 -->
        <div class="index-aside">

            <div class="box11">
                <a v-if="setting.customer_link" :href="setting.customer_link" rel="nofollow" target="_blank" class="cn1">
                    <span class="cn2"><img src="./assets/images/ys-284.png" alt=""></span>
                    <span class="cn3 font14">在线客服</span>
                </a>
            </div>

            <div class="box2">


                <div class="box box10">
                    <a href="javascript:;" rel="nofollow" class="cn1">
                        <span class="cn2"><img src="./assets/images/about-img15.png" alt=""></span>
                        <span class="cn3 font14">在线咨询</span>
                    </a>

                    <p class="cn11 cn12">
                        <img v-if="setting.consultQR" :src="hostUrl + setting.consultQR" alt="">
                        <span class="font14">微信扫码 在线联系</span>
                        <span class="syb"></span>
                    </p>

                </div>
                <div class="box">
                    <router-link to="contact" class="cn1">
                        <span class="cn2"><img src="./assets/images/about-img16.png" alt=""></span>
                        <span class="cn3 font14">在线留言</span>
                    </router-link>
                </div>
                <div class="box box10">
                    <a href="javascript:;" rel="nofollow" class="cn1">
                        <span class="cn2"><img src="./assets/images/about-img17.png" alt=""></span>
                        <span class="cn3 font14">联系电话</span>
                    </a>
                    <p class="cn10 font20">
                        <!-- <div class="c-div"> -->
                        <span class="font14">欢迎拨打电话咨询</span>
                        <span class="text_b">{{ setting.mobile }}</span>
                        <span class="syb"></span>
                        <!-- </div> -->
                        <!-- <div class="syb"></div> -->
                    </p>
                </div>
                <div class="box box10">
                    <a href="javascript:;" rel="nofollow" class="cn1">
                        <span class="cn2"><img src="./assets/images/about-img18.png" alt=""></span>
                        <span class="cn3 font14">微信公众号</span>
                    </a>

                    <p class="cn11">
                        <img v-if="setting.officialQR" :src="hostUrl + setting.officialQR" alt="">
                        <span class="font14">关注微信公众号</span>
                        <span class="syb"></span>
                    </p>
                </div>
            </div>
            <div class="box3">
                <div class="box">
                    <a href="javascript:;" rel="nofollow" class="cn1 goTop">
                        <span class="cn2"><img src="./assets/images/about-img19.png" alt=""></span>
                        <span class="cn3 font14">返回顶部</span>
                    </a>
                </div>
            </div>

        </div>


        <!-- 底部菜单 -->
        <div class="index-footer wow fadeInUp">
            <img class="bgimg" src="./assets/images/about-img10.jpg" alt="">
            <div class="container">
                <!-- 上面部分 信息和右侧菜单 -->
                <div class="box1">
                    <div class="left">
                        <!-- logo -->
                        <div class="footer-logo">
                            <router-link to="/" class="cn1"><img v-if="setting.logo" :src="hostUrl + setting.logo"
                                    alt="setting.name"></router-link>
                            <a v-if="item.link" :href="item.link" target="_blank" rel="nofollow" class="cn2"
                                v-for="(item, index) in setting.plogo" :key="index">
                                <img :src="hostUrl + item.image" alt=""></a>




                            <!-- <a href="javascript:;" rel="nofollow" @click="toast()" class="cn2 cn3">
                                <img src="./assets/images/ys-113.png" alt="">
                            </a> -->
                        </div>
                        <!-- 底部信息 -->
                        <div class="footer-message">
                            <!-- <ul class="cn3">
                                <li class="cn4 font16" v-if="setting.mobile">电话：{{ setting.mobile }}</li>
                                <li class="cn4 font16" v-if="setting.email">邮箱：{{ setting.email }}</li>
                                <li class="cn4 font16" v-if="setting.address">地址：{{ setting.address }}</li>
                            </ul> -->
                            <div class="cn3">
                                <div class="cn4 font16 pre" v-if="setting.contact">{{setting.contact}}</div>
                            </div>
                        </div>
                        <!-- 底部二维码 -->
                        <div class="footer-qrcode">
                            <div class="cn5" v-if="setting.officialQR">
                                <p class="img public-img"><img :src="hostUrl + setting.officialQR" alt=""></p>
                                <p class="cn6 font14">关注公众号</p>
                            </div>
                            <div class="cn5" v-if="setting.consultQR">
                                <p class="img public-img"><img :src="hostUrl + setting.consultQR" alt=""></p>
                                <p class="cn6 font14">在线咨询</p>
                            </div>
                        </div>
                    </div>
                    <div class="right">
                        <ul class="cn7">
                            <!-- 底部导航链接 -->
                            <li class="cn8" v-for="(item1, index1) in footer" :key="index1">
                                <!-- <router-link :to="link"  class="cn9 font20">{{ item1.name }}</router-link> -->
                                <router-link :target="item1.target" v-if="!isExternal(item1.url) && item1.url" :to="item1.url"
                                    class="cn9 font20">{{ item1.name }}</router-link>
                                <a :href="item1.url" target="_blank" rel="nofollow" v-else class="cn9 font20">{{ item1.name
                                }}</a>


                                <ul class="cn10" v-for="(item2, index2) in item1.child" :key="index2">
                                    <li class="cn11">
                                        <router-link v-if="!isExternal(item2.url) && item2.url" :target="item2.target" :to="item2.url"
                                            class="cn12 font16">{{ item2.name }}</router-link>
                                        <a :href="item2.url" target="_blank" rel="nofollow" v-else class="cn12 font16">{{
                                            item2.name }}</a>
                                    </li>
                                    <!-- <li class="cn11"><a href="javascript:;" rel="nofollow" @click="toast()"
                                            class="cn12 font16">数玲珑</a></li> -->
                                </ul>
                            </li>
                            <!-- <li class="cn8">
                                <a href="javascript:;" rel="nofollow" @click="toast()" class="cn9 font20">产品</a>

                                <ul class="cn10">
                                    <li class="cn11"><a href="javascript:;" rel="nofollow" @click="toast()"
                                            class="cn12 font16">零售云</a></li>
                                    <li class="cn11"><a href="javascript:;" rel="nofollow" @click="toast()"
                                            class="cn12 font16">营销云</a></li>
                                    <li class="cn11"><a href="javascript:;" rel="nofollow" @click="toast()"
                                            class="cn12 font16">PaaS平台</a>
                                    </li>
                                </ul>
                            </li>
                            <li class="cn8">
                                <a href="javascript:;" rel="nofollow" @click="toast()" class="cn9 font20">解决方案</a>

                                <ul class="cn10">
                                    <li class="cn11"><a href="javascript:;" rel="nofollow" @click="toast()"
                                            class="cn12 font16">商超零售</a>
                                    </li>
                                    <li class="cn11"><a href="javascript:;" rel="nofollow" @click="toast()"
                                            class="cn12 font16">母婴零售</a>
                                    </li>
                                    <li class="cn11"><a href="javascript:;" rel="nofollow" @click="toast()"
                                            class="cn12 font16">品牌零售</a>
                                    </li>
                                </ul>
                            </li>
                            <li class="cn8">
                                <a href="javascript:;" rel="nofollow" @click="toast()" class="cn9 font20">客户案例</a>

                                <ul class="cn10">
                                    <li class="cn11"><a href="javascript:;" rel="nofollow" @click="toast()"
                                            class="cn12 font16">商超案例</a>
                                    </li>
                                    <li class="cn11"><a href="javascript:;" rel="nofollow" @click="toast()"
                                            class="cn12 font16">母婴案例</a>
                                    </li>
                                    <li class="cn11"><a href="javascript:;" rel="nofollow" @click="toast()"
                                            class="cn12 font16">品牌案例</a>
                                    </li>
                                </ul>
                            </li>
                            <li class="cn8">
                                <a href="javascript:;" rel="nofollow" @click="toast()" class="cn9 font20">资讯中心</a>

                                <ul class="cn10">
                                    <li class="cn11"><a href="javascript:;" rel="nofollow" @click="toast()"
                                            class="cn12 font16">行业洞察</a>
                                    </li>
                                    <li class="cn11"><a href="javascript:;" rel="nofollow" @click="toast()"
                                            class="cn12 font16">白皮书下载</a>
                                    </li>
                                    <li class="cn11"><a href="javascript:;" rel="nofollow" @click="toast()"
                                            class="cn12 font16">方案下载</a>
                                    </li>
                                    <li class="cn11"><a href="javascript:;" rel="nofollow" @click="toast()"
                                            class="cn12 font16">产品热点</a>
                                    </li>
                                    <li class="cn11"><a href="javascript:;" rel="nofollow" @click="toast()"
                                            class="cn12 font16">市场活动</a>
                                    </li>
                                    <li class="cn11"><a href="javascript:;" rel="nofollow" @click="toast()"
                                            class="cn12 font16">帮助中心</a>
                                    </li>
                                </ul>
                            </li>
                            <li class="cn8">
                                <a href="/#/about" class="cn9 font20">关于我们</a>

                                <ul class="cn10">
                                    <li class="cn11"><a href="/#/about" class="cn12 font16">公司介绍</a>
                                    </li>
                                    <li class="cn11"><a href="javascript:;" rel="nofollow" @click="toast()"
                                            class="cn12 font16">品牌故事</a>
                                    </li>
                                    <li class="cn11"><a href="javascript:;" rel="nofollow" @click="toast()"
                                            class="cn12 font16">公司动态</a>
                                    </li>
                                    <li class="cn11"><a href="javascript:;" rel="nofollow" @click="toast()"
                                            class="cn12 font16">加入我们</a>
                                    </li>
                                </ul>
                            </li> -->
                        </ul>
                    </div>
                </div>
                <!-- 友情链接 -->
                <div class="links-box" v-show="friLinks!=''">
                    <div class="font16 c-div">友情链接：</div>
                    <ul class="c-ul">
                        <li class="c-li" v-for="(item,index) in friLinks" :key="index">
                            <a :href="item.link" rel="nofollw" target="_blank" class="font16 c-a">{{ item.name }}</a>
                        </li>
                    </ul>
                </div>
                <!-- 下面部分 证书备案号 -->
                <div class="box2">
                    <p class="cn1 font14">{{ setting.copyright }} </p>
                    <p class="cn2">
                        <a href="https://beian.miit.gov.cn/" target="_blank" rel="nofollw" class="cn3 font14">{{ setting.icp }}</a>
                        <a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=32011502011892" rel="nofollow" target="_blank" class="cn3 font14">
                            <img src="./assets/images/ys-403.png" alt="">苏公网安备32011502011892号
                        </a>
                        <a href="https://beian.miit.gov.cn" rel="nofollow" target="_blank" class="cn3 font14">
                            增值电信业务经营许可证：合字B2-20230300
                        </a>
                        <!-- <span class="font14" style="color: #999;margin-left: 20px;">技术支持:</span><a class="cn3 font14" target="_blank" href="https://www.growthman.cn">增长超人</a> -->
                    </p>
                </div>
            </div>
        </div>

        <!-- 登录弹窗 -->
        <div class="public-mask2" :class="loginHideShow ? 'active' : ''"></div>
        <div class="public-tanchuang xc-window-middle login-tc" :class="loginHideShow ? 'active' : ''">

            <div class="c-title-box">
                <div class="c-title font20">
                    欢迎登录元数科技
                </div>
                <div class="zz-close" @click="loginClose()">
                    <img src="./assets/images/ys-346.svg" alt="">
                </div>
            </div>

            <div class="c-form">
                <div class="login-nav">

                    <div class="nav-li" @click="loginNav(1)" :class="loginFlag == true ? 'on' : ''">
                        <div class="c-icon">
                            <img class="img1" src="./assets/images/ys-352.png" alt="">
                            <img class="img2" src="./assets/images/ys-351.png" alt="">
                        </div>
                        <div class="c-text font18 text_b">
                            手机登录
                        </div>
                    </div>

                    <div class="nav-li" @click="loginNav(0)" :class="loginFlag == false ? 'on' : ''">
                        <div class="c-icon">
                            <img class="img1" src="./assets/images/ys-350.png" alt="">
                            <img class="img2" src="./assets/images/ys-349.png" alt="">
                        </div>
                        <div class="c-text font18 text_b">
                            微信登录
                        </div>
                    </div>

                </div>

                <div class="login-box">
                    <div class="login-tips" v-show="login_state">
                        <div class="c-icon">
                            <img class="" src="./assets/images/ys-355.png" alt="">
                        </div>
                        <div class="c-text font24">
                            登录成功
                        </div>
                    </div>

                    <div class="c-box">
                        <div class="login-li login-tel" v-show="loginFlag == true">
                            <div class="c-box1">
                                <div class="box1">
                                    <div class="c-text font16">
                                        中国大陆（86）
                                    </div>
                                    <div class="inputBox">
                                        <input type="text" v-model="login_tel" class="font16" placeholder="手机号">
                                    </div>
                                </div>

                                <div class="box2">
                                    <div class="inputBox">
                                        <input type="text" v-model="login_code" class="font16" placeholder="验证码">
                                    </div>
                                    <div class="countDown">
                                        <div class="c-p c-p-1 font16 hi-btn1 hi-btn1-2 c-a" @click="lgGetCode"
                                            v-if="!lgCountFlag">
                                            <span>获取验证码</span>
                                        </div>

                                        <div class="c-p c-p-2 font16 hi-btn1 hi-btn1-2 c-a c-a2" v-if="lgCountFlag">
                                            <span>{{ lgCountNum }}s后重发</span>
                                        </div>
                                    </div>
                                </div>

                                <div class="box3" @click="loginFn()">
                                    <span class="font16">登录</span>
                                    <img src="./assets/images/ys-353.svg" alt="">
                                </div>
                            </div>

                            <div class="c-box2">
                                <div class="public-clause">
                                    <input @click="login_term = !login_term" type="checkbox" name="" value=""
                                        class="xc-radio c-input formClause">
                                    <div class="c-div">
                                        <a href="/privacy" target="_blank" @click="privacyServer()"
                                            class="c-a font16">《隐私政策》
                                        </a>
                                        <div class="c-syb">|</div>
                                        <a href="/server" target="_blank" @click="privacyServer()"
                                            class="c-a font16">《用户服务协议》</a>
                                    </div>
                                </div>
                            </div>


                        </div>

                        <div class="login-li login-wx" :class="wxCodeOn ? 'on' : ''" v-show="loginFlag == false">

                            <div class="imgbox">
                                <img class="bgimg" src="./assets/images/ys-347.png" alt="">
                                <img v-if="wxTicket" class="wximg" :src="wxHost + wxTicket" alt="微信二维码">

                                <div class="c-tips">
                                    <div class="font16 text_b c-p1">
                                        二维码已失效
                                    </div>
                                    <div class="font16 text_b c-p2" @click="wxLogin()">
                                        <div class="c-span">点击刷新</div>
                                    </div>
                                </div>

                            </div>
                            <div class="c-text font16">
                                微信扫码关注“元数科技 Meta”即可快速登录
                            </div>
                        </div>


                    </div>

                </div>

            </div>

        </div>

    </div>
</template>

<script>
import $ from 'jquery';
import './assets/css/head.css';
import hostUrl from '@/util/baseUrl';

export default {
    name: "App",
    components: {},
    props: {
    },
    // metaInfo() {
    //     return {
    //         title: this.seoAll_name, //设置页面title
    //         meta: [
    //             {                 //设置页面meta
    //                 name: 'keywords',
    //                 content: this.setting.seoKeyword
    //             },
    //             {
    //                 name: "description",
    //                 content: this.setting.seoDesc,
    //             },
    //             {
    //                 name: "renderer",
    //                 content: 'webkit',
    //             },
    //             {
    //                 name: "force-rendering",
    //                 content: 'webkit',
    //             },
    //             {
    //                 name: "format-detection",
    //                 content: 'telephone=no,telephone=no,email=no,adress=no',
    //             },
    //             {
    //                 name: "viewport",
    //                 content: 'width=device-width,user-scalable=no,initial-scale=1.0,maximum-scale=1.0,minimum-scale=1.0',
    //             },
    //         ]
    //     }
    // },
    data() {
        return {
            // key:Date.now()
            headShow: true,
            crumbsTop2: '',
            switchFlag: false, //移动端右上角下拉
            // 屏幕宽度
            wW: '',
            searchFlag: false, //头部搜索按钮
            search: '', //搜索参数
            // 登录弹窗
            lgCountNum: 60, //验证码
            lgCountFlag: false,
            loginFlag: true, //微信扫码和手机登录
            loginHideShow: false,
            login_tel: '',
            login_code: '',
            login_term: false,
            login_state: false, //登录状态
            lgVerify: '',
            userName: '', //用户名称
            // 微信登录信息
            wxHost: 'https://mp.weixin.qq.com/cgi-bin/showqrcode?ticket=',
            wxTicket: '',
            qrcodeData: '', //微信二维码
            contactFlag: false, //留言页面显示隐藏底部banner
            wxCodeOn: false, //微信二维码过期则为真
            lgTimers: '', //微信二维码计时器
            lgWay: '', //登录方式
            // 接口数据
            hostUrl: hostUrl,
            header: [],
            footer: [],
            setting: {},
            receive: [],
            hotSearch: [],
            friLinks:[], //底部友情链接
            // seo信息
            // seoAll: {
            //     name:'',
            //     seoKeyword: '',
            //     seoDesc: ''
            // },
            seoAll_name: '',
            seoAll_seoKeyword: '',
            seoAll_seoDesc: '',
        }
    },
    created() {
        // this.$http.get("/api1").then((res) => {
        //   console.log(res);
        // });
        // this.$bus.on("headShow", value => {
        //     this.headShow = value
        // });

        this.$bus.on("crumbsTop", value => {
            this.crumbsTop2 = value
        });

        this.$bus.on("loginHideShow", value => {
            this.loginHideShow = value
        });

        this.syGetData()

    },
    mounted() {
        // this.fixHeadShow()
        // this.reload()
        // this.key=Date.now()    

        //         var ifrm=obj.contentWindow.document.body;
        //   ifrm.style.cssText="margin:0px;padding:0px;overflow:hidden";
        //   var div=document.createElement("img");
        //   div.src=obj.src;
        //   obj.height=div.height;
        //   obj.width=div.width;

        this.login_info()

        // lgWay是登录方式
        var lgInfo = JSON.parse(localStorage.getItem('yuanshu_info'))
        if (lgInfo != null) {
            this.lgWay = lgInfo.lgWay
        }

        // this.$bus.off("headShow");
        // this.$bus.on("headShow", value => {
        //     this.headShow = value
        // });

        this.$bus.$off("crumbsTop");
        this.$bus.on("crumbsTop", value => {
            this.crumbsTop2 = value
        });

        this.$bus.$off("loginHideShow");
        this.$bus.on("loginHideShow", value => {
            this.loginHideShow = value
        });



        this.headShow = true

        this.wW = document.documentElement.clientWidth || document.body.clientWidth //获取浏览器宽度


        // 返回顶部
        $('.goTop').click(function () {
            $("html,body").animate({ scrollTop: 0 }, 500);
        })

        // wowjs
        this.$nextTick(() => {
            new this.$wow.WOW({
                live: false,
            }).init()
        })
        
        window.addEventListener('scroll', this.handleScroll2)

        if (this.$route.path.indexOf('contact') > 0 || this.$route.path.indexOf('privacy')>0 || this.$route.path.indexOf('server')>0) {
            this.contactFlag = true
        } else {
            this.contactFlag = false
        }

    },
    updated() {

    },
    beforeRouteUpdate() {

    },
    watch: {
        $route: {
            // 监听路由变化
            handler: function (val, oldVal) {
                // setTimeout(function () {
                // this.$bus.$off("headShow");
                // this.$bus.on("headShow", value => {
                //     this.headShow = value
                // });
                // }, 50)

                // if(this.$route.path=='customer'){
                //      this.headShow=true
                // }

                this.$bus.$off("crumbsTop");
                this.$bus.on("crumbsTop", value => {
                    this.crumbsTop2 = value
                });

                this.headShow = true

                if (this.$route.path.indexOf('contact') > 0 || this.$route.path.indexOf('privacy')>0 || this.$route.path.indexOf('server')>0) {
                    this.contactFlag = true
                } else {
                    this.contactFlag = false
                }
                

            },
            // console.log(to.path,from.path)

            // if(to.path==='/customer'){
            //     console.log(1)
            //    this.headShow=true
            //    console.log(this.headShow)
            // }
            // if(window.location.href.indexOf("customer") >= 0){
            //     this.headShow=true
            //     console.log('测试')
            // }

            deep: true
        }
    },
    computed: {
        // fixHeadShow(){
        //     if(this.$route.path=='customer'){
        //         console.log(this.$route.path)
        //         return this.headShow==true?'Yes':'No'

        //     }

        // },

    },
    methods: {
        toast(x) {
            this.$toast.show('页面建设中，请耐心等待…', '您可通过电话或微信扫描底部二维码联系我们', 5000)
            if (x == 'c') {
                this.switchFlag = !this.switchFlag
            }
        },
        // 移动端下拉按钮
        switchFn(param) {
            if (param != 'not') {
                this.switchFlag = !this.switchFlag
            }
            if (param == 'lg') {
                this.loginHideShow = !this.loginHideShow
            }
        },
        // 在线咨询弹窗--目前未使用
        onlineAsk() {
            var nb_invite_ok = document.getElementById('nb_invite_ok')
            nb_invite_ok.click();
        },
        // 登录验证码
        lgGetCode() {

            var t = /^((13|14|15|16|17|18|19)+\d{9})$/;
            if (this.login_tel == '') {
                this.$toast.show('手机号不能为空', '', 3000)
                return
            }
            if (!t.test(this.login_tel)) {
                this.$toast.show('手机号不正确', '', 3000)
                return
            }
            this.lgCountFlag = !this.lgCountFlag
            this.countDown2()
            this.$axios.get('/api/send_verify', {
                params: {
                    mobile: this.login_tel,
                }
            }).then(res => {
                this.lgVerify = res.data.msg
                this.$toast.show('验证码已发送', '', 3000)
                // console.log(this.lgVerify, 'code')
            }).catch(error => {
                this.$toast.show('请求发送超时,请刷新页面重试', '', 3000)
            })
        },
        // 倒计时
        countDown2() {
            this.lgCountNum = 60

            var timers = setInterval(() => {
                this.lgCountNum -= 1
                if (this.lgCountNum == 0) {
                    clearInterval(timers)
                    this.lgCountFlag = !this.lgCountFlag
                }
            }, 1000)
        },
        // 头部登录按钮
        loginBtn(param) {
            if (param == 'wap') {
                this.switchFlag = !this.switchFlag //如果是移动端则收起下拉导航
            }

            this.loginHideShow = !this.loginHideShow
            this.wxLogin()
        },
        // 微信二维码登录
        wxLogin() {
            this.wxCodeOn = false
            this.$axios.get('/wechat/login_qrcode').then(res => {
                console.log(res.data.code)
                if (res.data.code == 200) {
                    this.wxTicket = res.data.ticket.ticket // 获取微信二维码

                    let wxLgCountNum = 60

                    this.lgTimers = setInterval(() => {
                        wxLgCountNum -= 1
                        if (wxLgCountNum == 0) {
                            clearInterval(this.lgTimers)
                            this.wxCodeOn = true
                            return
                        }
                    }, 5000)

                        this.$axios.get('/api/qr_login', {
                            params: {
                                ticket: this.wxTicket,
                            }
                        }).then(res => {
                            if (res.data.code == 200) {

                                clearInterval(this.lgTimers)
                                this.wxCodeOn = true

                                var lgData = {
                                    login_state: true,
                                    accessToken: res.data.data.accessToken,
                                    lgWay: 2, //1是手机登录，2是微信登录
                                }

                                localStorage.setItem('yuanshu_info', JSON.stringify(lgData))

                                // 登录成功提示
                                this.login_state = true
                                var that = this
                                setTimeout(() => {
                                    that.loginHideShow = !that.loginHideShow
                                    this.$router.go(0)
                                }, 1000)

                            } else if (res.data.code == 500) {
                                this.wxCodeOn = true
                                this.$toast.show('二维码已失效,请重新获取', '', 3000)
                                clearInterval(this.lgTimers)
                            } else {
                                this.$toast.show('请求失败,请刷新页面重试', '', 3000)
                                clearInterval(this.lgTimers)
                            }

                        })

                    

                } else {
                    this.$toast.show('未获取到二维码,请刷新页面重试', '', 3000)
                }
            })
        },
        loginClose() {
            this.loginHideShow = !this.loginHideShow
            clearInterval(this.lgTimers)
        },
        // 登录切换
        loginNav(index) {
            this.loginFlag = !this.loginFlag
        },
        // 手机登录提交
        loginFn() {
            var t = /^((13|14|15|16|17|18|19)+\d{9})$/;
            if (this.login_tel == '') {
                this.$toast.show('手机号不能为空', '', 3000)
                return
            }
            if (!t.test(this.login_tel)) {
                this.$toast.show('手机号不正确', '', 3000)
                return
            }
            if (this.login_code == '') {
                this.$toast.show('验证码不能为空', '', 3000)
                return
            }
            if (this.lgVerify == '') {
                this.$toast.show('请先获取验证码', '', 3000)
                return
            }
            if (this.login_term == false) {
                this.$toast.show('请同意隐私政策和用户服务协议', '', 3000)
                return
            }

            var data = {
                mobile: this.login_tel,
                code: this.login_code,
                verify: this.lgVerify,
            }
            this.$axios.post('/api/login',
                data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                }
            }).then(res => {
                console.log(res.data, 'login')
                if (res.data.code == 200) {
                    this.formTips = true
                    this.login_tel = this.login_code = this.lgVerify = ''

                    var lgData = {
                        login_state: true,
                        accessToken: res.data.data.accessToken,
                        mobile: res.data.data.mobile,
                        lgWay: 1, //1是手机登录，2是微信登录
                    }

                    localStorage.setItem('yuanshu_info', JSON.stringify(lgData))

                    // 登录成功提示
                    this.login_state = true
                    var that = this
                    setTimeout(() => {
                        that.loginHideShow = !that.loginHideShow
                        this.$router.go(0)
                    }, 1000)
                } else if (res.data.code == 500) {
                    this.$toast.show('验证码不正确', '', 3000)
                } else {
                    this.$toast.show('提交失败,请刷新页面重试', '', 3000)
                }

            })



        },
        // 刷新页面
        refresh() {
            // this.$router.go(0)
        },
        // 退出登录
        logout() {
            this.login_state = !this.login_state
            localStorage.removeItem('yuanshu_info')

            this.$toast.show('退出登录成功', '', 3000)
            this.$router.go(0)
            if (this.wW < 770) {
                this.switchFlag = !this.switchFlag
            }
        },
        // 头部搜索
        searchFn() {
            if (this.search == '') {
                this.$toast.show('请输入关键字', '', 3000)
                return
            }

            this.$router.push({ name: 'search', query: { text: this.search } })
            // this.$router.go(0)
            if (this.wW > 770) {
                this.searchFlag = !this.searchFlag
            } else {
                this.switchFlag = !this.switchFlag
            }
        },
        handleScroll2() {
            let scrollTop2 = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
            // console.log(scrollTop,this.crumbsTop)
            var that = this
            // 二级导航浮动
            if (this.$route.path.indexOf('case') > 0 || this.$route.path.indexOf('about') > 0 || this.$route.path.indexOf('down') > 0 || (this.$route.path.indexOf('news') > 0 && this.$route.path.indexOf('newsdetail') < 0) || this.$route.path.indexOf('team') > 0 || this.$route.path.indexOf('trends') > 0 || this.$route.path.indexOf('brand') > 0) {
                // console.log(1)
                if (scrollTop2 >= that.crumbsTop2) {
                    setTimeout(function () {
                        that.headShow = false
                    }, 50)
                } else {
                    setTimeout(function () {
                        that.headShow = true
                    }, 50)
                }


            }
        },
        // 请求后端接口
        syGetData() {
            this.header = []
            this.footer = []
            this.setting = {}
            this.receive = []
            this.friLinks=[]
            this.hotSearch = []

            this.$axios.get('/api/public/').then(res => {

                this.header = res.data.data.header
                this.footer = res.data.data.footer
                this.setting = res.data.data.setting
                this.receive.title = this.setting.receive[0].title
                this.receive.image = this.setting.receive[0].image
                this.receive.name = this.setting.receive[0].name
                this.receive.icon = this.setting.receive[0].icon
                this.receive.link = this.setting.receive[0].link
                this.friLinks=res.data.data.links
                this.hotSearch = res.data.data.keyword.popular

                console.log(this.header,this.setting,'header')

                // seo全局参数传值
                this.$bus.emit("seoInfoTitle", this.setting.name)
                this.$bus.emit("seoInfoKeyWords", this.setting.seoKeyword)
                this.$bus.emit("seoInfoSeoDesc", this.setting.seoDesc)

                var ysSeoData = {
                    title:this.setting.name,
                    keyWords:this.setting.seoKeyword,
                    description:this.setting.seoDesc
                }
                localStorage.setItem('yuanshu_seoInfo', JSON.stringify(ysSeoData))

                var yuanshu_email = res.data.data.setting.email
                localStorage.setItem('yuanshu_email', yuanshu_email)

                this.$nextTick(() => {
                    // pc端产品&解决方案显示隐藏
                    $('#c-header .c-nav li').mouseenter(function () {
                        // $(this).find('.drop-downBox,.drop-downBox2').stop().slideDown();
                        setTimeout(() => {
                            $(this).find('.drop-downBox,.drop-downBox2').stop().fadeIn();
                        }, 50)

                    })

                    $('#c-header .c-nav li').mouseleave(function () {
                        // $(this).find('.drop-downBox,.drop-downBox2').stop().slideUp();
                        setTimeout(() => {
                            $(this).find('.drop-downBox,.drop-downBox2').stop().fadeOut();
                        }, 50)

                    })

                    // 搜索框下拉显示隐藏
                    var that = this
                    // $('#c-header .c-gn form').click(function () {
                    //     console.log(that.searchFlag)
                    //     if (that.searchFlag == false) {
                    //         $('.drop-searchBox,.public-mask').stop().fadeIn();
                    //         that.searchFlag = true
                    //     } else {
                    //         $('.drop-searchBox,.public-mask').stop().fadeOut();
                    //         that.searchFlag = false
                    //     }
                    // })

                    $('#c-header .c-nav > li > .c-title-box,.drop-downBox .d-left .da2-a,.drop-downBox .d-right .dul2 .dscroll .dli2 .da2-a,.drop-downBox2 .dli .da2-a').click(function () {
                        $('.drop-downBox,.drop-downBox2').stop().fadeOut(100);
                    })


                    // 移动端导航下拉
                    $('#c-header .c-nav2 li .c-title-box').click(function () {
                        $(this).next().stop().slideToggle();
                        $(this).toggleClass("on");
                    })

                })



            })
        },
        // 登录状态信息
        login_info() {
            var lgInfo = JSON.parse(localStorage.getItem('yuanshu_info'))
            // console.log(lgInfo, 'test')
            if (lgInfo != null) {
                if (lgInfo.lgWay != 0) {
                    this.login_state = lgInfo.login_state
                }
                if (lgInfo.lgWay == 1) {
                    this.userName = lgInfo.mobile
                }
                if (lgInfo.lgWay == 2) {
                    this.userName = ''
                }
            }
        },
        // 搜索显示隐藏
        searchHideShow() {
            this.searchFlag = !this.searchFlag
        },
        // 判断链接是不是外链
        isExternal(x) {
            return /^(https?:|mailto:|tel:)/.test(x);
        },
        // 回车搜索
        enterSearch() {
            this.searchFn()
        },
        privacyServer(){
            if(this.wW<770){
                this.loginHideShow = !this.loginHideShow
            }
        }
    },
};
</script>

<style scoped lang="less"></style>
